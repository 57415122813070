import { z } from 'zod';

interface alertDefinitionControlNamesType {
  operator: 'operator';
  amount: 'amount';
  threshold: 'threshold';
  user: 'user';
}

export const alertDefinitionSchema = z.object({
  operator: z.string().min(1, 'Required'),
  amount: z.union([
    z.number().min(1, 'Required'),
    z.string().min(1, 'Required'),
  ]),
  threshold: z.string().min(1, 'Required'),
  user: z.union([z.number().min(1, 'Required'), z.string().min(1, 'Required')]),
});

export const alertDefinitionControlNames: alertDefinitionControlNamesType = {
  operator: 'operator',
  amount: 'amount',
  threshold: 'threshold',
  user: 'user',
};

export type alertDefinitionSchemaType = z.infer<typeof alertDefinitionSchema>;
