import { ConfirmModal } from '@components/confirmModal';
import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import {
  useDeleteLuzmoDashboard,
  useFetchLuzmoReportsList,
} from 'api/sysadmin/sysAdminServices';
import useDisclosure from 'hooks/useDisclosure';
import { t } from 'i18next';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useGetLuzmoReportsTableColumn } from '../hooks';
import { EditLuzmoReportModal } from '../modal';

const LuzmoTable: FC = () => {
  const [selectedLuzmo, setSelectedLuzmo] = useState<number | undefined>();
  const {
    isOpen: isEditOpen,
    handleOpen: handleEditOpen,
    handleClose: handleEditClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose,
  } = useDisclosure();

  const {
    mutate: deleteLuzmo,
    isPending: isDeleteLuzmoPending,
    isSuccess: isDeleteLuzmoSuccess,
  } = useDeleteLuzmoDashboard();

  const {
    data: luzmoReportList,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchLuzmoReportsList();

  const flatData: UserResponse[] = useMemo(
    () =>
      luzmoReportList?.pages?.flatMap((page: any) => page?.luzmo_dashboards) ??
      [],
    [luzmoReportList]
  );

  const totalDBRowCount = luzmoReportList?.pages?.[0]?.total ?? 0;

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.id;
    const actions = [
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setSelectedLuzmo(id);
          handleEditOpen();
        },
      },
      {
        name: t('common.actions.delete'),
        onClick: () => {
          setSelectedLuzmo(id);
          handleDeleteOpen();
        },
      },
    ];
    return <TableActions key={`action-${id}`} actions={actions} />;
  };

  const { columns } = useGetLuzmoReportsTableColumn({ createTableActions });

  const handleDeleteLuzmo = (): void => {
    deleteLuzmo(selectedLuzmo);
  };

  useEffect(() => {
    if (isDeleteLuzmoSuccess) {
      handleDeleteClose();
    }
  }, [isDeleteLuzmoSuccess]);

  return (
    <>
      <Table
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        data={flatData}
        columns={columns}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        rowEstimateSize={75}
      />
      <EditLuzmoReportModal
        isOpen={isEditOpen}
        handleClose={handleEditClose}
        selectedLuzmo={selectedLuzmo}
      />
      <ConfirmModal
        contentText="Are you sure you want to delete this Luzmo dashboard? This action can't be undone and all information will be lost."
        isOpen={isDeleteOpen}
        handleClose={handleDeleteClose}
        onConfirm={handleDeleteLuzmo}
        isLoading={isDeleteLuzmoPending}
      />
    </>
  );
};
export default LuzmoTable;
