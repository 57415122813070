import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import { OrganizationalSavingsChart } from '../../charts/OrganizationalSavingsChart';

const OrganizationalSavingsContainer: FC = () => {
  const mockedPayload = [
    {
      value: 10,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 1',
      organizationalImpact: '5%',
      plannedSavings: '1%',
    },
    {
      value: 15,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 2,
      name: 'Building 2',
      organizationalImpact: '7%',
      plannedSavings: '2%',
    },
    {
      value: 20,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 3,
      name: 'Building 3',
      organizationalImpact: '10%',
      plannedSavings: '3%',
    },
    {
      value: 25,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 4,
      name: 'Building 4',
      organizationalImpact: '10%',
      plannedSavings: '4%',
    },
    {
      value: 10,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 5',
      organizationalImpact: '5%',
      plannedSavings: '1%',
    },
    {
      value: 15,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 2,
      name: 'Building 6',
      organizationalImpact: '7%',
      plannedSavings: '2%',
    },
    {
      value: 20,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 3,
      name: 'Building 7',
      organizationalImpact: '10%',
      plannedSavings: '3%',
    },
    {
      value: 25,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 2,
      name: 'Building 8',
      organizationalImpact: '10%',
      plannedSavings: '2%',
    },
    {
      value: 10,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 9',
      organizationalImpact: '5%',
      plannedSavings: '1%',
    },
    {
      value: 15,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 0,
      name: 'Building 10',
      organizationalImpact: '7%',
      plannedSavings: '2%',
    },
    {
      value: 20,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 11',
      organizationalImpact: '5%',
      plannedSavings: '1%',
    },
    {
      value: 25,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 15,
      name: 'Building 12',
      organizationalImpact: '7%',
      plannedSavings: '1%',
    },
    {
      value: 5,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 13',
      organizationalImpact: '3%',
      plannedSavings: '1%',
    },
    {
      value: 10,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 2,
      name: 'Building 14',
      organizationalImpact: '5%',
      plannedSavings: '2%',
    },
    {
      value: 15,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 2,
      name: 'Building 15',
      organizationalImpact: '7%',
      plannedSavings: '2%',
    },
    {
      value: 20,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 2,
      name: 'Building 16',
      organizationalImpact: '5%',
      plannedSavings: '2%',
    },
    {
      value: 10,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 17',
      organizationalImpact: '5%',
      plannedSavings: '1%',
    },
    {
      value: 15,
      time: 'Tue, 22 Feb 2023 15:00:00 GMT',
      secondary: 1,
      name: 'Building 18',
      organizationalImpact: '5%',
      plannedSavings: '1%',
    },
  ];
  return (
    <CardContainer gap={6}>
      <h2 className="uppercase text-sm">Organizational Savings</h2>
      <h3 className="text-base">
        All buildings below represent potential and planned savings within the
        organization.
      </h3>
      <OrganizationalSavingsChart data={mockedPayload} />
    </CardContainer>
  );
};
export default OrganizationalSavingsContainer;
