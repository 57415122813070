import axiosInstance from 'api/axios';
import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { handleError } from 'utils/handleError/handleError';
import queryClient from 'lib/queryClient';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { type AxiosError, type AxiosResponse } from 'axios';
import { type ChangePasswordSchemaType } from 'pages/SettingsPage/forms/ChangePasswordForm/schema';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';
import { type UserDetails } from 'interfaces/user/UserDetails.interface';
import { type ListResponse } from 'interfaces/request/ListResponse.interfance';
import { type UserFeatureFlagResponse } from 'interfaces/featureFlags/FeatureFlags.interface';
import { type UserNotificationListResponse } from 'interfaces/user/UserNotification.interface';

export interface NullQueryResponse {
  data: null;
  isFetched: boolean;
  isFetching: boolean;
}
export interface UndefinedMutationResponse {
  mutate: undefined;
  mutateAsync: undefined;
  isLoading: boolean;
}

export interface CompanyUsersListResponse extends ListResponse {
  total_users: number;
  users: UserResponse[];
}
export interface FetchUserResponse {
  user: UserResponse;
}

export interface UpdateUserPayload {
  given_name: string;
  family_name: string;
  id?: string;
  role: string;
  function: string;
  groups?: { id: string | number }[];
  meters?: { id: string | number }[];
  assets?: { id: string | number }[];
}
export interface UpdateUserDetailsPayload {
  given_name: string;
  family_name: string;
  function: string;
  id?: string;
  groups?: { id: string | number }[];
  meters?: { id: string | number }[];
  assets?: { id: string | number }[];
}
export interface UpdateUserRolePayload {
  role: string;
}

export const useCreateUser = (
  companyId?: number | null | undefined
): UseMutationResult => {
  return useMutation({
    mutationFn: async (params) => {
      const response = await axiosInstance.post(`user/`, params);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User invited successfully');
      await queryClient.invalidateQueries({ queryKey: ['usersList'] });
    },
  });
};

export const useFetchUser = (
  id: number | null | undefined | string
): UseQueryResult<FetchUserResponse> => {
  return useQuery<FetchUserResponse>({
    queryKey: ['user', id],
    queryFn: async () => {
      if (!id) return;
      const response = await axiosInstance.get(`user/${id}`);
      return response.data;
    },
  });
};

export const useListNotifications =
  (): UseQueryResult<UserNotificationListResponse> => {
    return useQuery<UserNotificationListResponse>({
      queryKey: ['listNotifications'],
      queryFn: async () => {
        const response = await axiosInstance.get(`notification/`);
        return response.data;
      },
    });
  };

export const useReadAllNotifications = (): UseMutationResult => {
  return useMutation({
    mutationFn: async () => {
      const response = await axiosInstance.patch(`notification/`);
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['listNotifications'] });
    },
  });
};
export const useReadNotification = (): UseMutationResult<
  void,
  Error | AxiosError,
  number
> => {
  return useMutation({
    mutationFn: async (id) => {
      if (!id) return;
      await axiosInstance.patch(`notification/${id}`);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['listNotifications'],
      });
    },
  });
};

export const useFetchUserFeatureMap =
  (): UseQueryResult<UserFeatureFlagResponse> => {
    return useQuery<UserFeatureFlagResponse>({
      queryKey: ['userFeatureFlag'],
      queryFn: async () => {
        const response = await axiosInstance.get(`/user/features`);
        return response.data;
      },
    });
  };

export const useUpdateUserDetails = (
  id: number | string | undefined | null
): UseMutationResult<
  UserDetails | null,
  Error | AxiosError,
  UpdateUserDetailsPayload
> => {
  return useMutation({
    mutationFn: async (params) => {
      if (!id) {
        return null;
      }
      const request = await axiosInstance.patch(`user/details`, params);
      return request?.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User updated successfully');
      await queryClient.invalidateQueries({ queryKey: ['user'] });
      await queryClient.invalidateQueries({ queryKey: ['usersList'] });
    },
  });
};

export const useUpdateUser = (
  id: number | string | undefined | null
): UseMutationResult<
  UserDetails | null,
  Error | AxiosError,
  UpdateUserPayload
> => {
  return useMutation({
    mutationFn: async (params) => {
      if (!id) {
        return null;
      }
      const request = await axiosInstance.patch(`user/${id}`, params);
      return request?.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User updated successfully');
      await queryClient.invalidateQueries({ queryKey: ['user'] });
      await queryClient.invalidateQueries({ queryKey: ['usersList'] });
    },
  });
};

export const useUpdateUserRole = (
  id: number | string | undefined | null
): UseMutationResult<
  UserDetails | null,
  Error | AxiosError,
  UpdateUserRolePayload
> => {
  return useMutation({
    mutationFn: async (params) => {
      if (!id) {
        return null;
      }
      const request = await axiosInstance.patch('user/role', params);
      return request?.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User role updated successfully');
      await queryClient.invalidateQueries({ queryKey: ['user'] });
      await queryClient.invalidateQueries({ queryKey: ['usersList'] });
    },
  });
};

export const useDeleteUser = (): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  string | number,
  unknown
> => {
  return useMutation({
    mutationFn: async (id: string | number) => {
      await axiosInstance.delete(`user/${id}`);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('User deleted succesfully');
      await queryClient.invalidateQueries({ queryKey: ['usersList'] });
    },
  });
};

export const useFetchCompanyUsers = ({
  page = 1,
  perPage = 20,
  query,
  roles,
  functions,
}: {
  page?: number;
  perPage?: number;
  query?: string;
  roles?: string;
  functions?: string;
}): UseInfiniteQueryResult<InfiniteData<CompanyUsersListResponse>> => {
  return useInfiniteQuery<CompanyUsersListResponse>({
    queryKey: ['usersList', query, roles, functions],
    queryFn: async ({ pageParam = 1 }) => {
      const params = {
        page: pageParam ?? page,
        perPage,
        query,
        role: roles,
        function: functions?.replace(/ /g, '_'),
      };

      if (!params?.query) {
        delete params?.query;
      }

      if (!params?.role) {
        delete params?.role;
      }

      if (!params?.function) {
        delete params?.function;
      }
      const response = await axiosInstance.get(`user/list`, {
        params,
      });
      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNextPage) {
        return lastPage.nextPage;
      }
    },
    refetchOnWindowFocus: false,
  });
};

export const changePassword = async ({
  currentPassword,
  newPassword,
  confirmNewPassword,
}: ChangePasswordSchemaType): Promise<AxiosResponse | undefined> => {
  try {
    const response = await axiosInstance.patch(`user/change_password`, {
      current_password: currentPassword,
      new_password: newPassword,
    });
    handleSuccess('Password successfully changed');
    return response;
  } catch (error: any) {
    handleError(error);
  }
};
