import { type FC, forwardRef } from 'react';

import useChart, { parseChartDecimals } from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { getReadableUnitName } from 'utils/formatters/units/getReadableUnitName';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';

interface SavingsCuSUMChartProps {
  id?: string;
  momentChange?: string;
  dataGrouping?: [string, number[] | null][] | undefined;
  unit?: string;
  data?: ChangeTS[] | null;
  changesData?: string[];
  xAxisEvents?: Highcharts.XAxisEventsOptions | undefined;
  savingsTarget?: number;
}

interface SavingsOption {
  x: number;
  y: number;
  impactPerDay: number;
  time: string;
}

const SavingsCuSUMChart: FC<SavingsCuSUMChartProps> = forwardRef(
  ({ unit = '', dataGrouping, data, xAxisEvents, savingsTarget = 0 }, _ref) => {
    const { defaultOptions, labelFormatter } = useChart({
      chartData: data,
      unit: getReadableUnitName(unit),
      type: 'excess_cusum',
    });

    function pointFormatter(this: Highcharts.Point): string {
      const options = this.options as SavingsOption;
      const type = 'Savings CuSUM';

      const timeString = `Time: <b>${convertToReadableDate(
        options.time
      )}</b><br/>`;
      const savingsCuSum = `${type}: <b>${parseChartDecimals(
        this.y ?? 0
      )} ${getReadableUnitName(unit)}</b></br>`;

      return `${timeString}${savingsCuSum}`;
    }

    const { theme } = useTheme();

    const options: Highcharts.Options = {
      ...defaultOptions,
      xAxis: {
        events: {
          ...xAxisEvents,
        },
      },
      yAxis: [
        {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'CuSUM Savings',
          },
        },
      ],
      series: [
        {
          name: 'CuSUM Savings',
          type: 'column',
          color: theme.colors.tertiary,
          data: data?.map((ts, index) => ({
            x: new Date(ts.time).getTime(),
            y: ts.savings_cusum,
            impactPerDay: ts.savings,
            expectedConsumption: ts.result_baseline,
            time: ts.time,
          })),
          dataGrouping: {
            approximation: 'average',
            groupPixelWidth: 10,
            units: [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          tooltip: {
            pointFormatter,
          },
          turboThreshold: data?.length,
          yAxis: 0,
        },
        {
          color: theme.colors.primary,
          type: 'line',
          name: 'Savings Target',
          data: data?.map((ts, index, array) => {
            const cumulativeSum = array
              .slice(0, index + 1)
              .reduce((sum, currentTs) => sum + currentTs.result_baseline, 0);
            return [new Date(ts.time).getTime(), savingsTarget * cumulativeSum];
          }),
          dataGrouping: {
            approximation: 'average',
            groupPixelWidth: 10,
            units: dataGrouping ?? [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          yAxis: 0,
          turboThreshold: data?.length,
          enableMouseTracking: false,
        },
      ],
    };

    return <>{options && <HChart options={options} />}</>;
  }
);
export default SavingsCuSUMChart;
