import { SearchBar } from '@components/inputs/SearchBar';
import { useDebounce } from 'hooks/useDebounce';
import { type FC, useState } from 'react';
import { DemoAccountTable } from './table';
import { useTranslation } from 'react-i18next';
import { Button } from 'layout/Button';
import useDisclosure from 'hooks/useDisclosure';
import { CreateDemoAccountModal } from './modal/CreateDemoAccountModal';

const DemoAccountsPage: FC = () => {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const { isOpen, handleOpen, handleClose } = useDisclosure();

  const debouncedQuery: string = useDebounce(query, 300);
  return (
    <div>
      <div className="flex gap-6 items-center justify-between mb-8">
        <div className="flex-1 max-w-[340px]">
          <SearchBar
            placeholder={t('sysAdminPage.placeholders.search')}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <Button
          label={t('sysAdminPage.demo.addDemoAccount')}
          onClick={handleOpen}
        />
      </div>
      <CreateDemoAccountModal isOpen={isOpen} handleClose={handleClose} />
      <DemoAccountTable query={debouncedQuery} />
    </div>
  );
};
export default DemoAccountsPage;
