import { type FC, forwardRef } from 'react';

import useChart from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { getReadableUnitName } from 'utils/formatters/units/getReadableUnitName';
import layerSvg from 'assets/images/chart-layer.svg';
import { ImpactChartLegendIcon } from '@components/icons/ImpactChartLegendIcon';

interface OrganizationalSavingsChartProps {
  id?: string;
  momentChange?: string;
  dataGrouping?: [string, number[] | null][] | undefined;
  unit?: string;
  data?: any[] | null;
  changesData?: string[];
  xAxisEvents?: Highcharts.XAxisEventsOptions | undefined;
  savingsTarget?: number;
}

interface Organizatinal_savings_Option {
  y: number;
  name: string;
  organizationalImpact: string;
  plannedSavings: string;
}

const OrganizationalSavingsChart: FC<OrganizationalSavingsChartProps> =
  forwardRef(
    (
      { unit = '', dataGrouping, data, xAxisEvents, savingsTarget = 0 },
      _ref
    ) => {
      const { defaultOptions, labelFormatter } = useChart({
        chartData: data,
        unit: getReadableUnitName(unit),
        type: 'excess_cusum',
      });

      function pointFormatter(this: Highcharts.Point): string {
        const options = this.options as Organizatinal_savings_Option;
        const buildingName = `<b>Building name:</b> ${options.name}<br/>`;
        const organizationalImpact = `<b>Organizational impact:</b> ${options.organizationalImpact}<br/>`;
        const plannedSavings = `<b>Planned savings:</b> ${options.plannedSavings}<br/>`;
        const co2intensity = `<b>CO₂ intensity:</b> ${'0.8 tCO₂/m₂'}<br/>`;

        return `${buildingName}${organizationalImpact}${plannedSavings}${co2intensity}`;
      }

      const { theme } = useTheme();

      const options: Highcharts.Options = {
        ...defaultOptions,
        rangeSelector: {
          enabled: false,
        },
        tooltip: {
          formatter(
            this: Highcharts.TooltipFormatterContextObject,
            tooltip: Highcharts.Tooltip
          ): string | string[] {
            tooltip.update({
              backgroundColor: theme.colors['gray-60'],
              style: {
                color: 'white',
                fontSize: '12px',
                fontWeight: '400',
              },
            });

            return tooltip.defaultFormatter.call(this, tooltip);
          },
        },
        xAxis: {
          events: {
            ...xAxisEvents,
          },
        },
        yAxis: {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'CuSUM Savings',
          },
        },

        navigator: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            borderRadius: 0,
            borderWidth: 0,
            innerSize: '70%',
          },
        },

        series: [
          {
            name: 'CuSUM Savings',
            type: 'pie',
            size: '100%',
            data: data?.map((ts, index) => ({
              y: ts.value,
              name: ts.name,
              organizationalImpact: ts.organizationalImpact,
              plannedSavings: ts.plannedSavings,
            })),
            tooltip: {
              pointFormatter,
            },
          },
          {
            name: 'Pattern Overlay',
            type: 'pie',
            className: 'pointer-events-none	bg-no-repeat',
            // enableMouseTracking: false,
            size: '100%', // Slightly larger to overlay
            // innerSize: '65%', // Match the inner size of the donut,
            dataLabels: {
              enabled: false, // Disable data labels for the overlay series
            },
            data: data?.flatMap((ts, index) => [
              {
                y: ts.secondary,

                color: {
                  pattern: {
                    // path: {
                    //   d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    //   stroke: '#00000075',
                    //   //   fill: '#FF0000',
                    //   strokeWidth: 4,
                    //   //   fillRule: 'evenodd',
                    //   //   clipRule: 'evenodd',
                    //   //   transform: `rotate(20)`, // Rotate pattern based on angle
                    // },
                    image: layerSvg,
                    width: 8,
                    height: 400,
                  },
                },
                borderWidth: 0,
                time: ts.time,
              },
              {
                y: ts.value - ts.secondary, // Remaining part of the slice
                color: 'transparent',
                borderWidth: 0,
                legend: false,
              },
              //   {
              //     y: 80,
              //     color: 'transparent', // Full transparent to match the other slice
              //     borderWidth: 0,
              //     legend: false,
              //   },
            ]),
          },
        ],
      };

      return (
        <>
          {options && (
            <div>
              <HChart options={options} />
              <div className="flex justify-between items-center">
                <div className="flex gap-4 items-center">
                  <ImpactChartLegendIcon />
                  <span>Total savings planned</span>
                </div>
                <span className="text-2xl font-bold">22%</span>
              </div>
            </div>
          )}
        </>
      );
    }
  );
export default OrganizationalSavingsChart;
