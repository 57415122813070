import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Handle, { type HandleProps } from 'rc-slider/lib/Handles/Handle';
import { type FC, type ReactElement } from 'react';

export interface SliderComponentProps {
  value?: number;
  initialValue?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}

const SliderComponent: FC<SliderComponentProps> = ({
  onChange,
  initialValue,
  value,
  disabled = false,
}) => {
  const { theme } = useTheme();

  const CustomHandle = ({ props }: { props: HandleProps }): ReactElement => {
    return (
      <Handle {...props}>
        <span className="text-gray-45 font-bold text-base relative -top-[35px] transform -translate-x-1/2 -left-1/2">
          {value ? Math.trunc(value) : 0}%
        </span>
      </Handle>
    );
  };

  return (
    <div className="pb-6 pt-8 px-3">
      <Slider
        value={value}
        onChange={(currentValue) => {
          onChange(currentValue as number);
        }}
        handleRender={CustomHandle}
        range={false}
        min={0}
        max={100}
        dotStyle={{ display: 'none' }}
        activeDotStyle={{ display: 'none' }}
        disabled={disabled}
        marks={{
          0: {
            label: '0',
            style: {
              fontSize: '16px',
              color: theme.colors['gray-60'],
              marginTop: '12px',
            },
          },
          100: {
            label: '100',
            style: {
              fontSize: '16px',
              color: theme.colors['gray-60'],
              marginTop: '12px',
            },
          },
        }}
        styles={{
          rail: { backgroundColor: theme.colors['gray-38'], height: '8px' },
          track: {
            backgroundColor: theme.colors['tertiary-dark'],
            height: '8px',
          },
          handle: {
            backgroundColor: theme.colors.tertiary,
            opacity: 1,
            borderColor: theme.colors['tertiary-lighter'],
            borderWidth: '4px',
            width: '20px',
            height: '20px',
            marginTop: '-6px',
          },
        }}
      />
    </div>
  );
};
export default SliderComponent;
