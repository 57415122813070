import { type FC, type ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

const AccordionSection: FC<{
  title: string;
  children: ReactNode;
  isLoading?: boolean;
}> = ({ title, children, isLoading }) => {
  return (
    <div className="flex flex-col gap-4">
      {isLoading ? (
        <Skeleton containerClassName="flex-1 max-w-[200px]" height={'20px'} />
      ) : (
        <p className="font-bold text-base text-gray-60">
          {title.toUpperCase()}
        </p>
      )}
      {isLoading ? <Skeleton height={'50px'} borderRadius="8px" /> : children}
    </div>
  );
};

export default AccordionSection;
