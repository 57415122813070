import { z } from 'zod';

export interface MeterOption {
  name: string;
  value: string | number;
}

interface Address {
  city: string;
  country_iso: string;
  number: string;
  street: string;
  zip_code: string;
  province?: string;
}

export interface AssetFormData {
  address: Address;
  function: string;
  name: string;
  surface_area?: string;
  type: string;
  meters: MeterOption[];
}

interface manageAssetSchemaType {
  name: 'name';
  function: 'function';
  type: 'type';
  meters: 'meters';
  surface_area: 'surface_area';
  address: {
    country_iso: 'address.country_iso';
    zip_code: 'address.zip_code';
    street: 'address.street';
    number: 'address.number';
    city: 'address.city';
    province: 'address.province';
  };
}

export const manageAssetSchema = z.object({
  type: z.string().nonempty({
    message: "Can't be empty",
  }),
  name: z.string().trim().min(2),
  surface_area: z.number().optional(),
  function: z.string().nonempty({
    message: "Can't be empty",
  }),
  meters: z
    .array(
      z.object({ name: z.string(), value: z.union([z.string(), z.number()]) })
    )
    .min(1),
  address: z.object({
    country_iso: z.string(),
    zip_code: z.string().min(4, 'Required'),
    street: z.string().min(3, 'Required'),
    number: z.string().min(1, 'Required'),
    city: z.string().min(3, 'Required'),
    province: z.string().optional(),
  }),
});

export const manageAssetControlNames: manageAssetSchemaType = {
  name: 'name',
  function: 'function',
  type: 'type',
  meters: 'meters',
  surface_area: 'surface_area',
  address: {
    country_iso: 'address.country_iso',
    zip_code: 'address.zip_code',
    street: 'address.street',
    number: 'address.number',
    city: 'address.city',
    province: 'address.province',
  },
};

export type ManageAssetSchemaType = z.infer<typeof manageAssetSchema>;
