import { type FC } from 'react';
import { ClipLoader } from 'react-spinners';

interface LoadingSpinnerProps {
  size?: number;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ size = 20 }) => {
  return (
    <div className="flex justify-center">
      <ClipLoader color="#522B89" size={size} data-testid="loading-spinner" />
    </div>
  );
};
export default LoadingSpinner;
