import { useGetShortOrganizationImpactSummary } from 'api/impact/impactServices';
import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { convertToPercentageParsedString } from 'utils/formatters/number/numberUtils';

const OrganizationImpactSummary: FC = () => {
  const { data, isFetching } = useGetShortOrganizationImpactSummary();
  const { t } = useTranslation();
  console.log('data', data?.planned_savings);
  return (
    <CardContainer gap={6}>
      <div className="flex flex-col gap-2">
        <h2 className="uppercase">{t('impactPage.shortSummary.title')}</h2>
        <h3>{t('impactPage.shortSummary.subTitle')}</h3>
      </div>
      <div className="flex gap-6">
        <OrganizationContainer
          label={'Organizational target in %'}
          value={data?.organization_impact ?? 0}
          isLoading={isFetching}
        />
        <OrganizationContainer
          label={'Planned savings'}
          value={data?.planned_savings ?? 0}
          isLoading={isFetching}
        />
      </div>
    </CardContainer>
  );
};

const OrganizationContainer: FC<{
  label: string;
  value: number;
  isLoading: boolean;
}> = ({ label, value, isLoading }) => {
  return (
    <div className="flex p-4 bg-gray-10 flex-1 rounded-lg justify-between items-center">
      {isLoading ? (
        <Skeleton width={190} height={24} />
      ) : (
        <span className="text-base">{label}</span>
      )}
      {isLoading ? (
        <Skeleton width={50} height={29} />
      ) : (
        <span className="bold text-2xl">
          {convertToPercentageParsedString(value)}
        </span>
      )}
    </div>
  );
};
export default OrganizationImpactSummary;
