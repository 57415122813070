import styled from 'styled-components';
import tw from 'twin.macro';

interface StyledInputProps {
  $disabled?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  ${tw`flex flex-1 bg-inherit`}
`;
