import styled from 'styled-components';
import tw from 'twin.macro';

interface StyledSideMenuProps {
  $isExpanded: boolean;
}

export const StyledSideMenu = styled.div<StyledSideMenuProps>`
  transition: all 0.2s cubic-bezier(0, -0.44, 0.03, 1);
  ${tw`bg-menu-background h-screen shadow-side-menu flex flex-col overflow-hidden fixed top-0 z-40`}
  ${(props) => (props.$isExpanded ? tw`w-[260px] px-2` : tw`w-[88px]`)}
`;
