import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { type FC, type PropsWithChildren } from 'react';

interface PermissionWrapperProps extends PropsWithChildren {
  validate: {
    endpoint: string;
    method: string;
  };
}

const PermissionWrapper: FC<PermissionWrapperProps> = ({
  validate,
  children,
}) => {
  const { hasFeatureFlagPermission } = useFeatureFlag();

  return <div>{hasFeatureFlagPermission(validate) ? children : <></>}</div>;
};
export default PermissionWrapper;
