import { type FC, type Ref } from 'react';
import { NumericFormat, type NumericFormatProps } from 'react-number-format';
import { addInputErrorClasses } from 'utils/handleFormErrorStyle';
import { FErrorLabel } from '../../form/FErrorLabel';
import InputAddorment, {
  type InputAddormentProps,
} from '../InputAddornment/InputAddorment';

export interface NumericInputProps
  extends Omit<NumericFormatProps, 'onChange'>,
    InputAddormentProps {
  ref?: ((el: HTMLInputElement) => void) | Ref<any> | undefined;
  onChangeValue?: (values?: number | undefined) => void;
  prefix?: string;
  onChange?: (value: number) => void;
}

const NumericInput: FC<NumericInputProps> = ({
  ref,
  addorment,
  onChangeValue,
  error,
  thousandSeparator = '.',
  decimalSeparator = ',',
  decimalScale = 2,
  onChange,
  ...props
}) => {
  return (
    <div className="flex-1 bg-white">
      <InputAddorment error={error} addorment={addorment}>
        <NumericFormat
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          className={`w-full ${addInputErrorClasses(error)}`}
          displayType="input"
          fixedDecimalScale={false}
          onValueChange={(values) => {
            if (values.floatValue) {
              onChangeValue?.(values.floatValue);
              onChange?.(values.floatValue);
            }
          }}
          getInputRef={ref}
          {...props}
        />
      </InputAddorment>
      <FErrorLabel message={error?.message} />
    </div>
  );
};
export default NumericInput;
