import mixpanel from 'mixpanel-browser';
mixpanel.init(
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ??
    '10cd17c9672b1c336d2bb514765a40b4',
  {
    track_pageview: true,
  }
);

const isImpersonated = (): boolean => {
  const parseJwt = (token: string): any => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  const token = localStorage.getItem('jwtToken');
  if (token) {
    const payload = parseJwt(token);

    if (payload?.identity?.is_impersonated) {
      return true;
    }
  }

  return false;
};

const actions = {
  identify: (id: string) => {
    if (isImpersonated()) return;
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (isImpersonated()) return;
    mixpanel.alias(id);
  },
  pageview: (
    name: string = 'page_view',
    props: object = { page: location.pathname }
  ) => {
    if (isImpersonated()) return;
    mixpanel.track(name, props);
  },
  track: (name: string, props: object = {}) => {
    if (isImpersonated()) return;
    mixpanel.track(name, props);
  },
  register: (props: object) => {
    if (isImpersonated()) return;
    mixpanel.register(props);
  },
  set_group: (key: string, value: string) => {
    mixpanel.set_group(key, value);
  },
  people: {
    set: (props: object) => {
      mixpanel.people.set(props);
    },
  },
  logout: () => {
    mixpanel.reset();
  },
};

export const Mixpanel = actions;
