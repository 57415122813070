import axios, { type AxiosRequestConfig } from 'axios';
import { clearStorage, navigateToLoginPage } from 'utils/auth/authUtils';
import { handleError } from 'utils/handleError/handleError';
import { logout, refreshToken } from './auth/authService';

export interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipAuthorizationInterceptor?: boolean;
}

let refreshTokenPromise: Promise<any> | null = null;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    if (!(config as CustomAxiosRequestConfig).skipAuthorizationInterceptor) {
      if (refreshTokenPromise) {
        await refreshTokenPromise;
      }

      const token = localStorage.getItem('jwtToken');

      if (token) {
        config.headers.Authorization = `${token}`;
      }
    }

    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      if (originalRequest.url === 'auth/refresh') {
        clearStorage();
        navigateToLoginPage();
      }

      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshToken();

        const responseToken = await refreshTokenPromise.then((req) => {
          if (req?.token) {
            return req.token;
          }
          return null;
        });

        if (responseToken) {
          localStorage.setItem('jwtToken', responseToken);
        }
      }

      await refreshTokenPromise;
      const token = localStorage.getItem('jwtToken');
      if (!token) {
        await logout();
        return;
      }
      originalRequest.headers.Authorization = `${token}`;
      try {
        if (token !== 'undefined') {
          return await axios.request(originalRequest);
        }
      } catch (innerError) {
        handleError(innerError as Error);
      }
    }
    refreshTokenPromise = null;
    // await Promise.reject(new Error(error));
    await Promise.reject(error);
  }
);

export default axiosInstance;
