import { WarningIcon } from '@components/icons/WarningIcon';
import useDisclosure from 'hooks/useDisclosure';
import { Button } from 'layout/Button';
import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import { CreateLuzmoReportModal, HowToIntegrateModal } from './modal';
import { LuzmoTable } from './table';

const LuzmoDashboardPage: FC = () => {
  const { isOpen, handleClose, handleOpen } = useDisclosure();
  const {
    isOpen: howToIntegrateIsOpen,
    handleClose: howToIntegrateHandleClose,
    handleOpen: howToIntegrateHandleOpen,
  } = useDisclosure();

  return (
    <div className="flex flex-col gap-6">
      <div>
        <div className="flex justify-end mb-4">
          <Button label="Add new dashboard" onClick={handleOpen} />
        </div>
        <LuzmoTable />
        <CreateLuzmoReportModal isOpen={isOpen} handleClose={handleClose} />
      </div>
      <CardContainer>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <WarningIcon />
            <p className="text-base">
              {
                'This is an integration with a third-party platform. To create a dashboard, you must first follow a step-by-step process.'
              }
            </p>
          </div>

          <Button
            label="How to integrate"
            variant="secondary"
            onClick={howToIntegrateHandleOpen}
          />
          <HowToIntegrateModal
            isOpen={howToIntegrateIsOpen}
            handleClose={howToIntegrateHandleClose}
          />
        </div>
      </CardContainer>
    </div>
  );
};
export default LuzmoDashboardPage;
