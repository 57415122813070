import { type FC, useEffect, useState } from 'react';

export interface ProgressBarComponentProps {
  completed: number;
  maxCompleted: number;
  maxWidth?: number;
}

const getColorByPercentage = (percentage: number): string => {
  // Define the gradient colors
  const colors = [
    '#ED4145',
    '#EE503D',
    '#F05A35',
    '#F2642D',
    '#F46E25',
    '#F4D528',
    '#D3C830',
    '#B2BA39',
    '#91C241',
    '#70CA4A',
    '#8ED234',
  ];

  // Calculate the index based on the percentage
  const index = Math.floor(percentage / 10);

  // Return the corresponding color
  return colors[index];
};

function getCurrentPercentage(currentValue: number, maxValue: number): number {
  // Calculate the percentage of the current value relative to the maximum value
  const percentage = (currentValue / maxValue) * 100;

  // Ensure percentage stays within the 0-100 range
  return Math.max(0, Math.min(100, percentage));
}

const ProgressBarComponent: FC<ProgressBarComponentProps> = ({
  completed,
  maxCompleted,
  maxWidth,
}) => {
  const [positiveCompleted, setPositiveCompleted] = useState(0);
  const [negativeCompleted, setNegativeCompleted] = useState(0);

  const colorPercentage = getCurrentPercentage(completed, maxCompleted);

  useEffect(() => {
    if (Math.sign(completed) === 1) {
      setPositiveCompleted(colorPercentage);
    } else {
      setNegativeCompleted(Math.abs(completed));
    }
  }, [maxCompleted, completed]);
  return (
    <div className="w-full" style={{ maxWidth, minWidth: 100 }}>
      <div className="flex w-full rounded-full bg-gray-25 h-[8px] relative">
        {/* Negative progress bar */}
        <div className="w-1/4">
          <div
            className={`h-full bg-error rounded-l-full float-right`}
            style={{ width: `${negativeCompleted}%` }}
          />
        </div>
        {/* Positive progress bar */}
        <div className="w-3/4">
          <div
            className={`h-full rounded-[inherit] rounded-r-full`}
            style={{
              width: `${positiveCompleted}%`,
              backgroundColor: getColorByPercentage(colorPercentage),
            }}
          />
        </div>
        {/* Division line */}
        <span className="absolute left-1/4 h-[12px] w-[2px] bg-black rounded-full -translate-y-[2px]" />
      </div>
    </div>
  );
};
export default ProgressBarComponent;
