import {
  ElectricityIcon,
  HeatIcon,
  WaterIcon,
} from '@components/icons/UtilityTypeIcons';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { type ReactNode } from 'react';

export const getUtilityIcon = (
  utility: UtilityTypeEnum,
  fill?: string
): ReactNode => {
  switch (utility?.toUpperCase()) {
    case UtilityTypeEnum.ELECTRICITY:
      return <ElectricityIcon fill={fill} />;
    case UtilityTypeEnum.WATER:
      return <WaterIcon fill={fill} />;
    case UtilityTypeEnum.GAS:
      return <HeatIcon fill={fill} />;
    case UtilityTypeEnum.THERMAL_ENERGY:
      return <HeatIcon fill={fill} />;
    default:
      return <></>;
  }
};

export const getUtilityTypeReadableName = (
  utility: UtilityTypeEnum
): string | null => {
  switch (utility) {
    case UtilityTypeEnum.ELECTRICITY:
      return 'Electricity';
    case UtilityTypeEnum.WATER:
      return 'Water';
    case UtilityTypeEnum.GAS:
      return 'Gas';
    case UtilityTypeEnum.THERMAL_ENERGY:
      return 'Thermal energy';
    default:
      return null;
  }
};

export const getUtilityTypeUnit = (utility: UtilityTypeEnum): string => {
  switch (utility) {
    case UtilityTypeEnum.ELECTRICITY:
      return 'kWh';
    case UtilityTypeEnum.WATER:
      return '㎥';
    case UtilityTypeEnum.GAS:
      return 'kWh';
    case UtilityTypeEnum.THERMAL_ENERGY:
      return 'kWh';
    default:
      return 'kWh';
  }
};
