import { ReportsIcon } from '@components/icons/ReportsIcon';
import AssetsIcon from 'components/icons/MenuIcons/AssetsIcon';
import DashboardIcon from 'components/icons/MenuIcons/DashboardIcon';
import ManagementIcon from 'components/icons/MenuIcons/ManagementIcon';
import OperationsIcon from 'components/icons/MenuIcons/OperationsIcon';
import { MenuOptionsEnum } from 'enums/MenuOptionsEnum';
import { type FC, type SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import ImpactIcon from '@components/icons/MenuIcons/ImpactIcon';

export interface MenuOptions {
  optionLabel: string;
  option: MenuOptionsEnum;
  icon: FC<SVGProps<SVGSVGElement>>;
  hide?: boolean;
  subMenu?: {
    label: string;
    linkTo: string;
    incoming?: boolean;
    hide?: boolean;
  }[];
  incoming?: boolean;
  linkTo?: string;
}

const useGetMenuOptions = (): { sideMenuItems: MenuOptions[] } => {
  const { t } = useTranslation();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const MENU_BUTTON_LIST = [
    {
      optionLabel: t('sideMenu.dashboard'),
      option: MenuOptionsEnum.DASHBOARD,
      icon: DashboardIcon,
      incoming: false,
      linkTo: 'dashboard',
    },
    {
      optionLabel: t('sideMenu.operations.title'),
      option: MenuOptionsEnum.OPERATIONS,
      icon: OperationsIcon,
      subMenu: [
        {
          label: t('sideMenu.operations.changesList'),
          linkTo: 'operations/anomalies',
          hide: !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.list),
        },
        {
          label: t('sideMenu.operations.alerts'),
          linkTo: 'operations/alerts',
          incoming: false,
        },
      ],
    },
    {
      optionLabel: t('sideMenu.management.title'),
      option: MenuOptionsEnum.MANAGEMENT,
      icon: ManagementIcon,
      hide: !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.list),
      subMenu: [
        {
          label: t('sideMenu.management.projects'),
          linkTo: 'management/projects',
          incoming: false,
        },
      ],
    },
    {
      optionLabel: 'Impact',
      option: MenuOptionsEnum.IMPACT,
      icon: ImpactIcon,
      hide: !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.impact.get),
      subMenu: [
        {
          label: 'Summary',
          linkTo: 'impact/summary',
          incoming: true,
        },
        {
          label: 'Strategy',
          linkTo: 'impact/',
          incoming: true,
        },
      ],
    },
    {
      optionLabel: t('sideMenu.assets.title'),
      option: MenuOptionsEnum.ASSETS,
      icon: AssetsIcon,
      linkTo: 'assets/list#meters',
    },
    {
      optionLabel: t('sideMenu.reports'),
      option: MenuOptionsEnum.REPORTS,
      icon: ReportsIcon,
      linkTo: 'reports',
    },
  ];
  return { sideMenuItems: MENU_BUTTON_LIST };
};
export default useGetMenuOptions;
