import {
  Listbox,
  ListboxButton,
  ListboxOption,
  Transition,
} from '@headlessui/react';
import {
  type ButtonHTMLAttributes,
  type FC,
  Fragment,
  type ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCheckbox,
  IoIosClose,
} from 'react-icons/io';
import { CustomListboxOptions } from '../styled';
import { FErrorLabel } from 'components/form/FErrorLabel';
import { type InputAddormentProps } from '@components/inputs/InputAddornment/InputAddorment';
import { addInputErrorClasses } from 'utils/handleFormErrorStyle';
import { usePopper } from 'hooks/usePopper';
import { SelectedItem } from '@components/inputs/ComboBox/styled';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';

export interface MultiSelectInputProps
  extends InputAddormentProps,
    Omit<
      ButtonHTMLAttributes<HTMLButtonElement>,
      'name' | 'children' | 'onChange' | 'value'
    > {
  options: DropdownOptions[];
  defaultValue?: string | number;
  placeholder?: string;
  dropdownClassName?: string;
  getSelectedName?: (value: DropdownOptions | undefined) => ReactNode;
  value?: string[] | number[];
  onChange?: (value: any) => void;
  disabledOptions?: string[];
  multiple?: boolean;
  componentRender?: (value: string | number) => ReactNode;
  disableSelectedList?: boolean;
}
export interface DropdownOptions {
  id?: string | number;
  value: string | number;
  name: string | undefined;
  startIcon?: ReactNode;
  dropdownOptions?: { name?: string; component?: ReactNode };
}

const MultiSelectInput: FC<MultiSelectInputProps> = forwardRef(
  (
    {
      error,
      options,
      defaultValue,
      dropdownClassName,
      placeholder,
      value,
      getSelectedName,
      onChange,
      componentRender,
      disabledOptions = [],
      multiple = false,
      disableSelectedList,
      disabled,
      ...props
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      modifiers: [{ name: 'applyStyles', options: { offset: [0, 10] } }],
    });
    const [multipleSelectedOptions, setMultipleSelectedOptions] = useState<
      (string | number)[]
    >(Array.isArray(value) ? value : []);
    const { theme } = useTheme();

    const handleDropDown = (): void => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOnChange = (value: string[] | number[]): void => {
      if (multiple) {
        setMultipleSelectedOptions(value as string[]);
        return;
      }
      onChange?.(value);
    };

    const removeFromSelectedList = (id: string | number | undefined): void => {
      if (!id) return;

      const filteredList = (multipleSelectedOptions || []).filter(
        (option) => option !== id.toString()
      );

      setMultipleSelectedOptions(filteredList);
    };

    const parsedMultiSelectedOptions = useMemo(() => {
      if (multipleSelectedOptions && Array.isArray(value)) {
        return multipleSelectedOptions?.map((selectedValue) => {
          const selectedOption = options.find(
            (option) => option.value === selectedValue
          );
          return {
            name: selectedOption?.name,
            value: selectedOption?.value,
          };
        });
      }
    }, [multipleSelectedOptions]);

    useEffect(() => {
      if (multiple) {
        onChange?.(multipleSelectedOptions);
      }
    }, [multipleSelectedOptions, multiple]);

    return (
      <div className="flex-1">
        <Listbox
          value={value}
          onChange={handleOnChange}
          multiple={multiple}
          ref={trigger}
          disabled={disabled}
        >
          <div className="relative w-full">
            <ListboxButton
              className={`relative w-full flex flex-1 items-center self-stretch px-3 py-2 border rounded-lg h-11 focus-within:border-primary-light
              ${error ? addInputErrorClasses(error) : 'border-gray-30'}
              `}
              onClick={handleDropDown}
              {...props}
            >
              <span className="flex items-center gap-2 justify-between w-full">
                <span className="flex items-center gap-2">
                  {multipleSelectedOptions?.length > 0 ? (
                    `Selected (${multipleSelectedOptions?.length ?? 0})`
                  ) : (
                    <span className="text-gray-400">{placeholder}</span>
                  )}
                </span>
                {isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <CustomListboxOptions
                className={`z-50 ${dropdownClassName ?? ''}`}
                ref={container}
              >
                {options.map((option, index) => {
                  const isDisabled =
                    disabledOptions?.length > 0 &&
                    disabledOptions?.some(
                      (disabledValue) => option.value === disabledValue
                    );
                  return (
                    <ListboxOption
                      key={option?.id ?? index}
                      className={({ focus }) =>
                        `relative cursor-default select-none z-40 ${
                          focus
                            ? 'bg-primary-lighter font-bold text-gray-60'
                            : 'text-gray-50 bg-white'
                        }`
                      }
                      disabled={isDisabled}
                      value={option.value}
                      data-testid="select-option"
                      data-value={option.value}
                    >
                      {({ selected, disabled }) => (
                        <div
                          className={`flex items-center justify-between gap-2 px-3 py-3 pr-4 cursor-pointer ${
                            selected ? 'bg-primary-lighter' : ''
                          }`}
                          onClick={handleDropDown}
                        >
                          <div className="flex gap-2.5 items-center">
                            {option.startIcon && (
                              <span className="w-6 h-6 inline-block">
                                {option.startIcon}
                              </span>
                            )}
                            <div className="flex gap-2">
                              {selected ? (
                                <IoIosCheckbox
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  data-testid="checked-item-combobox"
                                  color={theme.colors.primary}
                                />
                              ) : (
                                <MdCheckBoxOutlineBlank
                                  color="black"
                                  className="h-5 w-5"
                                />
                              )}

                              <span
                                className={`inline-block truncate ${
                                  disabled ? 'text-gray-30' : ''
                                } ${selected ? 'font-medium' : 'font-normal'}`}
                              >
                                {componentRender
                                  ? componentRender(option.value)
                                  : option?.dropdownOptions?.name ??
                                    option.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </ListboxOption>
                  );
                })}
              </CustomListboxOptions>
            </Transition>
          </div>
        </Listbox>
        {!disableSelectedList && (
          <div>
            {parsedMultiSelectedOptions?.map((option, index: number) => (
              <SelectedItem
                key={option?.value}
                data-testid="multiple-selected-options"
                $index={index}
                $length={parsedMultiSelectedOptions.length}
              >
                <span>{option?.name}</span>
                <IoIosClose
                  size={22}
                  className="cursor-pointer text-primary"
                  onClick={() => {
                    removeFromSelectedList(option?.value);
                  }}
                  data-testid={`remove-option-${index + 1}`}
                />
              </SelectedItem>
            ))}
          </div>
        )}

        <FErrorLabel message={error?.message} />
      </div>
    );
  }
);
export default MultiSelectInput;
