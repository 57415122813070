import { type FC, type SVGProps } from 'react';

const NotificationsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    {...props}
  >
    <path
      d="M10 23.6667C11.2833 23.6667 12.3333 22.6167 12.3333 21.3333H7.66666C7.66666 22.6167 8.71666 23.6667 10 23.6667ZM17 16.6667V10.8333C17 7.25167 15.0983 4.25333 11.75 3.46V2.66667C11.75 1.69833 10.9683 0.916668 10 0.916668C9.03166 0.916668 8.25 1.69833 8.25 2.66667V3.46C4.91333 4.25333 3 7.24 3 10.8333V16.6667L0.666664 19V20.1667H19.3333V19L17 16.6667ZM14.6667 17.8333H5.33333V10.8333C5.33333 7.94 7.095 5.58333 10 5.58333C12.905 5.58333 14.6667 7.94 14.6667 10.8333V17.8333Z"
      fill="#F7F2FF"
    />
  </svg>
);
export default NotificationsIcon;
