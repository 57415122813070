import { Modal } from '@components/modal';
import { useCreateProject } from 'api/projects/projectsServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageProjectForm } from '../../form';
import { manageProjectSchema } from '../../form/schema';
interface CreateProjectModalProps extends Disclosure {}
const CreateProjectModal: FC<CreateProjectModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const { methods } = useHRForm({
    schema: manageProjectSchema,
  });
  const { t } = useTranslation();

  const { mutate: createProject, isSuccess, isPending } = useCreateProject();

  const handleCreateProject = (): void => {
    const body = methods.getValues();

    createProject({ ...body, meter: body?.meter?.[0]?.value });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    methods.reset();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={'Create new project'}
      maxWidth={550}
      actionBtns={{
        primary: {
          label: t('common.buttons.createProject'),
          onClick: methods.handleSubmit(handleCreateProject),
          isLoading: isPending,
        },
        secondary: {
          label: t('common.buttons.cancel'),
        },
      }}
    >
      <FormProvider {...methods}>
        <ManageProjectForm />
      </FormProvider>
    </Modal>
  );
};
export default CreateProjectModal;
