import { type ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

export const checkPageFeatureFlag = (
  hasPermission: boolean,
  Page: ReactNode
): ReactNode => {
  if (hasPermission) {
    return Page;
  }
  return <Navigate to={'/app/not-found'} />;
};
