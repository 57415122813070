import { Modal } from '@components/modal';
import {
  useEditFeatureFlagDetail,
  useFetchFeatureFlagDetail,
} from 'api/sysadmin/sysAdminServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageFeatureFlagForm } from '../forms';
import { featureFlagSchema } from '../forms/schema';

interface EditFeatureFlagModalProps extends Disclosure {
  featureFlagId?: number;
}
const EditFeatureFlagModal: FC<EditFeatureFlagModalProps> = ({
  featureFlagId,
  isOpen,
  handleClose,
  handleOpen,
}) => {
  const { t } = useTranslation();
  const { data } = useFetchFeatureFlagDetail(featureFlagId);
  const { mutate, isPending, isSuccess } =
    useEditFeatureFlagDetail(featureFlagId);
  const initialValues = useMemo(
    () => ({
      name: data?.name ?? '',
      routes:
        data?.routes.map((route) => ({
          value: route.id,
          name: `${route.method} - ${route.endpoint}`,
        })) ?? [],
      company:
        data?.companies_affected.map((company) => ({
          name: company.name,
          value: company.id,
        })) ?? [],
      active: data?.active ?? false,
    }),
    [data]
  );
  const { methods } = useHRForm({
    schema: featureFlagSchema,
    initialValues,
  });

  const handleEditSubmit = (): void => {
    const values = methods.getValues();
    const body = {
      name: values.name,
      routes: values.routes?.map((route) => ({
        id: route.value,
      })),
      active: values.active,
      companies_affected: values.company?.map((company) => ({
        id: company?.value,
      })),
    };
    mutate(body);
  };

  useEffect(() => {
    methods.reset(initialValues);
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: 'Confirm',
          onClick: methods.handleSubmit(handleEditSubmit),
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      title={'Edit Feature Flag'}
      maxWidth={500}
    >
      <FormProvider {...methods}>
        <ManageFeatureFlagForm initialValues={initialValues} />
      </FormProvider>
    </Modal>
  );
};
export default EditFeatureFlagModal;
