import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import { t } from 'i18next';
import { type UtilityPriceResponse } from 'interfaces/utilityPrice/utilityPrice.interface';
import queryClient from 'lib/queryClient';
import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';

export interface FetchUtilityPricesResponse {
  utility_prices: UtilityPriceResponse[];
}

export const useFetchUtilityPrices = (
  companyId: number | null | undefined
): UseQueryResult<FetchUtilityPricesResponse> => {
  return useQuery<FetchUtilityPricesResponse>({
    queryKey: ['utilityPricesList', companyId],
    queryFn: async () => {
      const response = await axiosInstance.get(`utility_price/`);
      return response.data;
    },
  });
};

export const useCreateUtilityPrice = (
  companyId?: number | null | undefined
): UseMutationResult<UtilityPriceResponse, Error | AxiosError> => {
  return useMutation({
    mutationFn: async (params) => {
      const response = await axiosInstance.post(`utility_price/`, params);
      return response.data;
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('settingsPage.companyTab.toaster.success'));
      await queryClient.invalidateQueries({
        queryKey: ['utilityPricesList'],
      });
    },
  });
};

export const useUpdateUtilityPrice = (
  id: number | null | undefined | string,
  companyId?: number | null | undefined
): UseMutationResult<UtilityPriceResponse> => {
  return useMutation({
    mutationFn: async (params) => {
      if (!id) {
        return;
      }
      const response = await axiosInstance.patch(`utility_price/${id}`, params);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('settingsPage.companyTab.toaster.update'));
      await queryClient.invalidateQueries({
        queryKey: ['utilityPrice'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['utilityPricesList'],
      });
    },
  });
};

export const useDeleteUtilityPrice = (
  id: number | null | undefined | string,
  companyId?: number | null | undefined
): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  string | number,
  unknown
> => {
  return useMutation({
    mutationFn: async (id: string | number) => {
      await axiosInstance.delete(`utility_price/${id}`);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('settingsPage.companyTab.toaster.delete'));
      await queryClient.invalidateQueries({
        queryKey: ['utilityPricesList'],
      });
    },
  });
};
