import i18next from 'i18next';
import { z } from 'zod';

export const loginSchema = z.object({
  email: z.string().email(i18next.t('loginPage.errors.emailFormat')),
  password: z.string().min(10, i18next.t('common.errors.required')),
});

export const loginControlNames = {
  email: 'email',
  password: 'password',
};
export type LoginSchemaType = z.infer<typeof loginSchema>;
