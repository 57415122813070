import { Modal } from '@components/modal';
import { useCreateFeatureFlag } from 'api/sysadmin/sysAdminServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageFeatureFlagForm } from '../forms';
import { featureFlagSchema } from '../forms/schema';

interface CreateFeatureFlagModalProps extends Disclosure {}
const CreateFeatureFlagModal: FC<CreateFeatureFlagModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
}) => {
  const { t } = useTranslation();
  const { methods } = useHRForm({ schema: featureFlagSchema });
  const { mutate, isPending, isSuccess } = useCreateFeatureFlag();

  const handleCreateFeatureFlag = (): void => {
    const values = methods.getValues();
    const body = {
      name: values.name,
      active: values.active,
      routes: values?.routes?.map((route) => ({
        id: route.value,
      })),
      companies_affected: values?.company?.map((company) => ({
        id: company?.value,
      })),
    };
    mutate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: 'Create feature flag',
          onClick: methods.handleSubmit(handleCreateFeatureFlag),
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      maxWidth={500}
      title={'Create new Feature Flag'}
    >
      <FormProvider {...methods}>
        <ManageFeatureFlagForm />
      </FormProvider>
    </Modal>
  );
};
export default CreateFeatureFlagModal;
