import { type FC } from 'react';
import FLabel from '../FLabel/FLabel';
import { Controller, useFormContext } from 'react-hook-form';
import { SearchMeterInput } from '@components/inputs/SearchMeterInput';
import { type SearchMeterInputProps } from '@components/inputs/SearchMeterInput/SearchMeterInput';

interface FSearchMeterFieldProps extends SearchMeterInputProps {
  label?: string;
  subLabel?: string;
  name: string;
}

const FSearchMeterField: FC<FSearchMeterFieldProps> = ({
  label,
  subLabel,
  name,
  ...props
}) => {
  const { control } = useFormContext();
  const dataTestId = `${name}-search-meter-combobox`;

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex flex-col gap-1.5">
        {label && <FLabel>{label}</FLabel>}
        {subLabel && <span>{subLabel}</span>}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <SearchMeterInput
            error={error}
            data-testid={dataTestId}
            {...field}
            {...props}
          />
        )}
      />
    </div>
  );
};
export default FSearchMeterField;
