import styled from 'styled-components';
import tw from 'twin.macro';

interface DrawerMenuExpanded {
  $isDrawerExpanded: boolean;
}

interface StyledSideMenuButtonProps extends DrawerMenuExpanded {
  $isActive: boolean;
}

interface StyledMenuContentProps {
  $isMenuExpanded: boolean;
}

export const StyledSideMenuButton = styled.div<StyledSideMenuButtonProps>`
  ${tw`flex flex-col justify-center items-center min-h-[48px] rounded-lg transition-all px-5 cursor-pointer`}
  ${(props) => props.$isActive && tw`bg-tertiary-lighter`}
  ${(props) =>
    props.$isDrawerExpanded
      ? tw`w-full justify-center items-start`
      : tw`min-w-[48px]`}
`;

export const StyledMenuContent = styled.div<StyledMenuContentProps>`
  transition: all 0.1s cubic-bezier(0.78, 0.22, 1, 1);
  ${tw`max-h-[0px] overflow-hidden flex flex-col items-center`}
  ${(props) => props.$isMenuExpanded && tw`max-h-[200px]`}
`;

export const ButtonLabel = styled.div<DrawerMenuExpanded>`
  ${tw`text-lg text-gray-50 ml-3`}
  ${(props) => (props.$isDrawerExpanded ? tw`opacity-100` : tw`opacity-0`)}
`;
