import { FLabel } from '@components/form/FLabel';
import { MultiComboBoxInput } from '@components/inputs/ComboBox';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { Modal } from '@components/modal';
import {
  useFetchMeter,
  useListLinkableAssets,
  useUpdateMeter,
} from 'api/assets/assetsServices';
import { useDebounce } from 'hooks/useDebounce';
import { type Asset } from 'interfaces/assets/Asset.interface';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface LinkAssetModalProps extends Disclosure {
  meterId?: number;
}

const LinkAssetModal: FC<LinkAssetModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  meterId,
}) => {
  const { t } = useTranslation();
  const [linkedAssets, setLinkedAssets] = useState<DropdownOptions[]>();
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const { data, isFetching } = useListLinkableAssets({
    id: meterId,
    query: debouncedQuery,
  });
  const { data: meterData } = useFetchMeter(meterId);
  const { mutate: updateMeter, isPending, isSuccess } = useUpdateMeter(meterId);

  const assetsOptions: DropdownOptions[] = useMemo(
    () =>
      data?.assets?.map((asset: Asset) => ({
        name: asset.name,
        value: asset.id,
      })) ?? [],
    [data]
  );

  const handleQuery = (value: string): void => {
    setQuery(value);
  };

  const handleUpdateMeter = (): void => {
    if (!meterData) return;

    const newAssetsId = linkedAssets?.map((linkedAsset) => ({
      id: linkedAsset.value,
    }));
    if (!newAssetsId?.length) return;

    const currentAssetsId =
      meterData.meter.assets.map((asset) => ({
        id: asset?.id,
      })) ?? [];

    const body = {
      ...meterData.meter,
      assets: [...currentAssetsId, ...newAssetsId],
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { end_client_id, user_id, assets } = { ...body };

    updateMeter({ assets, end_client_id, user_id });
  };

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <Modal
      title={t('assetsPage.linkAssetModal.title')}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('common.buttons.continue'),
          onClick: handleUpdateMeter,
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
    >
      <div className="flex flex-col gap-4">
        <p>{t('assetsPage.linkAssetModal.body')}</p>
        <div className="flex flex-col gap-2">
          <FLabel>{t('common.assets')}</FLabel>
          <MultiComboBoxInput
            options={assetsOptions}
            query={query}
            handleQuery={handleQuery}
            isFetching={isFetching}
            selectedOptionsContainerClasses={'h-[150px] overflow-scroll'}
            value={linkedAssets}
            onChange={setLinkedAssets}
            placeholder="Asset name"
          />
        </div>
      </div>
    </Modal>
  );
};
export default LinkAssetModal;
