import { Slider } from '@components/inputs/Slider';
import { Modal } from '@components/modal';
import { useUpdateImpact } from 'api/impact/impactServices';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useState } from 'react';
import { convertToPercentageNumber } from 'utils/formatters/number/numberUtils';

interface EditAssetImpactModalProps extends Disclosure {
  savingsTarget: number;
  selectedId?: number;
}

const EditAssetImpactModal: FC<EditAssetImpactModalProps> = ({
  isOpen,
  handleClose,
  selectedId,
  savingsTarget,
}) => {
  const [value, setValue] = useState(0);

  const { mutate, isPending, isSuccess } = useUpdateImpact(selectedId);

  const updateAsset = (): void => {
    mutate({ savingsTarget: value / 100 });
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isOpen) {
      setValue(convertToPercentageNumber(savingsTarget));
    }
  }, [isOpen, savingsTarget]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Savings target"
      actionBtns={{
        primary: {
          label: 'Confirm',
          onClick: updateAsset,
          isLoading: isPending,
        },
      }}
    >
      <div>
        <p>Set the savings target for this building here.</p>
        <Slider
          value={value}
          onChange={(e) => {
            setValue(e);
          }}
        />
      </div>
    </Modal>
  );
};
export default EditAssetImpactModal;
