import { FSelectField } from '@components/form/FSelectField';
// import { FTextField } from '@components/form/FTextField';
import { UTILITY_DATA, UTILITY_TYPE } from 'lib/constants';
import { type FC, useEffect } from 'react';
import {
  addUtilityPriceControlNames,
  addUtilityPriceSchema,
  type addUtilityPriceSchemaType,
} from './schema';
import { Button } from 'layout/Button';
import useHRForm from 'hooks/useHRForm';
import { useCreateUtilityPrice } from 'api/utilityPrices/UtilityPriceService';
import { FNumericField } from '@components/form/FNumericField';
import Skeleton from 'react-loading-skeleton';
import { SkeletonInput } from '@components/skeleton';

interface AddUtilityPriceFormProps {
  initialValues?: any;
  companyId: number | undefined | null;
  isLoading?: boolean;
}
const AddUtilityPriceForm: FC<AddUtilityPriceFormProps> = ({
  initialValues,
  companyId,
  isLoading = false,
}) => {
  const {
    mutate: createUtilityPrice,
    isPending: isLoadingCreateUtilityPrice,
    isSuccess,
  } = useCreateUtilityPrice(companyId);

  const {
    Form,
    methods: { watch, resetField, reset },
  } = useHRForm({
    schema: addUtilityPriceSchema,
    initialValues,
  });

  const utilityType = watch(
    addUtilityPriceControlNames.utility_type
  ) as keyof typeof UTILITY_DATA;

  useEffect(() => {
    resetField('unit_type');
  }, [utilityType]);

  const onSubmit = async (data: addUtilityPriceSchemaType): Promise<void> => {
    // Replace comma with dot in the price value
    const modifiedData: addUtilityPriceSchemaType = {
      ...data,
      price: data.price,
    };
    createUtilityPrice(modifiedData);
  };

  const skeletonArray = Array(3).fill({});

  useEffect(() => {
    if (isSuccess) {
      reset({
        utility_type: UTILITY_TYPE[0].value,
        price: 0,
      });
    }
  }, [isSuccess]);

  return (
    <div data-testid="add-utility-price-form">
      <Form onSubmit={onSubmit}>
        <div className="flex justify-between w-full gap-6">
          {isLoading ? (
            <>
              {skeletonArray.map((_, index) => (
                <SkeletonInput key={index} withLabel={true} labelWidth={100} />
              ))}
              <div className="pt-5">
                <Skeleton width={45} height={38} borderRadius={'100px'} />
              </div>
            </>
          ) : (
            <>
              <FSelectField
                options={UTILITY_TYPE}
                label="Utility type"
                name={addUtilityPriceControlNames.utility_type}
                placeholder="Select utility type"
                data-testid="utilityType"
              />
              <FSelectField
                options={UTILITY_DATA[utilityType]}
                label="Unit"
                name={addUtilityPriceControlNames.unit_type}
                placeholder="Select unit"
                data-testid="unit"
              />
              <FNumericField
                label="Price in Euro (€)"
                name={addUtilityPriceControlNames.price}
                placeholder="Enter prince"
                data-testid="price"
              />
              <div className="pt-8">
                <Button
                  size="normal"
                  label={'+'}
                  variant="primary"
                  isLoading={isLoadingCreateUtilityPrice}
                  type="submit"
                  minWidth={'45'}
                />
              </div>
            </>
          )}
        </div>
      </Form>
    </div>
  );
};
export default AddUtilityPriceForm;
