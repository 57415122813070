import useHRForm from 'hooks/useHRForm';
import { type FC, useEffect } from 'react';
import {
  addAlertControlNames,
  addAlertSchema,
  type addAlertSchemaType,
} from './schema';
import { FSelectField } from '@components/form/FSelectField';
import { ALERT_THRESHOLDS, OPERATORS } from 'lib/constants';
import { FNumericField } from '@components/form/FNumericField';
import { Button } from 'layout/Button';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { useCreateAlertDefinition } from 'api/alerts/alertsServices';
import { t } from 'i18next';

interface AddAlertsFormProps {
  userOptions: DropdownOptions[];
}
const AddAlertsForm: FC<AddAlertsFormProps> = ({ userOptions }) => {
  const {
    mutate: createAlertDefinitionMutate,
    isPending,
    isSuccess,
  } = useCreateAlertDefinition();

  const {
    Form,
    methods: { reset },
  } = useHRForm({
    schema: addAlertSchema,
  });

  const onSubmit = (data: addAlertSchemaType): void => {
    const amount = data.amount;
    const condition = {
      entity: 'change_detection',
      rules: [
        {
          [data.threshold]: {
            operator: data.operator,
            value: amount,
          },
        },
      ],
    };
    const params = {
      condition,
      type: 'alert_change',
      users: [{ id: data.user }],
    };
    createAlertDefinitionMutate(params);
  };

  useEffect(() => {
    if (isSuccess) {
      reset({ amount: 0 }, { keepValues: false });
    }
  }, [isSuccess]);

  return (
    <Form onSubmit={onSubmit}>
      <div className="flex justify-between w-full gap-6">
        <div className="flex items-center justify-center h-10">Nofity</div>
        <FSelectField
          options={userOptions}
          label=""
          name={addAlertControlNames.user}
          placeholder={t('common.placeholders.selectUser')}
          data-testid="user"
        />
        <div className="flex items-center justify-center h-10">
          of deviations
        </div>
        <FSelectField
          options={OPERATORS}
          label=""
          name={addAlertControlNames.operator}
          placeholder={t('common.placeholders.selectCondition')}
          data-testid="operator"
        />
        <FNumericField
          label=""
          name={addAlertControlNames.amount}
          placeholder={t('common.placeholders.enterAmount')}
          data-testid="amount"
        />
        <div className="flex items-center justify-center h-10">of</div>
        <FSelectField
          options={ALERT_THRESHOLDS}
          label=""
          name={addAlertControlNames.threshold}
          placeholder={t('common.placeholders.selectThreshold')}
          data-testid="threshold"
        />
        <Button
          label={'+'}
          variant="primary"
          isLoading={isPending}
          type="submit"
          minWidth={'45'}
        />
      </div>
    </Form>
  );
};
export default AddAlertsForm;
