import MapContainer from '@components/map/Map';
import { useFetchDashboardMap } from 'api/dashboard/dashboardServices';
import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  type CircleLayer,
  type GeoJSONSource,
  Layer,
  type MapLayerMouseEvent,
  type MapRef,
  Source,
} from 'react-map-gl';
import type { FeatureCollection } from 'geojson';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { useNavigate } from 'react-router-dom';
import { useMapUtilities } from 'hooks/useMapUtilities';
import { type Feature } from 'hooks/useMapUtilities/useMapUtilities';
import { type MetersMap } from 'interfaces/map/MetersMap.interface';

interface DashboardMapProps {
  hoveredId?: number;
  setHoveredId?: (value?: number) => void;
}

const BuildingsMap: FC<DashboardMapProps> = ({ hoveredId, setHoveredId }) => {
  const [averages, setAverages] = useState({ projects: 0, changes: 0 });
  let { data: changesData } = useFetchDashboardMap();
  const mapRef = useRef<MapRef | null>(null);
  const useMockedData = true;
  const mockedData = {
    map_data: [
      {
        id: 1,
        total_score: 85,
        latitude: 50.8503,
        longitude: 4.3517,
        impact_average_diff_euro: 100,
        impact_total_euro: 60000,
      },
      {
        id: 2,
        total_score: 90,
        latitude: 48.8566,
        longitude: 2.3522,
        impact_average_diff_euro: 100,
        impact_total_euro: 80000,
      },
      {
        id: 3,
        total_score: 88,
        latitude: 52.52,
        longitude: 13.405,
        impact_average_diff_euro: 100,
        impact_total_euro: 72000,
      },
      {
        id: 4,
        total_score: 92,
        latitude: 41.9028,
        longitude: 12.4964,
        impact_average_diff_euro: 100,
        impact_total_euro: 88000,
      },
      {
        id: 5,
        total_score: 83,
        latitude: 47.4979,
        longitude: 19.0402,
        impact_average_diff_euro: 100,
        impact_total_euro: 56000,
      },
      {
        id: 6,
        total_score: 87,
        latitude: 48.1351,
        longitude: 11.582,
        impact_average_diff_euro: 100,
        impact_total_euro: 64000,
      },
      {
        id: 7,
        total_score: 89,
        latitude: 50.0755,
        longitude: 14.4378,
        impact_average_diff_euro: 100,
        impact_total_euro: 76000,
      },
      {
        id: 8,
        total_score: 86,
        latitude: 45.4642,
        longitude: 9.19,
        impact_average_diff_euro: 100,
        impact_total_euro: 62000,
      },
      {
        id: 9,
        total_score: 84,
        latitude: 48.2082,
        longitude: 16.3738,
        impact_average_diff_euro: 100,
        impact_total_euro: 58000,
      },
      {
        id: 10,
        total_score: 91,
        latitude: 51.1657,
        longitude: 10.4515,
        impact_average_diff_euro: 100,
        impact_total_euro: 84000,
      },
      {
        id: 11,
        total_score: 82,
        latitude: 50.1109,
        longitude: 8.6821,
        impact_average_diff_euro: 100,
        impact_total_euro: 52000,
      },
      {
        id: 12,
        total_score: 93,
        latitude: 44.4268,
        longitude: 26.1025,
        impact_average_diff_euro: 100,
        impact_total_euro: 92000,
      },
      {
        id: 13,
        total_score: 81,
        latitude: 52.2297,
        longitude: 21.0122,
        impact_average_diff_euro: 100,
        impact_total_euro: 48000,
      },
      {
        id: 14,
        total_score: 88,
        latitude: 53.5511,
        longitude: 9.9937,
        impact_average_diff_euro: 100,
        impact_total_euro: 72000,
      },
      {
        id: 15,
        total_score: 86,
        latitude: 52.3676,
        longitude: 4.9041,
        impact_average_diff_euro: 100,
        impact_total_euro: 64000,
      },
      {
        id: 16,
        total_score: 89,
        latitude: 50.9375,
        longitude: 6.9603,
        impact_average_diff_euro: 100,
        impact_total_euro: 76000,
      },
      {
        id: 17,
        total_score: 85,
        latitude: 49.6116,
        longitude: 6.1319,
        impact_average_diff_euro: 100,
        impact_total_euro: 60000,
      },
      {
        id: 18,
        total_score: 92,
        latitude: 48.5734,
        longitude: 7.7521,
        impact_average_diff_euro: 100,
        impact_total_euro: 88000,
      },
    ],
  };
  const {
    defaultCircleLayerStyle,
    calculateAverageMapCircleSize,
    getMinMaxSizeValue,
    onHoverPainting,
  } = useMapUtilities(mapRef);

  const { theme } = useTheme();
  const navigate = useNavigate();

  useMemo(() => {
    if (changesData?.map_data) {
      const average = calculateAverageMapCircleSize<MetersMap>(
        changesData.map_data,
        'impact_average_diff_euro'
      );
      setAverages((current) => ({ ...current, changes: average }));
    }
  }, [changesData]);

  const changesGeojson = useMemo(() => {
    if (!changesData || !averages.changes) return;
    if (useMockedData) changesData = mockedData;
    const parsedData: FeatureCollection = {
      type: 'FeatureCollection',
      features: changesData.map_data?.flatMap(
        (meter) =>
          [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [meter.longitude, meter.latitude],
              },
              properties: {
                score: meter.total_score,
                id: meter.id,
                impactPerDay: meter.impact_average_diff_euro,
                avgBasedSize: getMinMaxSizeValue(
                  averages.changes,
                  meter.impact_average_diff_euro
                ),
              },
              maxZoom: 8,
            },
          ] ?? []
      ),
    };
    return parsedData;
  }, [changesData, averages.changes]);

  const layerStyle: CircleLayer = {
    ...defaultCircleLayerStyle,
    id: 'meters',
    paint: {
      ...defaultCircleLayerStyle.paint,
      'circle-color': theme.colors['primary-light'],
      'circle-stroke-color': theme.colors.primary,
    },
  };

  const coordinates = useMemo(() => {
    const changesCoordinates =
      changesData?.map_data?.map((coord) => [
        coord.longitude,
        coord.latitude,
      ]) ?? [];

    return [...changesCoordinates];
  }, [changesData]);

  const handleOnClickLayer = (event: MapLayerMouseEvent): void => {
    const map = mapRef.current;
    if (!map) return;

    const features = map.queryRenderedFeatures(event.point, {
      layers: ['meters', 'projects'],
    });

    if (features.length > 0) {
      const firstFeature = features[0];
      const layerId = firstFeature?.layer?.id;
      const id: string = firstFeature.properties?.id;

      if (layerId === 'meters') {
        navigate(`/app/operations/anomalies/details/${id}`);
      } else if (layerId === 'projects') {
        navigate(`/app/management/projects/details/${id}`);
      }
    }
  };

  useEffect(() => {
    if (mapRef?.current) {
      const map = mapRef.current;

      const metersSource = map.getSource('meters-data') as GeoJSONSource;
      const projectsSource = map.getSource('projects-data') as GeoJSONSource;

      const metersFeatures: Feature[] = (metersSource as any)?._data?.features;
      const projectsFeatures: Feature[] = (projectsSource as any)?._data
        ?.features;

      if (metersFeatures && metersFeatures.length > 0) {
        const hoveredFeature = metersFeatures.find(
          (feature) => feature.properties.id === hoveredId
        );

        onHoverPainting({
          hoveredFeature,
          layerId: 'meters',
          paintProperty: 'circle-color',
          hoveredId,
          hoveredColor: theme.colors.tertiary,
          defaultColor: '#D9E4F7',
          border: {
            defaultColor: theme.colors.primary,
            hoveredColor: theme.colors['tertiary-dark'],
          },
        });
      }

      if (projectsFeatures && projectsFeatures.length > 0) {
        const hoveredFeature = projectsFeatures.find(
          (feature) => feature.properties.id === hoveredId
        );
        onHoverPainting({
          hoveredFeature,
          layerId: 'projects',
          paintProperty: 'circle-color',
          hoveredId,
          hoveredColor: theme.colors['light-blue'],
          defaultColor: theme.colors.blue,
          border: {
            defaultColor: theme.colors.blue,
            hoveredColor: theme.colors.blue,
          },
        });
      }
    }
  }, [hoveredId]);

  const layers = ['meters', 'projects'];
  mapRef.current?.on('mouseenter', layers, (event) => {
    const map = mapRef.current;
    if (!map) return;
    map.getCanvas().style.cursor = 'pointer';
    const hoveredMeterId = event.features?.[0]?.properties?.id;
    setHoveredId?.(hoveredMeterId);
  });

  mapRef.current?.on('mouseleave', layers, () => {
    const map = mapRef.current;
    if (!map) return;
    map.getCanvas().style.cursor = 'default';
    setHoveredId?.(undefined);
  });

  return (
    <div className="flex">
      <MapContainer
        ref={mapRef}
        onClick={handleOnClickLayer}
        coordinates={coordinates}
        height={400}
      >
        <Source id="meters-data" type="geojson" data={changesGeojson}>
          <Layer {...layerStyle} />
        </Source>
      </MapContainer>
    </div>
  );
};
export default BuildingsMap;
