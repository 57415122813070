import { Modal } from '@components/modal';
import {
  useFetchProject,
  useUpdateProject,
} from 'api/projects/projectsServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageProjectForm } from '../../form';
import { manageProjectSchema } from '../../form/schema';

interface EditProjectModalProps extends Disclosure {
  id?: number;
  handleDeleteOpen: () => void;
}
const EditProjectModal: FC<EditProjectModalProps> = ({
  isOpen,
  handleClose,
  id,
  handleDeleteOpen,
}) => {
  const { t } = useTranslation();
  const { data: projectData, isFetching } = useFetchProject(id);
  const {
    mutate: updateProject,
    isPending,
    isSuccess: isUpdateSuccess,
  } = useUpdateProject(id);

  const initialValues = useMemo(() => {
    if (!projectData) return;

    const { project } = projectData;

    return {
      name: project.name,
      description: project.description,
      implementationPeriod: {
        start: new Date(project.implementation_period_start),
        end: new Date(project.implementation_period_end),
      },
      meter: [
        {
          name: project.meter?.name,
          value: project.meter?.id,
        },
      ],
      responsible: project.responsible_user_id,
      category: project.category,
      investmentCostNumber: project.investment_cost,
      referencePeriod: {
        start: new Date(project.reference_period_start),
        end: new Date(project.reference_period_end),
      },
      savingsTarget: project.savings_target ? project.savings_target * 100 : 0,
    };
  }, [projectData]);

  const { methods } = useHRForm({
    schema: manageProjectSchema,
    initialValues,
  });

  const handleEditProject = (): void => {
    const body = methods.getValues();
    updateProject({ ...body, meter: body.meter?.[0]?.value });
  };

  useEffect(() => {
    if (isUpdateSuccess) {
      handleClose();
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useEffect(() => {
    methods.reset();
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={'Edit project'}
      maxWidth={550}
      actionBtns={{
        primary: {
          label: t('common.buttons.saveChanges'),
          onClick: handleEditProject,
          isLoading: isPending,
        },
        secondary: {
          label: t('common.buttons.cancel'),
        },
      }}
    >
      <FormProvider {...methods}>
        <ManageProjectForm
          isEditing={true}
          handleDeleteOpen={handleDeleteOpen}
          isFetching={isFetching}
        />
      </FormProvider>
    </Modal>
  );
};
export default EditProjectModal;
