import { z } from 'zod';

interface editProfileControlNamesType {
  givenName: 'given_name';
  familyName: 'family_name';
  fullName: 'full_name';
  email: 'email';
  phoneNumber: 'phone_number';
  role: 'role';
  function: 'function';
  language: 'language';
  password: 'password';
}

export const editProfileSchema = z.object({
  given_name: z.string().trim().min(2, 'Required'),
  family_name: z.string().trim().min(2, 'Required'),
  email: z.string().email(),
  phone_number: z.string(),
  role: z.string().nonempty({
    message: 'Required',
  }),
  function: z.string().nonempty({
    message: 'Required',
  }),
  language: z.string().nonempty({
    message: 'Required',
  }),
  password: z.string().nullable().optional(),
});

export const editProfileControlNames: editProfileControlNamesType = {
  givenName: 'given_name',
  familyName: 'family_name',
  fullName: 'full_name',
  email: 'email',
  phoneNumber: 'phone_number',
  role: 'role',
  function: 'function',
  language: 'language',
  password: 'password',
};
