import styled from 'styled-components';
import tw from 'twin.macro';
interface StyledStepProps {
  $activeStep: boolean;
}

export const StyledStep = styled.div<StyledStepProps>`
  ${tw`rounded-full w-2.5 h-2.5 cursor-pointer`}
  ${(props) => (props.$activeStep ? tw`bg-primary` : tw`bg-step`)}
`;
