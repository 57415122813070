/* eslint-disable react/no-unescaped-entities */
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from '@headlessui/react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { type FC, type ReactNode } from 'react';
import Tippy from '@tippyjs/react';

interface AccordionProps {
  title: string;
  body?: string | ReactNode;
  items?: { id: string | number; name: string }[];
  enableTooltip?: boolean;
  defaultOpen?: boolean;
}

const Accordion: FC<AccordionProps> = ({
  title,
  body,
  items,
  enableTooltip = false,
  defaultOpen = false,
}) => {
  return (
    <div className="w-full">
      <div className="mx-auto w-full rounded-2xl bg-menu-light-purple p-4">
        <Disclosure defaultOpen={defaultOpen}>
          {({ open }) => (
            <div>
              <DisclosureButton
                className={`flex w-full justify-between items-center rounded-lg text-left text-base focus:outline-none ${
                  items ? 'cursor-pointer' : 'cursor-default'
                }`}
              >
                <Tippy content={title} maxWidth={700} disabled={!enableTooltip}>
                  <span className="text-gray-55 font-bold truncate">
                    {title}
                  </span>
                </Tippy>
                {items && (
                  <div className="text-secondary-dark">
                    {open ? <FaMinus size={12} /> : <FaPlus size={12} />}
                  </div>
                )}
              </DisclosureButton>

              {items && (
                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-90 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-90 opacity-0"
                >
                  <DisclosurePanel className="text-sm text-gray-50 pt-4">
                    {body}
                    <div className="flex flex-col gap-2">
                      {items?.map((item) => (
                        <div key={item.id}>{item.name}</div>
                      ))}
                    </div>
                  </DisclosurePanel>
                </Transition>
              )}
            </div>
          )}
        </Disclosure>
      </div>
    </div>
  );
};
export default Accordion;
