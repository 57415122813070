import { Table } from '@components/table/Table';
import {
  useDeleteDemoAccount,
  useFetchSysAdminAllUsers,
} from 'api/sysadmin/sysAdminServices';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useGetDemoAccountsColumns } from '../hooks';
import { type SortingState, type Updater } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TableActions } from '@components/table/tableActions';
import useDisclosure from 'hooks/useDisclosure';
import { EditDemoAccountModal } from '../modal/EditDemoAccountModal';
import { ConfirmModal } from '@components/confirmModal';
interface DemoAccountTableProps {
  query: string;
  roleQuery?: string;
}

const DemoAccountTable: FC<DemoAccountTableProps> = ({ query, roleQuery }) => {
  const [activeSort, setActiveSort] = useState({
    id: '',
    desc: false,
  });
  const [orderBy, setOrderBy] = useState<string>('');
  const [demoAccountId, setDemoAccountId] = useState<number | undefined>();
  const { t } = useTranslation();

  const {
    isOpen: isDeleteOpen,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose,
  } = useDisclosure();

  const {
    isOpen: isEditOpen,
    handleOpen: handleEditOpen,
    handleClose: handleEditClose,
  } = useDisclosure();

  const {
    mutate: deleteDemoAccount,
    isPending: isDeletePending,
    isSuccess: isDeleteSuccess,
  } = useDeleteDemoAccount();

  const {
    data: usersListData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchSysAdminAllUsers({
    query,
    role: roleQuery,
    orderBy,
    companyName: 'Demo Company',
  });

  const flatData: UserResponse[] = useMemo(
    () => usersListData?.pages?.flatMap((page: any) => page.users) ?? [],
    [usersListData]
  );
  const totalDBRowCount = usersListData?.pages?.[0]?.total_users ?? 0;

  const createTableActions = (id: number): ReactNode => {
    const actions = [
      {
        name: t('common.actions.delete'),
        onClick: () => {
          setDemoAccountId(id);
          handleDeleteOpen();
        },
      },
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setDemoAccountId(id);
          handleEditOpen();
        },
      },
    ];
    return <TableActions key={`action-${id}`} actions={actions} />;
  };

  const { columns } = useGetDemoAccountsColumns({ createTableActions });

  const handleSortChange = (
    item: Updater<SortingState>
  ): SortingState | undefined => {
    if (typeof item !== 'function') {
      return;
    }

    let sortValue = item([activeSort]);
    if (!sortValue?.length) {
      if (activeSort.id) {
        sortValue = [
          {
            id: activeSort.id,
            desc: !activeSort.desc,
          },
        ];
      } else {
        return;
      }
    }
    setActiveSort(sortValue[0]);

    const { id: sortByColumnName, desc } = sortValue[0];
    const queryNameFilterBy: string = desc
      ? sortByColumnName
      : `-${sortByColumnName}`;

    setOrderBy(queryNameFilterBy);

    return sortValue;
  };

  const handleDeleteDemoAccount = (): void => {
    if (demoAccountId) {
      deleteDemoAccount(demoAccountId);
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      handleDeleteClose();
    }
  }, [isDeleteSuccess]);

  return (
    <>
      <Table
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        data={flatData}
        columns={columns}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        rowEstimateSize={75}
        handleSortChange={handleSortChange}
        manualSorting={true}
      />
      <ConfirmModal
        isOpen={isDeleteOpen}
        handleClose={handleDeleteClose}
        onConfirm={handleDeleteDemoAccount}
        isLoading={isDeletePending}
        contentText="Are you sure you want to delete this demo account? This action can't be undone and all information will be lost."
      />
      <EditDemoAccountModal
        isOpen={isEditOpen}
        handleClose={handleEditClose}
        selectedId={demoAccountId}
      />
    </>
  );
};
export default DemoAccountTable;
