import ThumbsDownIcon from '@components/icons/ThumbsDownIcon/ThumbsDownIcon';
import ThumbsUpIcon from '@components/icons/ThumbsUpIcon/ThumbsUpIcon';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type ReactNode } from 'react';

const useGetFeaturesFlagTableColumn = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): any => {
  const columnHelper = createColumnHelper<any>();
  const { theme } = useTheme();

  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Flag',
      cell: (info) => info.renderValue(),
      enableSorting: true,
      size: 50,
      maxSize: 50,
    }),
    columnHelper.accessor('routes_amount', {
      header: () => 'Routes',
      cell: (info) => info.getValue() ?? 0,
      enableSorting: true,
      size: 50,
      maxSize: 50,
    }),
    columnHelper.accessor('active', {
      header: () => 'Active',
      cell: (info) => {
        return info.getValue() ? (
          <ThumbsUpIcon data-active={info.getValue()} />
        ) : (
          <ThumbsDownIcon
            fill={theme.colors.ipmpv.icon.fail}
            data-active={info.getValue()}
          />
        );
      },
      enableSorting: true,
      size: 50,
      maxSize: 50,
    }),
    columnHelper.accessor('companies_amount', {
      header: () => 'Companies',
      cell: (info) => info.renderValue(),
      enableSorting: true,
      size: 50,
      maxSize: 50,
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};
export default useGetFeaturesFlagTableColumn;
