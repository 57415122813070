import { type FC, useState } from 'react';
import { ChangePasswordForm } from 'pages/SettingsPage/forms/ChangePasswordForm';
import { Button } from 'layout/Button';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

interface ChangeUserPasswordProps {
  isLoading?: boolean;
}

const ChangeUserPassword: FC<ChangeUserPasswordProps> = ({
  isLoading = false,
}) => {
  const [isChangingPassword, setIsChangingPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleIsChangingPassword = (): void => {
    setIsChangingPassword(!isChangingPassword);
  };
  return (
    <div className="font-bold text-base">
      {!isChangingPassword && (
        <div className="flex gap-2 items-center">
          <div className="w-4/12">
            {isLoading ? <Skeleton /> : <p>{t('common.inputs.password')}</p>}
          </div>
          {isLoading ? (
            <Skeleton width={'200px'} borderRadius="20px" height={'40px'} />
          ) : (
            <Button
              label={t('settingsPage.profileTab.changePassword')}
              variant="secondary"
              onClick={handleIsChangingPassword}
            />
          )}
        </div>
      )}
      {isChangingPassword && (
        <ChangePasswordForm
          handleIsChangingPassword={handleIsChangingPassword}
        />
      )}
    </div>
  );
};
export default ChangeUserPassword;
