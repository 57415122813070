import useDisclosure from 'hooks/useDisclosure';
import { Button } from 'layout/Button';
import { type FC, useState } from 'react';
import CreateFeatureFlagModal from './modal/CreateFeatureFlagModal';
import EditFeatureFlagModal from './modal/EditFeatureFlagModal';
import { FeaturesFlagTable } from './table';

const FeaturesFlagPage: FC = () => {
  const [selectedFeatureFlag, setSelectedFeatureMap] = useState<
    number | undefined
  >();
  const { isOpen, handleOpen, handleClose } = useDisclosure();
  const {
    isOpen: isEditOpen,
    handleOpen: handleEditOpen,
    handleClose: handleEditClose,
  } = useDisclosure();

  return (
    <div>
      <div className="flex flex-1 justify-end mb-4">
        <Button label="Add new feature flag" onClick={handleOpen} />
      </div>
      <FeaturesFlagTable
        handleEditOpen={handleEditOpen}
        selectedFeatureFlag={selectedFeatureFlag}
        setSelectedFeatureMap={setSelectedFeatureMap}
      />
      <CreateFeatureFlagModal isOpen={isOpen} handleClose={handleClose} />
      <EditFeatureFlagModal
        isOpen={isEditOpen}
        handleClose={handleEditClose}
        featureFlagId={selectedFeatureFlag}
      />
    </div>
  );
};
export default FeaturesFlagPage;
