import {
  type FC,
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface CurrentUser {
  id: number | null;
  family_name: string | null;
  name: string | null;
  email: string | null;
  company_name: string | null;
  company_id: number | null;
  role: string | null;
}

interface Props {
  children?: ReactNode;
  initState?: CurrentUser;
}

interface AuthContextType {
  user: CurrentUser | null;
  setUser: (user: CurrentUser) => void;
  resetUser: () => void;
}

const currentUser = localStorage.getItem('currentUser');

const getInitialCurrentUser = (): CurrentUser | null => {
  if (currentUser) {
    return JSON.parse(currentUser);
  }
  return null;
};

const initialValue = {
  user: getInitialCurrentUser(),
  setUser: () => {},
  resetUser: () => {},
};

const AuthContext = createContext<AuthContextType>(initialValue);

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

const AuthProvider: FC<Props> = ({ initState, children }) => {
  const [user, setUser] = useState<CurrentUser | null>(
    initState ?? initialValue.user
  );

  const localUser = localStorage.getItem('currentUser');

  const resetUser = (): void => {
    setUser({
      id: null,
      company_id: null,
      name: null,
      family_name: null,
      email: null,
      company_name: null,
      role: null,
    });
  };

  useEffect(() => {
    if (!user?.id && localUser) {
      setUser(JSON.parse(localUser));
    }
  }, [localUser]);

  useEffect(() => {
    if (user?.id) {
      localStorage.setItem('currentUser', JSON.stringify(user));
    } else {
      localStorage.removeItem('currentUser');
    }
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, setUser, resetUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
