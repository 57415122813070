import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { format } from 'date-fns-tz';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { useMemo } from 'react';
import { numericFormatter } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { getDaysFromDate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';
interface CustomPoint extends Highcharts.Point {
  customTooltip?: boolean;
  tooltipPos?: [number, number];
  customPointHeight?: number;
}

interface CustomPlotLineOption extends Highcharts.XAxisPlotLinesOptions {
  customTooltip?: boolean;
}

interface CustomEnergyData extends ChangeTS {
  isCurrentChange?: boolean;
}

interface defaultColumnData {
  x: number;
  y: number;
  impactPerDay: number;
  expectedConsumption: number;
  time: string;
}

export type Dictionary<T> = Record<string, T>;

export interface FormatterPoint
  extends Highcharts.TooltipFormatterContextObject {
  point: Point;
}

export interface Point extends Highcharts.Point {
  impactPerDay: string;
  expected: string;
  time?: string;
  isPlotLine?: boolean;
}

interface ChartHookReturnType {
  defaultOptions: Highcharts.Options;
  changes: CustomPlotLineOption[];
  currentChange: CustomPlotLineOption[];
  labelFormatter: (this: Highcharts.AxisLabelsFormatterContextObject) => string;
  defaultTooltipPointFormatter: (this: Highcharts.Point) => string;
}

export const parseChartDecimals = (value?: number): string =>
  numericFormatter(value?.toString() ?? '0', {
    decimalSeparator: ',',
    thousandSeparator: '.',
    decimalScale: 2,
  });

const useChart = ({
  chartData,
  momentChange,
  changesData,
  unit = '',
  type,
}: {
  chartData?: ChangeTS[] | null;
  momentChange?: string;
  changesData?: { date: string; id?: string }[];
  unit?: string;
  type: 'measured' | 'excess' | 'excess_cusum';
}): ChartHookReturnType => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  // Create the plot lines for the changes and the current change
  const createPlotLineEvents = ({
    linePointDate,
    linePointId,
    type,
  }: {
    linePointDate?: string;
    linePointId?: string;
    type?: 'current_change' | 'change';
  }): Highcharts.XAxisPlotLinesEventsOptions => ({
    mouseover(
      this: Highcharts.PlotLineOrBand,
      e: Event | Dictionary<any> | undefined
    ): void {
      const series = this.axis.series[0];
      const chart = series.chart;
      const PointClass = (series as any).pointClass;
      const tooltip = chart.tooltip;

      const dataPoint = chartData?.find(
        (series) => series.time === linePointDate
      );

      const point = new PointClass().init(series, {
        name: 'Measured',
        customTooltip: {
          ...dataPoint,
          isCurrentChange: type === 'current_change',
        },
      });

      const normalizedEvent = chart.pointer.normalize(e as MouseEvent);

      point.tooltipPos = [
        normalizedEvent.chartX - chart.plotLeft,
        normalizedEvent.chartY - chart.plotTop,
      ];

      point.customTooltip = (this.options as any).customTooltip;

      tooltip.refresh(point);
    },
    mouseout(
      this: Highcharts.PlotLineOrBand,
      e: Event | Dictionary<any> | undefined
    ): void {
      this.axis.chart.tooltip.hide();
    },
    click: (event: Event | Dictionary<any> | undefined) => {
      if (linePointId) {
        event?.preventDefault();
        event?.stopPropagation();
        navigate(`/app/operations/anomalies/details/${linePointId}`);
      }
    },
  });

  // Format the yAxis label to add the suffix unit
  function labelFormatter(
    this: Highcharts.AxisLabelsFormatterContextObject
  ): string {
    const value = this.value;
    return `${value as string} ${unit}`;
  }

  const getConsumptionLabel = (): string => {
    switch (type) {
      case 'excess_cusum':
        return 'CuSUM';
      case 'excess':
        return 'Excess';
      default:
        return 'Actual';
    }
  };

  // Create the tooltip body content for the change and current change
  const createTooltipBody = (customData: CustomEnergyData): string => {
    if (!customData.time) return '';
    const [time] = customData.time.split('GMT');

    const timeString = `Time: <b>${format(
      new Date(time),
      'dd/MM/yyyy HH:mm'
    )}</b><br/>`;

    const timeSinceChange = `Time since change: <b>${getDaysFromDate(
      customData.time
    )}</b></br>`;

    const totalImpact = `Total impact: <b>${parseChartDecimals(
      customData.total_impact ?? 0
    )} €</b>`;

    const impactPerDayLabel = `Impact per day: <b>${parseChartDecimals(
      customData.impact_per_day ?? 0
    )} ${unit}</b></br>`;

    return `${timeString}${timeSinceChange}${impactPerDayLabel}${totalImpact}`;
  };

  // Default tooltip body for the columns
  function defaultTooltipPointFormatter(this: Highcharts.Point): string {
    interface GroupedSeries extends Highcharts.Series {
      groupedData: {
        y: number;
      }[];
    }
    const options = this.options as defaultColumnData;
    const chart = this.series.chart;
    const secondSeries = chart?.series?.find(
      (series) => series.name === 'Expected consumption'
    ) as GroupedSeries;
    const secondSeriesValue =
      secondSeries?.groupedData?.[this.index]?.y ?? options.expectedConsumption;
    const type = getConsumptionLabel();
    const [time] = options.time.split('GMT');

    const excess = (this.y ?? 0) - secondSeriesValue;
    const timeString = `Time: <b>${convertToReadableDate(time)}</b><br/>`;
    const consumptionLabel = `${type} consumption: <b>${parseChartDecimals(
      this.y ?? 0
    )} ${unit}</b></br>`;
    const expectedConsumption = `Expected consumption: <b>${parseChartDecimals(
      secondSeriesValue
    )} ${unit}</b></br>`;
    const excessLabel = `Excess: <b>${parseChartDecimals(excess)} ${unit}</b>`;

    const isMeasuredChart = type === 'Actual';

    return `${timeString}${consumptionLabel}${
      isMeasuredChart ? expectedConsumption : ''
    }${isMeasuredChart ? excessLabel : ''}`;
  }

  // Default chart options
  const defaultOptions: Highcharts.Options = {
    chart: {
      zooming: {
        type: 'x',
      },
    },
    tooltip: {
      split: false,
      // Positioner function is used to position the custom tooltip (Change and Current change) correctly
      positioner(labelWidth, labelHeight, point) {
        const customPoint: CustomPoint = point;
        const chart = this.chart;
        const plotLeft = chart.plotLeft;
        const plotTop = chart.plotTop;
        const plotWidth = chart.plotWidth;
        const plotHeight = chart.plotHeight;
        const columnHeight = customPoint.customPointHeight ?? 1;

        const tooltipX =
          customPoint.plotX ?? customPoint?.tooltipPos?.[0] ?? 0 + plotLeft;

        const tooltipY = customPoint.plotY
          ? customPoint.plotY
          : customPoint?.tooltipPos?.[1] ?? 0 + plotTop + columnHeight / 2;

        const tooltipTop = tooltipY < plotTop + plotHeight / 2;

        const tooltipLeft = tooltipX < plotLeft + plotWidth / 2;

        return {
          x: tooltipLeft ? tooltipX : tooltipX - labelWidth,
          y: tooltipTop ? tooltipY : tooltipY - labelHeight,
        };
      },
      formatter(
        this: Highcharts.TooltipFormatterContextObject,
        tooltip: Highcharts.Tooltip
      ): string | string[] {
        const point: CustomPoint = this.point;

        // Check if it's a custom tooltip to change the color and render the custom body
        if (point.customTooltip) {
          const customData: CustomEnergyData = (point.options as any)
            .customTooltip;

          if (customData.isCurrentChange) {
            tooltip.update({ borderColor: theme.colors.error });
          } else {
            tooltip.update({ borderColor: theme.colors.alert });
          }

          return createTooltipBody(customData);
        } else {
          // Render the default tooltip body
          tooltip.update({ borderColor: theme.colors.tertiary });
          return tooltip.defaultFormatter.call(this, tooltip);
        }
      },
      shape: undefined,
      borderWidth: 2,
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        millisecond: '%b %H:%M:%S',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e. %b',
        week: '%e. %b',
        month: "%b '%y",
        year: '%Y',
      },
      accessibility: {
        enabled: false,
      },
    },
    time: {
      //  set to false to use the time zone of the user's browser
      useUTC: true,
    },
    legend: {
      enabled: true,
    },
  };

  // Changes plot lines
  const changes = useMemo(() => {
    if (changesData) {
      return changesData?.flatMap((linePoint) => {
        if (linePoint.date === momentChange) return [];

        return {
          customTooltip: true,
          color: theme.colors.alert,
          width: 4,
          value: new Date(linePoint.date).getTime(),
          label: {
            text: `Change`,
            style: {
              fontSize: '8px',
            },
          },
          zIndex: 5,
          events: {
            ...createPlotLineEvents({
              linePointDate: linePoint.date,
              linePointId: linePoint.id,
            }),
          },
        };
      });
    }
    return [];
  }, [changesData]);

  // Current change plot line
  const currentChange: CustomPlotLineOption[] = [
    {
      customTooltip: true,
      color: theme.colors.error,
      width: 4,
      value: momentChange ? new Date(momentChange).getTime() : undefined,
      label: {
        text: `Current change`,
        style: {
          fontSize: '8px',
        },
      },
      zIndex: 5,
      events: {
        ...createPlotLineEvents({
          linePointDate: momentChange,
          type: 'current_change',
        }),
      },
    },
  ];

  return {
    defaultOptions,
    changes,
    currentChange,
    labelFormatter,
    defaultTooltipPointFormatter,
  };
};
export default useChart;
