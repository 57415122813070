import { Modal } from '@components/modal';
import {
  useEditDemoAccount,
  useFetchDemoAccount,
} from 'api/sysadmin/sysAdminServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { ManageDemoAccountForm } from '../../form';
import { demoEditAccountSchema } from '../../form/schema';

interface EditDemoAccountModalProps extends Disclosure {
  selectedId?: number;
}

const EditDemoAccountModal: FC<EditDemoAccountModalProps> = ({
  isOpen,
  handleClose,
  selectedId,
}) => {
  const { data } = useFetchDemoAccount(selectedId);
  const { mutate, isPending, isSuccess } = useEditDemoAccount(selectedId);

  const initialValues = useMemo(() => {
    if (data?.user) {
      const user = data.user;
      return {
        email: user.email,
        given_name: user.given_name,
        family_name: user.family_name,
        phone_number: user.phone_number,
      };
    }
  }, [data]);

  const { methods } = useHRForm({
    schema: demoEditAccountSchema,
    initialValues,
  });

  useEffect(() => {
    if (isOpen) {
      methods.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (initialValues) {
      methods.reset(initialValues);
    }
  }, [initialValues]);

  const handleCreateDemoAccount = (): void => {
    const body = methods.getValues();
    mutate(body);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      actionBtns={{
        primary: {
          label: 'Edit Demo account',
          onClick: methods.handleSubmit(handleCreateDemoAccount),
          isLoading: isPending,
        },
        secondary: { label: 'Cancel' },
      }}
      maxWidth={500}
      title={'Create new Demo account'}
    >
      <FormProvider {...methods}>
        <ManageDemoAccountForm isEditing={true} />
      </FormProvider>
    </Modal>
  );
};
export default EditDemoAccountModal;
