import { SearchBar } from '@components/inputs/SearchBar';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import { useDebounce } from 'hooks/useDebounce';
import { ROLES } from 'lib/constants';
import { type FC, useState } from 'react';
import { ImpersonationTable } from './table';
import { useTranslation } from 'react-i18next';

const ImpersonationPage: FC = () => {
  const [query, setQuery] = useState('');
  const [role, setRole] = useState('');
  const { t } = useTranslation();

  const debouncedQuery: string = useDebounce(query, 300);
  return (
    <div>
      <div className="flex gap-6 mb-8">
        <div className="flex-1 max-w-[340px]">
          <SearchBar
            placeholder={t('sysAdminPage.placeholders.search')}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <div className="flex-1 max-w-[210px]">
          <SingleSelectInput
            options={ROLES}
            placeholder={t('common.placeholders.role')}
            value={role}
            onChange={(e) => {
              setRole(e as string);
            }}
          />
        </div>
      </div>
      <ImpersonationTable query={debouncedQuery} roleQuery={role} />
    </div>
  );
};
export default ImpersonationPage;
