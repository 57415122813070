import { type FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  type ColumnDef,
  type SortingState,
  type TableState,
  type Updater,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import 'tippy.js/dist/tippy.css'; // optional
import {
  type FetchNextPageOptions,
  type InfiniteQueryObserverResult,
} from '@tanstack/react-query';

interface TableProps {
  isFetching: boolean;
  data: any;
  columns: ColumnDef<any, any>[];
  state?: Partial<TableState>;
  totalDBRowCount: number;
  rowEstimateSize: number;
  enableRowSelection?: boolean;
  isFetchingNextPage?: boolean;
  manualSorting?: boolean;
  handleSortChange?: (item: Updater<SortingState>) => SortingState | undefined;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
  onClickRow?: (object?: any) => void;
  id?: string;
}

const Table: FC<TableProps> = ({
  data,
  isFetching,
  columns,
  enableRowSelection,
  onClickRow,
  state,
  fetchNextPage,
  totalDBRowCount,
  rowEstimateSize,
  isFetchingNextPage,
  handleSortChange,
  manualSorting = false,
  id,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const isFetchingOrQuerying = useMemo(
    () => isFetching && !isFetchingNextPage,
    [isFetching, isFetchingNextPage]
  );

  const tableData = useMemo(
    () => (isFetchingOrQuerying ? Array(10).fill({}) : data),
    [isFetchingOrQuerying, data]
  );

  const totalFetched = data?.length;

  const tableColumns = useMemo(
    () =>
      isFetchingOrQuerying
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />,
          }))
        : columns,
    [isFetchingOrQuerying, columns]
  );

  const onSortingChange = (item: Updater<SortingState>): void => {
    const sort = handleSortChange?.(item);
    setSorting(sort ?? item);
  };

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      ...state,
    },

    manualSorting,
    onSortingChange: (sortingUpdater) => {
      onSortingChange(sortingUpdater);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => (isFetchingOrQuerying ? 70 : rowEstimateSize),
    overscan: 15,
  });

  const { getVirtualItems, getTotalSize } = rowVirtualizer;

  const paddingTop: number =
    getVirtualItems().length > 0 ? getVirtualItems()?.[0]?.start || 0 : 0;
  const paddingBottom: number =
    getVirtualItems().length > 0
      ? getTotalSize() -
        (getVirtualItems()?.[getVirtualItems().length - 1]?.end || 0)
      : 0;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          void fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  return (
    <div
      className="rounded-lg border border-primary-lighter w-full max-h-[700px] overflow-y-scroll min-h-[300px] block relative bg-white shadow-card-container"
      onScroll={(e) => {
        fetchMoreOnBottomReached(e.target as HTMLDivElement);
      }}
      ref={tableContainerRef}
    >
      <table className="w-full bg-white" id={id}>
        <thead className="sticky top-0 z-20">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="px-4 py-3 text-start bg-gray-10 border-b border-primary-lighter "
                >
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none flex gap-2 items-center uppercase'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                      style: {
                        width:
                          header.getSize() === Number.MAX_SAFE_INTEGER
                            ? 'auto'
                            : header.getSize(),
                      },
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}

                    {header.column.getCanSort() &&
                    !header.column.getIsSorted() ? (
                      <IoIosArrowDown />
                    ) : null}

                    {{
                      asc: <IoIosArrowUp />,
                      desc: <IoIosArrowDown />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {paddingTop > 0 && (
            <tr>
              <td style={{ height: `${paddingTop}px` }} />
            </tr>
          )}
          {getVirtualItems().map((virtualRow, index) => {
            const isLoaderRow = virtualRow.index > rows?.length - 1;
            const row = rows[virtualRow.index];

            return (
              !isLoaderRow && (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell: any, index: number) => {
                    return (
                      <td
                        key={`${row.id} ${cell.id as string}`}
                        className={`${
                          onClickRow ? 'cursor-pointer' : ''
                        } border-b border-primary-lighter py-6 px-4`}
                        onClick={() => {
                          if (index !== row.getVisibleCells()?.length - 1) {
                            const rowData = cell.row.original;
                            if (!isFetching) onClickRow?.(rowData);
                          }
                        }}
                        style={{
                          width:
                            cell.column.getSize() === Number.MAX_SAFE_INTEGER
                              ? 'auto'
                              : cell.column.getSize(),
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              )
            );
          })}
          {isFetchingNextPage && (
            <tr>
              {table.getVisibleFlatColumns()?.map((column) => (
                <td
                  key={column.id}
                  className="border-b border-primary-lighter py-6 px-7"
                >
                  <Skeleton />
                </td>
              ))}
            </tr>
          )}
          {paddingBottom > 0 && (
            <tr>
              <td style={{ height: `${paddingBottom}px` }} />
            </tr>
          )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
};
export default Table;
