import { PasswordField } from 'components/auth/PasswordField';
import { FTextField } from 'components/form/FTextField';
import { useMultistep } from 'context/MultiStepProvider/MultiStepProvider';
import useHRForm from 'hooks/useHRForm';
import { Button } from 'layout/Button';
import { AuthRegisterFooter } from 'pages/Authentication/RegisterPage/components/AuthRegisterFooter';
import { RegisterContainer } from 'pages/Authentication/RegisterPage/components/RegisterContainer';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StepOneInitialValues } from '../initialValue';
import { registerControlNames, registerStepOneSchema } from '../schema';

const RegisterStepOne: FC = () => {
  const {
    methods: { getValues },
    Form,
  } = useHRForm({
    schema: registerStepOneSchema,
    initialValues: StepOneInitialValues,
  });
  const { goToNextStep } = useMultistep();
  const { t } = useTranslation();

  const handleSubmit = (): void => {
    const formData = getValues();
    goToNextStep(formData);
  };

  return (
    <RegisterContainer>
      <Form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-6">
          <FTextField
            name={registerControlNames.email}
            label={t('common.inputs.email')}
            placeholder={t('common.placeholders.email')}
            data-testid="email-input"
          />
          <PasswordField
            name={registerControlNames.password}
            label={t('common.inputs.password')}
            placeholder={t('common.placeholders.createPassword')}
          />
        </div>
        <Button
          type="submit"
          label={t('common.buttons.continue')}
          size="large"
          onClick={() => {}}
          className="w-full mt-9"
        />
        <div>
          <AuthRegisterFooter />
        </div>
      </Form>
    </RegisterContainer>
  );
};

export default RegisterStepOne;
