import { EuroIcon } from '@components/icons/EuroIcon';
import LampIcon from '@components/icons/LampIcon/LampIcon';
import { LuzmoDashboard } from '@components/LuzmoDashboard';
import {
  useFetchReport,
  useFetchReportList,
} from 'api/reports/reportsServices';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectableReportCard } from './components/SelectableReportCard';

export enum ReportEnum {
  ROI = 'ROI',
  VDAB = 'VDAB',
  ALERTS = 'ALERTS',
}

const ReportsPage: FC = () => {
  const { t } = useTranslation();
  const { data: reportList } = useFetchReportList();
  const [activeReport, setActiveReport] = useState('');
  const { data } = useFetchReport(activeReport);

  useEffect(() => {
    if (reportList?.reports && reportList.reports.length > 0 && !activeReport) {
      reportList.reports.sort((a, b) =>
        a.internal_name.localeCompare(b.internal_name)
      );
      setActiveReport(reportList.reports[0].internal_name);
    }
  }, [reportList]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1>{t('reportsPage.title')}</h1>
        <h3 className="text-base">{t('reportsPage.subTitle')}</h3>
      </div>
      <div className="pr-[42px]">
        <CardContainer>
          <div>
            <CardTitle title={t('reportsPage.selectReportTitle')} />
            <h3>{t('reportsPage.selectReportSubTitle')}</h3>
          </div>
          <div className="mt-6 flex gap-4">
            {reportList?.reports?.map((report) => {
              let tooltipMessage;
              let reportIcon;
              if (
                report.internal_name === 'Energy Performance' ||
                report.internal_name === 'Energy Performance Buildings'
              ) {
                tooltipMessage =
                  'Check the normalized evolution of the consumption of each building and asset.';
                reportIcon = <LampIcon />;
              } else if (
                report.internal_name === 'Building and Asset Benchmark' ||
                report.internal_name === 'Building Benchmark'
              ) {
                tooltipMessage =
                  'Compare the energy consumption of your buildings and assets in a smart way.';
                reportIcon = <LampIcon />;
              } else {
                tooltipMessage = t('reportsPage.cards.tooltips.roi');
                reportIcon = <EuroIcon />;
              }
              return (
                <SelectableReportCard
                  key={report.id}
                  value={report.internal_name}
                  onClick={setActiveReport}
                  isSelected={activeReport === report.internal_name}
                  icon={reportIcon}
                  tooltipMessage={tooltipMessage}
                  description={report.internal_name}
                />
              );
            })}
          </div>
        </CardContainer>
      </div>

      <div className="flex w-full">
        <LuzmoDashboard
          authKey={data?.authKey}
          authToken={data?.authToken}
          dashboardId={data?.dashboardId}
        />
      </div>
    </div>
  );
};
export default ReportsPage;
