import { z } from 'zod';

interface ManageDemoAccountControlNamesType {
  email: 'email';
  password: 'password';
  firstName: 'given_name';
  familyName: 'family_name';
  businessNumber: 'phone_number';
}

export const demoAccountSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(10, 'Password must be at least 10 characters long')
    .regex(/[A-Z]/, 'Password must contain at least one capital letter')
    .regex(
      /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
      'Password must contain at least one special character'
    ),
  given_name: z.string(),
  family_name: z.string(),
  phone_number: z.string(),
});

export const demoEditAccountSchema = z.object({
  email: z.string().email(),
  given_name: z.string(),
  family_name: z.string(),
  phone_number: z.string(),
});

export const manageDemoAccountControlNames: ManageDemoAccountControlNamesType =
  {
    email: 'email',
    password: 'password',
    firstName: 'given_name',
    familyName: 'family_name',
    businessNumber: 'phone_number',
  };

export type DemoAccountSchemaType = z.infer<typeof demoAccountSchema>;
export type EditDemoAccountSchemaType = z.infer<typeof demoEditAccountSchema>;
