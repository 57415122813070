import { Modal } from 'components/modal';
import { type FC, useEffect, useMemo, useState } from 'react';
import { ManageUserForm } from '../../forms/ManageUserForm';
import { useFetchUser, useUpdateUser } from 'api/user/userService';
import { manageUserSchema } from 'pages/SettingsPage/forms/ManageUserForm/schema';
import { FormProvider } from 'react-hook-form';
import useHRForm from 'hooks/useHRForm';
import useDisclosure from 'hooks/useDisclosure';
import { ConfirmModal } from '@components/confirmModal';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { useListMeters } from 'api/assets/assetsServices';
import { type Meter } from 'interfaces/assets/Meters.interface';

interface EditUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleDeleteUser: () => void;
  selectedUserId?: number | null;
  companyId?: number | null;
}

const EditUserModal: FC<EditUserModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  handleDeleteUser,
  selectedUserId,
}) => {
  const [metersQuery, setMetersQuery] = useState('');
  const { t } = useTranslation();

  const { data, isFetching } = useFetchUser(selectedUserId);

  const { data: meterList, isFetching: isFetchingMeterList } = useListMeters({
    query: metersQuery,
    perPage: 999,
  });

  const meterOptions = useMemo(
    () =>
      meterList?.pages?.flatMap(
        (page) =>
          page?.meters.map((meter: Meter) => ({
            name: meter.name,
            value: meter.id,
          }))
      ) ?? [],
    [meterList]
  );

  const {
    mutate: updateUserMutate,
    isPending,
    isSuccess,
  } = useUpdateUser(selectedUserId);

  const {
    isOpen: isConfirmOpen,
    handleOpen: handleConfirmOpen,
    handleClose: handleConfirmClose,
  } = useDisclosure();

  const initialValues = useMemo(() => {
    if (!data) return;
    const meters =
      data?.user.meters?.map((meter) => ({
        value: meter.id,
        name: meter.name,
      })) ?? [];
    const groups =
      data?.user.groups?.map((group) => ({
        value: group.id,
        name: group.name,
      })) ?? [];
    const assets =
      data?.user.assets?.map((asset) => ({
        value: asset.id,
        name: asset.name,
      })) ?? [];

    return {
      email: data?.user.email ?? '',
      given_name: data?.user.given_name ?? '',
      family_name: data?.user.family_name ?? '',
      phone_number: data?.user.phone_number ?? '+32',
      role: data?.user.role ?? '',
      function: data?.user.function ?? '',
      language: data?.user.language ?? '',
      meters,
      groups,
      assets,
    };
  }, [data]);

  const { methods } = useHRForm({
    schema: manageUserSchema,
    values: initialValues,
    initialValues,
  });

  const handleEditUser = (): void => {
    const body = methods.getValues();
    const parsedMetersId = body.meters
      ?.filter((meter) => meter.value !== 'selectAll')
      .map((meter) => ({ id: meter.value }));
    const parsedGroupsId = body.groups
      ?.filter((group) => group.value !== 'selectAll')
      .map((group) => ({ id: group.value }));
    const parsedAssetsId = body.assets
      ?.filter((asset) => asset.value !== 'selectAll')
      .map((asset) => ({ id: asset.value }));
    updateUserMutate({
      ...body,
      meters: parsedMetersId,
      assets: parsedAssetsId,
      groups: parsedGroupsId,
    });
  };

  const closeModal = (): void => {
    handleClose();
  };

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('common.buttons.saveChanges'),
          onClick: async () => {
            await methods.handleSubmit(handleEditUser)();
          },
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      title={t('settingsPage.usersTab.editUserModal.title')}
    >
      <div className="flex justify-end">
        {isFetching ? (
          <Skeleton width={80} />
        ) : (
          <p
            className="text-secondary-dark text-base cursor-pointer"
            onClick={handleConfirmOpen}
          >
            Delete user
          </p>
        )}
      </div>
      <FormProvider {...methods}>
        <ManageUserForm
          isLoading={isFetching}
          initialValues={initialValues}
          setMetersQuery={setMetersQuery}
          meterOptions={meterOptions}
          isFetchingMeterList={isFetchingMeterList}
          metersQuery={metersQuery}
        />
      </FormProvider>

      <ConfirmModal
        isOpen={isConfirmOpen}
        handleOpen={handleConfirmOpen}
        handleClose={handleConfirmClose}
        onConfirm={handleDeleteUser}
        contentText={t('common.confirmModal.delete')}
      />
    </Modal>
  );
};
export default EditUserModal;
