import { type FC } from 'react';
import { CompanyForm } from '../../forms/CompanyForm';
// import { ContractsInfo } from '../../components/ContractsInfo';
import UtilityPrices from 'pages/SettingsPage/components/UtilityPrices/UtilityPrices';

const CompanyTabContent: FC = () => {
  return (
    <div className="flex flex-col gap-6">
      <CompanyForm />
      {/* <ContractsInfo /> */}
      <UtilityPrices />
    </div>
  );
};
export default CompanyTabContent;
