import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { StatusChip } from '@components/statusChip';
import i18next from '../../../../../i18n';
import { getUtilityTypeUnit } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { numericFormatter } from 'react-number-format';

interface useGetAlertsHistoryTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetAlertsHistoryTableColumns =
  (): useGetAlertsHistoryTableColumnsReturnType => {
    const columnHelper = createColumnHelper<any>();

    const columns = [
      columnHelper.accessor('connection_point_id_client', {
        header: () => i18next.t('alertsPage.historyTab.table.meterId'),
        cell: (info) => (
          <span className="truncate phone:max-w-[250px] desktop:max-w-[300px] block">
            {info.renderValue()}
          </span>
        ),
      }),
      columnHelper.accessor('main_building', {
        header: () => i18next.t('alertsPage.historyTab.table.assetName'),
        cell: (info) => <span>{info.renderValue() ?? '-'}</span>,
      }),
      columnHelper.accessor('moment_change', {
        header: () => i18next.t('alertsPage.historyTab.table.momentOfChange'),
        cell: (info) => (
          <span>{convertToReadableDate(info.renderValue())}</span>
        ),
      }),
      columnHelper.accessor('custom_event_execution_time', {
        header: () => i18next.t('alertsPage.historyTab.table.alertSent'),
        cell: (info) => (
          <span>{convertToReadableDate(info.renderValue())}</span>
        ),
      }),
      columnHelper.accessor('user_email', {
        header: () => i18next.t('alertsPage.historyTab.table.user_email'),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.accessor('impact_total_euro', {
        header: () => i18next.t('alertsPage.historyTab.table.total_impact'),
        cell: (info) => {
          const utilityType = info.row.original.utility_type;
          return `${numericFormatter((info.getValue() ?? 0).toFixed(2), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 2,
          })} ${getUtilityTypeUnit(utilityType)}`;
        },
      }),
      columnHelper.accessor('status', {
        header: () =>
          i18next.t('alertsPage.historyTab.table.status_during_change'),
        cell: (info) => <StatusChip status={info.getValue()} />,
      }),
    ];

    return {
      columns,
    };
  };
export default useGetAlertsHistoryTableColumns;
