import { CalendarIcon } from '@components/icons/CalendarIcon';
import { type FC, useState } from 'react';
import DatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { type FieldError } from 'react-hook-form';
import { InputAddorment } from '../InputAddornment';
import { type InputAddormentProps } from '../InputAddornment/InputAddorment';

export interface DatePickerInputProps
  extends InputAddormentProps,
    Omit<ReactDatePickerProps, 'value'> {
  value?: Date;
  onChange: (date: Date | null) => void;
  disabled?: boolean;
  error?: FieldError | undefined;
}

const DatePickerInput: FC<DatePickerInputProps> = ({
  value,
  onChange,
  disabled,
  error,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <InputAddorment
      addorment={{
        endAddorment: (
          <CalendarIcon
            className="cursor-pointer"
            onClick={() => {
              setIsOpen(true);
            }}
          />
        ),
      }}
      inputDisabled={disabled}
      error={error}
    >
      <DatePicker
        // locale={'br'}
        open={isOpen}
        onInputClick={() => {
          setIsOpen(true);
        }}
        onClickOutside={() => {
          setIsOpen(false);
        }}
        wrapperClassName="flex-1"
        className="flex-1 w-full"
        selected={value}
        onChange={(date) => {
          onChange(date);
        }}
        dateFormatCalendar="dd MMM yyyy"
        dateFormat="dd/MM/yyyy"
        disabled={disabled}
        autoComplete="on"
        {...props}
      />
    </InputAddorment>
  );
};
export default DatePickerInput;
