import SliderComponent, {
  type SliderComponentProps,
} from '@components/inputs/Slider/Slider';
import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FLabel } from '../FLabel';
import { FErrorLabel } from '../FErrorLabel';

interface FSliderInputProps extends Omit<SliderComponentProps, 'onChange'> {
  label?: string;
  name: string;
}

const FSliderInput: FC<FSliderInputProps> = ({ name, label, ...props }) => {
  const { control } = useFormContext();
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex flex-col gap-1.5">
        {label && <FLabel>{label}</FLabel>}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <SliderComponent {...field} {...props} />
            <FErrorLabel message={error?.message} />
          </>
        )}
      />
    </div>
  );
};
export default FSliderInput;
