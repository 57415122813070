import {
  type FC,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Combobox,
  ComboboxButton,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCheckbox,
  IoIosClose,
} from 'react-icons/io';
import { LoadingSpinner } from '@components/loadingSpinner';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { SelectedItem, SelectedOptionsContainer } from './styled';
import { type DropdownOptions } from '../SelectInput/MultiSelectInput/MultiSelectInput';
import { arraysAreEqual } from 'utils/arraysAreEqual';
import { FErrorLabel } from '@components/form/FErrorLabel';
import { type FieldError } from 'react-hook-form';
import { addInputErrorClasses } from 'utils/handleFormErrorStyle';
import { useTranslation } from 'react-i18next';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { createRoot } from 'react-dom/client';
import SearchContainer from './SearchContainer';
import useClickOutside from 'hooks/useOutsideClick/useOutsideClick';

export interface MultiComboBoxInputProps {
  query: string;
  handleQuery: (value: string) => void;
  options: DropdownOptions[];
  value?: DropdownOptions[];
  placeholder?: string;
  isFetching: boolean;
  onChange?: (value: DropdownOptions[]) => void;
  initialValues?: DropdownOptions[];
  error?: FieldError | undefined;
  'data-testid'?: string;
  selectedOptionsContainerClasses?: string;
  removeRelativeContainer?: boolean;
  disableSelectedList?: boolean;
  maxSelected?: number;
  disabled?: boolean;
  selectAll?: boolean;
  id?: string;
}

const compareValues = (a: DropdownOptions, b: DropdownOptions): boolean =>
  a.value === b.value;

const MultiComboBoxInput: FC<MultiComboBoxInputProps> = forwardRef(
  (
    {
      query,
      handleQuery,
      options,
      placeholder = '',
      isFetching,
      value,
      onChange,
      error,
      initialValues,
      'data-testid': testid,
      selectedOptionsContainerClasses,
      removeRelativeContainer = false,
      disableSelectedList = false,
      maxSelected,
      disabled = false,
      selectAll = false,
      id,
    },
    ref
  ) => {
    const [selectedList, setSelected] = useState<DropdownOptions[]>([]);
    const [areAllSelected, setAreAllSelected] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [comboboxRef, setComboboxRef] = useState<Element | null>(null);
    const dropdownRef = useRef(null);

    const { theme } = useTheme();
    const { t } = useTranslation();

    const parsedOptions = useMemo(() => {
      if (selectAll && options?.length > 0) {
        return [{ value: 'selectAll', name: 'Select all results' }, ...options];
      }
      return options;
    }, [selectAll, options]);

    const handleOutsideClick = (): void => {
      setIsOpen(false);
    };

    useClickOutside(dropdownRef, handleOutsideClick);

    useEffect(() => {
      if (!onChange) return;

      if (selectAll) {
        const parsedList = selectedList.filter(
          (item) => item.value !== 'selectAll'
        );
        onChange(parsedList);
      }

      onChange(selectedList);
    }, [selectedList, onChange]);

    useEffect(() => {
      if (initialValues && selectedList.length === 0) {
        setSelected(initialValues);
      }
    }, [initialValues]);

    useEffect(() => {
      // reset selectAll option each time we change the options filter
      if (selectAll && areAllSelected) {
        setAreAllSelected(false);
        const updatedList = selectedList.filter(
          (item) => item.value !== 'selectAll'
        );
        setSelected(updatedList);
      }
    }, [parsedOptions]);

    const handleRemoveFromSelectedList = (id: number | string): void => {
      const updatedSelectedList = [...selectedList];
      const indexToRemove = updatedSelectedList.findIndex(
        (item) => item.value === id
      );

      if (indexToRemove !== -1) {
        updatedSelectedList.splice(indexToRemove, 1);
      }
      if (!arraysAreEqual(selectedList, updatedSelectedList)) {
        setSelected(updatedSelectedList);
      }
    };

    const handleSelect = (list: DropdownOptions[]): void => {
      // if we select "selectAll"
      if (isSelectAllInList(list) && !areAllSelected) {
        setAreAllSelected(true);
        // TODO verify the results + selected options size are not > maxSelected
        list = addAllResultsToList(list);
      } // if we unselect "selectAll"
      else if (!isSelectAllInList(list) && areAllSelected) {
        list = removeAllResultsFromList(list);
        setAreAllSelected(false);
      }
      if (maxSelected && maxSelected < list.length) {
        return;
      }
      setSelected(list);
    };

    const isSelectAllInList = (list: DropdownOptions[]): boolean => {
      return list.some((item) => item.value === 'selectAll');
    };

    const addAllResultsToList = (
      list: DropdownOptions[]
    ): DropdownOptions[] => {
      const combinedList = [...list, ...parsedOptions];
      const uniqueList = Array.from(
        new Map(combinedList.map((item) => [item.value, item])).values()
      );
      return uniqueList;
    };

    const removeAllResultsFromList = (
      list: DropdownOptions[]
    ): DropdownOptions[] => {
      const filteredList = list.filter(
        (listItem) =>
          !parsedOptions.some(
            (optionItem) => optionItem.value === listItem.value
          )
      );

      return filteredList;
    };

    // Add the search input inside the options modal
    useEffect(() => {
      if (isOpen && comboboxRef) {
        const newChild = document.createElement('div');
        const firstChild = comboboxRef.firstChild;
        comboboxRef.insertBefore(newChild, firstChild);
        const root = createRoot(newChild);
        root.render(<SearchContainer handleQuery={handleQuery} />);
      }
    }, [comboboxRef, isOpen]);

    return (
      <div
        className={`${removeRelativeContainer ? '' : 'relative'} flex-1`}
        ref={dropdownRef}
      >
        <Combobox
          value={selectedList}
          by={compareValues}
          onChange={handleSelect}
          multiple={true}
          virtual={{
            disabled: (option) => option.disabled,
            options:
              options?.length > 0
                ? parsedOptions
                : [{ value: 'empty', disabled: true }],
          }}
          disabled={disabled}
          onClose={() => {
            handleQuery('');
          }}
        >
          <div className="w-full">
            <ComboboxButton
              id={id}
              data-testid={id && `${id}-button`}
              className={`relative w-full flex flex-1 items-center justify-between self-stretch px-3 py-2 border rounded-lg h-11 focus-within:border-primary-light ${
                error ? addInputErrorClasses(error) : 'border-gray-30'
              } ${disabled ? 'bg-gray-25' : 'bg-white'}
 `}
              onClick={(event: any) => {
                setIsOpen(!isOpen);
              }}
            >
              <div>
                {!selectedList.length && (
                  <span className="text-gray-400">{placeholder}</span>
                )}
                {selectedList.length > 0 && (
                  <span>
                    Selected (
                    {areAllSelected
                      ? selectedList.length - 1
                      : selectedList.length}
                    )
                  </span>
                )}
              </div>

              {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </ComboboxButton>

            <div>
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                show={isOpen}
              >
                <div className="absolute left-0 w-full rounded-md py-1 max-h-60 bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none phone:text-sm z-10">
                  <ComboboxOptions
                    static
                    data-testid={id && `${id}-ul`}
                    className={
                      'mt-1 bg-white max-h-60 w-full overflow-auto py-1'
                    }
                    ref={setComboboxRef}
                    as={'ul'}
                  >
                    {({ option }) => {
                      return option.value === 'empty' && isFetching ? (
                        <div className="flex items-center gap-4 px-4 py-2">
                          <LoadingSpinner />
                          <span>{t('common.inputs.searching')}</span>
                        </div>
                      ) : option.value === 'empty' ? (
                        <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                          {t('common.errors.nothingFound')}
                        </div>
                      ) : (
                        <ComboboxOption
                          key={option?.value}
                          className={({ focus, selected }) =>
                            `relative cursor-pointer select-none w-full truncate py-2 pl-10 pr-4 ${
                              focus || selected
                                ? 'bg-primary-lighter font-bold text-gray-60'
                                : 'text-gray-50 bg-white'
                            }`
                          }
                          value={option}
                          as={'li'}
                        >
                          {({ selected }) => {
                            return (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? 'font-medium' : 'font-normal'
                                  }`}
                                >
                                  {option.name}
                                </span>

                                {/* Change checkbox icon if the option is selected */}
                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 left-3 flex items-center`}
                                  >
                                    <IoIosCheckbox
                                      className="h-5 w-5"
                                      data-testid="checked-item-combobox"
                                      color={theme.colors.primary}
                                    />
                                  </span>
                                ) : (
                                  // Default empty checkbox icon
                                  <span
                                    className={`absolute inset-y-0 left-3 flex items-center`}
                                  >
                                    <MdCheckBoxOutlineBlank
                                      color="black"
                                      className="h-5 w-5"
                                    />
                                  </span>
                                )}
                              </>
                            );
                          }}
                        </ComboboxOption>
                      );
                    }}
                  </ComboboxOptions>
                </div>
              </Transition>
            </div>
          </div>
          <FErrorLabel message={error?.message} />
          {!disableSelectedList && (
            <SelectedOptionsContainer
              className={selectedOptionsContainerClasses ?? ''}
              data-testid={`${testid ?? 'combobox'}-list`}
            >
              {selectedList
                ?.filter(
                  (selected) =>
                    selected.value !== 'selectAll' &&
                    selected.name !== 'selectAll'
                )
                .map((selected, index) => {
                  return (
                    <SelectedItem
                      key={selected.value}
                      $index={index}
                      $length={selectedList.length}
                    >
                      <span> {selected.name}</span>
                      {!disabled && (
                        <IoIosClose
                          size={22}
                          className="cursor-pointer text-primary"
                          onClick={() => {
                            handleRemoveFromSelectedList(selected.value);
                          }}
                          data-testid={`remove-option-${index + 1}`}
                        />
                      )}
                    </SelectedItem>
                  );
                })}
            </SelectedOptionsContainer>
          )}
        </Combobox>
      </div>
    );
  }
);

export default MultiComboBoxInput;
