import { SearchBar } from '@components/inputs/SearchBar';
import { Table } from '@components/table/Table';
import {
  type PatchPermissionRolePayload,
  useEditRolePermissions,
  useListPermissionMap,
} from 'api/sysadmin/sysAdminServices';
import { useDebounce } from 'hooks/useDebounce';
import { type PermissionFlag } from 'interfaces/permission/Permission.interface';
import { Button } from 'layout/Button';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useGetRolePermissionTableColumn } from '../hooks';

const RolePermissionTable: FC = () => {
  const [tableData, setTableData] = useState<PermissionFlag[]>([]);
  const [payload, setPayload] = useState<PatchPermissionRolePayload[]>([]);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);
  const {
    data: permissionList,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useListPermissionMap({ query: debouncedQuery });
  const { mutate } = useEditRolePermissions();

  const flatData = useMemo(
    () => permissionList?.pages?.flatMap((page) => page?.permission_maps) ?? [],
    [permissionList]
  );

  const totalDBRowCount = permissionList?.pages?.[0]?.total ?? 0;

  const handleCheckboxChange = (
    type: string,
    id: number,
    value: boolean
  ): void => {
    // Find the index of the element in the table data array
    const index = tableData.findIndex((el) => el.id === id);

    if (index === -1) return; // Exit if the element is not found

    // Create a copy of the table data array and update the element at the found index
    const updatedData = [...tableData];
    updatedData[index] = { ...updatedData[index], [type]: value };

    // Update the state with the new data array
    setTableData(updatedData);

    // Construct the new permission role object
    const newPermissionRole = {
      id,
      sysadmin: updatedData[index].sysadmin,
      reader: updatedData[index].reader,
      admin: updatedData[index].admin,
      responsible: updatedData[index].responsible,
      manager: updatedData[index].manager,
    };

    // Check if the payload already contains the role
    const payloadIndex = payload.findIndex((el) => el.id === id);

    // Create a copy of the payload array and update or add the new role
    const updatedPayload = [...payload];
    if (payloadIndex !== -1) {
      updatedPayload[payloadIndex] = newPermissionRole;
    } else {
      updatedPayload.push(newPermissionRole);
    }

    // Update the state with the new payload array
    setPayload(updatedPayload);
  };

  const { columns } = useGetRolePermissionTableColumn({
    onChange: handleCheckboxChange,
  });
  const saveRolePermissions = (): void => {
    mutate(payload);
  };

  const isDisabled = payload.length === 0;

  useEffect(() => {
    if (flatData) {
      setTableData(flatData);
    }
  }, [flatData]);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="max-w-[420px] flex-1">
          <SearchBar
            placeholder="Search by endpoint name..."
            value={query}
            onChange={(e) => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <Button
          minWidth="200"
          label="Save changes"
          onClick={saveRolePermissions}
          disabled={isDisabled}
          variant={isDisabled ? 'gray' : 'primary'}
        />
      </div>
      <Table
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        data={tableData}
        columns={columns}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        rowEstimateSize={75}
      />
    </>
  );
};
export default RolePermissionTable;
