export const parseDecimal = (num: number | string, fixed: number): string => {
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  const result = num?.toString().match(re);

  if (result) {
    return result[0];
  }

  return '';
};

export const convertNumericFormatToString = (num: string): string => {
  // convert "33.333.333.333,44" to "33333333333.44"
  let modified = num.replaceAll('.', '');
  modified = modified.replace(',', '.');
  return modified;
};

export const convertStringToInt = (str: string): number | null => {
  const isNum = !isNaN(parseInt(str));
  if (isNum) {
    return parseInt(str);
  }
  return null;
};

export const isNumberOdd = (num: number): boolean => {
  return num % 2 !== 0;
};
