import { FErrorLabel } from 'components/form/FErrorLabel';
import {
  type FC,
  type HTMLInputTypeAttribute,
  type InputHTMLAttributes,
  type Ref,
  forwardRef,
} from 'react';
import { addTextErrorClasses } from 'utils/handleFormErrorStyle';
import InputAddorment, {
  type InputAddormentProps,
} from '../InputAddornment/InputAddorment';
import { StyledInput } from './styled';

export interface TextInputProps
  extends InputAddormentProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'children'> {
  type?: HTMLInputTypeAttribute | undefined;
  className?: string;
  disabled?: boolean;
}

const TextInput: FC<TextInputProps> = forwardRef(
  (
    {
      addorment,
      className,
      error,
      type = 'text',
      disabled,
      wrapperStyle,
      ...props
    },
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <div className={`flex-1 bg-white`}>
        <InputAddorment
          error={error}
          addorment={addorment}
          inputDisabled={disabled}
          wrapperStyle={wrapperStyle}
        >
          <StyledInput
            ref={ref}
            type={type}
            className={`${className ?? ''} ${addTextErrorClasses(error)}`}
            disabled={disabled}
            {...props}
          />
        </InputAddorment>
        <FErrorLabel message={error?.message} />
      </div>
    );
  }
);

export default TextInput;
