import { type FC } from 'react';
import { useAuth } from 'hooks/contexts/AuthContext';
import { IntercomProvider } from 'react-use-intercom';

const IntercomChat: FC = () => {
  const { user } = useAuth();
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID ?? '';
  const INTERCOM_API_URL = process.env.REACT_APP_INTERCOM_API_URL ?? '';
  const sanitizeField = (value: string | number | undefined | null): string =>
    String(value ?? '');
  return user !== null ? (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      autoBoot
      autoBootProps={{
        name: sanitizeField(user.name),
        email: sanitizeField(user.email),
        createdAt: String(Date.now()),
        userId: sanitizeField(user.id),
        company: {
          name: sanitizeField(user.company_name),
          companyId: sanitizeField(user.company_id),
        },
      }}
      apiBase={INTERCOM_API_URL}
    />
  ) : null;
};

export default IntercomChat;
