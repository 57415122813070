import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const useGetGroupsTableColumn = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): any => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => t('assetsPage.table.meterName'),
      cell: (info) => (
        <span className="truncate phone:max-w-[250px] desktop:max-w-[300px] block">
          {info.renderValue()}
        </span>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('description', {
      header: () => t('assetsPage.table.description'),
      cell: (info) => (
        <span className="truncate block overflow-hidden phone:max-w-[100px] laptop:max-w-[130px]">
          {info.renderValue() ?? '-'}
        </span>
      ),
    }),
    columnHelper.accessor('meters', {
      header: () => t('assetsPage.group.table.numberOfMeters'),
      cell: (info) => info.getValue()?.length,
    }),
    columnHelper.accessor('assets', {
      header: () => t('assetsPage.group.table.numberOfAssets'),
      cell: (info) => info.getValue()?.length,
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};
export default useGetGroupsTableColumn;
