import { z } from 'zod';
interface GroupControlNamesType {
  name: 'name';
  description: 'description';
  meters: 'meters';
  building: 'building';
}

export const groupSchema = z.object({
  name: z.string().trim().min(3, 'Minimum 3 characters required'),
  description: z.string().optional(),
  meters: z
    .array(
      z.object({
        name: z.string(),
        value: z.number().or(z.string()),
      })
    )
    .min(1),

  building: z.string().optional(),
});

export const groupControlNames: GroupControlNamesType = {
  name: 'name',
  description: 'description',
  meters: 'meters',
  building: 'building',
};

export type GroupSchemaType = z.infer<typeof groupSchema>;
