import { useNavigate } from 'react-router-dom';

interface useURLSearchParamsReturnType {
  addURLSearchParams: (paramName: string, paramValue: string) => void;
  getURLSearchParams: (paramName: string) => string | null;
  deleteURLSearchParams: (paramName: string) => void;
  getAllUrlFilters: () => Record<string, string>;
}

const useURLSearchParams = (): useURLSearchParamsReturnType => {
  const navigate = useNavigate();

  const addURLSearchParams = (paramName: string, paramValue: string): void => {
    const params = new URLSearchParams(location.search);
    params.set(paramName, paramValue);

    navigate(`${location.pathname}?${params.toString()}`);
  };

  const getURLSearchParams = (paramName: string): string | null => {
    const params = new URLSearchParams(location.search);
    return params.get(paramName);
  };

  const deleteURLSearchParams = (paramName: string): void => {
    const params = new URLSearchParams(location.search);
    params.delete(paramName);
    navigate(`${location.pathname}?${params.toString()}`);
  };

  const getAllUrlFilters = (): Record<string, string> => {
    const params = new URLSearchParams(location.search);
    const filters: Record<string, string> = {};

    for (const [key, value] of params.entries()) {
      filters[key] = value;
    }

    return filters;
  };

  return {
    addURLSearchParams,
    getURLSearchParams,
    deleteURLSearchParams,
    getAllUrlFilters,
  };
};
export default useURLSearchParams;
