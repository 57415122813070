import { type FC } from 'react';
import FLabel from '../FLabel/FLabel';
import { RadioContainer } from '@components/inputs/RadioGroup';
import { Controller, useFormContext } from 'react-hook-form';
import { type RadioContainerProps } from '@components/inputs/RadioGroup/RadioGroup';
import { type ControlledInput } from 'interfaces/inputs/ControlledInput.interface';

interface LabelProp {
  label?: string;
}
export interface ControlledRadioGroupProps
  extends RadioContainerProps,
    ControlledInput {}

export type FRadioGroupProps = ControlledRadioGroupProps & LabelProp;

const FRadioGroup: FC<FRadioGroupProps> = ({
  label,
  name,
  ...props
}: FRadioGroupProps) => {
  const { control } = useFormContext();
  const dataTestId = `${name}-field`;

  return (
    <div className="flex flex-1 flex-col gap-2" data-testid={dataTestId}>
      {label && <FLabel>{label}</FLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <RadioContainer data-testid={dataTestId} {...field} {...props} />
          );
        }}
      />
    </div>
  );
};
export default FRadioGroup;
