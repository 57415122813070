// import NotificationsIcon from 'components/icons/MenuIcons/NotificationsIcon';
import Avatar from 'react-avatar';
import { type FC } from 'react';
import { MenuDropdown } from 'components/menuDropdown';
import { logout } from 'api/auth/authService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/contexts/AuthContext';
import { NotificationDrawer } from './components/NotificationDrawer';

const MainHeader: FC = () => {
  const navigate = useNavigate();

  const { user, resetUser } = useAuth();

  const handleLogout = async (): Promise<void> => {
    resetUser();
    await logout(navigate);
  };

  return (
    <div className="flex justify-between bg-primary h-[64px] px-11 py-3">
      <div className="text-white flex gap-1 items-center">
        <p className="font-bold">Hello, </p>
        <span>
          {user?.name} {user?.family_name}
        </span>
      </div>
      <div className="flex items-center gap-6">
        <NotificationDrawer />
        {
          // TODO: Add real name
        }
        <div>
          <MenuDropdown
            label={
              <Avatar
                name={user?.name ?? ''}
                size={'32px'}
                className="rounded-full cursor-pointer"
                textSizeRatio={2}
              />
            }
            menuOptions={[
              {
                name: 'Logout',
                onClick: handleLogout,
              },
            ]}
            enableDropdownArrow={false}
          />
        </div>
      </div>
    </div>
  );
};
export default MainHeader;
