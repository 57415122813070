import { Modal } from '@components/modal';
import useHRForm from 'hooks/useHRForm';
import { type FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import {
  type MeterOption,
  manageAssetSchema,
} from '../../form/ManageAssetForm/schema';
import { ManageAssetForm } from '../../form/ManageAssetForm';
import { useCreateAsset } from 'api/assets/assetsServices';
import { t } from 'i18next';
interface AddAssetModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
}

const AddAssetModal: FC<AddAssetModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
}) => {
  const { methods } = useHRForm({
    schema: manageAssetSchema,
  });

  const { mutate: createAssetMutate, isPending, isSuccess } = useCreateAsset();

  const closeModal = (): void => {
    methods.reset({}, { keepValues: false });
    handleClose();
  };

  const handleAddAsset = async (): Promise<void> => {
    const formData = methods.getValues();
    const params = {
      name: formData.name,
      function: formData.function,
      type: formData.type,
      meters: formData.meters?.map((meter: MeterOption) => ({
        id: meter.value,
      })),
      surface_area: formData.surface_area,
      address: {
        ...formData.address,
        timezone: 'America/New_York',
        latitude: 40.7128,
        longitude: -74.006,
        province: formData.address.province ?? '',
      },
      status: 'READY',
      function_string: 'Office Building',
    };
    methods.clearErrors();

    createAssetMutate(params);
    methods.reset({}, { keepValues: false });
  };

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('assetsPage.AddAssetModal.label'),
          onClick: methods.handleSubmit(handleAddAsset),

          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      title={t('assetsPage.AddAssetModal.title')}
    >
      <FormProvider {...methods}>
        <ManageAssetForm methods={methods} />
      </FormProvider>
    </Modal>
  );
};
export default AddAssetModal;
