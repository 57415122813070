import { type FC } from 'react';

interface FErrorLabelProps {
  message: string | undefined | null;
}

const FErrorLabel: FC<FErrorLabelProps> = ({ message }) => {
  return (
    <div className="text-error pl-1">
      <p>{message}</p>
    </div>
  );
};
export default FErrorLabel;
