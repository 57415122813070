import useHRForm from 'hooks/useHRForm';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangePasswordFormInitialValues } from './initialValues';
import {
  type ChangePasswordSchemaType,
  changePasswordControlNames,
  changePasswordSchema,
} from './schema';
import { PasswordField } from '@components/auth/PasswordField';
import { Button } from 'layout/Button';
import { changePassword } from 'api/user/userService';

interface ChangePasswordFormProps {
  handleIsChangingPassword: () => void;
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
  handleIsChangingPassword,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { Form } = useHRForm({
    schema: changePasswordSchema,
    initialValues: ChangePasswordFormInitialValues,
  });

  const onSubmit = async (data: ChangePasswordSchemaType): Promise<void> => {
    setIsLoading(true);
    const response = await changePassword(data);
    if (response?.status === 200) {
      handleIsChangingPassword();
    }
    setIsLoading(false);
  };

  return (
    <div data-testid="change-password-form">
      <Form onSubmit={onSubmit}>
        <p className="mb-4">{t('common.inputs.password')}</p>
        <div className="flex flex-col gap-6">
          <div>
            <label htmlFor="user-current-password">
              {t('common.inputs.currentPassword')}
            </label>
            <PasswordField
              name={changePasswordControlNames.currentPassword}
              data-testid="current-password"
              id="user-current-password"
            />
          </div>
          <div>
            <label htmlFor="user-new-password">
              {t('common.inputs.newPassword')}
            </label>
            <PasswordField
              name={changePasswordControlNames.newPassword}
              data-testid="new-password"
              id="user-new-password"
            />
          </div>
          <div>
            <label htmlFor="user-confirm-new-password">
              {t('common.inputs.confirmNewPassword')}
            </label>
            <PasswordField
              name={changePasswordControlNames.confirmNewPassword}
              data-testid="confirm-new-password"
              id="user-confirm-new-password"
            />
          </div>
          <div className="flex gap-6">
            <Button
              label={t('common.buttons.cancel')}
              variant="secondary"
              onClick={handleIsChangingPassword}
              data-testid="cancel-change-password"
            />
            <Button
              type="submit"
              label={t('common.buttons.saveChanges')}
              variant="primary"
              data-testid="save-change-password"
              isLoading={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
export default ChangePasswordForm;
