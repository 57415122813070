import useDisclosure from 'hooks/useDisclosure';
import { Button } from 'layout/Button';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { HeatMapModal } from '../../modal/HeatMapModal';
import { t } from 'i18next';
import Skeleton from 'react-loading-skeleton';
import { type Change } from 'interfaces/change/ChangeData.interface';

interface AbsoluteImpactProps {
  changeData?: Change;
  isLoading: boolean;
  previousAndNextChange:
    | { previous: string | null; next: string | null }
    | undefined;
}

const AbsoluteImpact: FC<AbsoluteImpactProps> = ({
  changeData,
  isLoading,
  previousAndNextChange,
}) => {
  const { isOpen, handleClose, handleOpen } = useDisclosure();
  return (
    <div>
      <CardContainer>
        <CardTitle
          title={t('anomaliesDetailsPage.absoluteImpact.title')}
          subtitle={t('anomaliesDetailsPage.absoluteImpact.subTitle')}
          style={{ fontSize: 14 }}
          className="mb-4"
          isLoading={isLoading}
          skeletonVariant="subtitle"
          endAddorment={
            <div className="mt-5">
              {isLoading ? (
                <Skeleton height={'40px'} width={'140px'} borderRadius={20} />
              ) : (
                <Button
                  label={t('anomaliesDetailsPage.buttons.seeHeatMap')}
                  variant="secondary"
                  onClick={handleOpen}
                />
              )}
            </div>
          }
        />

        <HeatMapModal
          connectionPointIdClient={changeData?.connection_point_id_client}
          momentChange={changeData?.moment_change}
          previousChange={previousAndNextChange?.previous}
          nextChange={previousAndNextChange?.next}
          isOpen={isOpen}
          handleClose={handleClose}
          handleOpen={handleOpen}
          unit={changeData?.meter_unit.unit_type}
        />
      </CardContainer>
    </div>
  );
};
export default AbsoluteImpact;
