import { IconTable } from '@components/table/IconTable';
import { useFetchDashboardList } from 'api/dashboard/dashboardServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type Change } from 'interfaces/change/ChangeData.interface';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetDashboardTableColumns } from '../hooks';

interface DashboardTableProps {
  columnVisibility: {
    impact_euro: boolean;
    impact_unit: boolean;
  };
  onRowHover: any;
  hoveredId: any;
}

const DashboardTable: FC<DashboardTableProps> = ({
  columnVisibility,
  onRowHover,
  hoveredId,
}) => {
  const { data, isFetching } = useFetchDashboardList();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const navigate = useNavigate();
  const { theme } = useTheme();

  const { columns } = useGetDashboardTableColumns();

  const onClickRow = (item: Change): void => {
    if (!item?.id) return;
    navigate(`/app/operations/anomalies/details/${item?.id}`);
  };

  return (
    <IconTable
      columns={columns}
      data={data?.change_detections ?? []}
      isFetching={isFetching}
      state={{ columnVisibility }}
      onClickRow={
        hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.get)
          ? onClickRow
          : undefined
      }
      hoverColor={theme.colors['tertiary-lighter']}
      enableVisualizationRequest={true}
      enableLastColumnClick={true}
      stripped={false}
      onRowHover={onRowHover}
      hoveredId={hoveredId}
    />
  );
};
export default DashboardTable;
