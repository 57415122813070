import ThumbsDownIcon from '@components/icons/ThumbsDownIcon/ThumbsDownIcon';
import ThumbsUpIcon from '@components/icons/ThumbsUpIcon/ThumbsUpIcon';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type ReactNode } from 'react';

const useGetLuzmoReportsTableColumn = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): any => {
  const columnHelper = createColumnHelper<any>();
  const { theme } = useTheme();

  const columns = [
    columnHelper.accessor('internal_name', {
      header: () => 'Name',
      cell: (info) => info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('luzmo_name', {
      header: () => 'Luzmo dashboard',
      cell: (info) => info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('companies', {
      header: () => 'Companies',
      cell: (info) => info.row.original.companies?.length ?? 0,
      enableSorting: true,
    }),
    columnHelper.accessor('is_universal', {
      header: () => 'Universal',
      cell: (info) =>
        info.row.original.is_universal ? (
          <ThumbsUpIcon />
        ) : (
          <ThumbsDownIcon fill={theme.colors.ipmpv.icon.fail} />
        ),
      enableSorting: true,
    }),

    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};
export default useGetLuzmoReportsTableColumn;
