import { Button } from 'layout/Button';
import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
interface EditFormButtonProps {
  isEditing: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
  handleIsEditing: () => void;
  onSubmit: () => any;
  onCancel?: () => void;
}

const EditFormButton: FC<EditFormButtonProps> = ({
  isEditing,
  isSuccess,
  isLoading,
  handleIsEditing,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const handleCancelBtn = (): void => {
    handleIsEditing();
    onCancel?.();
  };

  const handleSaveBtn = async (): Promise<void> => {
    await onSubmit();
  };

  useEffect(() => {
    if (isSuccess) handleIsEditing();
  }, [isSuccess]);

  const SaveChangesContainer: FC = () => (
    <div className="flex gap-4">
      <Button
        label={t('common.buttons.cancel')}
        variant="secondary"
        onClick={handleCancelBtn}
      />
      <Button
        label={t('common.buttons.saveChanges')}
        onClick={handleSaveBtn}
        isLoading={isLoading}
      />
    </div>
  );

  const EditFormContainer: FC = () => (
    <div className="flex gap-4">
      <Button
        label={t('common.buttons.edit')}
        variant="secondary"
        onClick={handleIsEditing}
      />
    </div>
  );
  return <>{isEditing ? <SaveChangesContainer /> : <EditFormContainer />}</>;
};
export default EditFormButton;
