import { type FC, type ReactNode } from 'react';
import LoginBackground from 'assets/images/login-background.png';
import { BackgroundContainer } from './styled';

interface OnboardingWrapperProps {
  children: ReactNode;
}

const OnboardingWrapper: FC<OnboardingWrapperProps> = ({ children }) => {
  return (
    <div className="flex relative" data-testid="login-wrapper">
      <BackgroundContainer>
        <img
          src={LoginBackground}
          alt="Login Background"
          className="w-full max-h-full object-cover object-left-top"
        />
      </BackgroundContainer>
      <div className="phone:flex-1 laptop:flex-[1_0_50%] desktop:flex-[1_1_40%] justify-center min-h-screen overflow-y-auto">
        {children}
      </div>
    </div>
  );
};
export default OnboardingWrapper;
