import { type FC, type SVGProps } from 'react';

const RadioButtonUncheckedIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Radio Button">
      <path
        id="Box"
        d="M1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10Z"
        fill="white"
        stroke="#45526A"
        strokeWidth="2"
      />
    </g>
  </svg>
);
export default RadioButtonUncheckedIcon;
