import { ProportionImpactWrapper } from '@components/wrappers/ProportionImpactWrapper';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TargetContainerProps {
  isLoading: boolean;
  savingsTarget: number;
  currentSavings: number;
}
const TargetContainer: FC<TargetContainerProps> = ({
  isLoading,
  savingsTarget,
  currentSavings,
}) => {
  const { t } = useTranslation();
  return (
    <CardContainer>
      <CardTitle
        title={t('projectsDetailsPage.target')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
      />

      <div className="flex justify-between mt-4">
        <ProportionImpactWrapper
          label={'Total in %'}
          value={currentSavings * 100}
          target={savingsTarget * 100}
          endAddorment={'%'}
          isLoading={isLoading}
          data-testid="savings-total"
          numericColorVisualization={{ isGray: true }}
        />
      </div>
    </CardContainer>
  );
};
export default TargetContainer;
