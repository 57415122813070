import { useFetchUserFeatureMap } from 'api/user/userService';
import { type UserFeatureFlagType } from 'interfaces/featureFlags/FeatureFlags.interface';
import React, {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface FeatureFlagFromProvider {
  endpoint: string;
  method: string;
  description?: string;
}

interface FeatureFlagContextType {
  featureFlags?: UserFeatureFlagType;
  hasFeatureFlagPermission: (flag: FeatureFlagFromProvider) => boolean;
}

const FeatureFlagContext = createContext<FeatureFlagContextType | null>(null);

export const useFeatureFlag = (): FeatureFlagContextType => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error('No context found');
  }
  return context;
};

export const FeatureFlagProvider = ({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element => {
  const location = useLocation();

  const { data, refetch } = useFetchUserFeatureMap();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      await refetch();
    };

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [location.pathname]);

  const hasFeatureFlagPermission = useCallback(
    (validate: FeatureFlagFromProvider) => {
      const features = data?.feature_flags;
      // Check if theres any feature flag, if not, it should render the component
      if (!features) return true;

      for (const feature of Object.values(features)) {
        // Check if the feature flag is active, false = HIDE, true = SHOW
        if (!feature.active) {
          // Validate every route in the response object
          for (const route of feature.routes) {
            if (
              route.method === validate?.method &&
              route.endpoint === validate?.endpoint
            ) {
              return false;
            }
          }
        }
      }
      return true;
    },
    [data]
  );

  return (
    <FeatureFlagContext.Provider
      value={{ featureFlags: data?.feature_flags, hasFeatureFlagPermission }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
