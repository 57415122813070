import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { type CSSProperties, type FC, type ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

interface CardTitleProps {
  title: string;
  subtitle?: string;
  endAddorment?: ReactNode;
  style?: CSSProperties;
  className?: string;
  isLoading?: boolean;
  skeletonVariant?: 'regular' | 'subtitle' | 'subtitleAndButton';
  tooltip?: string;
}

const CardTitle: FC<CardTitleProps> = ({
  title,
  subtitle,
  endAddorment,
  style,
  className,
  isLoading = false,
  skeletonVariant = 'title',
  tooltip,
}) => {
  return (
    <div className={className}>
      <div className="flex justify-between">
        <div>
          <div className="flex gap-2 items-center">
            <h2
              className="uppercase text-base text-gray-60"
              data-testid="card-title"
              style={style}
            >
              {isLoading ? <Skeleton width={'150px'} /> : title}
            </h2>
            {tooltip && <TooltipIconComponent content={tooltip} />}
          </div>

          <p
            className="text-gray-50 leading-[140%] mt-1.5 "
            data-testid="card-subtitle"
          >
            {isLoading &&
            (skeletonVariant === 'subtitleAndButton' ||
              skeletonVariant === 'subtitle') ? (
              <Skeleton width={'250px'} />
            ) : (
              subtitle
            )}
          </p>
        </div>
        <div>
          {isLoading && skeletonVariant === 'subtitleAndButton' ? (
            <Skeleton width={'140px'} height={'40px'} borderRadius={'20px'} />
          ) : (
            endAddorment
          )}
        </div>
      </div>
    </div>
  );
};
export default CardTitle;
