import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OnboardingContainer } from '../../../components/auth/OnboardingContainer';
import { OnboardingWrapper } from '../../../components/auth/OnboardingWrapper';
import { LoginForm } from './forms/LoginForm';

const LoginPage: FC = () => {
  const { t } = useTranslation();
  return (
    <OnboardingWrapper>
      <OnboardingContainer
        heading={t('loginPage.welcome')}
        detailsHeading={t('loginPage.enterDetails')}
      >
        <LoginForm />
      </OnboardingContainer>
    </OnboardingWrapper>
  );
};
export default LoginPage;
