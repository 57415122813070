import { Table } from '@components/table/Table';
import {
  useFetchSysAdminAllUsers,
  useImpersonateUser,
} from 'api/sysadmin/sysAdminServices';
import { useAuth } from 'hooks/contexts/AuthContext';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { Button } from 'layout/Button';
import { type FC, type ReactNode, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetImpersonateUserColumns } from '../hooks';
import { useTranslation } from 'react-i18next';
import { handleError } from 'utils/handleError/handleError';
import { useServerSideTableSorting } from 'hooks/useServerSideTableSorting';
interface ImpersonationTableProps {
  query: string;
  roleQuery?: string;
}

const ImpersonationTable: FC<ImpersonationTableProps> = ({
  query,
  roleQuery,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUser } = useAuth();
  const { orderBy, handleSortChange } = useServerSideTableSorting();

  const {
    data: usersListData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchSysAdminAllUsers({ query, role: roleQuery, orderBy });

  const { mutateAsync: impersonateUser } = useImpersonateUser();

  const flatData: UserResponse[] = useMemo(
    () => usersListData?.pages?.flatMap((page: any) => page.users) ?? [],
    [usersListData]
  );
  const totalDBRowCount = usersListData?.pages?.[0]?.total_users ?? 0;

  const createTableActions = (id: number): ReactNode => {
    return (
      <Button
        label={t('common.impersonate')}
        variant="secondary"
        onClick={async () => {
          try {
            const response = await impersonateUser(id);

            if (response) {
              localStorage.setItem('jwtToken', response.token);
              localStorage.setItem('refreshToken', response.refresh_token);

              const user = response.user;

              setUser({
                id: user.id,
                company_id: user.company_id,
                name: user.given_name,
                family_name: user.family_name,
                email: user.email,
                company_name: response.company_name,
                role: user.role,
              });
              navigate('/app/dashboard');
            }
          } catch (error: any) {
            handleError(error);
          }
        }}
      />
    );
  };

  const { columns } = useGetImpersonateUserColumns({ createTableActions });

  return (
    <Table
      isFetching={isFetching}
      isFetchingNextPage={isFetchingNextPage}
      data={flatData}
      columns={columns}
      fetchNextPage={fetchNextPage}
      totalDBRowCount={totalDBRowCount}
      rowEstimateSize={75}
      handleSortChange={handleSortChange}
      manualSorting={true}
    />
  );
};
export default ImpersonationTable;
