export const colors = {
  'gray-10': '#F6F6F6',
  'gray-20': '#DCF4ED',
  'gray-25': '#EEE',
  'gray-30': '#E2E1E0',
  'gray-35': '#D9D9D9',
  'gray-38': '#c2c6ce',
  'gray-40': '#939BA8',
  'gray-45': '#707B8D',
  'gray-50': '#45526A',
  'gray-55': '#383E49',
  'gray-60': '#001334',
  autofill: '#e7f0fe',
  primary: '#522B89',
  'primary-light': '#C09AF6',
  'primary-lighter': '#F7F2FF',
  secondary: '#AF5AF1',
  'secondary-dark': '#8511E0',
  tertiary: '#59F1C4',
  'tertiary-lighter': '#DCF4ED',
  'tertiary-light': '#E4FDF6',
  'tertiary-dark': '#44B895',
  placeholder: '#9ca3af',
  orange: '#EC7744',
  'orange-light': '#e9b187',
  menu: {
    background: '#FBFBFB',
    'light-purple': '#F9F5FF',
  },
  blue: '#1663E5',
  'light-blue': '#8AB1F2',
  tab: '#EEE',
  step: '#BABFC7',
  white: '#FFFFFF',
  alert: '#F6D527',
  error: '#E92252',
  success: '#8CD134',
  ipmpv: {
    background: {
      success: 'rgba(139, 211, 93, 0.40)',
      fail: 'rgba(222, 27, 74, 0.16)',
      uncertainty: 'rgba(243, 151, 52, 0.16)',
    },
    icon: {
      success: '#8BD35D',
      fail: '#DE1B4A',
      uncertainty: '#F39734',
    },
  },
  progress: {
    0: '#ED4145',
    10: '#EE503D',
    20: '#F05A35',
    30: '#F2642D',
    40: '#F46E25',
    50: '#F4D528',
    60: '#D3C830',
    70: '#B2BA39',
    80: '#91C241',
    90: '#70CA4A',
    100: '#8ED234',
  },
  status: {
    new: '#44B2E1',
    'to-be-identified': '#C92A3D',
    identifying: '#EF831F',
    'problems-detected': '#DF389C',
    solving: '#9725BF',
    solved: '#23B44C',
    closed: '#968E8E',
    negative: '#E92252',
    archived: '#001334',
  },
};
