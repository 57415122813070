import type Highcharts from 'highcharts/highstock';
import { type Dispatch, type SetStateAction } from 'react';

export interface TimeValuePair {
  time: string;
  value: number;
}
const useSetExtremes = ({
  setState,
}: {
  setState: Dispatch<SetStateAction<TimeValuePair[]>>;
}): {
  afterSetExtremes: (
    this: Highcharts.Axis,
    event: Highcharts.AxisSetExtremesEventObject
  ) => void;
} => {
  return {
    afterSetExtremes(
      this: Highcharts.Axis,
      event: Highcharts.AxisSetExtremesEventObject
    ) {
      const chart = this.chart;

      const series = chart.series[0];

      const currentData: TimeValuePair[] = [];

      const originalData = (series.options as any).data;

      // Iterate over the original data array
      originalData?.forEach((pointData: any) => {
        // Check if the point falls within the visible range on the yAxis
        const xValue = pointData.x;
        if (xValue >= event.min && xValue <= event.max) {
          // Add the x-value of the point to the currentData array
          currentData.push({
            time: pointData.time as string,
            value: pointData.impactPerDay as number,
          });
        }
      });

      setState?.(currentData);
    },
  };
};
export default useSetExtremes;
