import { AuthFormFooter } from 'components/auth/AuthFormFooter';
import { t } from 'i18next';
import { type FC } from 'react';

const AuthRegisterFooter: FC = () => {
  return (
    <AuthFormFooter
      text={t('registerPage.footer.haveAnAccount')}
      hyperlink={{ text: t('common.login'), redirectUrl: '/login' }}
    />
  );
};
export default AuthRegisterFooter;
