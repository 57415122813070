import { ConfirmModal } from '@components/confirmModal';
import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import {
  useDeleteFeatureFlag,
  useListFeatureMap,
} from 'api/sysadmin/sysAdminServices';
import useDisclosure from 'hooks/useDisclosure';
import { t } from 'i18next';
import { type FeatureFlagList } from 'interfaces/featureFlags/FeatureFlags.interface';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { type FC, type ReactNode, useEffect, useMemo } from 'react';
import { useGetFeaturesFlagTableColumn } from '../hooks';
import FeatureFlagDetailsModal from '../modal/FeatureFlagDetailsModal';

interface FeaturesFlagTableProps {
  handleEditOpen: () => void;
  selectedFeatureFlag?: number;
  setSelectedFeatureMap: (value?: number) => void;
}

const FeaturesFlagTable: FC<FeaturesFlagTableProps> = ({
  handleEditOpen,
  selectedFeatureFlag,
  setSelectedFeatureMap,
}) => {
  const { isOpen, handleOpen, handleClose } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose,
  } = useDisclosure();

  const {
    mutate: deleteFeatureFlag,
    isPending: isDeletePending,
    isSuccess: isDeleteSuccess,
  } = useDeleteFeatureFlag();
  const {
    data: featureMapList,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useListFeatureMap();

  const flatData: UserResponse[] = useMemo(
    () =>
      featureMapList?.pages?.flatMap((page: any) => page?.feature_flags) ?? [],
    [featureMapList]
  );

  const totalDBRowCount = featureMapList?.pages?.[0]?.total ?? 0;

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.id;
    const actions = [
      {
        name: t('common.actions.details'),
        onClick: () => {
          setSelectedFeatureMap(id);
          handleOpen();
        },
      },
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setSelectedFeatureMap(id);
          handleEditOpen();
        },
      },
      {
        name: t('common.actions.delete'),
        onClick: () => {
          setSelectedFeatureMap(id);
          handleDeleteOpen();
        },
      },
    ];
    return <TableActions key={`action-${id}`} actions={actions} />;
  };

  const { columns } = useGetFeaturesFlagTableColumn({ createTableActions });

  const handleDeleteFeatureFlag = (): void => {
    if (selectedFeatureFlag) {
      deleteFeatureFlag(selectedFeatureFlag);
    }
  };

  const handleClickRow = (featureFlag: FeatureFlagList): void => {
    setSelectedFeatureMap(featureFlag.id);
    handleOpen();
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      handleDeleteClose();
    }
  }, [isDeleteSuccess]);
  return (
    <>
      <Table
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        data={flatData}
        columns={columns}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        rowEstimateSize={75}
        onClickRow={handleClickRow}
      />
      <FeatureFlagDetailsModal
        isOpen={isOpen}
        handleEditOpen={handleEditOpen}
        handleClose={handleClose}
        featureFlagId={selectedFeatureFlag}
      />
      <ConfirmModal
        isOpen={isDeleteOpen}
        handleClose={handleDeleteClose}
        onConfirm={handleDeleteFeatureFlag}
        isLoading={isDeletePending}
        contentText="Are you sure you want to delete this Feature flag? This action can't be undone and all information will be lost."
      />
    </>
  );
};
export default FeaturesFlagTable;
