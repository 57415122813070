import { type FC } from 'react';
import { FlagImage, defaultCountries } from 'react-international-phone';
import { SelectInput } from '../SelectInput';
import { type DropdownOptions } from '../SelectInput/SingleSelectInput/SingleSelectInput';

interface SelectCountryInputProps {
  value?: string | number;
  onChange?: ((value: string | number | undefined) => void) | undefined;
  disableCountryCode?: boolean;
}

const SelectCountryInput: FC<SelectCountryInputProps> = ({
  value,
  onChange,
  disableCountryCode = false,
}) => {
  const parsedCountries = defaultCountries.map((country) => {
    const [name, iso2, countryCode] = country;
    return {
      name: name.toUpperCase(),
      value: iso2,
      startIcon: <FlagImage iso2={iso2} />,
      dropdownOptions: disableCountryCode
        ? { name: `${name}` }
        : { name: `${name} +${countryCode}` },
    };
  });

  return (
    <SelectInput
      options={parsedCountries}
      dropdownClassName="min-w-min"
      value={value}
      onChange={onChange}
      getSelectedName={(option: DropdownOptions) => (
        <>
          <span className="w-[24px] h-[24px]">{option?.startIcon} </span>
          <span>{option?.value?.toString().toUpperCase()}</span>
        </>
      )}
      data-testid="country-select"
    />
  );
};
export default SelectCountryInput;
