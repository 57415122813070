import { type FC, type ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';

interface FEditableFieldProps {
  label: string;
  value?: string;
  name?: string;
  isEditing: boolean;
  fieldComponent: ReactNode;
  capitalize?: boolean;
  isLoading?: boolean;
  uppercase?: boolean;
}

const FEditableField: FC<FEditableFieldProps> = ({
  label,
  name,
  isEditing,
  fieldComponent,
  value,
  capitalize = false,
  uppercase = false,
  isLoading = false,
}) => {
  const { watch } = useFormContext();
  const controlledValue = name && watch(name);

  const stringToCapitalize = (str: string): string => {
    if (capitalize && str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    } else if (uppercase && str) {
      return str.toUpperCase();
    } else {
      return str;
    }
  };

  return (
    <div>
      {isEditing && fieldComponent}
      {!isEditing && (
        <div
          className="flex gap-2 text-base"
          data-testid={`${name ?? 'input'}-editable`}
        >
          <div className="w-4/12 font-bold">
            {isLoading ? <Skeleton /> : label}
          </div>
          <div className="flex">
            {isLoading ? (
              <Skeleton width={'200px'} containerClassName="flex-1" />
            ) : (
              stringToCapitalize(value ?? controlledValue)
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default FEditableField;
