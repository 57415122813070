import { type FC } from 'react';

const EmailSentIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
  >
    <g clipPath="url(#clip0_720_813)">
      <path
        d="M70 10C71.841 10 73.3333 11.4924 73.3333 13.3333V66.6887C73.3333 68.5173 71.8157 70 70.0273 70H9.97267C8.14683 70 6.66667 68.517 6.66667 66.6887V63.3333H66.6667V24.3333L40 48.3333L6.66667 18.3333V13.3333C6.66667 11.4924 8.15907 10 10 10H70ZM26.6667 50V56.6667H0V50H26.6667ZM16.6667 33.3333V40H0V33.3333H16.6667ZM65.2197 16.6667H14.7805L40 39.3643L65.2197 16.6667Z"
        fill="#BABFC7"
      />
    </g>
    <defs>
      <clipPath id="clip0_720_813">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default EmailSentIcon;
