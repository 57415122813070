import styled from 'styled-components';
import tw from 'twin.macro';

export const DrawerContainer = styled.div`
  ${tw`bg-white min-w-[500px] shadow-md rounded-lg z-50`}
`;

export const DrawerHeader = styled.div`
  ${tw`flex justify-between items-center border-primary-lighter border-b-2 p-6`}
`;
export const DrawerBody = styled.div`
  ${tw`bg-white min-h-[250px] max-h-[400px] overflow-y-scroll py-6 px-3`}
`;
export const DrawerFooter = styled.div`
  ${tw`flex justify-end items-center border-primary-lighter border-t-2 p-6 gap-2`}
`;
