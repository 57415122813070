import {
  type FieldError,
  type FieldErrorsImpl,
  type Merge,
} from 'react-hook-form';

export const addTextErrorClasses = (
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
): string => {
  return error != null ? 'text-error' : '';
};

export const addInputErrorClasses = (
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
): string => {
  return error != null ? 'border-error' : '';
};
