import { OnboardingContainer } from '@components/auth/OnboardingContainer';
import { OnboardingWrapper } from '@components/auth/OnboardingWrapper';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ForgotPasswordForm from './forms/ForgotPasswordForm/ForgotPasswordForm';
import { EmailReceivedComponent } from './components';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

const ForgotPasswordPage: FC = () => {
  Mixpanel.pageview();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');

  const stepHeadings: Record<number, string> = {
    0: t('forgotPasswordPage.stepOne.heading'),
    1: t('forgotPasswordPage.stepTwo.heading'),
  };
  const stepDetailsHeadings: Record<number, string> = {
    0: t('forgotPasswordPage.stepOne.enterDetails'),
    1: t('forgotPasswordPage.stepTwo.enterDetails', { email }),
  };

  return (
    <OnboardingWrapper>
      <OnboardingContainer
        heading={stepHeadings[step]}
        detailsHeading={stepDetailsHeadings[step]}
      >
        {step === 0 && (
          <ForgotPasswordForm setStep={setStep} setEmail={setEmail} />
        )}
        {step === 1 && <EmailReceivedComponent email={email} />}
      </OnboardingContainer>
    </OnboardingWrapper>
  );
};
export default ForgotPasswordPage;
