import { OnboardingContainer } from 'components/auth/OnboardingContainer';
import { type FC, type ReactNode } from 'react';

const RegisterContainer: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <OnboardingContainer
      heading="Get started"
      detailsHeading="Create your account now"
    >
      {children}
    </OnboardingContainer>
  );
};
export default RegisterContainer;
