import { type FC } from 'react';
import FLabel from '../FLabel/FLabel';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { MultiComboBoxInput } from '@components/inputs/ComboBox';
import { Controller, useFormContext } from 'react-hook-form';
import { type MultiComboBoxInputProps } from '@components/inputs/ComboBox/MultiComboBoxInput';
import { type ControlledInput } from 'interfaces/inputs/ControlledInput.interface';

interface LabelProp {
  label?: string;
  labelTooltip?: string;
}

export interface ControlledMultiComboBoxProps
  extends ControlledInput,
    MultiComboBoxInputProps {}

export type FMultiComboBoxProps = ControlledMultiComboBoxProps & LabelProp;

const FMultiComboBox: FC<FMultiComboBoxProps> = ({
  label,
  name,
  options,
  initialValues,
  id,
  labelTooltip,
  ...props
}) => {
  const { control } = useFormContext();
  const dataTestId = `${name}-combobox`;

  return (
    <div className="flex flex-1 flex-col gap-2">
      {label && (
        <div className="flex items-center gap-2">
          <FLabel id={id}>{label}</FLabel>
          {labelTooltip && <TooltipIconComponent content={labelTooltip} />}
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <MultiComboBoxInput
              id={id}
              data-testid={dataTestId}
              error={error}
              options={options}
              initialValues={initialValues}
              {...field}
              {...props}
            />
          );
        }}
      />
    </div>
  );
};
export default FMultiComboBox;
