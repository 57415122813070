import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import { useDeleteAsset, useListAssets } from 'api/assets/assetsServices';
import useDisclosure from 'hooks/useDisclosure';
import { type FC, type ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetAssetsTableColumn from '../hooks/useGetAssetsTableColumn';
import { ConfirmModal } from '@components/confirmModal';
import { AssetDetailsModal } from '../modal/AssetDetailsModal';
import EditAssetModal from '../modal/EditAssetModal/EditAssetModal';
import { type AssetDetails } from 'interfaces/assets/Asset.interface';
import { getAssetTypeReadableNameFromStringEnum } from 'utils/assetUtils/assetUtils';

interface AssetsTableProps {
  query?: string;
  filters?: {
    type?: string;
    assetFunction?: string;
  };
}
const AssetsTable: FC<AssetsTableProps> = ({ query, filters }) => {
  const [assetId, setAssetId] = useState<number>();
  const { t } = useTranslation();

  const {
    isOpen: isDetailsOpen,
    handleClose: handleDetailsClose,
    handleOpen: handleDetailsOpen,
  } = useDisclosure();
  const {
    isOpen: isEditAssetOpen,
    handleClose: handleEditAssetClose,
    handleOpen: handleEditAssetOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteAssetOpen,
    handleClose: handleDeleteAssetClose,
    handleOpen: handleDeleteAssetOpen,
  } = useDisclosure();

  const { data, isFetching, fetchNextPage, isFetchingNextPage } = useListAssets(
    {
      query,
      type: filters?.type,
      assetFunction: filters?.assetFunction,
    }
  );

  const { mutate: deleteAssetMutate, isPending: isLoadingDeleteAsset } =
    useDeleteAsset();

  const totalDBRowCount = data?.pages?.[0]?.total ?? 0;

  const flatDataWithLocation = useMemo(() => {
    if (!data?.pages) return [];
    const flattenAssets = data.pages.flatMap((page) => page?.assets || []);

    const modifiedAssets = flattenAssets.map((asset) => {
      return {
        ...asset,
        type: getAssetTypeReadableNameFromStringEnum(asset.type),
      };
    });

    return modifiedAssets;
  }, [data]);

  const handleRowClick = (asset: AssetDetails): void => {
    setAssetId(asset.id);
    handleDetailsOpen();
  };

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.id;
    const actions = [
      {
        name: t('common.actions.details'),
        onClick: () => {
          setAssetId(id);
          handleDetailsOpen();
        },
      },
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setAssetId(id);
          handleEditAssetOpen();
        },
      },
      {
        name: t('common.actions.delete'),
        onClick: () => {
          setAssetId(id);
          handleDeleteAssetOpen();
        },
      },
    ];
    return <TableActions key={`action-${id}`} actions={actions} />;
  };

  const handleDeleteAsset = (): void => {
    if (assetId) {
      deleteAssetMutate(assetId);
      handleDeleteAssetClose();
    }
  };

  const { columns } = useGetAssetsTableColumn({ createTableActions });

  return (
    <>
      <Table
        columns={columns}
        data={flatDataWithLocation}
        enableRowSelection={true}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        onClickRow={handleRowClick}
        rowEstimateSize={75}
      />
      {assetId && (
        <AssetDetailsModal
          assetId={assetId}
          isOpen={isDetailsOpen}
          handleClose={handleDetailsClose}
          handleOpen={handleDetailsOpen}
          handleEditOpen={handleEditAssetOpen}
        />
      )}
      {assetId && (
        <EditAssetModal
          isOpen={isEditAssetOpen}
          handleClose={handleEditAssetClose}
          handleOpen={handleEditAssetOpen}
          selectedAssetId={assetId}
        />
      )}
      <ConfirmModal
        isOpen={isDeleteAssetOpen}
        handleOpen={handleDeleteAssetOpen}
        handleClose={handleDeleteAssetClose}
        onConfirm={handleDeleteAsset}
        contentText={t('common.confirmModal.delete')}
        isLoading={isLoadingDeleteAsset}
      />
    </>
  );
};

export default AssetsTable;
