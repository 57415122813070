import { type FC } from 'react';
import { Link } from 'react-router-dom';

interface AuthFormFooterProps {
  text: string;
  hyperlink?: {
    text: string;
    redirectUrl: string;
  };
}

const AuthFormFooter: FC<AuthFormFooterProps> = ({ text, hyperlink }) => {
  return (
    <p className="mt-xl text-lg text-center text-gray-50">
      {text}{' '}
      {hyperlink && (
        <Link to={hyperlink?.redirectUrl} className="font-bold">
          {hyperlink?.text}
        </Link>
      )}
    </p>
  );
};
export default AuthFormFooter;
