import { type FC } from 'react';
import MultiSelectInput, {
  type MultiSelectInputProps,
} from './MultiSelectInput/MultiSelectInput';
import SingleSelectInput, {
  type SingleSelectInputProps,
} from './SingleSelectInput/SingleSelectInput';

export type SelectInputProps = MultiSelectInputProps | SingleSelectInputProps;

const isMultiSelectProps = (
  props: SelectInputProps
): props is MultiSelectInputProps => props?.multiple === true;

const SelectInput: FC<SelectInputProps> = (props) => {
  return (
    <>
      {isMultiSelectProps(props) ? (
        <MultiSelectInput {...props} />
      ) : (
        <SingleSelectInput {...props} />
      )}
    </>
  );
};
export default SelectInput;
