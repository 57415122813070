import { DatePickerInput } from '@components/inputs/DatePickerInput';
import { type DatePickerInputProps } from '@components/inputs/DatePickerInput/DatePickerInput';
import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FErrorLabel } from '../FErrorLabel';
import { FLabel } from '../FLabel';

interface FDatePickerFieldProps extends Omit<DatePickerInputProps, 'onChange'> {
  label?: string;
  name: string;
  id?: string;
}

const FDatePickerField: FC<FDatePickerFieldProps> = ({
  label,
  name,
  id,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex flex-col gap-1.5">
        {label && <FLabel id={id}>{label}</FLabel>}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <>
            <DatePickerInput id={id} error={error} {...field} {...props} />
            <FErrorLabel message={error?.message} />
          </>
        )}
      />
    </div>
  );
};
export default FDatePickerField;
