import { createColumnHelper } from '@tanstack/react-table';

const useGetRolePermissionTableColumn = ({
  onChange,
}: {
  onChange: (type: string, id: number, value: boolean) => void;
}): any => {
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => 'Name',
      cell: (info) => info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('method', {
      header: () => 'Method',
      cell: (info) => info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('endpoint', {
      header: () => 'Endpoint',
      cell: (info) => info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('sysadmin', {
      header: () => 'Sys Admin',
      cell: (info) => (
        <input
          type="checkbox"
          defaultChecked={info.getValue()}
          onChange={(e) => {
            onChange('sysadmin', info.row.original.id, e.target.checked);
          }}
        />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('admin', {
      header: () => 'Admin',
      cell: (info) => (
        <input
          type="checkbox"
          defaultChecked={info.getValue()}
          onChange={(e) => {
            onChange('admin', info.row.original.id, e.target.checked);
          }}
        />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('manager', {
      header: () => 'Manager',
      cell: (info) => (
        <input
          type="checkbox"
          defaultChecked={info.getValue()}
          onChange={(e) => {
            onChange('manager', info.row.original.id, e.target.checked);
          }}
        />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('responsible', {
      header: () => 'Responsible',
      cell: (info) => (
        <input
          type="checkbox"
          defaultChecked={info.getValue()}
          onChange={(e) => {
            onChange('responsible', info.row.original.id, e.target.checked);
          }}
        />
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('reader', {
      header: () => 'Reader',
      cell: (info) => (
        <input
          type="checkbox"
          defaultChecked={info.getValue()}
          onChange={(e) => {
            onChange('reader', info.row.original.id, e.target.checked);
          }}
        />
      ),
      enableSorting: true,
    }),
  ];

  return {
    columns,
  };
};
export default useGetRolePermissionTableColumn;
