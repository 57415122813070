import { type FC, type SVGProps } from 'react';

const NotificationCircleIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="#AF5AF1"
    {...props}
  >
    <circle cx="6" cy="6" r="6" fill="current" />
  </svg>
);
export default NotificationCircleIcon;
