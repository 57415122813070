import { numericFormatter } from 'react-number-format';

export const convertToPercentageNumber = (value: number): number => {
  const percentage = value * 100;
  return Math.max(0, Math.min(100, percentage));
};

export const convertToPercentageParsedString = (
  value: number,
  options?: { decimalScale?: number }
): string => {
  const percentage = convertToPercentageNumber(value);
  const format = numericFormatter(percentage.toString(), {
    decimalSeparator: '.',
    decimalScale: options?.decimalScale ?? 2,
    allowLeadingZeros: false,
  });
  return `${format}%`;
};
