import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import { type ListChangesDashboard } from 'interfaces/change/ChangeData.interface';
import { type MetersMapResponse } from 'interfaces/map/MetersMap.interface';
import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { type DashboardProjectsResponse } from 'interfaces/projects/ProjectData.interface';
import { type ProjectsMapResponse } from 'interfaces/map/ProjectMap.interface';

export const useFetchDashboardList = (): UseQueryResult<
  ListChangesDashboard | null,
  Error | AxiosError
> => {
  return useQuery<ListChangesDashboard>({
    queryKey: ['dashboardList'],
    queryFn: async () => {
      const response = await axiosInstance.get(`change_detection/score`);
      return response?.data;
    },
  });
};

export const useFetchDashboardMap = (): UseQueryResult<
  MetersMapResponse | null,
  Error | AxiosError
> => {
  return useQuery<MetersMapResponse>({
    queryKey: ['dashboardMap'],
    queryFn: async () => {
      const response = await axiosInstance.get(`change_detection/map`);
      return response?.data;
    },
  });
};

export const useFetchProjectDashboardMap = (): UseQueryResult<
  ProjectsMapResponse | null,
  Error | AxiosError
> => {
  return useQuery<ProjectsMapResponse>({
    queryKey: ['projectDashboardMap'],
    queryFn: async () => {
      const response = await axiosInstance.get(`project/map`);
      return response?.data;
    },
  });
};

export const useListDashboardProjects =
  (): UseQueryResult<DashboardProjectsResponse> => {
    return useQuery({
      queryKey: ['dashboardProjects'],
      queryFn: async () => {
        const response = await axiosInstance.get('project/filter/dashboard');
        return response?.data;
      },
    });
  };
