import { type FC, type PropsWithChildren } from 'react';
import { Label, Radio, RadioGroup } from '@headlessui/react';
import {
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
} from '@components/icons/RadioButtonIcon';

interface RadioButtonProps {
  label: string;
  value: any;
}
const RadioButton: FC<RadioButtonProps> = ({ label, value }) => {
  return (
    <Radio
      value={value}
      data-testid={`${label}-radio-option`}
      className={`bg-white relative flex cursor-pointer rounded-lg focus:outline-none`}
    >
      {({ focus, checked }) => (
        <div className="flex w-full items-center justify-start gap-4">
          {checked ? (
            <div className="shrink-0">
              <RadioButtonCheckedIcon />
            </div>
          ) : (
            <RadioButtonUncheckedIcon />
          )}
          <div className="flex items-center">
            <div className="text-base">
              <Label as="p" className="text-gray-60">
                {label}
              </Label>
            </div>
          </div>
        </div>
      )}
    </Radio>
  );
};

export interface RadioContainerProps extends PropsWithChildren {
  value?: string | number;
  onChange?: (value: string | number) => void;
}
const RadioContainer: FC<RadioContainerProps> = ({
  children,
  onChange,
  value,
}) => {
  return (
    <div className="w-full">
      <div className="w-min whitespace-nowrap">
        <RadioGroup value={value} onChange={onChange}>
          <div className="space-y-4">{children}</div>
        </RadioGroup>
      </div>
    </div>
  );
};

export { RadioContainer, RadioButton };
