import styled from 'styled-components';
import tw from 'twin.macro';

export const DetailsRow = styled.div`
  ${tw`flex justify-between items-center px-4 py-2 border-b border-gray-10`}
`;

export const RelatabeItemContainer = styled.div`
  ${tw`flex justify-between items-center p-4 border border-gray-10 cursor-pointer gap-2`}
`;
