import { type FC, type SVGProps } from 'react';

const AssetsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="currentColor"
    {...props}
  >
    <path d="M19 18H1C0.44772 18 0 17.5523 0 17V9.4868C0 9.1978 0.12501 8.9229 0.34282 8.733L4 5.54435V1C4 0.44772 4.44772 0 5 0H19C19.5523 0 20 0.44772 20 1V17C20 17.5523 19.5523 18 19 18ZM7 16H10V9.9416L6 6.45402L2 9.9416V16H5V12H7V16ZM12 16H18V2H6V4.12729C6.23444 4.12729 6.46888 4.20938 6.65718 4.37355L11.6572 8.733C11.875 8.9229 12 9.1978 12 9.4868V16ZM14 8H16V10H14V8ZM14 12H16V14H14V12ZM14 4H16V6H14V4ZM10 4H12V6H10V4Z" />
  </svg>
);
export default AssetsIcon;
