import { FMultiComboBox } from '@components/form/FMultiComboBox';
import { FNumericField } from '@components/form/FNumericField';
import { FSelectField } from '@components/form/FSelectField';
import { FSliderInput } from '@components/form/FSliderInput';
import { FTextAreaField } from '@components/form/FTextAreaField';
import { FTextField } from '@components/form/FTextField';
import { SkeletonInput } from '@components/skeleton';
import { useFetchCompanyMeters } from 'api/assets/assetsServices';
import { useFetchProjectCategories } from 'api/projects/projectsServices';
import { useFetchCompanyUsers } from 'api/user/userService';
import { addDays } from 'date-fns';
import { useAuth } from 'hooks/contexts/AuthContext';
import { useDebounce } from 'hooks/useDebounce';
import { type FC, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import DatePickerRange from './DatePickerRange';
import { manageProjectControlNames } from './schema';

interface ManageProjectFormProps {
  isEditing?: boolean;
  handleDeleteOpen?: () => void;
  isFetching?: boolean;
}

const ManageProjectForm: FC<ManageProjectFormProps> = ({
  isEditing = false,
  isFetching = false,
  handleDeleteOpen,
}) => {
  const [meterQuery, setMeterQuery] = useState('');
  const debouncedQuery = useDebounce(meterQuery, 300);
  const { user } = useAuth();
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();

  const initialMeter = watch('meter');

  const referencePeriodEndDate = watch(
    manageProjectControlNames.referencePeriod.end
  );

  const implementationPeriodStartDate = useMemo(() => {
    if (referencePeriodEndDate) {
      return addDays(new Date(referencePeriodEndDate), 1).toISOString();
    }
  }, [referencePeriodEndDate]);

  const { data: meterList, isFetching: isFetchingMeters } =
    useFetchCompanyMeters(user?.company_id, {
      query: debouncedQuery,
      perPage: 999,
      external_meter_id: 1,
    });
  const { data: userList } = useFetchCompanyUsers({ perPage: 100 });
  const { data: categoriesList } = useFetchProjectCategories();

  const categoryOptions = useMemo(
    () =>
      categoriesList?.map?.((category) => ({
        value: category.key,
        name: category.value,
      })) ?? [],
    [categoriesList]
  );

  const userOptions = useMemo(
    () =>
      userList?.pages?.flatMap?.(
        (page) =>
          page?.users?.map?.((user) => ({
            name: `${user.given_name} ${user.family_name}`,
            value: user.id,
          }))
      ) ?? [],
    [userList]
  );

  const meterOptions = useMemo(() => {
    if (!meterList?.meters) return [];

    return meterList.meters.map((meter) => ({
      value: meter.id,
      name:
        meter.assets.length >= 1
          ? `${meter.assets[0].name} - ${meter.name}`
          : meter.name,
    }));
  }, [meterList]);

  return (
    <form className="flex flex-col gap-6">
      <div>
        {isEditing && (
          <div className="flex justify-end">
            {isFetching ? (
              <Skeleton width={80} />
            ) : (
              <span
                className="text-secondary-dark text-base cursor-pointer py-1 px-2"
                role={'button'}
                onClick={handleDeleteOpen}
              >
                Delete
              </span>
            )}
          </div>
        )}

        {isFetching ? (
          <SkeletonInput withLabel={true} labelWidth={100} />
        ) : (
          <FTextField
            label={t('common.inputs.name')}
            placeholder={t('projectsPage.placeholders.name')}
            name={manageProjectControlNames.name}
            autoComplete="off"
            id={'project-input'}
          />
        )}
      </div>

      {isFetching ? (
        <div>
          <Skeleton width={120} />
          <Skeleton height={130} />
        </div>
      ) : (
        <FTextAreaField
          label={t('common.inputs.description')}
          name={manageProjectControlNames.description}
          placeholder={t('projectsPage.placeholders.description')}
          draggable={false}
          rows={5}
          className="resize-none"
          autoComplete="off"
          id={'description-input'}
        />
      )}

      {isFetching ? (
        <SkeletonInput withLabel={true} labelWidth={100} />
      ) : (
        <FMultiComboBox
          label={t('common.meter')}
          initialValues={initialMeter}
          options={meterOptions}
          name={manageProjectControlNames.meter}
          placeholder={t('common.placeholders.selectMeter')}
          isFetching={isFetchingMeters}
          query={meterQuery}
          handleQuery={(value) => {
            setMeterQuery(value);
          }}
          maxSelected={1}
          disabled={isEditing}
          id={'meter-input'}
        />
      )}

      {isFetching ? (
        <div className="flex flex-col gap-10">
          <Skeleton width={160} />
          <Skeleton />
        </div>
      ) : (
        <FSliderInput
          label={t('projectsPage.savingsTarget')}
          name={manageProjectControlNames.savingsTarget}
          disabled={isEditing}
        />
      )}

      {isFetching ? (
        <SkeletonInput withLabel={true} labelWidth={100} />
      ) : (
        <FNumericField
          name={manageProjectControlNames.investmentCostNumber}
          placeholder={t('projectsPage.placeholders.investmentCost')}
          label={t('projectsPage.investmentCost')}
          onChangeValue={(e) => {
            setValue(manageProjectControlNames.investmentCostNumber, e);
          }}
          suffix={' €'}
          id={'investment-cost-input'}
          fixedDecimalScale={false}
        />
      )}

      {isFetching ? (
        <div className="flex gap-6">
          <SkeletonInput withLabel={true} labelWidth={100} />
          <SkeletonInput withLabel={true} labelWidth={100} />
        </div>
      ) : (
        <DatePickerRange
          label={t('projectsPage.referencePeriod')}
          tooltipMessage={t('projectsPage.referencePeriodTooltip')}
          startControlName={manageProjectControlNames.referencePeriod.start}
          endControlName={manageProjectControlNames.referencePeriod.end}
          disabled={isEditing}
          id={'reference-range'}
          containerId="reference-container"
        />
      )}

      {isFetching ? (
        <div className="flex gap-6">
          <SkeletonInput withLabel={true} labelWidth={100} />
          <SkeletonInput withLabel={true} labelWidth={100} />
        </div>
      ) : (
        <DatePickerRange
          label={t('projectsPage.implementationPeriod')}
          tooltipMessage={t('projectsPage.implementationPeriodTooltip')}
          startControlName={
            manageProjectControlNames.implementationPeriod.start
          }
          endControlName={manageProjectControlNames.implementationPeriod.end}
          initialDate={implementationPeriodStartDate}
          disabled={isEditing}
          id={'implementation-range'}
          containerId="implementation-container"
        />
      )}

      {isFetching ? (
        <SkeletonInput withLabel={true} labelWidth={100} />
      ) : (
        <FSelectField
          label={t('common.category')}
          name={manageProjectControlNames.category}
          options={categoryOptions}
          placeholder={t('projectsPage.placeholders.category')}
        />
      )}

      {isFetching ? (
        <SkeletonInput withLabel={true} labelWidth={100} />
      ) : (
        <FSelectField
          label={t('common.responsible')}
          placeholder={t('common.placeholders.selectResponsible')}
          name={manageProjectControlNames.responsible}
          options={userOptions}
        />
      )}
    </form>
  );
};
export default ManageProjectForm;
