import { StatusEnum } from 'enums/StatusEnum';
import i18next, { t } from 'i18next';
import { type AssignedUser } from 'interfaces/activityLog/ActivityLog.interface';
import { type FC, type ReactNode } from 'react';
import Avatar from 'react-avatar';
import { Trans, useTranslation } from 'react-i18next';
import { stringToCapitalize } from 'utils/formatters/string/stringFormatter';
import { getStatusReadableName } from 'utils/statusUtil/statusUtil';

const AssignedToUserAvatar: FC<{
  children?: { assignedTo?: string };
  assignedFrom?: string;
  assignedTo?: string;
}> = ({ children, assignedFrom, assignedTo }) => {
  const { t } = useTranslation();
  return (
    <span>
      {assignedFrom === assignedTo ? (
        <span>{t('anomaliesPage.activityLog.himself')}</span>
      ) : (
        <div className="inline-flex items-baseline gap-1 ml-0.5">
          <Avatar
            name={assignedTo ?? '*'}
            className="rounded-full cursor-pointer z-10 self-center"
            textSizeRatio={1.8}
            size={'18px'}
          />
          <span className="font-bold">{children}</span>
        </div>
      )}
    </span>
  );
};

const createActivityLogComment = ({
  fieldName,
  assignedFrom,
  type,
}: {
  fieldName: string;
  assignedFrom: AssignedUser;
  type?: 'default' | 'min';
}): ReactNode => {
  if (fieldName === 'activity') {
    return type === 'default' ? (
      <p>
        {i18next.t('anomaliesPage.activityLog.newComment', {
          name: assignedFrom?.name as string,
        })}
      </p>
    ) : (
      <p>{i18next.t('anomaliesPage.activityLog.newComment-min')}</p>
    );
  }
  return null;
};

const createRootCauseChanged = ({ value }: { value?: string }): ReactNode => (
  <p>
    {i18next.t('anomaliesPage.activityLog.rootCauseChanged')}
    <span className="font-bold">
      {value ? stringToCapitalize(value).replaceAll('_', ' ') : ''}
    </span>
  </p>
);

const createLessonsLearnedChanged = ({
  value,
}: {
  value?: string;
}): ReactNode => (
  <p>
    {i18next.t('anomaliesPage.activityLog.lessonsLearnedChanged')}
    <span className="font-bold">{value}</span>
  </p>
);

const createStatusUpdated = ({
  assignedFrom,
  assignedTo,
  value,
  type = 'default',
}: {
  assignedFrom: AssignedUser;
  assignedTo: AssignedUser;
  value?: string;
  type?: 'default' | 'min';
}): ReactNode => {
  const status = getStatusReadableName(
    StatusEnum[value as keyof typeof StatusEnum]
  );
  return type === 'default' ? (
    <p>
      <Trans i18nKey="anomaliesPage.activityLog.statusUpdated">
        <span className="font-bold">{{ name: assignedFrom.name }}</span>
        <span className="font-bold">
          {{
            status,
          }}
        </span>

        <AssignedToUserAvatar
          assignedFrom={assignedFrom.name}
          assignedTo={assignedTo.name}
        >
          {{ assignedTo: assignedTo.name }}
        </AssignedToUserAvatar>
      </Trans>
    </p>
  ) : (
    <p>
      {t('anomaliesPage.activityLog.statusUpdated-mini')}
      <span className="font-bold">{status}</span>
    </p>
  );
};

export const createLog = ({
  fieldName,
  assignedFrom,
  assignedTo,
  title,
  value,
  type = 'default',
}: {
  fieldName: string;
  assignedFrom: AssignedUser;
  assignedTo: AssignedUser;
  title: string;
  value?: string;
  type?: 'default' | 'min';
}): ReactNode => {
  switch (fieldName) {
    case 'activity':
      return createActivityLogComment({ fieldName, assignedFrom, type });
    case 'root_cause':
      return createRootCauseChanged({ value });
    case 'lessons_learned':
      return createLessonsLearnedChanged({ value });
    case 'status':
      return createStatusUpdated({ assignedFrom, assignedTo, value, type });
    default:
      return title;
  }
};
