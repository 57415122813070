const IMPACT_MULTIPLIER = 24;

export const calculateImpactPerDayUnit = (
  impactAverageDiff: number
): number => {
  return IMPACT_MULTIPLIER * impactAverageDiff;
};

export const calculateImpactTotalUnit = (
  impactPerDayUnit: number = 0,
  impactTotalEuro: number = 0,
  impactPerDayEuro: number = 0
): number => {
  if (
    impactPerDayEuro === 0 ||
    impactTotalEuro === 0 ||
    impactPerDayUnit === 0
  ) {
    return 0;
  }

  return (impactPerDayUnit * impactTotalEuro) / impactPerDayEuro;
};
