import { Modal } from '@components/modal';
import { useCreateLuzmoDashboard } from 'api/sysadmin/sysAdminServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageLuzmoReportForm } from '../forms';
import { luzmoReportSchema } from '../forms/schema';

interface CreateLuzmoReportModalProps extends Disclosure {}

const CreateLuzmoReportModal: FC<CreateLuzmoReportModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { methods } = useHRForm({ schema: luzmoReportSchema });
  const { mutate, isPending, isSuccess } = useCreateLuzmoDashboard();

  const handleCreateLuzmoDashboard = (): void => {
    const values = methods.getValues();
    const body = {
      internal_name: values.internal_name,
      luzmo_name: values.luzmo_name?.[0]?.name,
      companies: values?.companies?.map((company) => ({
        id: company?.value,
      })),
      filters: { filters: values.filters },
      is_universal: values.is_universal,
    };
    mutate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isOpen) {
      methods.reset();
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      actionBtns={{
        primary: {
          label: 'Create Embed Dashboard',
          onClick: methods.handleSubmit(handleCreateLuzmoDashboard),
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      maxWidth={500}
      title={'Integrate new Dashboard'}
    >
      <FormProvider {...methods}>
        <ManageLuzmoReportForm />
      </FormProvider>
    </Modal>
  );
};
export default CreateLuzmoReportModal;
