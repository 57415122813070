import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axios';
import {
  type LuzmoReport,
  type ReportResponse,
} from 'interfaces/luzmo/Luzmo.interface';

export const useFetchReport = (name: string): UseQueryResult<LuzmoReport> => {
  return useQuery({
    queryKey: ['fetchLuzmoDashboard', name],
    queryFn: async () => {
      const response = await axiosInstance.get(`report/generate/${name}`);
      return response.data;
    },
  });
};

export const useFetchReportList = (): UseQueryResult<ReportResponse> => {
  return useQuery({
    queryKey: ['fetchReportList'],
    queryFn: async () => {
      const response = await axiosInstance.get(`report/`);
      return response.data;
    },
  });
};
