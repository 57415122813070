import { FTextField } from '@components/form/FTextField';
import { PhoneNumberInput } from '@components/inputs/PhoneNumberInput';
import { type FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { generatePassword } from 'utils/password/generatePasswordUtils';
import { manageDemoAccountControlNames } from './schema';
import { GiPerspectiveDiceSixFacesRandom } from 'react-icons/gi';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

interface ManageDemoAccountFormProps {
  isEditing?: boolean;
}

const ManageDemoAccountForm: FC<ManageDemoAccountFormProps> = ({
  isEditing,
}) => {
  const { setValue } = useFormContext();
  const { t } = useTranslation();

  const handleGenerateRandomPassword = (): void => {
    const password = generatePassword();
    if (password) {
      setValue('password', password);
    }
  };
  return (
    <div className="flex flex-col gap-6">
      <FTextField
        name={manageDemoAccountControlNames.email}
        label="Email"
        id={'email'}
        placeholder={t('sysAdminPage.demo.placeholders.email')}
        disabled={isEditing}
      />
      {!isEditing && (
        <div className="flex items-end gap-2">
          <FTextField
            name={manageDemoAccountControlNames.password}
            label="Password"
            id={'password'}
            autoComplete="off"
            placeholder={t('sysAdminPage.demo.placeholders.password')}
            addorment={{
              endAddorment: (
                <Tippy content="Randomize">
                  <span>
                    <GiPerspectiveDiceSixFacesRandom
                      className="cursor-pointer"
                      onClick={handleGenerateRandomPassword}
                      size={27}
                    />
                  </span>
                </Tippy>
              ),
            }}
          />
        </div>
      )}

      <FTextField
        name={manageDemoAccountControlNames.firstName}
        label="First name"
        id={'firstName'}
        placeholder={t('sysAdminPage.demo.placeholders.name')}
      />
      <FTextField
        name={manageDemoAccountControlNames.familyName}
        label="Family name"
        id={'familyName'}
        placeholder={t('sysAdminPage.demo.placeholders.familyName')}
      />
      <PhoneNumberInput
        name={manageDemoAccountControlNames.businessNumber}
        label="Business number"
      />
    </div>
  );
};
export default ManageDemoAccountForm;
