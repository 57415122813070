import { type FC } from 'react';
import FLabel from '../FLabel/FLabel';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { SelectInput } from '@components/inputs/SelectInput';
import { Controller, useFormContext } from 'react-hook-form';
import { type MultiSelectInputProps } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { type SingleSelectInputProps } from '@components/inputs/SelectInput/SingleSelectInput/SingleSelectInput';
import { type ControlledInput } from 'interfaces/inputs/ControlledInput.interface';
interface LabelProp {
  label?: string;
  labelTooltip?: string;
}

export type ControlledSelectInputProps =
  | (MultiSelectInputProps & ControlledInput)
  | (SingleSelectInputProps & ControlledInput);

export type FSelectFieldProps = ControlledSelectInputProps & LabelProp;

const FSelectField: FC<FSelectFieldProps> = ({
  label,
  name,
  options,
  id,
  labelTooltip,
  dropdownClassName,
  multiple,
  disabledOptions,
  ...props
}: FSelectFieldProps) => {
  const { control } = useFormContext();
  const dataTestId = `${name}-field`;

  return (
    <div className="flex flex-1 flex-col gap-2">
      {label && (
        <div className="flex items-center gap-2">
          <FLabel id={id}>{label}</FLabel>
          {labelTooltip && <TooltipIconComponent content={labelTooltip} />}
        </div>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <SelectInput
              id={id}
              data-testid={dataTestId}
              error={error}
              options={options}
              dropdownClassName={dropdownClassName}
              disabledOptions={disabledOptions}
              multiple={multiple}
              {...field}
              {...props}
            />
          );
        }}
      />
    </div>
  );
};
export default FSelectField;
