export enum StatusEnum {
  NEW = 'NEW',
  TO_BE_IDENTIFIED = 'TO_BE_IDENTIFIED',
  IDENTIFYING = 'IDENTIFYING',
  PROBLEM_DETECTED = 'PROBLEM_DETECTED',
  SOLVING = 'SOLVING',
  SOLVED = 'SOLVED',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

export const statusOrder = [
  'NEW',
  'TO_BE_IDENTIFIED',
  'IDENTIFYING',
  'PROBLEM_DETECTED',
  'SOLVING',
  'SOLVED',
  'CLOSED',
];
