import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { getUtilityIcon } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { numericFormatter } from 'react-number-format';
import Tippy from '@tippyjs/react';
import { TableProgressBar } from 'pages/Management/ProjectListPage/components';

interface ProjectsTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetDashboardProjectsTableColumns =
  (): ProjectsTableColumnsReturnType => {
    const columnHelper = createColumnHelper<any>();

    const columns = [
      columnHelper.accessor('utility_type', {
        header: () => <></>,
        enableSorting: false,
        cell: (info) => (
          <div className="fill-gray-50">{getUtilityIcon(info.getValue())}</div>
        ),
        size: 20,
      }),
      columnHelper.accessor('name', {
        header: () => <></>,
        cell: (info) => (
          <Tippy content={info.getValue()} maxWidth={450}>
            <span className="max-w-[300px] desktop-l:max-w-[400px] block text-ellipsis overflow-hidden truncate">
              {info.renderValue()}
            </span>
          </Tippy>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor('building_name', {
        header: () => <></>,
        cell: (info) =>
          info ? (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="max-w-[150px] desktop-l:max-w-[200px] block text-ellipsis overflow-hidden truncate">
                {info.renderValue()}
              </span>
            </Tippy>
          ) : (
            '-'
          ),
      }),
      columnHelper.accessor('distance_from_target', {
        id: 'distance_from_target',
        header: () => <></>,
        cell: (info) => {
          const values = info.row.original;
          const completed = parseFloat(
            numericFormatter(((values?.savings_rel ?? 0) * 100).toString(), {
              decimalSeparator: ',',
              thousandSeparator: '.',
              decimalScale: 1,
            })
          );
          const maxCompleted = parseFloat(
            numericFormatter(((values?.savings_target ?? 0) * 100).toString(), {
              decimalSeparator: ',',
              thousandSeparator: '.',
              decimalScale: 1,
            })
          );
          return (
            <TableProgressBar
              completed={completed}
              maxCompleted={maxCompleted}
              maxWidth={120}
            />
          );
        },
      }),
    ];

    return {
      columns,
    };
  };
export default useGetDashboardProjectsTableColumns;
