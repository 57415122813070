import { Dialog, DialogBackdrop, DialogTitle } from '@headlessui/react';
import { CloseIcon } from 'components/icons/CloseIcon';
import { Button, type ButtonProps } from 'layout/Button';
import { type FC, Fragment, type PropsWithChildren, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { CustomDialogPanel, ModalContainer, ModalHeader } from './styled';

interface ModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
  title?: string;
  actionBtns?: {
    primary?: ButtonProps;
    secondary?: ButtonProps;
  };
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  position?: 'left' | 'center' | 'right';
  useSkeleton?: boolean;
  headerVariant?: 'normal' | 'small';
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  onOpen,
  title,
  children,
  actionBtns,
  width,
  maxWidth,
  minWidth = 550,
  position = 'center',
  useSkeleton = false,
  headerVariant = 'normal',
}) => {
  const ref = useRef(null);

  return (
    <>
      <Dialog
        as="div"
        className="relative z-40"
        open={isOpen}
        onClose={onClose}
        initialFocus={ref}
      >
        <DialogBackdrop
          className="fixed inset-0 bg-black bg-opacity-25 transition duration-200 ease-out data-[closed]:opacity-0"
          transition={true}
        />

        <ModalContainer $position={position}>
          <CustomDialogPanel
            transition={true}
            style={{ width, maxWidth, minWidth }}
          >
            <ModalHeader $variant={headerVariant}>
              {useSkeleton && !title && (
                <Skeleton
                  containerClassName="flex-1 mr-3 max-w-[320px]"
                  borderRadius={'8px'}
                  height={'20px'}
                />
              )}
              {title && (
                <DialogTitle
                  ref={ref}
                  as="h3"
                  className="text-lg font-bold leading-6 text-gray-60 max-w-[400px] text-ellipsis overflow-hidden ..."
                >
                  {title}
                </DialogTitle>
              )}
              <CloseIcon className="cursor-pointer" onClick={onClose} />
            </ModalHeader>
            <div className="p-6 h-full">
              <div className="text-sm text-gray-500" data-testid="modal-body">
                {children}
              </div>
            </div>

            {actionBtns && (
              <div className="flex justify-end gap-6 p-6 border-t-2 border-primary-lighter">
                {actionBtns?.secondary && (
                  <Button
                    variant="secondary"
                    onClick={onClose}
                    {...actionBtns.secondary}
                  />
                )}
                {actionBtns?.primary && (
                  <Button variant="primary" {...actionBtns?.primary} />
                )}
              </div>
            )}
          </CustomDialogPanel>
        </ModalContainer>
      </Dialog>
    </>
  );
};
export default Modal;
