import { type FC, type SVGProps } from 'react';

const AddIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#AF5AF1"
    {...props}
  >
    <path
      d="M2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12ZM13 7L13 11L17 11L17 13L13 13L13 17L11 17L11 13L7 13L7 11L11 11L11 7L13 7Z"
      fill="current"
    />
  </svg>
);
export default AddIcon;
