import { ConfirmModal } from '@components/confirmModal';
import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import { useDeleteGroup, useListGroups } from 'api/assets/assetsServices';
import { RoleEnum } from 'enums/RoleEnum';
import { useAuth } from 'hooks/contexts/AuthContext';
import useDisclosure from 'hooks/useDisclosure';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetGroupsTableColumn from '../hooks/useGetGroupsTableColumns';
import { EditGroupModal } from '../modals/EditGroupModal';
import { GroupDetailsModal } from '../modals/GroupDetailsModal';

interface GroupsTableProps {
  query?: string;
}

const GroupsTable: FC<GroupsTableProps> = ({ query }) => {
  const [groupId, setGroupId] = useState<number>();
  const { t } = useTranslation();
  const { user } = useAuth();

  const { data, isFetching, fetchNextPage, isFetchingNextPage } = useListGroups(
    { query }
  );
  const {
    mutate: deleteGroup,
    isPending: isLoadingDeleteGroup,
    isSuccess: isDeleteSuccess,
  } = useDeleteGroup();

  const totalDBRowCount = data?.pages?.[0]?.total ?? 0;

  const flatData = useMemo(
    () => data?.pages?.flatMap((page: any) => page?.groups ?? []) ?? [],
    [data]
  );

  const {
    isOpen: isDetailsOpen,
    handleClose: handleDetailsClose,
    handleOpen: handleDetailsOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteGroupOpen,
    handleClose: handleDeleteGroupClose,
    handleOpen: handleDeleteGroupOpen,
  } = useDisclosure();

  const {
    isOpen: isEditGroupOpen,
    handleClose: handleEditGroupClose,
    handleOpen: handleEditGroupOpen,
  } = useDisclosure();

  const handleRowClick = (meter?: any): void => {
    setGroupId(meter.id);
    handleDetailsOpen();
  };

  const handleDeleteGroup = (): void => {
    deleteGroup(groupId);
  };

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.id;
    const actions = [
      {
        name: t('common.actions.details'),
        onClick: () => {
          setGroupId(id);
          handleDetailsOpen();
        },
      },
      user?.role === RoleEnum.ADMINISTRATOR || user?.role === RoleEnum.SYSADMIN
        ? {
            name: t('common.actions.edit'),
            onClick: () => {
              setGroupId(id);
              handleEditGroupOpen();
            },
          }
        : null,
      user?.role === RoleEnum.ADMINISTRATOR || user?.role === RoleEnum.SYSADMIN
        ? {
            name: t('common.actions.delete'),
            onClick: () => {
              setGroupId(id);
              handleDeleteGroupOpen();
            },
          }
        : null,
    ];
    return <TableActions key={`action-${id}`} actions={actions} />;
  };

  const { columns } = useGetGroupsTableColumn({ createTableActions });

  useEffect(() => {
    if (isDeleteSuccess) {
      handleDeleteGroupClose();
    }
  }, [isDeleteSuccess]);
  return (
    <>
      <Table
        columns={columns}
        data={flatData}
        enableRowSelection={true}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        onClickRow={handleRowClick}
        rowEstimateSize={75}
      />
      <GroupDetailsModal
        isOpen={isDetailsOpen}
        handleClose={handleDetailsClose}
        handleEditOpen={handleEditGroupOpen}
        groupId={groupId}
      />
      <EditGroupModal
        isOpen={isEditGroupOpen}
        handleClose={handleEditGroupClose}
        groupId={groupId}
      />
      <ConfirmModal
        isOpen={isDeleteGroupOpen}
        handleOpen={handleDeleteGroupOpen}
        handleClose={handleDeleteGroupClose}
        contentText={
          "Are you sure you want to delete this group? This action can't be undone and all information will be lost."
        }
        onConfirm={handleDeleteGroup}
        isLoading={isLoadingDeleteGroup}
      />
    </>
  );
};
export default GroupsTable;
