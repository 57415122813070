export enum MenuOptionsEnum {
  UNKNOWN_OPTION = 'UNKNOWN_OPTION',
  DASHBOARD = 'Dashboard',
  ASSETS = 'Assets',
  OPERATIONS = 'Operation',
  IMPACT = 'Impact',
  MANAGEMENT = 'Management',
  REPORTS = 'Reports',
  ADMIN = 'Admin',
  SETTINGS = 'Settings',
}
