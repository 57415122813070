import { FTextField } from 'components/form/FTextField/';
import { type FTextFieldProps } from 'components/form/FTextField/FTextField';
import {
  HidePasswordIcon,
  ShowPasswordIcon,
} from 'components/icons/PasswordIcon';
import { type FC, useState } from 'react';

interface PasswordFieldProps extends FTextFieldProps {}

const PasswordField: FC<PasswordFieldProps> = ({
  name,
  label,
  placeholder,
  id,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleOnClick = (): void => {
    setShowPassword(!showPassword);
  };
  return (
    <FTextField
      name={name}
      type={!showPassword ? 'password' : 'text'}
      label={label}
      placeholder={placeholder}
      data-testid="password-input"
      id={id}
      addorment={{
        endAddorment: !showPassword ? (
          <ShowPasswordIcon
            className="cursor-pointer"
            onClick={handleOnClick}
          />
        ) : (
          <HidePasswordIcon
            className="cursor-pointer"
            onClick={handleOnClick}
          />
        ),
      }}
      {...props}
    />
  );
};
export default PasswordField;
