import { MeasuredVsExpectedChart } from '@components/charts';
import { SelectInput } from '@components/inputs/SelectInput';
import { Modal } from '@components/modal';
import { UtilityChip } from '@components/utilityChip';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { Button } from 'layout/Button';
import { type FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ImpactCard } from '../ImpactCard';
import { SelectStatus } from '../SelectStatus';
import { useTranslation } from 'react-i18next';
import {
  useFetchChange,
  useFetchTimeSeries,
  useUpdateStatus,
} from 'api/changes/changesServices';
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { getDaysFromDate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';
import { MinActivityLog } from '@components/activityLog';
import { getReadableUnitName } from 'utils/formatters/units/getReadableUnitName';
import {
  calculateImpactPerDayUnit,
  calculateImpactTotalUnit,
} from 'utils/chart/chartUtils';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';

interface ChangeSideMenuProps extends Disclosure {
  changeId?: string;
}

const ChangeSideMenu: FC<ChangeSideMenuProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  changeId,
}) => {
  const [responsibleUser, setResponsibleUser] = useState<number>();
  const [status, setStatus] = useState('');

  const { t } = useTranslation();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const { data, isFetched } = useFetchChange(changeId);
  const { data: chartData } = useFetchTimeSeries({
    id: data?.change.connection_point_id_client,
    momentChange: data?.change.moment_change ?? '',
  });

  const { mutate, isPending } = useUpdateStatus(changeId);

  const users = useMemo(
    () =>
      data?.available_statuses?.users?.map((user) => ({
        name: user.given_name,
        value: user?.id,
      })) ?? [],
    [data]
  );

  useEffect(() => {
    if (data?.change.status) setStatus(data?.change.status);
    if (data?.change?.assigned_to_user?.id)
      setResponsibleUser(data?.change.assigned_to_user.id);
  }, [data]);

  const handleUserChange = (userId: number | string | undefined): void => {
    if (userId) setResponsibleUser(userId as number);
  };

  const handleStatusChange = (stat: string | number | undefined): void => {
    if (stat) {
      setStatus(stat as string);
    }
  };

  const updateStatus = (): void => {
    const body = {
      status,
      assigned_to_user: responsibleUser,
    };
    mutate(body);
  };

  const lastThreeMonthsData = useMemo(() => {
    const currentDate = new Date();

    const threeMonthsAgo = new Date(currentDate);
    threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
    const threeMonthsAgoTime = threeMonthsAgo.getTime();

    return chartData?.time_series?.filter(
      (point) => new Date(point?.time).getTime() >= threeMonthsAgoTime
    );
  }, [chartData?.time_series]);

  const changesMap = useMemo(() => {
    if (chartData?.changes_map) {
      return Object.entries(chartData.changes_map).map(([id, date]) => ({
        date,
        id,
      }));
    }
    return [];
  }, [chartData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleClose}
      position="right"
      title={data?.change?.connection_point_id}
      useSkeleton={true}
      maxWidth="565px"
    >
      <div className="flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="flex flex-col">
            {data ? (
              <>
                <p className="flex font-bold text-gray-60">
                  {t('anomaliesPage.momentOfChange')}
                </p>
                <p>{convertToReadableDate(data?.change.moment_change)}</p>
              </>
            ) : (
              <>
                <Skeleton containerClassName="flex-1" width={'200px'} />
                <Skeleton containerClassName="flex-1" width={'170px'} />
              </>
            )}
          </div>
          {data ? (
            <UtilityChip utility={data.change.meter_unit.utility_type} />
          ) : (
            <Skeleton borderRadius={'20px'} width="100px" height={'30px'} />
          )}
        </div>
        <div className="border border-gray-10 rounded-lg ">
          <div className="border-b border-gray-10 py-4 px-4 mb-4">
            <p className="uppercase font-bold text-gray-60">
              {data ? (
                t('anomaliesPage.changeDetection')
              ) : (
                <Skeleton width={'250px'} />
              )}
            </p>
          </div>

          {data ? (
            <div className="mt-4 px-4 pb-4">
              <MeasuredVsExpectedChart
                id={data.change.connection_point_id_client}
                data={lastThreeMonthsData}
                changesData={changesMap}
                momentChange={chartData?.current_change}
                unit={getReadableUnitName(data?.change.meter_unit.unit_type)}
                dataGrouping={[
                  ['hour', [1]],
                  ['day', [1, 2, 4]],
                  ['month', [1, 3, 6]],
                  ['year', null],
                ]}
              />
            </div>
          ) : (
            <div className="mt-4 px-4 pb-4">
              <Skeleton height={'200px'} />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-4">
          <ImpactCard
            impact={{
              perDay: data?.change.impact_average_diff_euro,
              total: data?.change.impact_total_euro,
            }}
            type="euros"
            isFetched={isFetched}
          />
          <ImpactCard
            impact={{
              perDay: calculateImpactPerDayUnit(
                data?.change.impact_average_diff ?? 0
              ),
              total: calculateImpactTotalUnit(
                calculateImpactPerDayUnit(
                  data?.change.impact_average_diff ?? 0
                ),
                data?.change.impact_total_euro,
                data?.change.impact_average_diff_euro
              ),
            }}
            isFetched={isFetched}
            type={getReadableUnitName(
              data?.change.meter_unit.unit_type ?? 'undefined'
            )}
            isMissingData={
              !(
                data?.change.impact_average_diff &&
                data?.change.impact_total_euro &&
                data?.change.impact_average_diff_euro
              )
            }
          />
        </div>

        <div className="border border-gray-10 rounded-lg">
          <div className="flex gap-12 px-4 py-4 items-center justify-between border-b border-gray-10">
            <label className="w-7/12 flex">
              {data ? (
                <div>
                  <span className="font-bold text-gray-60">
                    {t('common.statusLabel')}
                  </span>{' '}
                  {t('anomaliesPage.sideMenu.timeOnStatus', {
                    time: getDaysFromDate(data.change.time_on_status),
                  })}
                </div>
              ) : (
                <Skeleton containerClassName="flex-1" />
              )}
            </label>
            <div className="flex w-5/12 shrink-0">
              {data ? (
                <SelectStatus
                  value={status}
                  statusList={data.available_statuses.statuses}
                  onChange={handleStatusChange}
                  disabled={
                    !hasFeatureFlagPermission(
                      FEATURE_FLAGS_LIST.changes.patch_status
                    )
                  }
                />
              ) : (
                <Skeleton
                  containerClassName="flex-1"
                  height={'40px'}
                  borderRadius={'8px'}
                />
              )}
            </div>
          </div>

          <div className="flex gap-12 px-4 py-4 items-center justify-between">
            <label className="text-base w-6/12 text-gray-60 w-7/12">
              {data ? t('common.responsible') : <Skeleton width={'160px'} />}
            </label>
            <div className="w-5/12 shrink-0">
              {data ? (
                <SelectInput
                  value={responsibleUser}
                  options={(users as DropdownOptions[]) ?? []}
                  placeholder="Select user"
                  onChange={handleUserChange}
                  disabled={
                    !hasFeatureFlagPermission(
                      FEATURE_FLAGS_LIST.changes.patch_status
                    )
                  }
                />
              ) : (
                <Skeleton height={'40px'} borderRadius={'8px'} />
              )}
            </div>
          </div>
          <div className="flex justify-end px-4 pb-4">
            {data ? (
              <Button
                label="Assign"
                size="normal"
                onClick={updateStatus}
                isLoading={isPending}
                disabled={
                  !hasFeatureFlagPermission(
                    FEATURE_FLAGS_LIST.changes.patch_status
                  )
                }
              />
            ) : (
              <Skeleton width="100px" height="40px" borderRadius="20px" />
            )}
          </div>
        </div>

        <div className="flex flex-col border border-gray-10 rounded-lg">
          <div className="flex justify-between px-4 py-4 items-center">
            {data ? (
              <>
                <p className="uppercase font-bold text-gray-60">
                  {t('common.details')}
                </p>
                <Link
                  className="text-tertiary-dark font-bold text-base"
                  to={`details/${changeId ?? '#'}`}
                >
                  {t('common.buttons.seeDetails')}
                </Link>
              </>
            ) : (
              <Skeleton containerClassName="flex-1 max-w-[220px]" />
            )}
          </div>
          <div className="flex justify-between px-4 py-1.5 border-t border-gray-10">
            {data ? (
              <>
                <p className="text-gray-60">
                  {data ? t('anomaliesPage.momentOfChange') : <Skeleton />}
                </p>
                <p>{convertToReadableDate(data.change.moment_change)}</p>
              </>
            ) : (
              <>
                <Skeleton containerClassName="w-3/12" />
                <Skeleton containerClassName="w-5/12" />
              </>
            )}
          </div>
          <div className="flex justify-between px-4 py-1.5 border-t border-gray-10">
            {data ? (
              <>
                <p className="text-gray-60">
                  {t('anomaliesPage.timeOnStatus')}
                </p>
                <p>
                  <p>{getDaysFromDate(data.change.time_on_status)}</p>
                </p>
              </>
            ) : (
              <>
                <Skeleton containerClassName="w-2/12" />
                <Skeleton containerClassName="w-2/12" />
              </>
            )}
          </div>
        </div>

        <div className="border border-gray-10 rounded-lg">
          <div className="flex gap-12 px-4 py-4 items-center justify-between border-b border-gray-10">
            <label className="w-7/12 flex">
              {data ? (
                <p className="uppercase font-bold text-gray-60">
                  {t('common.activity')}
                </p>
              ) : (
                <Skeleton containerClassName="flex-1" />
              )}
            </label>
          </div>
          <div className="px-4">
            <MinActivityLog changeId={data?.change.id} isLoading={!data} />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ChangeSideMenu;
