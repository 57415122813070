import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';

export const downloadPDF = async (
  pdfRef: HTMLDivElement | null
): Promise<void> => {
  const input = pdfRef;
  const canvas = await html2canvas(input as HTMLDivElement);

  const imgData = canvas.toDataURL('image/png');
  const pdf = new JsPDF('p', 'mm', 'a4', true);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = pdf.internal.pageSize.getHeight();
  const imgWidth = canvas.width;
  const imgHeight = canvas.height;
  const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  const imgX = (pdfWidth - imgWidth * ratio) / 2;
  const imgY = 30;

  pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
  pdf.save('details.pdf');
};
