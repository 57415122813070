import { SkeletonInput } from '@components/skeleton';
import { type FetchUtilityPricesResponse } from 'api/utilityPrices/UtilityPriceService';
import { UtilityPriceForm } from 'pages/SettingsPage/forms/UtilityPriceForm';
import { type FC } from 'react';
import Skeleton from 'react-loading-skeleton';

interface UtilityPriceListProps {
  utilityPricesListData: FetchUtilityPricesResponse;
  companyId?: number | null | undefined;
  isLoading?: boolean;
}

const UtilityPriceList: FC<UtilityPriceListProps> = ({
  utilityPricesListData,
  companyId,
  isLoading = false,
}) => {
  const skeletonArray = Array(3).fill({});
  return (
    <div data-testid="utility-price-list">
      {utilityPricesListData.utility_prices.map((element, index) => {
        if (element.utility_type === 'UNDEFINED') return null;
        return isLoading ? (
          <div className="flex justify-between w-full gap-6 mt-2">
            {skeletonArray.map((_, index) => (
              <SkeletonInput key={index} />
            ))}
            <div>
              <Skeleton width={45} height={38} borderRadius={'100px'} />
            </div>
          </div>
        ) : (
          <div key={index}>
            <UtilityPriceForm
              key={element.id}
              id={element.id}
              initialValues={{
                id: element.id,
                utility_type: element.utility_type,
                unit_type: element.unit_type,
                price: element.price ? parseFloat(element.price) : 0,
              }}
              companyId={companyId}
            />
          </div>
        );
      })}
    </div>
  );
};

export default UtilityPriceList;
