import { type FC } from 'react';
import { RolePermissionTable } from './table';

const RolePermissionsPage: FC = () => {
  return (
    <div className="flex flex-col gap-4">
      <RolePermissionTable />
    </div>
  );
};
export default RolePermissionsPage;
