import ProgressBarComponent, {
  type ProgressBarComponentProps,
} from '@components/progressBar/ProgressBar';
import { type FC } from 'react';
import { numericFormatter } from 'react-number-format';

interface TableProgressBarProps extends ProgressBarComponentProps {}

const TableProgressBar: FC<TableProgressBarProps> = ({
  completed,
  maxCompleted,
  maxWidth,
}) => {
  return (
    <div className="flex flex-1 items-center gap-4">
      <ProgressBarComponent
        completed={completed}
        maxCompleted={maxCompleted}
        maxWidth={maxWidth}
      />
      <div className="flex gap-1 color-gray-50 w-[80px]">
        <span className="font-bold">
          {numericFormatter(completed.toString(), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 1,
          })}
          %
        </span>

        <span>/</span>
        <span>
          {numericFormatter(maxCompleted.toString(), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 1,
          })}
          %
        </span>
      </div>
    </div>
  );
};
export default TableProgressBar;
