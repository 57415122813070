import { type FieldError } from 'react-hook-form';
import styled from 'styled-components';
import tw from 'twin.macro';

interface AddormentContainerProps {
  rightAligned?: boolean;
}

interface AddormentProps {
  error?: FieldError | undefined;
  $inputDisabled?: boolean;
}

export const AddormentContainer = styled.div<AddormentContainerProps>`
  ${tw`flex items-center`}
`;

export const Addorment = styled.div<AddormentProps>`
  ${tw`flex flex-1 self-stretch px-3 py-2 border rounded-lg`}
  ${(props) => (props.$inputDisabled ? tw`bg-gray-25` : tw`bg-white`)}
`;
