import i18next from 'i18next';

export const getDaysFromDate = (date: string): string => {
  if (date) {
    const currentDate = new Date();
    const inputDate = new Date(date);
    if (isNaN(inputDate.getTime())) {
      return i18next.t('common.errors.undefinedDate');
    }
    const timeDiff = Math.abs(inputDate.getTime() - currentDate.getTime());
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const measurement: string =
      daysDiff === 0 || daysDiff === 1
        ? i18next.t('common.measurement.day')
        : i18next.t('common.measurement.days');

    return `${daysDiff} ${measurement}`;
  } else {
    return i18next.t('common.errors.undefinedDate');
  }
};

export const getDaysFromStatusLastUpdate = (dateString: string): string => {
  const match = /^(\d+ days)/.exec(dateString);
  if (match) {
    return match[1];
  }
  return '1 Day';
};
