import { type FC } from 'react';
import Skeleton, { type SkeletonProps } from 'react-loading-skeleton';

interface SkeletonInputProps extends SkeletonProps {
  withLabel?: boolean;
  labelWidth?: number | string;
}

const SkeletonInput: FC<SkeletonInputProps> = (props) => {
  return (
    <div className="flex flex-col flex-1 w-full gap-2">
      {props.withLabel && <Skeleton width={props.labelWidth} />}
      <Skeleton borderRadius={'8px'} height={'40px'} {...props} />
    </div>
  );
};
export default SkeletonInput;
