import { FEditableField } from 'components/form/FEditableField';
import { FSelectField } from 'components/form/FSelectField';
import { FTextField } from 'components/form/FTextField';
import { EmailIcon } from 'components/icons/EmailIcon';
import useHRForm from 'hooks/useHRForm';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC, useEffect, useMemo, useState } from 'react';
import { ChangeUserPassword, EditFormButton } from '../../components';
import { editProfileControlNames, editProfileSchema } from './schema';
import { useTranslation } from 'react-i18next';
import {
  useFetchUser,
  useUpdateUserDetails,
  useUpdateUserRole,
} from 'api/user/userService';
import { useAuth } from 'hooks/contexts/AuthContext';
import { LANGUAGES, ROLES, USER_FUNCTIONS } from 'lib/constants';

const ProfileTabContent: FC = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: userData } = useFetchUser(user?.id);
  const {
    mutate: updateUserMutate,
    isSuccess: isEditUserSuccess,
    isPending: isEditUserLoading,
  } = useUpdateUserDetails(user?.id);

  const { mutateAsync: updateUserRole } = useUpdateUserRole(user?.id);

  const initialValues = useMemo(() => {
    if (!userData) return;
    const user = userData?.user;
    return {
      email: user.email,
      given_name: user.given_name,
      family_name: user.family_name,
      phone_number: user.phone_number,
      role: user.role,
      function: user.function,
      language: user.language,
    };
  }, [userData]);

  const { methods, Form } = useHRForm({
    schema: editProfileSchema,
    initialValues,
  });

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  const handleIsEditingForm = (): void => {
    setIsEditing(!isEditing);
  };

  const updateUser = async (): Promise<void> => {
    const { role, ...updateUserBody } = methods.getValues();

    updateUserMutate(updateUserBody);
    if (userData?.user.role === 'ADMINISTRATOR') await updateUserRole({ role });
  };

  return (
    <CardContainer>
      <CardTitle
        title={t('settingsPage.profileTab.title')}
        subtitle={t('settingsPage.profileTab.subTitle')}
        isLoading={!userData}
        skeletonVariant={'subtitleAndButton'}
        endAddorment={
          <EditFormButton
            isEditing={isEditing}
            handleIsEditing={handleIsEditingForm}
            onSubmit={async () => {
              await methods.handleSubmit(updateUser)();
            }}
            isSuccess={isEditUserSuccess}
            isLoading={isEditUserLoading}
          />
        }
      />
      <div className="flex flex-col">
        <div>
          <Form onSubmit={updateUser}>
            <div className="flex flex-col w-5/12 gap-6 mt-6">
              <FEditableField
                label={t('common.inputs.fullName')}
                name={editProfileControlNames.fullName}
                isEditing={isEditing}
                isLoading={!userData}
                value={`${userData?.user?.given_name ?? ''} ${
                  userData?.user?.family_name ?? ''
                }`}
                fieldComponent={
                  <FTextField
                    label={t('common.inputs.firstName')}
                    name={editProfileControlNames.givenName}
                    id="user-name"
                  />
                }
              />
              {isEditing && (
                <FEditableField
                  label={t('common.inputs.familyName')}
                  name={editProfileControlNames.familyName}
                  isEditing={isEditing}
                  isLoading={!userData}
                  fieldComponent={
                    <FTextField
                      label={t('common.inputs.familyName')}
                      name={editProfileControlNames.familyName}
                      id="user-family-name"
                    />
                  }
                />
              )}
              <FEditableField
                label={t('common.inputs.emailAddress')}
                name={editProfileControlNames.email}
                isEditing={isEditing}
                isLoading={!userData}
                fieldComponent={
                  <FTextField
                    label={t('common.inputs.emailAddress')}
                    name={editProfileControlNames.email}
                    addorment={{ endAddorment: <EmailIcon /> }}
                    id="user-email"
                  />
                }
              />
              <FEditableField
                label={t('common.inputs.role')}
                name={editProfileControlNames.role}
                isEditing={userData?.user.role === 'ADMINISTRATOR' && isEditing}
                capitalize={true}
                isLoading={!userData}
                value={userData?.user.role}
                fieldComponent={
                  <FSelectField
                    label={t('common.inputs.role')}
                    name={editProfileControlNames.role}
                    options={ROLES}
                  />
                }
              />
              <FEditableField
                label={t('common.inputs.function')}
                name={editProfileControlNames.function}
                isEditing={isEditing}
                capitalize={true}
                isLoading={!userData}
                fieldComponent={
                  <FSelectField
                    label={t('common.inputs.function')}
                    name={editProfileControlNames.function}
                    options={USER_FUNCTIONS}
                  />
                }
              />
              <FEditableField
                label={t('common.inputs.language')}
                name={editProfileControlNames.language}
                isEditing={isEditing}
                capitalize={true}
                isLoading={!userData}
                fieldComponent={
                  <FSelectField
                    label={t('common.inputs.language')}
                    name={editProfileControlNames.language}
                    options={LANGUAGES}
                  />
                }
              />
            </div>
          </Form>
          <div className="w-5/12 gap-6 mt-6">
            <ChangeUserPassword isLoading={!userData} />
          </div>
        </div>
      </div>
    </CardContainer>
  );
};
export default ProfileTabContent;
