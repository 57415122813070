import { ChartButton } from '@components/charts/ChartButton';
import { useFetchProjectTimeSeries } from 'api/projects/projectsServices';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useDebounce } from 'hooks/useDebounce';
import { t } from 'i18next';
import useSetExtremes, { type TimeValuePair } from '../../hooks/useSetExtremes';
import ProjectMeasuredVsExpectedChart from '../../charts/ProjectMeasuredVsExpectedChart/ProjectMeasuredVsExpectedChart';
import { SavingsChart } from '../../charts/SavingsChart';
import { SavingsCuSUMChart } from '../../charts/SavingsCuSUM';
import { type HeatMapData } from 'interfaces/change/ChangeData.interface';
import { HeatMap } from '@components/heatMap';

type HeatmapDateAndHour = Record<string, { value: number; count: number }>;

interface SavingsChartsProps {
  isLoading: boolean;
  id?: string | number;
  unit?: string;
  savingsTarget?: number;
}

const CHART_BUTTON_VALUES = {
  MEASURED: 'measured',
  SAVINGS: 'savings',
  SAVINGS_CUSUM: 'savings_cusum',
};

const SavingsCharts: FC<SavingsChartsProps> = ({
  isLoading,
  id,
  unit,
  savingsTarget,
}) => {
  const [viewportData, setViewportData] = useState<TimeValuePair[]>([]);
  const debouncedViewportData = useDebounce(viewportData, 300);

  const { data } = useFetchProjectTimeSeries(id);

  const xAxisEvents = useSetExtremes({ setState: setViewportData });

  const [activeChart, setActiveChart] = useState<string>(
    CHART_BUTTON_VALUES.MEASURED
  );

  const buttonList = [
    {
      label: t('anomaliesDetailsPage.buttons.measuredVsExcepted'),
      value: CHART_BUTTON_VALUES.MEASURED,
    },
    {
      label: t('common.savings'),
      value: CHART_BUTTON_VALUES.SAVINGS,
    },
    {
      label: t('common.savingsCuSUM'),
      value: CHART_BUTTON_VALUES.SAVINGS_CUSUM,
    },
  ];

  const heatmapData = useMemo(() => {
    if (!debouncedViewportData) return [];
    const parsed: HeatMapData[] = debouncedViewportData?.map(
      (ts: HeatMapData) => ({
        day: new Date(ts.time).toLocaleDateString('en-US', { weekday: 'long' }),
        time: format(new Date(ts.time), "HH'h'"),
        value: ts.value,
      })
    );

    const sumWithInitial = parsed?.reduce(
      (accumulator: HeatmapDateAndHour, currentValue) => {
        const key = `${currentValue.day}-${currentValue.time}`;

        if (!accumulator[key]) {
          accumulator[key] = { value: currentValue.value, count: 1 };
        } else {
          accumulator[key].value += currentValue.value;
          accumulator[key].count++;
        }
        return accumulator;
      },
      {}
    );
    const result = [];

    for (const key in sumWithInitial) {
      const [day, time] = key.split('-');
      const { count, value } = sumWithInitial[key];
      const averageValue = count !== 0 ? value / count : 0;
      result.push({ day, value: averageValue, time });
    }

    return result;
  }, [debouncedViewportData]);

  useEffect(() => {
    setViewportData([]);
  }, [activeChart]);

  return (
    <CardContainer>
      <CardTitle
        title={'Savings details'}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
      />
      <ChartButton
        activeChart={activeChart}
        buttonList={buttonList}
        handleActiveChart={setActiveChart}
        isLoading={isLoading}
      />
      {activeChart === CHART_BUTTON_VALUES.MEASURED && (
        <ProjectMeasuredVsExpectedChart
          data={data}
          xAxisEvents={xAxisEvents}
          unit={unit}
        />
      )}
      {activeChart === CHART_BUTTON_VALUES.SAVINGS && (
        <SavingsChart data={data} xAxisEvents={xAxisEvents} unit={unit} />
      )}
      {activeChart === CHART_BUTTON_VALUES.SAVINGS_CUSUM && (
        <SavingsCuSUMChart
          data={data}
          xAxisEvents={xAxisEvents}
          unit={unit}
          savingsTarget={savingsTarget}
        />
      )}
      <div className="mt-9">
        <p className="mb-6 text-base">
          {t('projectsDetailsPage.heatmapTitle')}
        </p>
        <HeatMap heatMapData={heatmapData} unit={unit} positiveIsRed={false} />
      </div>
    </CardContainer>
  );
};
export default SavingsCharts;
