import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type StatusEnum } from 'enums/StatusEnum';
import { type FC } from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import Skeleton from 'react-loading-skeleton';
import { getStatusReadableName } from 'utils/statusUtil/statusUtil';
import { Filter } from './styled';

interface TableFiltersProps {
  disableArrows?: boolean;
  filters?: Record<string, number>;
  activeFilter?: string;
  isLoading?: boolean;
  onClick?: (value: string) => void;
}

const TableFilters: FC<TableFiltersProps> = ({
  filters = {},
  disableArrows = false,
  activeFilter,
  isLoading,
  onClick,
}) => {
  const { theme } = useTheme();
  const skeletonArray = Array(7).fill({});

  return (
    <div className="flex flex-1 py-2.5 px-4 bg-gray-25 justify-around items-center rounded-lg">
      {!isLoading ? (
        Object.entries(filters).map(([key, value], index) => {
          return (
            <>
              <Filter
                key={key}
                onClick={() => onClick?.(key)}
                $isActive={activeFilter === key}
              >
                <label className="cursor-pointer">
                  {getStatusReadableName(key as StatusEnum)}
                </label>
                <span>({value ?? 0})</span>
              </Filter>
              {!disableArrows && index + 1 !== Object.keys(filters).length && (
                <IoIosArrowForward key={index} data-testid="arrow-icon" />
              )}
            </>
          );
        })
      ) : (
        <div className="flex flex-1 items-center py-2.5 px-4 gap-4">
          {skeletonArray.map((_, index) => (
            <>
              <Skeleton
                height={20}
                containerClassName={'flex flex-1'}
                baseColor={theme.colors['gray-35']}
              />
              {!disableArrows && index + 1 !== Object.keys(filters).length && (
                <IoIosArrowForward key={index} data-testid="arrow-icon" />
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
export default TableFilters;
