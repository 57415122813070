import { ProtectedLayout } from 'layout/ProtectedLayout';
import { HomePage } from 'pages/HomePage';
import { LoginPage } from 'pages/Authentication/LoginPage';
import { Navigate, type RouteObject } from 'react-router-dom';
import { RegisterPage } from 'pages/Authentication/RegisterPage';
import { SettingsPage } from 'pages/SettingsPage';
import { ChangesListPage } from 'pages/Changes/ChangesListPage';
import { ChangeDetailsPage } from 'pages/Changes/ChangeDetailsPage';
import { ForgotPasswordPage } from 'pages/Authentication/ResetPassword/ForgotPasswordPage';
import { ResetPasswordPage } from 'pages/Authentication/ResetPassword/ResetPasswordPage';
import AssetsPage from 'pages/Assets/AssetsPage';
import { AlertsPage } from 'pages/Alerts/AlertsPage';
import { PublicSwaggerPage } from 'pages/Docs/PublicSwaggerPage';
import { PrivateSwaggerPage } from 'pages/Docs/PrivateSwaggerPage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import { SysAdminPage } from 'pages/SysAdminPage';
import { ProjectsPage } from 'pages/Management/ProjectListPage';
import { ReportsPage } from 'pages/ReportsPage';
import { NotFoundPage } from 'pages/NotFound';
import { checkPageFeatureFlag } from 'utils/permission/permissionUtil';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FeatureFlagFromProvider } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { ProjectDetailsPage } from 'pages/Management/ProjectDetailsPage';
import { ImpactListPage } from 'pages/ImpactPage/ImpactListPage';
import { ImpactDetails } from 'pages/ImpactPage/ImpactDetails';

const generateRoutes = (
  isAuthenticated: boolean,
  hasFeatureFlagPermission: (flag: FeatureFlagFromProvider) => boolean
): RouteObject[] => [
  {
    path: '/',
    element: <Navigate to="app/operations/anomalies" />,
  },
  {
    path: '/v1/docs',
    element: <PublicSwaggerPage />,
  },
  {
    path: '/v2/docs',
    element: isAuthenticated ? (
      <PrivateSwaggerPage />
    ) : (
      <Navigate to="/login" />
    ),
  },
  { path: '*', element: <NotFoundPage /> },
  {
    path: '/app',
    element: isAuthenticated ? <ProtectedLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'not-found', element: <NotFoundPage /> },
      {
        path: '',
        element: <HomePage />,
      },
      {
        path: 'dashboard',
        element: <DashboardPage />,
      },
      { path: 'settings', element: <SettingsPage /> },
      {
        path: 'management',
        children: [
          {
            path: 'projects',
            children: [
              {
                path: '',
                element: checkPageFeatureFlag(
                  hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.list),
                  <ProjectsPage />
                ),
              },
              {
                path: 'details/:id',
                element: checkPageFeatureFlag(
                  hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.get),
                  <ProjectDetailsPage />
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'operations',
        children: [
          {
            path: 'anomalies',
            children: [
              {
                path: '',
                element: checkPageFeatureFlag(
                  hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.list),
                  <ChangesListPage />
                ),
              },
              {
                path: 'details/:id',
                element: checkPageFeatureFlag(
                  hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.get),
                  <ChangeDetailsPage />
                ),
              },
            ],
          },
          {
            path: 'alerts',
            children: [{ path: '', element: <AlertsPage /> }],
          },
        ],
      },
      {
        path: 'impact',
        children: [
          { path: '', element: <ImpactListPage /> },
          { path: 'summary', element: <ImpactDetails /> },
        ],
      },
      { path: 'reports', children: [{ path: '', element: <ReportsPage /> }] },
      {
        path: 'assets',
        children: [{ path: 'list', element: <AssetsPage /> }],
      },
      {
        path: 'admin',
        children: [{ path: '', element: <SysAdminPage /> }],
      },
    ],
  },

  { path: 'login', element: <LoginPage /> },
  { path: 'register', element: <RegisterPage /> },
  { path: 'forgot-password', element: <ForgotPasswordPage /> },
  { path: 'reset-password', element: <ResetPasswordPage /> },
];

export default generateRoutes;
