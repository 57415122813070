import { type FC } from 'react';
import { Link } from 'react-router-dom';

export interface SubMenuOptionProps {
  label: string;
  linkTo: string;
  incoming?: boolean;
  hide?: boolean;
}

const SubMenuOption: FC<SubMenuOptionProps> = ({ label, linkTo }) => {
  return (
    <Link
      to={linkTo}
      className="hover:text-tertiary-dark text-base text-gray-50 px-6 py-2 transition-all"
    >
      {label}
    </Link>
  );
};
export default SubMenuOption;
