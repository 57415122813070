import MultiStep from 'components/multiStep/MultiStep';
import { MultistepProvider } from 'context/MultiStepProvider/MultiStepProvider';
import { type FC } from 'react';
import {
  RegisterStepFour,
  RegisterStepOne,
  RegisterStepThree,
  RegisterStepTwo,
} from '../../forms';

const RegisterForm: FC = () => {
  const steps = [
    <RegisterStepOne key="step-one" />,
    <RegisterStepTwo key="step-two" />,
    <RegisterStepThree key="step-three" />,
    <RegisterStepFour key="step-four" />,
  ];

  return (
    <div className="flex items-center h-full py-32 justify-center overflow-auto">
      <MultistepProvider>
        <MultiStep steps={steps} />
      </MultistepProvider>
    </div>
  );
};
export default RegisterForm;
