import { z } from 'zod';

interface addAlertControlNamesType {
  operator: 'operator';
  amount: 'amount';
  threshold: 'threshold';
  user: 'user';
}

export const addAlertSchema = z.object({
  operator: z.string(),
  amount: z.number(),
  threshold: z.string(),
  user: z.number(),
});

export const addAlertControlNames: addAlertControlNamesType = {
  operator: 'operator',
  amount: 'amount',
  threshold: 'threshold',
  user: 'user',
};

export type addAlertSchemaType = z.infer<typeof addAlertSchema>;
