import React, {
  type ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import { colors } from '../../lib/colors';

type ThemeType = typeof colors;

const ThemeContext = createContext<{ theme: { colors: ThemeType } }>({
  theme: { colors },
});

export const useTheme = (): { theme: { colors: ThemeType } } => {
  return useContext(ThemeContext);
};

export const ThemeProvider = ({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element => {
  const theme = colors;

  const [currentTheme] = useState(theme);

  return (
    <ThemeContext.Provider value={{ theme: { colors: currentTheme } }}>
      {children}
    </ThemeContext.Provider>
  );
};
