import { SearchBar } from '@components/inputs/SearchBar';
import { SelectInput } from '@components/inputs/SelectInput';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { useDebounce } from 'hooks/useDebounce';
import { type ChangeEvent, type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUtilityTypeReadableName } from 'utils/utilityTypeUtils/utilityTypeUtils';
import MetersTable from './Tables/MetersTable';
import { useAuth } from 'hooks/contexts/AuthContext';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';

const MetersListPage: FC = () => {
  const { t } = useTranslation();
  const [utilityType, setUtilityType] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [utilityOptions] = useState<DropdownOptions[]>([
    ...Object.entries(UtilityTypeEnum).map(([key, value]) => {
      return {
        name: getUtilityTypeReadableName(value) ?? undefined,
        value: value as string,
      };
    }),
  ]);
  const { user } = useAuth();
  const query = useDebounce(searchValue, 300);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  const handleUtilityTypeFilter = (
    utility: string | number | undefined
  ): void => {
    if (typeof utility === 'string') {
      setUtilityType(utility === 'all' ? '' : utility);
    }
  };

  return (
    <div>
      <h1>{'Meters'}</h1>
      <div className="flex gap-4 my-6 w-6/12">
        <div className="flex-[1.5_1_0%]">
          <SearchBar
            placeholder={t('anomaliesPage.placeholders.search')}
            onChange={handleSearchInput}
          />
        </div>
        <SelectInput
          placeholder={t('common.utilityType')}
          options={utilityOptions}
          value={utilityType}
          onChange={handleUtilityTypeFilter}
          data-testid="utility-dropdown"
        />
      </div>
      {!!user?.company_id && (
        <div>
          <MetersTable
            query={query}
            filters={{
              utilityType: utilityType?.toUpperCase(),
              companyId: user?.company_id,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default MetersListPage;
