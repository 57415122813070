import { type FC, useMemo, useState } from 'react';
// import { AlertsAutomaticAssignments } from './components/AlertsAutomaticAssignments';
// import { AlertsReminders } from './components/AlertsReminders';
// import { WeeklyReports } from './components/WeeklyReports';
import { useFetchCompanyUsers } from 'api/user/userService';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { AlertDefinitions } from './components/AlertDefinitions';
import { WeeklyReports } from './components/WeeklyReports';

const AlertsSettingsPage: FC = () => {
  const [params] = useState({
    query: '',
    roles: '',
  });
  const { data: usersListData } = useFetchCompanyUsers({
    roles: params.roles,
    query: params.query,
  });

  const userOptions: DropdownOptions[] = useMemo(
    () =>
      usersListData?.pages[0].users?.map((user: UserResponse) => ({
        name: user.given_name + ' ' + user.family_name,
        value: user.id,
      })) ?? [],
    [usersListData]
  );
  return (
    <div className="flex flex-col gap-6">
      <AlertDefinitions userOptions={userOptions} />
      {/* <AlertsAutomaticAssignments userOptions={userOptions} />
      <AlertsReminders /> */}
      <WeeklyReports />
    </div>
  );
};
export default AlertsSettingsPage;
