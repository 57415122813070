import React, { forwardRef } from 'react';
import type { ChartComponentLike, ChartType } from 'chart.js';
import { MatrixController } from 'chartjs-chart-matrix';

import {
  type ChartJSOrUndefined,
  type ChartProps,
  type TypedChartComponent,
} from 'react-chartjs-2/dist/types';
import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';

function createTypedMatrixChart<T extends ChartType>(
  type: T,
  registerables: ChartComponentLike
): TypedChartComponent<T> {
  // Specify the type argument for TypedChartComponent here
  ChartJS.register(registerables);

  return forwardRef<ChartJSOrUndefined<T>, Omit<ChartProps<T>, 'type'>>(
    (props, ref) => <Chart {...props} ref={ref} type={type} />
  ) as TypedChartComponent<T>;
}

export const Matrix = /* #__PURE__ */ createTypedMatrixChart(
  'matrix',
  MatrixController
);
