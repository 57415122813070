import { type MenuOptionsEnum } from 'enums/MenuOptionsEnum';
import { type FC, type SVGProps } from 'react';
import { SubMenuOption } from '../SubMenuOption';
import { type SubMenuOptionProps } from '../SubMenuOption/SubMenuOption';
import { ButtonLabel, StyledMenuContent, StyledSideMenuButton } from './styled';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { IncomingFeatureTag } from '@components/incomingFeatureTag';

interface SideMenuButtonProps {
  Icon: FC<SVGProps<SVGSVGElement>>;
  label: string;
  isDrawerExpanded: boolean;
  activeMenu: MenuOptionsEnum;
  menuOption: MenuOptionsEnum;
  subMenuList?: SubMenuOptionProps[];
  currentExpandedMenuOption: MenuOptionsEnum;
  handleMenuExpansion: (option: MenuOptionsEnum) => void;
  linkTo?: string;
  incoming?: boolean;
}

const SideMenuButton: FC<SideMenuButtonProps> = ({
  Icon,
  label,
  isDrawerExpanded,
  activeMenu,
  menuOption,
  subMenuList,
  currentExpandedMenuOption,
  handleMenuExpansion,
  linkTo,
  incoming,
}) => {
  const navigate = useNavigate();
  const isMenuActive = activeMenu === menuOption;
  const isMenuExpanded = currentExpandedMenuOption === menuOption;
  return (
    <div className="w-full flex flex-col items-center ">
      <StyledSideMenuButton
        className={`group ${isMenuActive ? '' : 'hover:bg-menu-light-purple'}`}
        $isActive={isMenuActive}
        $isDrawerExpanded={isDrawerExpanded}
        onClick={() => {
          handleMenuExpansion(menuOption);
          if (linkTo) navigate(linkTo);
        }}
      >
        <div className="flex gap-2 items-center w-full">
          <div className="flex justify-center items-center">
            {
              <Icon
                className={`${
                  isMenuActive
                    ? 'fill-tertiary-dark'
                    : 'fill-gray-40 group-hover:fill-primary'
                }`}
              />
            }
          </div>
          {isDrawerExpanded && (
            <div className="flex items-center justify-between flex-1">
              <ButtonLabel
                $isDrawerExpanded={isDrawerExpanded}
                className="hover:text-gray-60"
              >
                {label}
              </ButtonLabel>
              {subMenuList && subMenuList?.length > 0 && (
                <span>
                  {isMenuExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
              )}
              {incoming && <IncomingFeatureTag />}
            </div>
          )}
        </div>
      </StyledSideMenuButton>
      {subMenuList && subMenuList?.length > 0 && (
        <StyledMenuContent $isMenuExpanded={isMenuExpanded}>
          <div className="flex flex-col gap-2 py-4">
            {subMenuList.map(
              (subMenu) =>
                !subMenu.hide && (
                  <div
                    key={subMenu.label}
                    className="flex justify-between items-center flex-1"
                  >
                    <SubMenuOption
                      key={subMenu.label}
                      label={subMenu.label}
                      linkTo={subMenu.linkTo}
                    />
                    {subMenu.incoming && <IncomingFeatureTag />}
                  </div>
                )
            )}
          </div>
        </StyledMenuContent>
      )}
    </div>
  );
};
export default SideMenuButton;
