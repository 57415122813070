import IntercomChat from '@components/intercom/intercom';
import useGetMenuOptions from 'hooks/sideMenu/useGetMenuOptions';
import { MainHeader } from 'layout/MainHeader';
import { SideMenu } from 'layout/SideMenu';
import { type FC } from 'react';
import { Outlet } from 'react-router-dom';

const ProtectedLayout: FC = () => {
  const { sideMenuItems } = useGetMenuOptions();
  return (
    <div className="flex min-h-screen">
      <SideMenu menuOptions={sideMenuItems} />
      <div className="flex flex-col w-full">
        <MainHeader />
        <div className="px-11 py-9 phone:max-w-[1420px] desktop-l:max-w-[1520px] w-full mx-auto bg-menu-background h-full">
          <Outlet />
        </div>
      </div>
      <IntercomChat />
    </div>
  );
};
export default ProtectedLayout;
