import { useListDashboardProjects } from 'api/dashboard/dashboardServices';
import { CardContainer, CardTitle } from 'layout/Card';
import { BestPerformanceProjectsTable } from 'pages/DashboardPage/table/BestPerformanceProjectsTable';
import { NeedAttentionProjectsTable } from 'pages/DashboardPage/table/NeedAttentionProjectsTable';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface ProjectsPerformanceTablesProps {
  hoveredId?: number;
  onRowHover: (value: number | undefined) => void;
}

const ProjectsPerformanceTables: FC<ProjectsPerformanceTablesProps> = ({
  hoveredId,
  onRowHover,
}) => {
  const { t } = useTranslation();
  const { data, isFetching } = useListDashboardProjects();
  return (
    <div className="flex flex-col gap-4" data-testid="projects-container">
      <div className="flex justify-between items-center">
        <CardTitle
          title={t('common.projects')}
          tooltip={t('dashboardPage.projectsTooltip')}
        />

        <Link
          to={'/app/management/projects'}
          className="text-tertiary-dark font-bold uppercase"
        >
          {t('common.buttons.seeAll')}
        </Link>
      </div>

      <div className="flex flex-col laptop-l:flex-row gap-6">
        <CardContainer>
          <NeedAttentionProjectsTable
            data={data?.needs_attention}
            isFetching={isFetching}
            hoveredId={hoveredId}
            onRowHover={onRowHover}
          />
        </CardContainer>
        <CardContainer>
          <BestPerformanceProjectsTable
            data={data?.best_performance}
            isFetching={isFetching}
            hoveredId={hoveredId}
            onRowHover={onRowHover}
          />
        </CardContainer>
      </div>
    </div>
  );
};
export default ProjectsPerformanceTables;
