import { type FC, type SVGProps } from 'react';

const RemoveIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="#AF5AF1"
    {...props}
  >
    <g clipPath="url(#clip0_4146_14243)">
      <path
        d="M9.92993 9.86776C6.00987 13.7541 5.98249 20.0897 9.86881 24.0098C13.7551 27.9298 20.0908 27.9572 24.0108 24.0709C27.9309 20.1846 27.9583 13.8489 24.0719 9.92888C20.1856 6.00882 13.85 5.98144 9.92993 9.86776ZM21.2252 14.1593L18.3846 16.9754L21.2008 19.8161L19.7804 21.2241L16.9643 18.3835L14.1236 21.1997L12.7155 19.7794L15.5562 16.9632L12.74 14.1226L14.1603 12.7145L16.9765 15.5551L19.8171 12.7389L21.2252 14.1593Z"
        fill="current"
      />
    </g>
    <defs>
      <clipPath id="clip0_4146_14243">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 16.8965) rotate(-44.7524)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default RemoveIcon;
