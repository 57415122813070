import useHRForm from 'hooks/useHRForm';
import { type FC, useState } from 'react';
import { ForgotPasswordInitialValues } from './initialValues';
import {
  type ForgotPasswordSchemaType,
  forgotPasswordControlNames,
  forgotPasswordSchema,
} from './schema';
import { FTextField } from '@components/form/FTextField';
import { EmailIcon } from '@components/icons/EmailIcon';
import { useTranslation } from 'react-i18next';
import { Button } from 'layout/Button';
import { requestResetPasswordEmail } from 'api/auth/authService';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordFormProps {
  setStep: (step: number) => void;
  setEmail: (email: string) => void;
}

const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  setStep,
  setEmail,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { Form } = useHRForm({
    schema: forgotPasswordSchema,
    initialValues: ForgotPasswordInitialValues,
  });

  const onSubmit = async (data: ForgotPasswordSchemaType): Promise<void> => {
    setIsLoading(true);
    const response = await requestResetPasswordEmail(data.email);
    setIsLoading(false);
    if (response?.status === 202) {
      setEmail(data.email);
      setStep(1);
    }
  };

  const goBackToLogIn = (): void => {
    navigate('/login');
  };

  return (
    <div data-testid="forgot-password-step-one-form">
      <Form onSubmit={onSubmit}>
        <div className="flex flex-col gap-6">
          <FTextField
            name={forgotPasswordControlNames.email}
            label={t('common.inputs.email')}
            placeholder={t('common.placeholders.email')}
            addorment={{ endAddorment: <EmailIcon /> }}
            data-testid="email-input"
          />
        </div>
        <Button
          className="w-full mt-lg"
          type="submit"
          label={t('common.buttons.continue')}
          size="large"
          isLoading={isLoading}
        />
        <Button
          className="w-full mt-lg"
          type="submit"
          variant="secondary"
          label={t('common.buttons.backToLogin')}
          size="large"
          isLoading={isLoading}
          onClick={goBackToLogIn}
        />
      </Form>
    </div>
  );
};
export default ForgotPasswordForm;
