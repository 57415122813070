import { type CircleLayer } from 'mapbox-gl';
import { type MutableRefObject } from 'react';
import { type MapRef } from 'react-map-gl';

export interface Feature {
  type: string;
  properties: { id: number };
  geometry: any;
}

export interface HoverPaintingProperties {
  layerId: string;
  paintProperty: string;
  hoveredId?: number | undefined;
  hoveredColor: string;
  defaultColor: string;
  hoveredFeature?: Feature | undefined;
  border?:
    | {
        hoveredColor: string;
        defaultColor: string;
      }
    | undefined;
}

const useMapUtilities = (
  mapRef: MutableRefObject<MapRef | null>
): {
  calculateAverageMapCircleSize: <T extends Record<string, any>>(
    list: T[],
    key: string
  ) => number;
  getMinMaxSizeValue: (avg: number, value: number) => number;
  defaultCircleLayerStyle: CircleLayer;
  onHoverPainting: ({
    layerId,
    paintProperty,
    hoveredId,
    hoveredColor,
    defaultColor,
    hoveredFeature,
    border,
  }: HoverPaintingProperties) => void;
} => {
  function calculateAverageMapCircleSize<T extends Record<string, any>>(
    list: T[],
    key: string
  ): number {
    const sum = list.reduce((acc, obj) => {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (typeof value === 'number') {
          return acc + value;
        }
      }
      return acc;
    }, 0);
    return sum / list.length;
  }

  const getMinMaxSizeValue = (avg: number, value: number): number => {
    const average = value / avg;
    if (average < 0.5) {
      return 0.5;
    }
    if (average > 2) {
      return 2.0;
    }
    return average;
  };

  const onHoverPainting = ({
    layerId,
    paintProperty,
    hoveredId,
    hoveredColor,
    defaultColor,
    hoveredFeature,
    border,
  }: {
    layerId: string;
    paintProperty: string;
    hoveredId?: number;
    hoveredColor: string;
    defaultColor: string;
    hoveredFeature?: Feature;
    border?: { hoveredColor: string; defaultColor: string };
  }): void => {
    const map = mapRef?.current;
    if (hoveredFeature) {
      map
        ?.getMap()
        .setPaintProperty(layerId, paintProperty, [
          'match',
          ['get', 'id'],
          hoveredId,
          hoveredColor,
          defaultColor,
        ]);

      if (border) {
        map
          ?.getMap()
          .setPaintProperty(layerId, 'circle-stroke-color', [
            'match',
            ['get', 'id'],
            hoveredId,
            border.hoveredColor,
            border.defaultColor,
          ]);
      }
    } else {
      map?.getMap().setPaintProperty(layerId, paintProperty, defaultColor);
      if (border) {
        map
          ?.getMap()
          .setPaintProperty(
            layerId,
            'circle-stroke-color',
            border.defaultColor
          );
      }
    }
  };

  const defaultCircleLayerStyle: CircleLayer = {
    id: '',
    type: 'circle',
    paint: {
      'circle-radius': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0,
        ['*', ['get', 'avgBasedSize'], 10], // Adjusted radius for zoom level 0
        5,
        ['*', ['get', 'avgBasedSize'], 13], // Adjusted radius for zoom level 5
        10,
        ['*', ['get', 'avgBasedSize'], 30], // Adjusted radius for zoom level 10
        15,
        ['*', ['get', 'avgBasedSize'], 20], // Adjusted radius for zoom level 15
        18,
        ['*', ['get', 'avgBasedSize'], 50], // Adjusted radius for zoom level 18
        20,
        ['*', ['get', 'avgBasedSize'], 60], // Adjusted radius for zoom level 20
      ],
      'circle-stroke-width': 1,
      'circle-opacity': 0.4,
    },
  };

  return {
    calculateAverageMapCircleSize,
    getMinMaxSizeValue,
    defaultCircleLayerStyle,
    onHoverPainting,
  };
};
export default useMapUtilities;
