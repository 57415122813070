import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';
import { MultiTab } from 'components/multiTab';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CompanyTabContent,
  ProfileTabContent,
  UsersTabContent,
} from './TabsContent';
import { useAuth } from 'hooks/contexts/AuthContext';
import { RoleEnum } from 'enums/RoleEnum';

const SettingsPage: FC = () => {
  Mixpanel.pageview();
  const { t } = useTranslation();
  const { user } = useAuth();
  const tabs = [
    {
      title: t('common.profile'),
      component: <ProfileTabContent />,
    },
    ...(user?.role !== RoleEnum.READER
      ? [
          {
            title: t('common.companyInfo'),
            component: <CompanyTabContent />,
          },
          {
            title: t('common.users'),
            component: <UsersTabContent />,
          },
        ]
      : []),
  ];
  return (
    <div>
      <h1 className="mb-6">{t('common.settings')}</h1>
      <MultiTab tabs={tabs} />
    </div>
  );
};
export default SettingsPage;
