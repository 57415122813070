import { type MenuOption } from '@components/menuDropdown/MenuDropdown';
import ActionIcon from 'components/icons/ActionIcon/ActionIcon';
import { MenuDropdown } from 'components/menuDropdown';
import { type FC } from 'react';

interface TableActionsProps {
  id?: string;
  actions: (MenuOption | null)[];
}

const TableActions: FC<TableActionsProps> = ({ id, actions }) => {
  return (
    <MenuDropdown
      label={<ActionIcon />}
      menuOptions={actions}
      enableDropdownArrow={false}
      id={id}
      data-testid={'table-actions'}
    />
  );
};
export default TableActions;
