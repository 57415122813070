import { UTILITY_TYPE } from 'lib/constants';
import { z } from 'zod';

export const AddUtilityPriceInitialValues = {
  utility_type: UTILITY_TYPE[0].value,
};

interface addUtilityPriceControlNamesType {
  utility_type: 'utility_type';
  unit_type: 'unit_type';
  price: 'price';
}

export const addUtilityPriceSchema = z.object({
  utility_type: z.string().nonempty({
    message: "Can't be empty",
  }),
  unit_type: z.string().nonempty({
    message: "Can't be empty",
  }),
  price: z.number(),
});

export const addUtilityPriceControlNames: addUtilityPriceControlNamesType = {
  utility_type: 'utility_type',
  unit_type: 'unit_type',
  price: 'price',
};

export type addUtilityPriceSchemaType = z.infer<typeof addUtilityPriceSchema>;
