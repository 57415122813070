import { Menu, Transition } from '@headlessui/react';
import { usePopper } from 'hooks/usePopper';
import { type FC, Fragment, type ReactNode, useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import { createPortal } from 'react-dom';
import { Link } from 'react-router-dom';

interface MenuDropdownProps {
  label: string | ReactNode;
  enableDropdownArrow?: boolean;
  menuOptions: (MenuOption | null)[];
  id?: string;
}

export interface MenuOption {
  onClick?: (() => void) | (() => Promise<void>);
  name: string;
  isAnchor?: boolean;
  linkTo?: string;
}

const MenuDropdown: FC<MenuDropdownProps> = ({
  menuOptions,
  label,
  enableDropdownArrow = 'true',
  id,
  ...props
}) => {
  const [trigger, container] = usePopper({
    placement: 'bottom-end',
    modifiers: [{ name: 'applyStyles', options: { offset: [0, 10] } }],
  });

  return (
    <div className="flex justify-end">
      <Menu as="div" className="relative inline-block text-center" {...props}>
        <div className="flex items-center">
          <Menu.Button
            id={id}
            className="inline-flex justify-center items-center hover:bg-opacity-30 focus:outline-none z-10"
            onClick={(e: any) => {
              e?.stopPropagation();
            }}
            ref={trigger}
            tabIndex={0}
          >
            {label}
            {enableDropdownArrow && (
              <IoIosArrowDown
                className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
              />
            )}
          </Menu.Button>
        </div>
        <Portal>
          <div ref={container} className="z-[100]">
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-[140px] origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {menuOptions?.map(
                  (option) =>
                    option && (
                      <div className="px-1 py-1 " key={option?.name}>
                        <Menu.Item>
                          {({ active }) =>
                            option.isAnchor ? (
                              <Link
                                to={option.linkTo ?? '#'}
                                className={`${
                                  active
                                    ? 'bg-primary-light text-white'
                                    : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                {option?.name}
                              </Link>
                            ) : (
                              <button
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={option?.onClick}
                                className={`${
                                  active
                                    ? 'bg-primary-light text-white'
                                    : 'text-gray-900'
                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                {option?.name}
                              </button>
                            )
                          }
                        </Menu.Item>
                      </div>
                    )
                )}
              </Menu.Items>
            </Transition>
          </div>
        </Portal>
      </Menu>
    </div>
  );
};
export default MenuDropdown;

function Portal(props: { children: ReactNode }): any {
  const { children } = props;
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;
  return createPortal(children, document.body);
}
