import { PhoneNumberUtil } from 'google-libphonenumber';
import { z } from 'zod';

interface manageUserSchemaType {
  given_name: 'given_name';
  family_name: 'family_name';
  email: 'email';
  phone_number: 'phone_number';
  role: 'role';
  function: 'function';
  meters: 'meters';
  assets: 'assets';
  groups: 'groups';
}

export const manageUserSchema = z.object({
  given_name: z.string().trim().nonempty('Required'),
  family_name: z.string().trim().nonempty('Required'),
  email: z.string().email(),
  phone_number: z.string().superRefine((value, ctx) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const isValid =
      value.length > 6 &&
      phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value));
    if (!isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid phone number format.',
      });
    }
  }),
  role: z.string().nonempty({
    message: 'You need to select a role.',
  }),
  function: z.string().nonempty({
    message: 'You need to select a function.',
  }),
  meters: z.array(
    z.object({
      name: z.string(),
      value: z.number().or(z.string()),
    })
  ),
  assets: z.array(
    z.object({
      name: z.string(),
      value: z.number().or(z.string()),
    })
  ),
  groups: z.array(
    z.object({
      name: z.string(),
      value: z.number().or(z.string()),
    })
  ),
});

export const manageUserControlNames: manageUserSchemaType = {
  given_name: 'given_name',
  family_name: 'family_name',
  email: 'email',
  phone_number: 'phone_number',
  role: 'role',
  function: 'function',
  meters: 'meters',
  assets: 'assets',
  groups: 'groups',
};

export type ManageUserSchemaType = z.infer<typeof manageUserSchema>;
