import { type DetailedHTMLProps, type FC, type HTMLAttributes } from 'react';

interface ContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  className: string;
}

const Container: FC<ContainerProps> = ({ children, className, ...props }) => {
  return (
    <div
      className={`rounded-lg border border-gray-10 ${className || ''}`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Container;
