import { useMultistep } from 'context/MultiStepProvider/MultiStepProvider';
import { type FC, type ReactNode } from 'react';
import Step from './Step';
export interface MultiStepProps {
  /**
   * An array of ReactNode or functional components representing the steps.
   */
  steps: ReactNode[] | FC[];
  /**
   * The current step index.
   */
  currentStep?: number;
  /**
   * The initial step index.
   */
  initialStep?: number;
  /**
   * Additional CSS class for the component.
   */
  className?: string;
  /**
   * If true, navigation between steps is disabled.
   */
  disableNavigation?: boolean;
}

/**
 * @component
 *
 * MultiStep component for handling multi-step user interfaces.
 *
 * @returns {ReactElement} The rendered MultiStep component.
 *
 * @example <MultiStep steps={steps} />
 */
const MultiStep: FC<MultiStepProps> = ({
  steps,
  className,
  disableNavigation = false,
}) => {
  const { currentStep, goToSpecificStep } = useMultistep();

  /**
   * Handle a click event on a step.
   *
   * @param {number} stepNumber - The step number to navigate to.
   */
  const handleChangeStep = (stepNumber: number): void => {
    if (currentStep > stepNumber) {
      goToSpecificStep(stepNumber);
    }
  };

  const renderStep = (): ReactNode => {
    return typeof steps[currentStep] === 'function'
      ? (steps[currentStep] as FC)({})
      : (steps[currentStep] as ReactNode);
  };

  return (
    <div className={className}>
      <div className="flex justify-center w-full gap-4">
        {steps.map((_step, index) => (
          <Step
            key={index}
            activeStep={currentStep === index}
            onClick={() => {
              if (!disableNavigation) handleChangeStep(index);
            }}
          />
        ))}
      </div>
      <div className="mt-16">{renderStep()}</div>
    </div>
  );
};
export default MultiStep;
