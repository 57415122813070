import { FLabel } from '@components/form/FLabel';
import { SelectInput } from '@components/inputs/SelectInput';
import { useUpdateStatus } from 'api/changes/changesServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { type User } from 'interfaces/change/ChangeData.interface';
import { Button } from 'layout/Button';
import { CardContainer } from 'layout/Card';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { SelectStatus } from 'pages/Changes/ChangesListPage/components/SelectStatus';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { getDaysFromDate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

interface StatusProps {
  users?: User[];
  currentUser?: string | number;
  currentStatus?: string;
  timeOnStatus?: string;
  changeId?: string;
  statusList?: string[];
  isLoading?: boolean;
}

const Status: FC<StatusProps> = ({
  changeId,
  users,
  timeOnStatus,
  currentStatus,
  currentUser,
  statusList,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const {
    mutate,
    isPending: isUpdateStatusLoading,
    isSuccess,
  } = useUpdateStatus(changeId);
  const [statusValue, setStatusValue] = useState<string | number | undefined>(
    currentStatus
  );

  const [userResponsible, setUserResponsible] = useState<
    string | number | undefined
  >(currentUser);

  const { hasFeatureFlagPermission } = useFeatureFlag();

  const options = useMemo(() => {
    return users?.map((user) => {
      const fullName = `${user.given_name} ${user.family_name}`;

      return {
        name: fullName,
        value: user.id,
      };
    });
  }, [users]);

  useEffect(() => {
    if (currentUser) setUserResponsible(currentUser);
  }, [currentUser]);

  useEffect(() => {
    if (currentStatus) setStatusValue(currentStatus);
  }, [currentStatus]);

  const handleStatusChange = (value: string | number | undefined): void => {
    setStatusValue(value);
  };

  const handleUserResponsibleChange = (
    value: string | number | undefined
  ): void => {
    setUserResponsible(value);
  };

  const onSubmit = (): void => {
    const body = {
      status: statusValue,
      assigned_to_user: userResponsible,
    };
    mutate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      Mixpanel.track('status_updated', {
        status: statusValue,
        user: userResponsible,
        change_id: changeId,
      });
    }
  }, [isSuccess]);

  return (
    <CardContainer>
      <div className="flex justify-start gap-2 items-center mb-4">
        {isLoading ? (
          <Skeleton containerClassName="flex-1 max-w-[250px]" height={'20px'} />
        ) : (
          <>
            <label
              style={{ fontSize: 14 }}
              className="font-bold uppercase text-gray-60"
            >
              {t('common.statusLabel')}
            </label>
            <span>
              {timeOnStatus &&
                t('anomaliesPage.sideMenu.timeOnStatus', {
                  time: getDaysFromDate(timeOnStatus),
                })}
            </span>
          </>
        )}
      </div>
      <div className="flex justify-between items-end gap-6">
        {isLoading ? (
          <Skeleton count={2} containerClassName="flex-1" height={'30px'} />
        ) : (
          <div className="flex flex-col gap-2 w-full">
            <FLabel> {t('common.statusLabel')}</FLabel>
            <SelectStatus
              value={statusValue}
              statusList={statusList}
              onChange={handleStatusChange}
              disabled={
                !hasFeatureFlagPermission(
                  FEATURE_FLAGS_LIST.changes.patch_status
                )
              }
            />
          </div>
        )}

        {isLoading ? (
          <Skeleton count={2} containerClassName="flex-1" height={'30px'} />
        ) : (
          <div className="flex flex-col gap-2 w-full">
            <FLabel>{t('common.responsible')}</FLabel>
            <SelectInput
              options={options ?? []}
              placeholder={t('common.placeholders.selectUser')}
              onChange={handleUserResponsibleChange}
              value={userResponsible}
              data-testid="user-select"
              disabled={
                !hasFeatureFlagPermission(
                  FEATURE_FLAGS_LIST.changes.patch_status
                )
              }
            />
          </div>
        )}

        {isLoading ? (
          <Skeleton width={'140px'} height={'40px'} borderRadius={'20px'} />
        ) : (
          <Button
            label="Assign"
            onClick={onSubmit}
            isLoading={isUpdateStatusLoading}
            disabled={
              !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch_status)
            }
          />
        )}
      </div>
    </CardContainer>
  );
};
export default Status;
