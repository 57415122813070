import { Modal } from '@components/modal';
import {
  useEditLuzmoDashboard,
  useFetchLuzmoDashboard,
} from 'api/sysadmin/sysAdminServices';
import useHRForm from 'hooks/useHRForm';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ManageLuzmoReportForm } from '../forms';
import { luzmoReportSchema } from '../forms/schema';

interface EditLuzmoReportModalProps extends Disclosure {
  selectedLuzmo?: number;
}

const EditLuzmoReportModal: FC<EditLuzmoReportModalProps> = ({
  isOpen,
  handleClose,
  selectedLuzmo,
}) => {
  const { data } = useFetchLuzmoDashboard(selectedLuzmo);

  const { t } = useTranslation();
  const initialValues = useMemo(() => {
    if (data?.luzmo_dashboard) {
      const dashboard = data.luzmo_dashboard;
      return {
        ...dashboard,
        luzmo_name: [
          { name: dashboard.luzmo_name, value: dashboard.luzmo_name },
        ],
        companies: dashboard.companies.map((company) => ({
          name: company.name,
          value: company.id,
        })),
        filters: dashboard.filters.filters,
      };
    }
  }, [data]);
  const { methods } = useHRForm({ schema: luzmoReportSchema, initialValues });
  const { mutate, isPending, isSuccess } = useEditLuzmoDashboard(selectedLuzmo);

  const handleEditLuzmoDashboard = (): void => {
    const values = methods.getValues();
    const body = {
      internal_name: values.internal_name,
      luzmo_name: values.luzmo_name?.[0]?.name,
      companies: values?.companies?.map((company) => ({
        id: company?.value,
      })),
      filters: { filters: values.filters },
      is_universal: values.is_universal,
    };
    mutate(body);
  };

  useEffect(() => {
    methods.reset();
  }, []);

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      actionBtns={{
        primary: {
          label: 'Save',
          onClick: methods.handleSubmit(handleEditLuzmoDashboard),
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      maxWidth={500}
      title={'Edit Dashboard'}
    >
      <FormProvider {...methods}>
        <ManageLuzmoReportForm initialValues={initialValues} />
      </FormProvider>
    </Modal>
  );
};
export default EditLuzmoReportModal;
