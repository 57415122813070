import { LoadingSpinner } from '@components/loadingSpinner';
import { Modal } from '@components/modal';
import { type FC, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { ManageAssetForm } from '../../form/ManageAssetForm';
import { ConfirmModal } from '@components/confirmModal';
import { t } from 'i18next';
import {
  useDeleteAsset,
  useFetchAsset,
  useUpdateAsset,
} from 'api/assets/assetsServices';
import useHRForm from 'hooks/useHRForm';
import {
  type MeterOption,
  manageAssetSchema,
} from '../../form/ManageAssetForm/schema';
import useDisclosure from 'hooks/useDisclosure';
import { type Meter } from 'interfaces/assets/Meters.interface';

interface EditAssetModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  selectedAssetId?: number;
}

const EditAssetModal: FC<EditAssetModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  selectedAssetId,
}) => {
  const {
    isOpen: isConfirmOpen,
    handleOpen: handleConfirmOpen,
    handleClose: handleConfirmClose,
  } = useDisclosure();

  const { isFetched, data } = useFetchAsset(selectedAssetId);
  const { mutate: deleteAssetMutate } = useDeleteAsset();
  const {
    mutate: updateAssetMutate,
    isSuccess,
    isPending,
  } = useUpdateAsset(selectedAssetId);

  const initialValues = useMemo(() => {
    if (!data) return;
    return {
      ...data?.asset,
      surface_area: data?.asset.surface_area ?? '',
      meters:
        data?.asset.meters.map((meter: Meter) => ({
          value: meter.id,
          name: meter.name,
        })) ?? [],
      address: {
        number: data?.asset?.address.number ?? '',
        country_iso: data?.asset?.address.country_iso ?? '',
        zip_code: data?.asset?.address.zip_code ?? '',
        street: data?.asset?.address.street ?? '',
        city: data?.asset?.address.city ?? '',
        province: data?.asset?.address.province ?? '',
      },
    };
  }, [data]);

  const { methods } = useHRForm({
    schema: manageAssetSchema,
    values: initialValues,
  });

  const closeModal = (): void => {
    methods.reset(initialValues);
    handleClose();
  };

  const handleEditAsset = async (): Promise<void> => {
    const formData = methods.getValues();
    const params = {
      name: formData.name,
      function: formData.function,
      type: formData.type,
      meters: formData.meters?.map((meter: MeterOption) => ({
        id: meter.value,
      })),
      surface_area: formData.surface_area,
      address: {
        ...formData.address,
        timezone: 'America/New_York',
        latitude: 40.7128,
        longitude: -74.006,
        province: formData.address.province ?? '',
      },
      status: 'READY',
      function_string: 'Office Building',
    };

    updateAssetMutate(params);
  };

  const handleDeleteAsset = async (): Promise<void> => {
    if (selectedAssetId) {
      deleteAssetMutate(selectedAssetId);
    }
  };

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('common.buttons.saveChanges'),
          onClick: methods.handleSubmit(handleEditAsset),
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      title={t('assetsPage.EditAssetModal.title')}
      maxWidth={600}
    >
      {isFetched && !isPending ? (
        <>
          <div className="flex justify-end">
            <p
              className="text-secondary-dark text-base cursor-pointer"
              onClick={handleConfirmOpen}
            >
              Delete asset
            </p>
          </div>
          <FormProvider {...methods}>
            <ManageAssetForm methods={methods} initialValues={initialValues} />
          </FormProvider>
          <ConfirmModal
            isOpen={isConfirmOpen}
            handleOpen={handleConfirmOpen}
            handleClose={handleConfirmClose}
            onConfirm={handleDeleteAsset}
            contentText={t('common.confirmModal.delete')}
          />
        </>
      ) : (
        <LoadingSpinner />
      )}
    </Modal>
  );
};

export default EditAssetModal;
