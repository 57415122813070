import { z } from 'zod';
import i18next from '../../../../../i18n';

export const resetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .min(10, i18next.t('resetPasswordPage.errors.passwordLength'))
      .regex(
        /[A-Z]/,
        i18next.t('resetPasswordPage.errors.passwordCapitalLetter')
      )
      .regex(
        /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
        i18next.t('resetPasswordPage.errors.passwordSpecialCharacter')
      ),
    confirmNewPassword: z
      .string()
      .min(10, i18next.t('resetPasswordPage.errors.passwordLength'))
      .regex(
        /[A-Z]/,
        i18next.t('resetPasswordPage.errors.passwordCapitalLetter')
      )
      .regex(
        /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
        i18next.t('resetPasswordPage.errors.passwordSpecialCharacter')
      ),
  })
  .superRefine(({ confirmNewPassword, newPassword }, ctx) => {
    if (confirmNewPassword !== newPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18next.t('resetPasswordPage.errors.passwordsDoNotMatch'),
        path: ['confirmNewPassword'],
      });
    }
  });

export const resetPasswordControlNames = {
  newPassword: 'newPassword',
  confirmNewPassword: 'confirmNewPassword',
};
export type ResetPasswordSchemaType = z.infer<typeof resetPasswordSchema>;
