import { SelectInput } from '@components/inputs/SelectInput';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { Modal } from '@components/modal';
import { useFetchChange, useUpdateStatus } from 'api/changes/changesServices';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectStatus } from '../../components/SelectStatus';

interface UpdateStatusModalProps extends Disclosure {
  changeId?: string;
}

const UpdateStatusModal: FC<UpdateStatusModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  changeId,
}) => {
  const { data, isFetching } = useFetchChange(changeId);
  const { t } = useTranslation();
  const users = useMemo(() => {
    if (!data) return;

    return data.available_statuses.users?.map((user) => ({
      name: user.given_name,
      value: user.id,
    }));
  }, [data]);

  const [responsibleUser, setResponsibleUser] = useState<number>();
  const [status, setStatus] = useState('');

  const { mutate, isPending, isSuccess } = useUpdateStatus(changeId);

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  useEffect(() => {
    if (data?.change.status) setStatus(data?.change.status);
    if (data?.change?.assigned_to_user?.id)
      setResponsibleUser(data?.change.assigned_to_user.id);
  }, [data]);

  const handleUserChange = (userId: number | string | undefined): void => {
    if (userId) setResponsibleUser(userId as number);
  };

  const handleStatusChange = (stat: string | number | undefined): void => {
    if (stat) {
      setStatus(stat as string);
    }
  };

  const updateStatus = (): void => {
    const body = {
      status,
      assigned_to_user: responsibleUser,
    };
    mutate(body);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      title={t('anomaliesPage.modal.title')}
      width={550}
      actionBtns={{
        primary: {
          label: t('common.buttons.confirm'),
          isLoading: isPending,
          onClick: updateStatus,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
    >
      <div className="flex flex-col gap-6">
        <p>{t('anomaliesPage.modal.body')}</p>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-gray-60">
            {t('common.statusLabel')}
          </label>
          <SelectStatus
            value={status}
            statusList={data?.available_statuses.statuses}
            onChange={handleStatusChange}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold  text-gray-60">
            {t('common.responsible')}
          </label>
          <SelectInput
            options={(users as DropdownOptions[]) ?? []}
            placeholder={t('common.placeholders.selectUser')}
            value={responsibleUser}
            onChange={handleUserChange}
            isFetching={isFetching}
          />
        </div>
      </div>
    </Modal>
  );
};
export default UpdateStatusModal;
