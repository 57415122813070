import NotificationCircleIcon from '@components/icons/MenuIcons/NotificationCircle';
import { format } from 'date-fns';
import { type FC } from 'react';

interface NotificationItemProps {
  title: string;
  time: string;
  read: boolean;
  id: number;
  onClick: (id: number) => void;
}

const NotificationItem: FC<NotificationItemProps> = ({
  title,
  time,
  read,
  id,
  onClick,
}) => {
  const formattedTime = format(new Date(time), "EEEE HH'h'mm");
  return (
    <div
      className="flex flex-col gap-2 px-6 py-4 border-b border-gray-10 cursor-pointer hover:bg-primary-lighter rounded-lg"
      onClick={() => {
        if (!read) {
          onClick(id);
        }
      }}
    >
      <div className="flex justify-between items-center gap-2">
        <p className="font-bold">{title}</p>
        {!read && <NotificationCircleIcon />}
      </div>

      <p>{formattedTime}</p>
    </div>
  );
};
export default NotificationItem;
