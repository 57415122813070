import { ConfirmModal } from '@components/confirmModal';
import { PermissionWrapper } from '@components/PermissionWrapper';
import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import {
  useDeleteProject,
  useListProjects,
} from 'api/projects/projectsServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import useDisclosure from 'hooks/useDisclosure';
import { useServerSideTableSorting } from 'hooks/useServerSideTableSorting';
import { type ProjectData } from 'interfaces/projects/ProjectData.interface';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetProjectsColumns from '../hooks/useGetProjectsColumns';
import { EditProjectModal } from '../modals/EditProjectModal';

interface ProjectsTableProps {
  columnVisibility: {
    savings_euro: boolean;
    savings_total: boolean;
  };
  query?: string;
  assetFilter?: number;
  utilityTypeFilter?: string;
}

const ProjectsTable: FC<ProjectsTableProps> = ({
  query,
  columnVisibility,
  assetFilter,
  utilityTypeFilter,
}) => {
  const [selectedId, setSelectedId] = useState<number | undefined>();

  const navigate = useNavigate();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const { orderBy = '-savings_rel', handleSortChange } =
    useServerSideTableSorting();

  const {
    data: usersListData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useListProjects({
    query,
    asset: assetFilter,
    utilityType: utilityTypeFilter,
    orderBy,
  });

  const {
    mutate: deleteProject,
    isPending: isDeleteLoading,
    isSuccess,
  } = useDeleteProject();

  const {
    isOpen: isEditProjectOpen,
    handleClose: handleEditProjectClose,
    handleOpen: handleEditProjectOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteProjectOpen,
    handleClose: handleDeleteProjectClose,
    handleOpen: handleDeleteProjectOpen,
  } = useDisclosure();

  const flatData: UserResponse[] = useMemo(
    () => usersListData?.pages?.flatMap((page: any) => page.projects) ?? [],
    [usersListData]
  );

  const totalDBRowCount = usersListData?.pages?.[0]?.total ?? 0;

  const createTableActions = (id: number): ReactNode => {
    const actions = [
      hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.patch)
        ? {
            name: 'Edit',
            isAnchor: false,
            onClick: () => {
              setSelectedId(id);
              handleEditProjectOpen();
            },
          }
        : null,
      hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.delete)
        ? {
            name: 'Delete',
            onClick: () => {
              setSelectedId(id);
              handleDeleteProjectOpen();
            },
          }
        : null,
    ];
    return <TableActions actions={actions} />;
  };

  const { columns } = useGetProjectsColumns({ createTableActions });

  const handleDeleteProject = (): void => {
    if (!selectedId) return;
    deleteProject(selectedId);
  };

  const onClickRow = (row: ProjectData): void => {
    if (!row || !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.project.get))
      return;
    navigate(`details/${row?.id?.toString()}`);
  };

  useEffect(() => {
    if (isSuccess) {
      handleDeleteProjectClose();
    }
  }, [isSuccess]);

  return (
    <>
      <Table
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        data={flatData}
        columns={columns}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        rowEstimateSize={75}
        state={{ columnVisibility }}
        handleSortChange={handleSortChange}
        manualSorting={true}
        onClickRow={onClickRow}
      />
      <PermissionWrapper validate={FEATURE_FLAGS_LIST.project.patch}>
        <EditProjectModal
          id={selectedId}
          isOpen={isEditProjectOpen}
          handleClose={handleEditProjectClose}
          handleDeleteOpen={handleDeleteProjectOpen}
        />
      </PermissionWrapper>
      <PermissionWrapper validate={FEATURE_FLAGS_LIST.project.delete}>
        <ConfirmModal
          isOpen={isDeleteProjectOpen}
          handleClose={handleDeleteProjectClose}
          contentText={
            "Are you sure you want to delete this project? This action can't be undone and all information will be lost."
          }
          onConfirm={handleDeleteProject}
          isLoading={isDeleteLoading}
        />
      </PermissionWrapper>
    </>
  );
};
export default ProjectsTable;
