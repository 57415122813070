import { Dialog, DialogBackdrop, TransitionChild } from '@headlessui/react';
import { type FC, Fragment, type PropsWithChildren, useRef } from 'react';
import { DialogPanelHeadlessModal, ModalContainer } from './styled';

interface HeadlessModalProps extends PropsWithChildren {
  isOpen: boolean;
  handleClose: () => void;
  width?: number | string;
  maxWidth?: number | string;
  minWidth?: number | string;
  isLoading?: boolean;
}

const HeadlessModal: FC<HeadlessModalProps> = ({
  isOpen,
  handleClose,
  width,
  maxWidth,
  minWidth = 550,
  isLoading,
  children,
}) => {
  const ref = useRef(null);

  return (
    <>
      <Dialog
        as="div"
        className="relative z-50"
        open={isOpen}
        onClose={handleClose}
        initialFocus={ref}
      >
        <DialogBackdrop
          className="fixed inset-0 bg-black bg-opacity-25 transition duration-200 ease-out data-[closed]:opacity-0"
          transition={true}
        />

        <div className="fixed inset-0 overflow-y-auto">
          <ModalContainer $position={'center'}>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanelHeadlessModal
                style={{ width, maxWidth, minWidth }}
                data-modal-body={isOpen}
              >
                <div
                  className="flex text-sm text-gray-60 flex-1 p-4"
                  data-testid="modal-body"
                >
                  {children}
                </div>
              </DialogPanelHeadlessModal>
            </TransitionChild>
          </ModalContainer>
        </div>
      </Dialog>
    </>
  );
};
export default HeadlessModal;
