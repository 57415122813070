import i18next from 'i18next';
import { z } from 'zod';

export const forgotPasswordSchema = z.object({
  email: z.string().email(i18next.t('forgotPasswordPage.errors.emailFormat')),
});

export const forgotPasswordControlNames = {
  email: 'email',
};
export type ForgotPasswordSchemaType = z.infer<typeof forgotPasswordSchema>;
