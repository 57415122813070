import { type FC, type SVGProps } from 'react';

const MenuLogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="Subtract"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9806 17.4545H28.2312C29.9101 17.4545 31.271 18.9198 31.271 20.7273C31.271 22.5348 29.9101 24 28.2312 24H19.9806C18.3018 24 16.9409 22.5348 16.9409 20.7273C16.9409 17.7148 14.6726 15.2727 11.8747 15.2727C10.2099 15.2727 8.85838 13.8316 8.83596 12.0449C8.83532 12.0308 8.83493 12.0164 8.83493 12.0014C8.83378 8.98834 6.56721 6.54545 3.76871 6.54546C2.08993 6.54546 0.729004 5.08018 0.729004 3.27271C0.729004 1.46524 2.08993 0 3.76871 0C5.45137 0 6.8021 1.48221 6.80848 3.29382C6.81904 6.29657 9.0832 8.72728 11.8747 8.72728C11.8893 8.72728 11.9034 8.72771 11.9172 8.72843C13.5763 8.75305 14.9144 10.2079 14.9144 12C14.9144 15.0125 17.1826 17.4545 19.9806 17.4545ZM28.231 8.72727H19.9803C17.1823 8.72727 14.9141 6.28518 14.9141 3.27273C14.9141 1.46524 13.5532 0 11.8744 0C10.1956 0 8.8347 1.46524 8.8347 3.27273C8.8347 5.08021 10.1956 6.54545 11.8744 6.54545C14.6724 6.54545 16.9406 8.98755 16.9406 12C16.9406 13.8075 18.3015 15.2727 19.9803 15.2727H28.231C29.9098 15.2727 31.2707 13.8075 31.2707 12C31.2707 10.1925 29.9098 8.72727 28.231 8.72727ZM28.2308 6.54545H19.9801C18.3013 6.54545 16.9404 5.08021 16.9404 3.27273C16.9404 1.46524 18.3013 0 19.9801 0H28.2308C29.9096 0 31.2705 1.46524 31.2705 3.27273C31.2705 5.08021 29.9096 6.54545 28.2308 6.54545Z"
      fill="#522B89"
    />
  </svg>
);
export default MenuLogoIcon;
