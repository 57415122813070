import { type FC, type ReactNode } from 'react';

interface OnboardingContainerProps {
  heading: string;
  detailsHeading: string;
  detailsClassName?: string;
  children: ReactNode;
}

const OnboardingContainer: FC<OnboardingContainerProps> = ({
  heading,
  detailsHeading,
  detailsClassName,
  children,
}) => {
  return (
    <div
      className="flex items-center justify-center h-full"
      data-testid="login-container"
    >
      <div className="w-[380px]">
        <div className="mb-3xl flex flex-col gap-4">
          <h1 className="text-primary font-bold text-[32px]">{heading}</h1>
          <h2
            className={detailsClassName ?? `text-lg font-normal text-gray-50`}
          >
            {detailsHeading}{' '}
          </h2>
        </div>
        {children}
      </div>
    </div>
  );
};
export default OnboardingContainer;
