import { type FC, type SVGProps } from 'react';

const ArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_778_10860)">
      <path
        d="M8.58984 7.41L13.1698 12L8.58984 16.59L9.99984 18L15.9998 12L9.99984 6L8.58984 7.41Z"
        fill="#BABFC7"
      />
    </g>
    <defs>
      <clipPath id="clip0_778_10860">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 24) rotate(-90)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default ArrowIcon;
