import { z } from 'zod';

interface ManageFeatureFlagControlNamesType {
  name: 'name';
  routes: 'routes';
  company: 'company';
  users: 'users';
  active: 'active';
}

export const featureFlagSchema = z.object({
  name: z.string().min(3, 'Required.'),
  routes: z
    .array(
      z.object({
        name: z.string(),
        value: z.number().or(z.string()),
      })
    )
    .optional(),
  company: z.array(
    z
      .object({
        name: z.string(),
        value: z.number().or(z.string()),
      })
      .optional()
  ),
  active: z.boolean(),
  users: z.string().optional(),
});

export const ManageFeatureFlagControlNames: ManageFeatureFlagControlNamesType =
  {
    name: 'name',
    routes: 'routes',
    company: 'company',
    users: 'users',
    active: 'active',
  };

export type FeatureFlagSchemaType = z.infer<typeof featureFlagSchema>;
