import { format, utcToZonedTime } from 'date-fns-tz';

export const convertToReadableDate = (
  dateString?: string,
  options?: { onlyDate?: boolean; disableTimezone?: boolean }
): string => {
  if (!dateString) return '';
  const inputDate = new Date(dateString);

  if (!options?.disableTimezone) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const zonedDate = utcToZonedTime(inputDate, userTimeZone);
    return options?.onlyDate
      ? format(zonedDate, 'dd/MM/yyyy', {
          timeZone: userTimeZone,
        })
      : format(zonedDate, 'dd/MM/yyyy HH:mm', {
          timeZone: userTimeZone,
        });
  } else {
    return options?.onlyDate
      ? format(inputDate, 'dd/MM/yyyy')
      : format(inputDate, 'dd/MM/yyyy HH:mm');
  }
};
