import styled from 'styled-components';
import tw from 'twin.macro';
interface ReportCardProps {
  $isSelected: boolean;
}

export const ReportCard = styled.div<ReportCardProps>`
  ${tw`flex flex-col gap-4 px-4 py-2.5 bg-gray-10 rounded-lg w-fit min-w-[200px] border `}
  ${({ $isSelected }) =>
    $isSelected
      ? tw`border-primary bg-menu-light-purple cursor-default`
      : tw`border-transparent cursor-pointer`}
`;
