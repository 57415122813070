import { z } from 'zod';

export const manageProjectSchema = z
  .object({
    name: z.string().trim().nonempty({ message: 'Required' }),
    description: z.string().optional(),
    meter: z
      .array(z.object({ name: z.string(), value: z.number() }))
      .length(1, 'You need to select a meter.'),
    savingsTarget: z.number({
      required_error: 'Target needs to be at least 1%.',
    }),
    investmentCostNumber: z.number().gt(0, 'Required'),
    referencePeriod: z
      .object({
        start: z.date(),
        end: z.date(),
      })
      .required(),
    implementationPeriod: z
      .object({
        start: z.date(),
        end: z.date(),
      })
      .required(),
    category: z
      .string()
      .trim()
      .nonempty({ message: 'You need to select a category.' }),
    responsible: z.number({
      required_error: 'You need to select a responsible.',
    }),
  })
  .superRefine((value, ctx) => {
    const { referencePeriod, implementationPeriod } = value;

    if (implementationPeriod.start <= referencePeriod.end) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The start date must come after the reference period end date',
        path: ['implementationPeriod.start'],
      });
    }
    if (
      Math.ceil(
        (referencePeriod.end.getTime() - referencePeriod.start.getTime()) /
          (1000 * 60 * 60 * 24)
      ) < 270
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The reference period should be at least 9 months.',
        path: ['referencePeriod.start'],
      });
    }
    if (implementationPeriod.end >= new Date()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The end date must come before today's date",
        path: ['implementationPeriod.end'],
      });
    }
  });

export const manageProjectControlNames = {
  name: 'name',
  description: 'description',
  meter: 'meter',
  savingsTarget: 'savingsTarget',
  investmentCost: 'investmentCost',
  investmentCostNumber: 'investmentCostNumber',
  referencePeriod: {
    start: 'referencePeriod.start',
    end: 'referencePeriod.end',
  },
  implementationPeriod: {
    start: 'implementationPeriod.start',
    end: 'implementationPeriod.end',
  },
  category: 'category',
  responsible: 'responsible',
};
export type ManageProjectSchemaType = z.infer<typeof manageProjectSchema>;
