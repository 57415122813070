import { type StatusEnum } from 'enums/StatusEnum';
import { type FC, useMemo } from 'react';
import {
  getStatusReadableName,
  getStatusTailwindColor,
} from 'utils/statusUtil/statusUtil';

interface StatusChipProps {
  status: StatusEnum;
}

const StatusChip: FC<StatusChipProps> = ({ status }) => {
  const statusColor = useMemo(() => getStatusTailwindColor(status), [status]);
  return (
    <div className="flex items-center gap-2">
      <div className={`rounded-full h-2.5 w-2.5 ${statusColor}`} />
      <label className="uppercase text-xs min-w-max">
        {getStatusReadableName(status)}
      </label>
    </div>
  );
};
export default StatusChip;
