import { type FC, type SVGProps } from 'react';

const OperationsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="currentColor"
    {...props}
  >
    <path d="M11.866 1.25005L21.3922 17.7501C21.6684 18.2284 21.5045 18.84 21.0262 19.1161C20.8742 19.2039 20.7018 19.2501 20.5262 19.2501H1.47363C0.921343 19.2501 0.473633 18.8024 0.473633 18.2501C0.473633 18.0745 0.519833 17.9021 0.607603 17.7501L10.1339 1.25005C10.4101 0.771749 11.0216 0.607879 11.4999 0.884019C11.652 0.971789 11.7782 1.09803 11.866 1.25005ZM3.20568 17.2501H18.7942L10.9999 3.75005L3.20568 17.2501ZM9.99987 14.2501H11.9999V16.2501H9.99987V14.2501ZM9.99987 7.25005H11.9999V12.2501H9.99987V7.25005Z" />
  </svg>
);
export default OperationsIcon;
