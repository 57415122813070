import { MultiTab } from '@components/multiTab';
import { type FC, useEffect } from 'react';
import { ImpersonationPage } from './ImpersonationPage';
import { useTranslation } from 'react-i18next';
import { FeaturesFlagPage } from './FeaturesFlagPage';
import { LuzmoDashboardPage } from './LuzmoDashboardPage';
import { RolePermissionsPage } from './RolePermissionsPage';
import { DemoAccountsPage } from './DemoAccountsPage';
import { useAuth } from 'hooks/contexts/AuthContext';
import { RoleEnum } from 'enums/RoleEnum';
import { useNavigate } from 'react-router-dom';

const SysAdminPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();

  const adminTabs = [
    { title: t('common.impersonation'), component: <ImpersonationPage /> },
    { title: 'Features flag', component: <FeaturesFlagPage /> },
    { title: 'Luzmo Dashboard', component: <LuzmoDashboardPage /> },
    { title: 'Role permissions', component: <RolePermissionsPage /> },
    { title: 'Demo accounts', component: <DemoAccountsPage /> },
  ];

  useEffect(() => {
    if (user?.role !== RoleEnum.SYSADMIN) {
      navigate('/app/not-found');
    }
  }, [user]);

  return (
    <div>
      <h1 className="mb-6">{t('sysAdminPage.title')}</h1>
      <MultiTab tabs={adminTabs}></MultiTab>
    </div>
  );
};
export default SysAdminPage;
