import { type FC } from 'react';
import { Button } from 'layout/Button';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  const handleNavigation = (): void => {
    navigate('/app/dashboard');
  };
  return (
    <div className="flex items-center justify-evenly h-full">
      <div className="flex flex-1 flex-col gap-4 max-w-[450px]">
        <h1 className="text-primary font-bold text-[56px] leading-normal">
          {"Oops! Looks like we're off the grid."}
        </h1>
        <p className="text-primary text-base">
          {
            "Don't worry, your assets are all being monitored. It just seems that the page you are looking for, no longer exists."
          }
        </p>
        <div className="flex mt-[48px]">
          <Button
            label="Back to home page"
            size="large"
            minWidth="250"
            onClick={handleNavigation}
          />
        </div>
      </div>
      <img
        className="w-full max-w-[600px] max-h-[600px]"
        src={
          'https://storage.googleapis.com/frontend_production_app/static/media/404_error.png'
        }
      />
    </div>
  );
};
export default NotFoundPage;
