import { FNumericField } from '@components/form/FNumericField';
import { FSelectField } from '@components/form/FSelectField';
import useHRForm from 'hooks/useHRForm';
import { Button } from 'layout/Button';
import { ALERT_THRESHOLDS, OPERATORS } from 'lib/constants';
import { type FC, useEffect, useState } from 'react';
import {
  alertDefinitionControlNames,
  alertDefinitionSchema,
  type alertDefinitionSchemaType,
} from './schema';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import {
  useDeleteAlertDefinition,
  useUpdateAlertDefinition,
} from 'api/alerts/alertsServices';
import { t } from 'i18next';

interface AlertDefinitionFormProps {
  id: number;
  userOptions: DropdownOptions[];
  initialValues: any;
}
const AlertDefinitionForm: FC<AlertDefinitionFormProps> = ({
  id,
  userOptions,
  initialValues,
}) => {
  const [isLoadingDelete] = useState(false);
  const { mutate: deleteAlertDefinitionMutate } = useDeleteAlertDefinition();
  const { mutate: updateAlertDefinitionMutate } = useUpdateAlertDefinition(id);

  const getAlertOperator = (rules: any): string => {
    if (rules !== undefined && rules?.length !== 0) {
      if (rules[0].impact_average_diff_euro) {
        return rules[0].impact_average_diff_euro.operator;
      } else if (rules[0].excess_total) {
        return rules[0].excess_total.operator;
      }
    }
    return '';
  };

  const getAlertAmount = (rules: any): string => {
    if (rules !== undefined && rules?.length !== 0) {
      if (rules[0].impact_average_diff_euro) {
        return rules[0].impact_average_diff_euro.value;
      } else if (rules[0].excess_total) {
        return rules[0].excess_total.value;
      }
    }
    return '';
  };
  const {
    Form,
    methods: {
      getValues,
      formState: { defaultValues, isSubmitSuccessful },
      reset,
    },
  } = useHRForm({
    schema: alertDefinitionSchema,
    values: {
      operator: getAlertOperator(initialValues.condition?.rules),
      amount: getAlertAmount(initialValues.condition?.rules),
      threshold: initialValues.rule,
      user: initialValues?.users[0].id,
    },
    mode: 'all',
  });

  const onSubmit = async (data: alertDefinitionSchemaType): Promise<void> => {
    updateAlertDefinition();
  };

  const deleteAlertDefinition = (): void => {
    deleteAlertDefinitionMutate(id);
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      // reset will put formState.defaultValues to ensure the update will work if updated multiple times
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  function hasChanged(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return true;
    }

    for (const key of keys1) {
      const value1 = obj1[key];
      const value2 = obj2[key];

      if (
        typeof value1 === 'object' &&
        typeof value2 === 'object' &&
        hasChanged(value1, value2)
      ) {
        return true; // Recursive call for nested objects
      }

      if (
        typeof value1 !== 'object' &&
        typeof value2 !== 'object' &&
        value1 !== value2
      ) {
        return true; // Values are different (ignoring numeric formatting)
      }
    }

    return false;
  }

  const updateAlertDefinition = (): void => {
    const amount = getValues().amount;
    const condition = {
      entity: 'change_detection',
      rules: [
        {
          [getValues().threshold]: {
            operator: getValues().operator,
            value: amount,
          },
        },
      ],
    };
    const params = {
      condition,
      type: 'alert_change',
      users: [{ id: getValues().user }],
    };
    if (hasChanged(defaultValues, getValues())) {
      const result = alertDefinitionSchema.safeParse(getValues());
      if (result.success) {
        updateAlertDefinitionMutate(params);
      }
    }
  };

  return (
    <div className="pt-3">
      <Form onSubmit={onSubmit}>
        <div className="flex justify-between w-full gap-6">
          <div className="flex items-center justify-center h-10">Notify</div>
          <FSelectField
            onBlur={updateAlertDefinition}
            options={userOptions}
            label=""
            name={alertDefinitionControlNames.user}
            placeholder={t('common.placeholders.selectUser')}
            data-testid="user"
          />
          <div className="flex items-center justify-center h-10">
            of deviations
          </div>
          <FSelectField
            onBlur={updateAlertDefinition}
            options={OPERATORS}
            label=""
            name={alertDefinitionControlNames.operator}
            placeholder={t('common.placeholders.selectCondition')}
            data-testid="operator"
          />
          <FNumericField
            onBlur={updateAlertDefinition}
            label=""
            name={alertDefinitionControlNames.amount}
            placeholder={t('common.placeholders.enterAmount')}
            data-testid="amount"
          />
          <div className="flex items-center justify-center h-10">of</div>
          <FSelectField
            onBlur={updateAlertDefinition}
            options={ALERT_THRESHOLDS}
            label=""
            name={alertDefinitionControlNames.threshold}
            placeholder={t('common.placeholders.selectThreshold')}
            data-testid="threshold"
          />
          <Button
            label={'-'}
            variant="secondary"
            isLoading={isLoadingDelete}
            minWidth={'45'}
            onClick={async () => {
              deleteAlertDefinition();
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default AlertDefinitionForm;
