import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { useDebounce } from 'hooks/useDebounce';
import { type FC, useEffect, useState } from 'react';
import { SearchBar } from '../SearchBar';

interface SearchContainerProps {
  handleQuery: (value: string) => void;
}

const SearchContainer: FC<SearchContainerProps> = ({ handleQuery }) => {
  const { theme } = useTheme();
  const [query, setQuery] = useState<string | null>(null);
  const debouncedQuery = useDebounce(query, 200);

  useEffect(() => {
    if (query !== null) {
      handleQuery(debouncedQuery);
    }
  }, [debouncedQuery]);

  return (
    <div className="px-4 py-2 z-10">
      <SearchBar
        iconPosition="end"
        placeholder="Search"
        wrapperStyle={{
          backgroundColor: theme.colors['gray-25'],
          border: 'none',
        }}
        autoFocus={true}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
      />
    </div>
  );
};

export default SearchContainer;
