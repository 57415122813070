import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import Tippy from '@tippyjs/react';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC } from 'react';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import { NumericFormat } from 'react-number-format';
import {
  type ImpactTextColorOptions,
  getImpactTextColorClassName,
} from 'utils/formatters/string/stringFormatter';

interface ImpactWrapperProps {
  label: string;
  value?: number;
  endAddorment: string;
  isLoading?: boolean;
  isMissingData?: boolean;
  containerClassName?: string;
  decimalScale?: number;
  tooltip?: string;
  direction?: 'row' | 'column';
  numericColorVisualization?: ImpactTextColorOptions;
  enableNumericColorVisualization?: boolean;
}

const ImpactWrapper: FC<ImpactWrapperProps> = ({
  label,
  value,
  endAddorment,
  isLoading = false,
  isMissingData,
  containerClassName = '',
  decimalScale = 2,
  tooltip,
  direction = 'row',
  numericColorVisualization = {},
  enableNumericColorVisualization = true,
  ...props
}) => {
  const { theme } = useTheme();

  const fallbackValue = (): number => {
    if (!value || isNaN(value)) {
      return 0;
    }
    return value;
  };

  const textColor = getImpactTextColorClassName(
    fallbackValue(),
    numericColorVisualization
  );

  return (
    <div
      className={`flex py-2.5 px-4 bg-gray-10 rounded-lg items-center flex-1 ${containerClassName}`}
      style={{
        flexDirection: direction === 'row' ? direction : 'column',
        gap: direction === 'row' ? 16 : 8,
      }}
    >
      {isLoading ? (
        <Skeleton containerClassName="flex-1" />
      ) : (
        <div className="flex gap-2 items-center">
          <span className="text-base">{label}</span>
          {tooltip && <TooltipIconComponent content={tooltip} />}
        </div>
      )}
      <div
        className="flex justify-end flex-1 gap-1 text-gray-50 text-2xl"
        {...props}
      >
        {isLoading ? (
          <Skeleton containerClassName="flex-1" />
        ) : (
          <div className="flex gap-2.5 items-center">
            <NumericFormat
              className={
                enableNumericColorVisualization ? textColor : 'text-gray-60'
              }
              displayType="text"
              value={fallbackValue()}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={decimalScale}
            />
            {endAddorment}
            {isMissingData && (
              <Tippy
                content={
                  'The value may be incorrectly calculated due to missing data. Contact an administrator for more details'
                }
                className="bg-red"
              >
                <span>
                  <HiQuestionMarkCircle color={theme.colors.error} size={20} />
                </span>
              </Tippy>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ImpactWrapper;
