import { MultiTab } from '@components/multiTab';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertsSettingsPage } from '../AlertsSettingsPage';
import { AlertsHistoryPage } from '../AlertsHistoryPage';

const AlertsPage: FC = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      title: t('common.settings'),
      component: <AlertsSettingsPage />,
    },
    {
      title: t('common.history'),
      component: <AlertsHistoryPage />,
    },
  ];
  return (
    <div>
      <h1 className="mb-6">{t('alertsPage.title')}</h1>
      <MultiTab tabs={tabs} />
    </div>
  );
};
export default AlertsPage;
