import { type ReactNode, createContext, useContext, useState } from 'react';
import { type NavigateFunction, useNavigate } from 'react-router-dom';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

interface MultistepContextType {
  currentStep: number;
  formData: Record<string, any>;
  isLoadingNextStep: boolean;
  goToNextStep: (data: any) => void;
  goToSpecificStep: (step: number) => void;
  goToPreviousStep: () => void;
  setIsLoadingNextStep: (isLoading: boolean) => void;
  navigate: NavigateFunction;
}

const MultistepContext = createContext<MultistepContextType | null>(null);

export const useMultistep = (): MultistepContextType => {
  const context = useContext(MultistepContext);
  if (!context) {
    throw new Error('useMultistep must be used within a MultistepProvider');
  }
  return context;
};

export const MultistepProvider = ({
  children,
  initialStep = 0,
}: {
  children: ReactNode;
  initialStep?: number;
}): JSX.Element => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [formData, setFormData] = useState<Record<string, any>>({});
  const [isLoadingNextStep, setIsLoadingNextStep] = useState<boolean>(false);
  const navigate = useNavigate();

  const goToNextStep = (data: any): void => {
    setFormData({ ...formData, ...data });
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = (): void => {
    setCurrentStep(currentStep - 1);
  };

  const goToSpecificStep = (step: number): void => {
    setCurrentStep(step);
  };

  const contextValue: MultistepContextType = {
    currentStep,
    formData,
    isLoadingNextStep,
    goToNextStep,
    goToSpecificStep,
    goToPreviousStep,
    setIsLoadingNextStep,
    navigate,
  };
  Mixpanel.track(`Register Step ${currentStep}`, {
    currentStep,
    formData,
    goToNextStep,
  });

  return (
    <MultistepContext.Provider value={contextValue}>
      {children}
    </MultistepContext.Provider>
  );
};
