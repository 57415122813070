import { forwardRef, useEffect } from 'react';

import useChart from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { type HighchartsReactRefObject } from 'highcharts-react-official';

interface MeasuredVsExpectedChartProps {
  id?: string;
  momentChange?: string;
  unit?: string;
  dataGrouping?: [string, number[] | null][] | undefined;
  data?: ChangeTS[] | null;
  setExtremes?: ({ min, max }: { min: number; max: number }) => void;
  changesData?: { date: string; id?: string }[];
  min?: number;
  max?: number;
}

const MeasuredVsExpectedChart = forwardRef<
  HighchartsReactRefObject,
  MeasuredVsExpectedChartProps
>(
  (
    {
      momentChange,
      id,
      unit = '',
      dataGrouping,
      data,
      changesData,
      setExtremes,
      min,
      max,
    },
    ref
  ) => {
    const {
      defaultOptions,
      changes,
      currentChange,
      labelFormatter,
      defaultTooltipPointFormatter,
    } = useChart({
      chartData: data,
      momentChange,
      changesData,
      unit: !unit || unit === 'UNDEFINED' ? '' : unit,
      type: 'measured',
    });

    const { theme } = useTheme();

    const options: Highcharts.Options = {
      ...defaultOptions,
      xAxis: {
        plotLines: [...changes, ...currentChange],
        events: {
          afterSetExtremes: (extremes) => {
            setExtremes?.({ min: extremes.min, max: extremes.max });
          },
        },
      },
      yAxis: [
        {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'Energy Use',
          },
        },
        {
          title: {
            text: 'Result Baseline',
          },
          opposite: true,
        },
      ],
      series: [
        {
          animation: {
            duration: 0,
          },
          name: 'Measured',
          type: 'column',
          color: theme.colors.tertiary,
          data: data?.map((ts, index) => ({
            x: new Date(ts.time).getTime(),
            y: ts.energy_use,
            impactPerDay: ts.savings,
            expectedConsumption: ts.result_baseline,
            time: ts.time,
          })),
          dataGrouping: {
            approximation: 'sum',
            groupPixelWidth: 10,
            units: dataGrouping ?? [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          tooltip: {
            pointFormatter: defaultTooltipPointFormatter,
          },
          turboThreshold: data?.length,
          yAxis: 0,
        },
        {
          animation: {
            duration: 0,
          },
          color: theme.colors.primary,
          type: 'line',
          name: 'Expected consumption',
          data: data?.map((ts, index) => [
            new Date(ts.time).getTime(),
            ts.result_baseline,
          ]),
          dataGrouping: {
            approximation: 'sum',
            groupPixelWidth: 10,
            units: dataGrouping ?? [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          yAxis: 0,
          turboThreshold: data?.length,
          enableMouseTracking: false,
        },
      ],
    };

    useEffect(() => {
      if (typeof ref !== 'function') {
        const chart = ref?.current?.chart;
        if (chart && min && max) {
          chart.xAxis[0].setExtremes(min, max, true, false);
        }
      }
    }, [ref]);

    return <>{options && <HChart ref={ref} options={options} />}</>;
  }
);
export default MeasuredVsExpectedChart;
