import { type FC } from 'react';
import { NumericFormat } from 'react-number-format';

interface CurrencyProps {
  value: string | number | null | undefined;
  addorment: string;
  className?: string;
}

const Currency: FC<CurrencyProps> = ({ addorment, value, ...props }) => {
  return (
    <div className="flex gap-2.5">
      <NumericFormat
        displayType="text"
        value={value}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        {...props}
      />
      {addorment}
    </div>
  );
};

export default Currency;
