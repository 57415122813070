import { FLabel } from '@components/form/FLabel';
import { TextInput } from '@components/inputs/TextInput';
import { Modal } from '@components/modal';
import { SkeletonInput } from '@components/skeleton';
import { useFetchMeter, useUpdateMeter } from 'api/assets/assetsServices';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type ChangeEvent, type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

interface EditMeterModalProps extends Disclosure {
  meterId?: number;
}

const EditMeterModal: FC<EditMeterModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  meterId,
}) => {
  const [meterName, setMeterName] = useState('');
  const [meterDescription, setMeterDescription] = useState('');

  const { data, isFetching } = useFetchMeter(meterId);
  const { mutate, isPending, isSuccess } = useUpdateMeter(meterId);

  const { t } = useTranslation();

  useMemo(() => {
    if (data?.meter) {
      const meter = data.meter;
      setMeterName(meter.name);
      setMeterDescription(meter?.description ?? '');
    }
  }, [data]);

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ): void => {
    const value = event.target.value;
    if (type === 'name') {
      setMeterName(value);
    } else if (type === 'description') {
      setMeterDescription(value);
    }
  };

  const handleUpdateMeter = (): void => {
    const body = {
      id: data?.meter.id,
      end_client_id: data?.meter.end_client_id,
      user_id: data?.meter.user_id,
      name: meterName,
      description: meterDescription,
    };
    if (meterName === '') {
      toast.error('Meter name cannot be empty');
    } else {
      mutate(body);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      title={t('assetsPage.editMeter')}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('common.buttons.continue'),
          onClick: handleUpdateMeter,
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          {isFetching ? (
            <>
              <Skeleton width={200} />
              <SkeletonInput />
            </>
          ) : (
            <>
              <FLabel>{t('assetsPage.table.meterName')}</FLabel>
              <TextInput
                value={meterName}
                placeholder={t('assetsPage.table.meterName')}
                onChange={(e) => {
                  handleChange(e, 'name');
                }}
              />
            </>
          )}
        </div>

        <div className="flex flex-col gap-2">
          {isFetching ? (
            <>
              <Skeleton width={150} />
              <SkeletonInput />
            </>
          ) : (
            <>
              <FLabel>{t('common.description')}</FLabel>
              <TextInput
                value={meterDescription}
                placeholder={t('assetsPage.placeholders.meterDescription')}
                onChange={(e) => {
                  handleChange(e, 'description');
                }}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default EditMeterModal;
