import { SearchBar } from '@components/inputs/SearchBar';
import { SelectInput } from '@components/inputs/SelectInput';
import { SwitchButton } from '@components/switchButton';
import {
  useFetchChangeTableStatus,
  useFetchUtilityTypeFilters,
} from 'api/changes/changesServices';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { type ChangeEvent, type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUtilityTypeReadableName } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { TableFilters } from '../../../components/table/TableFilters';
import { ChangesTable } from './tables/ChangesTable';
import { type UtilityTypeFiltersResponse } from 'interfaces/assets/Meters.interface';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { useListAssets, useListGroups } from 'api/assets/assetsServices';
import { type Group } from 'interfaces/group/Group.interface';
import { type Asset } from 'interfaces/assets/Asset.interface';
import { useURLSearchParams } from 'hooks/useURLSearchParams.tsx';
import { convertStringToInt } from 'utils/numberUtils/numberUtils';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

const getUtilityTypeOptions = (
  data: UtilityTypeFiltersResponse | undefined
): DropdownOptions[] => {
  const options: DropdownOptions[] = [];

  if (!data) {
    return [{ name: 'All', value: 'all' }];
  }

  data.utility_types.forEach((item) => {
    const value = UtilityTypeEnum[item as keyof typeof UtilityTypeEnum];
    // don't take undefined type into account
    if (value) {
      const option: DropdownOptions = {
        name: getUtilityTypeReadableName(value) ?? undefined,
        value,
      };
      options.push(option);
    }
  });

  return options;
};

const ChangeListPage: FC = () => {
  Mixpanel.pageview();
  const [showImpact, setShowImpact] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    impact_average_diff: false,
    impact_average_diff_euro: true,
  });
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [utilityType, setUtilityType] = useState('');
  const [statusQuery, setStatusQuery] = useState('');
  const [groupQuery, setGroupQuery] = useState<number | undefined>();
  const [assetQuery, setAssetQuery] = useState<number | undefined>();

  const { data, isFetching } = useFetchUtilityTypeFilters();
  const { data: groupList, isFetching: isFetchingGroup } = useListGroups({
    perPage: 100,
  });
  const { data: assetList, isFetching: isFetchingAsset } = useListAssets({
    perPage: 150,
    type: 'BUILDING',
  });

  const { t } = useTranslation();
  const { data: statusFilter, isFetching: isLoadingFilters } =
    useFetchChangeTableStatus({
      query: debouncedQuery,
      utilityType,
      groupQuery,
      assetQuery,
    });
  const { addURLSearchParams, deleteURLSearchParams, getAllUrlFilters } =
    useURLSearchParams();

  const utilityOptions = useMemo(() => getUtilityTypeOptions(data), [data]);

  const groupOptions = useMemo(
    () =>
      groupList?.pages?.flatMap(
        (page: any) =>
          page?.groups.map((group: Group) => ({
            name: group.name,
            value: group.id,
          }))
      ) ?? [],
    [groupList]
  );

  const assetOptions = useMemo(
    () =>
      assetList?.pages?.flatMap(
        (page: any) =>
          page?.assets.map((asset: Asset) => ({
            name: asset.name,
            value: asset.id,
          }))
      ) ?? [],
    [assetList]
  );

  useEffect(() => {
    if (showImpact) {
      setColumnVisibility({
        impact_average_diff: true,
        impact_average_diff_euro: false,
      });
    } else {
      setColumnVisibility({
        impact_average_diff: false,
        impact_average_diff_euro: true,
      });
    }
  }, [showImpact]);

  useEffect(() => {
    const delay = 300;
    const timeoutId = setTimeout(() => {
      setDebouncedQuery(query);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [query]);

  const handleShowImpact = (): void => {
    setShowImpact(!showImpact);
  };

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  const handleUtilityTypeOnChange = (
    value: string | number | undefined
  ): void => {
    if (typeof value === 'string' && value) {
      addURLSearchParams('utilityType', value ?? '');
    } else {
      deleteURLSearchParams('utilityType');
    }
  };

  const handleStatusOnChange = (status: string): void => {
    if (status === statusQuery) {
      deleteURLSearchParams('status');
      return;
    }
    addURLSearchParams('status', status);
  };

  const handleGroupOnChange = (groupId: string | number | undefined): void => {
    if (groupId) {
      addURLSearchParams('group', groupId as string);
    } else {
      deleteURLSearchParams('group');
    }
  };

  const handleAssetOnChange = (assetId: string | number | undefined): void => {
    if (assetId) {
      addURLSearchParams('asset', assetId as string);
    } else {
      deleteURLSearchParams('asset');
    }
  };

  useEffect(() => {
    const searchParams = getAllUrlFilters();
    const { utilityType, asset, group, status } = searchParams;

    setUtilityType(utilityType);
    setAssetQuery(convertStringToInt(asset) ?? undefined);
    setGroupQuery(convertStringToInt(group) ?? undefined);
    setStatusQuery(status);
  }, [location.search]);

  return (
    <div>
      <h1>{t('anomaliesPage.title')}</h1>
      <div className="flex gap-4 my-6 w-full">
        <div className="flex-[1.5_1_0%] max-w-[311px]">
          <SearchBar
            placeholder={t('anomaliesPage.placeholders.search')}
            onChange={handleSearchOnChange}
          />
        </div>
        <SelectInput
          placeholder={t('common.placeholders.groups')}
          options={groupOptions}
          onChange={handleGroupOnChange}
          value={groupQuery}
          maxWidth={'200px'}
          isFetching={isFetchingGroup}
        />

        <SelectInput
          placeholder={t('common.placeholders.asset')}
          options={assetOptions}
          onChange={handleAssetOnChange}
          value={assetQuery}
          maxWidth={'200px'}
          isFetching={isFetchingAsset}
        />
        <SelectInput
          placeholder="Utility type"
          options={utilityOptions}
          value={utilityType}
          onChange={handleUtilityTypeOnChange}
          data-testid="utility-dropdown"
          maxWidth={'200px'}
          isFetching={isFetching}
        />

        <div className="w-4/12 flex items-center justify-end">
          <SwitchButton
            label={t('anomaliesPage.buttons.showImpact')}
            checked={showImpact}
            onChange={handleShowImpact}
          />
        </div>
      </div>
      <div className="mb-6">
        <TableFilters
          filters={statusFilter}
          onClick={handleStatusOnChange}
          activeFilter={statusQuery}
          isLoading={isLoadingFilters}
        />
      </div>

      <div>
        <ChangesTable
          columnVisibility={columnVisibility}
          query={debouncedQuery}
          filters={{ utilityType, statusQuery, groupQuery, assetQuery }}
        />
      </div>
    </div>
  );
};
export default ChangeListPage;
