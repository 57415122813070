import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import { StatusEnum, statusOrder } from 'enums/StatusEnum';
import { type ActivityLogs } from 'interfaces/activityLog/ActivityLog.interface';
import {
  type ChangeData,
  type HeatMapDataResponse,
  type ListChanges,
  type TimeSeries,
} from 'interfaces/change/ChangeData.interface';
import queryClient from 'lib/queryClient';
import { type UtilityTypeFiltersResponse } from 'interfaces/assets/Meters.interface';
import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';
import { type SubOptionsResponse } from 'interfaces/request/SubOptionsResponse.interface';

/**
 * Fetch anomaly data by ID using a React Query hook.
 *
 * This function makes an HTTP request to retrieve anomaly data based on the provided ID.
 *
 * @param {string} [id] - The ID of the anomaly data to fetch.
 *
 * @returns {UseQueryResult<ChangeData | null, Error | AxiosError>} The result of the React Query hook, including the anomaly data or an error if one occurs.
 */

interface ChangeListPayload {
  page: number;
  per_page: number;
  query?: string;
  utility_type?: string;
  status?: string;
  group_id?: string;
  asset_id?: string;
  order_by?: string;
}

export const useFetchChange = (id?: string): UseQueryResult<ChangeData> => {
  return useQuery<ChangeData, AxiosError>({
    queryKey: ['anomaly', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`change_detection/${id}`);
      return response.data;
    },
    retry: 2,
  });
};

export const useFetchTimeSeries = ({
  id,
  momentChange,
  disabled = false,
}: {
  id?: string;
  momentChange?: string;
  disabled?: boolean;
}): UseQueryResult<TimeSeries | null, Error | AxiosError> => {
  return useQuery({
    queryKey: ['timeseries', id, momentChange],
    queryFn: async () => {
      if (!id || !momentChange || disabled) {
        return null;
      }
      const response = await axiosInstance.get<ChangeData>(
        `change_detection/ts`,
        {
          params: {
            group_by: 'hour',
            connection_point_id_client: id,
            moment_change: momentChange,
          },
        }
      );
      return response.data;
    },
  });
};

export const useUpdateStatus = (
  id?: string
): UseMutationResult<any | null, Error | AxiosError> => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) {
        return null;
      }
      await axiosInstance.patch(`change_detection/${id}/status`, body);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      toast.success('Status updated');
      await queryClient.invalidateQueries({
        queryKey: ['anomaly'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['activityLog'],
      });
    },
  });
};

export const useUpdateChange = (
  id?: string
): UseMutationResult<any | null, Error | AxiosError> => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) {
        return null;
      }
      await axiosInstance.patch(`change_detection/${id}`, body);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Change updated successfully');
      await queryClient.invalidateQueries({
        queryKey: ['anomaly'],
      });
      await queryClient.invalidateQueries({ queryKey: ['activityLog'] });
    },
  });
};

export const useListChanges = ({
  page = 1,
  perPage = 20,
  query = '',
  utilityType = '',
  status = '',
  group,
  asset,
  orderBy = '-moment_change',
}: {
  page?: number;
  perPage?: number;
  query?: string;
  utilityType?: string;
  status?: string;
  group?: number;
  asset?: number;
  orderBy?: string;
} = {}): UseInfiniteQueryResult<InfiniteData<ListChanges>> => {
  return useInfiniteQuery<ListChanges>({
    queryKey: [
      'listChanges',
      query,
      utilityType,
      status,
      group,
      asset,
      orderBy,
    ],
    queryFn: async ({ pageParam }) => {
      const params: ChangeListPayload = {
        page: pageParam as number,
        per_page: perPage,
        query,
        utility_type: utilityType,
        status,
        group_id: group?.toString(),
        asset_id: asset?.toString(),
        order_by: orderBy,
      };

      if (params.utility_type === '') {
        delete params.utility_type;
      }

      if (!params.group_id) {
        delete params.group_id;
      }

      if (!params.asset_id) {
        delete params.asset_id;
      }

      if (params.status === '') {
        delete params.status;
      }

      const response = await axiosInstance.get(`change_detection/filter`, {
        params,
      });
      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNextPage) {
        return lastPage.nextPage;
      }
    },
    refetchOnWindowFocus: false,
  });
};

export const useListDropdownChanges = ({
  page = 1,
  perPage = 20,
  connectionPointIdClient,
}: {
  page?: number;
  perPage?: number;
  connectionPointIdClient?: string;
} = {}): UseQueryResult<ListChanges> => {
  return useQuery<ListChanges>({
    queryKey: ['dropdownListChanges', connectionPointIdClient],
    queryFn: async () => {
      if (!connectionPointIdClient) return;
      const response = await axiosInstance.get(
        `change_detection/list/changes`,
        {
          params: {
            connection_point_id_client: connectionPointIdClient,
          },
        }
      );
      return response.data;
    },
  });
};

export const useFetchChangeTableStatus = ({
  query,
  groupQuery,
  utilityType,
  assetQuery,
}: {
  query?: string;
  groupQuery?: number;
  utilityType?: string;
  assetQuery?: number;
}): UseQueryResult<any | null, Error | AxiosError> => {
  return useQuery({
    queryKey: ['listChangesStatus', query, groupQuery, utilityType, assetQuery],
    queryFn: async () => {
      const response = await axiosInstance.get('change_detection/status', {
        params: {
          query,
          group_id: groupQuery,
          utility_type: utilityType,
          asset_id: assetQuery,
        },
      });
      const statusData: Record<string, number> = response.data;
      const sortedStatus: Record<string, number> = {};

      statusOrder.forEach((key) => {
        const value = StatusEnum[key as keyof typeof StatusEnum];
        if (value && statusData) {
          sortedStatus[value] = statusData[value];
        }
      });

      return sortedStatus;
    },
  });
};

export const useFetchRootCauses = (): UseQueryResult<SubOptionsResponse[]> => {
  return useQuery<SubOptionsResponse[]>({
    queryKey: ['rootCauses'],
    queryFn: async () => {
      const response = await axiosInstance.get('change_detection/root_causes');
      return response.data;
    },
  });
};

export const useFetchActivityLog = (
  changeDetectionId?: number
): UseQueryResult<ActivityLogs> => {
  return useQuery<ActivityLogs>({
    queryKey: ['activityLog', changeDetectionId],
    queryFn: async () => {
      if (!changeDetectionId) return;
      const response = await axiosInstance.get(
        `change_detection/${changeDetectionId}/activity`
      );
      return response.data;
    },
  });
};

export const useFetchHeatMap = (
  id?: string,
  momentChange?: string
): UseQueryResult<HeatMapDataResponse> => {
  return useQuery<HeatMapDataResponse>({
    queryKey: ['heatMap', id, momentChange],
    queryFn: async () => {
      if (!id || !momentChange) {
        return null;
      }
      const response = await axiosInstance.get<any>(
        `change_detection/heatmap/${id}/${momentChange}`
      );
      return response.data;
    },
  });
};

export const usePostActivityLogComment = (
  changeId?: number
): UseMutationResult<any | null, Error | AxiosError> => {
  return useMutation({
    mutationFn: async (body) => {
      if (!changeId) return;
      const response = await axiosInstance.post(
        `change_detection/${changeId}/activity`,
        body
      );
      return response?.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      toast.success('Added a new comment');
      await queryClient.invalidateQueries({ queryKey: ['activityLog'] });
    },
  });
};

export const useFetchUtilityTypeFilters = (): UseQueryResult<
  UtilityTypeFiltersResponse | undefined,
  Error | AxiosError
> => {
  return useQuery({
    queryKey: ['utilityTypeFilters'],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `change_detection/utility_type_filters`
      );
      return response.data;
    },
  });
};
