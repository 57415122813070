import { CardContainer, CardTitle } from 'layout/Card';
import AddUtilityPriceForm from 'pages/SettingsPage/forms/AddUtilityPriceForm/AddUtilityPriceForm';
import { AddUtilityPriceInitialValues } from 'pages/SettingsPage/forms/AddUtilityPriceForm/schema';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { UtilityPriceList } from '../UtilityPriceList';
import { useFetchUtilityPrices } from 'api/utilityPrices/UtilityPriceService';
import { useAuth } from 'hooks/contexts/AuthContext';

const UtilityPrices: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const companyId = user?.company_id;
  const { data: utilityPricesListData, isFetching } =
    useFetchUtilityPrices(companyId);

  return (
    <CardContainer>
      <CardTitle
        title={t('settingsPage.companyTab.utilityPrices')}
        subtitle={t('settingsPage.companyTab.utilityPricesInfo')}
        isLoading={isFetching}
        skeletonVariant="subtitle"
      />
      <div className="gap-6 pt-6 max-w-[1000px]">
        <AddUtilityPriceForm
          initialValues={AddUtilityPriceInitialValues}
          companyId={companyId}
          isLoading={isFetching}
        />
        <UtilityPriceList
          utilityPricesListData={
            utilityPricesListData ?? { utility_prices: [] }
          }
          companyId={companyId}
          isLoading={isFetching}
        />
      </div>
    </CardContainer>
  );
};
export default UtilityPrices;
