import { UTILITY_TYPE } from 'lib/constants';
import { z } from 'zod';

export const UtilityPriceInitialValues = {
  utility_type: UTILITY_TYPE[0].value,
};

interface utilityPriceControlNamesType {
  utility_type: 'utility_type';
  unit_type: 'unit_type';
  price: 'price';
}

export const utilityPriceSchema = z.object({
  id: z.number().optional(),
  utility_type: z.string().nonempty({
    message: "Can't be empty",
  }),
  unit_type: z.string().nonempty({
    message: "Can't be empty",
  }),
  price: z.number(),
});

export const utilityPriceControlNames: utilityPriceControlNamesType = {
  utility_type: 'utility_type',
  unit_type: 'unit_type',
  price: 'price',
};

export type utilityPriceSchemaType = z.infer<typeof utilityPriceSchema>;
