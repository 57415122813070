import { type FC } from 'react';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { type AlertDefinition } from 'interfaces/alerts/AlertDefinition.interface';
import AlertDefinitionForm from '../../forms/AlertDefinitionForm/AlertDefinitionForm';
import { SkeletonInput } from '@components/skeleton';
import Skeleton from 'react-loading-skeleton';

interface AlertDefinitionListProps {
  userOptions: DropdownOptions[];
  alertDefinitions: AlertDefinition[];
  isLoading?: boolean;
}
const AlertDefinitionList: FC<AlertDefinitionListProps> = ({
  userOptions,
  alertDefinitions,
  isLoading,
}) => {
  const skeletonArray = Array(3).fill({});
  return (
    <div>
      {isLoading
        ? skeletonArray.map((_, index) => (
            <div key={index} className="pt-3">
              <div className="flex justify-between w-full gap-6">
                <div className="flex items-center justify-center">
                  <Skeleton width={45} height={38} borderRadius={'100px'} />
                </div>
                <SkeletonInput />
                <div className="flex items-center justify-center">
                  <Skeleton width={90} height={38} borderRadius={'100px'} />
                </div>
                <SkeletonInput />
                <SkeletonInput />
                <div className="flex items-center justify-center">
                  <SkeletonInput
                    width={20}
                    height={38}
                    borderRadius={'100px'}
                  />
                </div>
                <SkeletonInput />
                <Skeleton width={45} height={38} borderRadius={'100px'} />
              </div>
            </div>
          ))
        : alertDefinitions?.map((element: any, index: any) => {
            return (
              <div key={index}>
                <AlertDefinitionForm
                  key={element.id}
                  id={element.id}
                  userOptions={userOptions}
                  initialValues={element}
                />
              </div>
            );
          })}
    </div>
  );
};
export default AlertDefinitionList;
