import EmailSentIcon from '@components/icons/EmailIcon/EmailSentIcon';
import { requestResetPasswordEmail } from 'api/auth/authService';
import { Button } from 'layout/Button';
import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface EmailReceivedComponentProps {
  email: string;
}

const EmailReceivedComponent: FC<EmailReceivedComponentProps> = ({ email }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const sendEmailAgain = async (): Promise<void> => {
    setIsLoading(true);
    await requestResetPasswordEmail(email);
    setIsLoading(false);
  };

  return (
    <div>
      <div className="flex justify-center items-center pb-6">
        <EmailSentIcon />
      </div>
      <Button
        className="w-full mt-lg"
        type="submit"
        label={t('common.buttons.sendEmailAgain')}
        size="large"
        isLoading={isLoading}
        variant="secondary"
        onClick={sendEmailAgain}
        data-testid="send-email-again-button"
      />
    </div>
  );
};

export default EmailReceivedComponent;
