import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import { t } from 'i18next';
import { type AssetsAddressListResponse } from 'interfaces/assets/Address.interface';
import {
  type AssetFunctionsResponse,
  type AssetResponse,
  type AssetsListResponse,
} from 'interfaces/assets/Asset.interface';
import {
  type MeterDetails,
  type MeterGroupFilterResponse,
  type MetersListResponse,
  type MetersResponse,
} from 'interfaces/assets/Meters.interface';
import {
  type AssetGroups,
  type GroupResponse,
  type LinkableGroupsResponse,
} from 'interfaces/group/Group.interface';
import queryClient from 'lib/queryClient';
import { type GroupSchemaType } from 'pages/Assets/Groups/form/ManageGroupForm/schema';
import { toast } from 'react-toastify';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';

export const useCreateAsset = (): UseMutationResult<
  AssetResponse,
  Error | AxiosError
> => {
  return useMutation({
    mutationFn: async (params) => {
      const response = await axiosInstance.post('asset/', params);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('assetsPage.assetsTab.toasters.create'));
      await queryClient.invalidateQueries({ queryKey: ['listAssets'] });
    },
  });
};

export const useListMeters = ({
  page = 1,
  perPage = 20,
  query = '',
  utilityType = '',
  companyId = null,
}: {
  page?: number;
  perPage?: number;
  query?: string;
  utilityType?: string;
  companyId?: number | null;
} = {}): UseInfiniteQueryResult<InfiniteData<MetersListResponse>> => {
  return useInfiniteQuery<MetersListResponse>({
    queryKey: ['listMeters', query, utilityType],
    queryFn: async ({ pageParam }) => {
      const response = await axiosInstance.get(`meter/list`, {
        params: {
          page: pageParam,
          per_page: perPage,
          query,
          utility_type: utilityType,
          company_id: companyId,
        },
      });
      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNextPage) {
        return lastPage.nextPage;
      }
    },
  });
};

export const useListAssets = ({
  page = 1,
  perPage = 20,
  query = '',
  type = '',
  assetFunction = '',
}: {
  page?: number;
  perPage?: number;
  query?: string;
  type?: string;
  assetFunction?: string;
} = {}): UseInfiniteQueryResult<InfiniteData<AssetsListResponse>> => {
  return useInfiniteQuery<AssetsListResponse>({
    queryKey: ['listAssets', query, type, assetFunction],
    queryFn: async ({ pageParam }) => {
      const response = await axiosInstance.get(`asset/list`, {
        params: {
          page: pageParam ?? 1,
          per_page: perPage,
          query,
          type,
          function: assetFunction,
        },
      });
      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (_lastGroup, groups) => {
      if (_lastGroup?.hasNextPage) {
        return _lastGroup.nextPage;
      }
    },
  });
};

export const useListLinkableAssets = ({
  query = '',
  id,
}: {
  query?: string;
  id?: number;
} = {}): UseQueryResult<AssetsListResponse> => {
  return useQuery<AssetsListResponse>({
    queryKey: ['listLinkableAssets', query, id],
    queryFn: async () => {
      if (!id) return;
      const response = await axiosInstance.get(`meter/${id}/linkable_assets`, {
        params: {
          query,
        },
      });
      return response.data;
    },
  });
};

export const useFetchAsset = (id?: number): UseQueryResult<AssetResponse> => {
  return useQuery<AssetResponse>({
    queryKey: ['assetDetails', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`asset/${id}`);
      return response.data;
    },
  });
};

export const useFetchAssetLinkableMeter = (id?: number): UseQueryResult => {
  return useQuery<MetersResponse>({
    queryKey: ['assetLinkableMeters', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`asset/${id}/linkable_meters`);
      return response.data;
    },
  });
};

export const useUpdateAsset = (id?: number): UseMutationResult => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.patch(`asset/${id}`, body);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('assetsPage.assetsTab.toasters.update'));
      await queryClient.invalidateQueries({ queryKey: ['assetDetails'] });
      await queryClient.invalidateQueries({ queryKey: ['listAssets'] });
    },
  });
};

export const useListAssetAdresses = ({
  query,
}: {
  query?: string;
}): UseQueryResult<AssetsAddressListResponse> => {
  return useQuery<AssetsAddressListResponse>({
    queryKey: ['listAssetAddresses', query],
    queryFn: async () => {
      const response = await axiosInstance.get(`/asset/addresses`, {
        params: { query },
      });
      return response.data;
    },
  });
};

export const useListAssetFunctions =
  (): UseQueryResult<AssetFunctionsResponse> => {
    return useQuery<AssetFunctionsResponse>({
      queryKey: ['listAssetFunctions'],
      queryFn: async () => {
        const response = await axiosInstance.get(`asset/functions`);
        return response.data;
      },
    });
  };

export const useFetchMeter = (id?: number): UseQueryResult<MeterDetails> => {
  return useQuery<MeterDetails>({
    queryKey: ['meterDetails', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`meter/${id}`);
      return response.data;
    },
  });
};
export const useListFilteredMeters = ({
  assets,
  addresses,
  utilityTypes,
  query,
}: {
  assets?: number[];
  addresses?: number[];
  utilityTypes?: string[];
  query?: string;
}): UseQueryResult<MeterGroupFilterResponse | null, Error | AxiosError> => {
  return useQuery({
    queryKey: ['meterDetails', assets, addresses, utilityTypes, query],
    queryFn: async () => {
      const response = await axiosInstance.get(`meter/group_filter`, {
        params: {
          assets,
          addresses,
          utility_types: utilityTypes,
          query,
        },
        paramsSerializer: { indexes: null },
      });
      return response.data;
    },
  });
};

export const useUpdateMeter = (id?: number): UseMutationResult => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) return;
      const response = await axiosInstance.patch(`meter/${id}`, body);
      return response.data;
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['listGroups', 'fetchGroupDetails', 'listMeters'],
      });
      await queryClient.refetchQueries({
        stale: true,
      });
      toast.success('Meter successfully updated');
    },
  });
};

export const useDeleteMeter = (): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  string | number
> => {
  return useMutation({
    mutationFn: async (id) => {
      await axiosInstance.delete(`meter/${id}`);
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['listMeters'] });
    },
  });
};

export const useDeleteAsset = (): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  string | number,
  unknown
> => {
  return useMutation({
    mutationFn: async (id) => {
      await axiosInstance.delete(`asset/${id}`);
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('assetsPage.assetsTab.toasters.delete'));
      await queryClient.invalidateQueries({ queryKey: ['listAssets'] });
    },
  });
};

export const useFetchCompanyMeters = (
  companyId?: number | null,
  options?: {
    query?: string;
    perPage?: number;
    external_meter_id?: number;
  }
): UseQueryResult<MetersResponse> => {
  return useQuery<MetersResponse>({
    queryKey: ['companyMeters', companyId, options],
    queryFn: async () => {
      if (!companyId) {
        return null;
      }
      const response = await axiosInstance.get(`meter/list`, {
        params: {
          company_id: companyId,
          query: options?.query,
          per_page: options?.perPage,
          external_meter_id: options?.external_meter_id ?? 0,
        },
      });
      return response.data;
    },
  });
};

export const useFetchLinkedRelatableMeters = ({
  id,
  relatable,
}: {
  id?: number;
  relatable: string;
}): UseQueryResult<MetersResponse> => {
  return useQuery<MetersResponse>({
    queryKey: ['linkedRelatableMeters', id, relatable],
    queryFn: async () => {
      if (!id) return;
      const response = await axiosInstance.get(
        `meter/hierarchy/${id}/${relatable}`
      );
      return response.data;
    },
  });
};

export const useFetchRelatableMeters = ({
  id,
  relatable,
  query,
}: {
  id?: number;
  query?: string;
  relatable: string;
}): UseQueryResult<MetersResponse> => {
  return useQuery<MetersResponse>({
    queryKey: ['relatableMeters', id, query, relatable],
    queryFn: async () => {
      if (!id || !relatable) return;
      const response = await axiosInstance.get(
        `meter/hierarchy/${id}/${relatable}/linkable`,
        {
          params: {},
        }
      );
      return response.data;
    },
  });
};

export const useAddMeterHierarchy = (): UseMutationResult<void> => {
  return useMutation({
    mutationFn: async (body) => {
      await axiosInstance.post(`meter/hierarchy`, body);
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Meter relation created successfuly');
      await queryClient.invalidateQueries({
        queryKey: ['linkedRelatableMeters'],
      });
    },
  });
};

export const useDeleteMeterHierarchy = (): UseMutationResult<
  void,
  AxiosError | Error,
  { parent_id: number; child_id: number }
> => {
  return useMutation({
    mutationFn: async (body) => {
      await axiosInstance.delete(`meter/hierarchy`, { data: body });
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Meter relation removed successfully');
      await queryClient.invalidateQueries({
        queryKey: ['linkedRelatableMeters'],
      });
    },
  });
};

export const useListGroups = ({
  page = 1,
  perPage = 20,
  query = '',
}: {
  page?: number;
  perPage?: number;
  query?: string;
  utilityType?: string;
} = {}): UseInfiniteQueryResult<InfiniteData<AssetGroups>, Error> => {
  return useInfiniteQuery<AssetGroups>({
    queryKey: ['listGroups', query],
    queryFn: async ({ pageParam }) => {
      const response = await axiosInstance.get(`group/list`, {
        params: {
          page: pageParam,
          per_page: perPage,
          query,
        },
      });
      return response.data;
    },

    initialPageParam: page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNextPage) {
        return lastPage.nextPage;
      }
    },
    refetchOnWindowFocus: false,
  });
};

export const useFetchLinkableGroups = ({
  id,
  query,
}: {
  id?: number;
  query?: string;
}): UseQueryResult<LinkableGroupsResponse> => {
  return useQuery<LinkableGroupsResponse>({
    queryKey: ['useFetchLinkableGroups', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`meter/${id}/linkable_groups`);
      return response.data;
    },
  });
};

export const useFetchGroup = (id?: number): UseQueryResult<GroupResponse> => {
  return useQuery<GroupResponse>({
    queryKey: ['fetchGroupDetails', id],
    queryFn: async () => {
      if (!id) {
        return null;
      }
      const response = await axiosInstance.get(`group/${id}`);
      return response.data;
    },
  });
};

export const useUpdateGroup = (id?: number): UseMutationResult => {
  return useMutation({
    mutationFn: async (body) => {
      if (!id) return;
      const response = await axiosInstance.patch(`group/${id}`, body);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('assetsPage.toasters.groupUpdated'));
      await queryClient.invalidateQueries({
        queryKey: ['listGroups', 'fetchGroupDetails'],
      });
      await queryClient.refetchQueries({
        stale: true,
      });
    },
  });
};

export const useDeleteGroup = (): UseMutationResult<
  any,
  Error | AxiosError,
  number | undefined
> => {
  return useMutation({
    mutationFn: async (id) => {
      if (!id) return;
      const response = await axiosInstance.delete(`group/${id}`);
      return response.data;
    },
    onError: (error) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['listGroups'] });
      toast.success('Group deleted.');
    },
  });
};

export const useCreateGroup = (): UseMutationResult<
  any,
  Error | AxiosError,
  Omit<GroupSchemaType, 'meters'> & { meters: { id: string | number }[] }
> => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await axiosInstance.post(`group/`, body);
      return response?.data;
    },
    mutationKey: ['createGroup'],
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('assetsPage.toasters.groupCreated'));
      await queryClient.invalidateQueries({ queryKey: ['listGroups'] });
    },
  });
};
