import Tippy from '@tippyjs/react';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
// import { convertToPercentageNumber } from 'utils/formatters/number/numberUtils';

export interface DoubleLayerProgressBarProps {
  completed: number;
  maxCompleted: number;
  maxWidth?: number;
  minWidth?: number;
  layerCompleted: number;
  tooltipProjects?: string;
}

export const getColorByPercentage = (percentage: number): string => {
  // Define the gradient colors
  const colors = ['#ED4145', '#F46E25', '#F4D528', '#8ED234'];

  if (percentage < 1) {
    return colors[0];
  } else if (percentage <= 15) {
    return colors[1];
  } else if (percentage <= 25) {
    return colors[2];
  } else {
    return colors[3];
  }
};

export function getCurrentPercentage(
  currentValue: number,
  maxValue: number
): number {
  // Calculate the percentage of the current value relative to the maximum value
  const percentage = (currentValue / maxValue) * 100;

  // Ensure percentage stays within the 0-100 range
  return Math.max(0, Math.min(100, percentage));
}

const DoubleLayerProgressBar: FC<DoubleLayerProgressBarProps> = ({
  completed,
  maxCompleted,
  layerCompleted,
  maxWidth,
  minWidth,
  tooltipProjects,
  ...props
}) => {
  // const colorPercentage = convertToPercentageNumber(completed);
  const layerPercentage = getCurrentPercentage(layerCompleted, maxCompleted);
  const { t } = useTranslation();

  return (
    <Tippy
      disabled={!tooltipProjects}
      content={t('common.progressBar', {
        totalSavings: layerPercentage.toFixed(2),
        projects: tooltipProjects,
      })}
    >
      <div
        className="w-full"
        style={{ maxWidth, minWidth: minWidth ?? 100 }}
        {...props}
      >
        <div className="flex w-full rounded-full bg-gray-25 h-[8px] relative">
          {/* Positive progress bar */}
          <div
            className="absolute h-full rounded-full overflow-hidden"
            style={{
              width: `${layerPercentage}%`,
            }}
          >
            <div className={`bg-bar-layer h-full bg-repeat`} />
          </div>

          <div
            className={`h-full rounded-[inherit] rounded-r-full`}
            style={{
              width: `100%`,
              backgroundColor: getColorByPercentage(layerPercentage),
            }}
          />
        </div>
      </div>
    </Tippy>
  );
};
export default DoubleLayerProgressBar;
