import { type AxiosError, isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

const useNotFoundPage = (): {
  validateAndRedirectToNotFound: (
    isError: boolean,
    error: Error | AxiosError | null
  ) => void;
} => {
  const navigate = useNavigate();

  const validateAndRedirectToNotFound = (
    isError: boolean,
    error: Error | AxiosError | null
  ): void => {
    if (
      isError &&
      isAxiosError(error) &&
      (error?.response?.status === 404 || error?.response?.status === 403)
    ) {
      navigate('/app/not-found');
    }
  };

  return { validateAndRedirectToNotFound };
};
export default useNotFoundPage;
