import axios, { type AxiosError } from 'axios';
import { toast } from 'react-toastify';

interface ErrorMessage {
  error: {
    message: string;
  };
}

export const handleError = (
  error: Error | AxiosError,
  message: string | undefined = undefined
): void => {
  if (axios.isAxiosError(error)) {
    // const axiosError = error as Error;
    // const customErrorData = axiosError.response?.data?.error?.message;
    // console.log('axiosError', axiosError);
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const err = error.response.data as ErrorMessage;
      if (err.error?.message !== 'Signature has expired') {
        if (err.error?.message) {
          toast.error(message ?? err.error?.message);
        } else {
          switch (error.response.status) {
            case 401:
              toast.error(message ?? 'Unauthorized request');
              break;
            case 403:
              toast.error(message ?? 'Forbidden request');
              break;
            case 404:
              toast.error(message ?? 'Not found');
              break;
            case 500:
              toast.error(message ?? 'An error occurred.');
              break;
            case 429:
              toast.error(message ?? 'Too many requests.');
              break;
            case 400:
              // eslint-disable-next-line no-case-declarations
              const dynamicMessage = err.error
                ? Object.keys(err.error)?.[0]
                : 'An error occurred.';
              toast.error(dynamicMessage);
              break;
            default:
              toast.error('An error occurred.');
              break;
          }
        }
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
      toast.error('An error occurred');
    }
  } else {
    console.log(error);
    toast.error(message ?? 'An error occurred.');
  }
};
