import { Accordion } from '@components/accordion';
import { AccordionSection } from '@components/accordionSection';
import { Modal } from '@components/modal';
import { UtilityChip } from '@components/utilityChip';
import {
  useFetchLinkedRelatableMeters,
  useFetchMeter,
} from 'api/assets/assetsServices';
import useDisclosure from 'hooks/useDisclosure';
import { type Asset } from 'interfaces/assets/Asset.interface';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, type ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { formatAddress } from 'utils/formatters/string/stringFormatter';
import { HierarchyModal } from '../HierarchyModal';
import RelatableMeterContainer from './RelatableMeterContainer';
import { DetailsRow } from './styled';

interface MeterDetailsModalProps extends Disclosure {
  meterId?: number;
  setMeterId: (meter: number) => void;
}

const MeterDetailsModal: FC<MeterDetailsModalProps> = ({
  meterId,
  isOpen,
  handleOpen,
  handleClose,
  setMeterId,
}) => {
  const [currentlyEditing, setCurrentlyEditing] = useState<
    'parents' | 'children' | undefined
  >();

  const [foundBuilding, setFoundBuilding] = useState(false);
  const [foundGroup, setFoundGroup] = useState(false);
  const { data, isFetching } = useFetchMeter(meterId);

  const { data: childrenMetersData, isFetching: isFetchingChildren } =
    useFetchLinkedRelatableMeters({
      id: meterId,
      relatable: 'children',
    });
  const { data: parentMetersData, isFetching: isFetchingParents } =
    useFetchLinkedRelatableMeters({
      id: meterId,
      relatable: 'parents',
    });

  const { t } = useTranslation();

  const {
    isOpen: isHierarchyOpen,
    handleClose: handleHierarchyClose,
    handleOpen: handleHierarchyOpen,
  } = useDisclosure();

  const createBuildingAccordion = (asset: Asset): ReactNode => {
    if (!foundBuilding && asset) setFoundBuilding(true);
    return <Accordion key={asset.id} title={asset.name} />;
  };

  const createGroupAccordion = (asset: Asset): ReactNode => {
    if (!foundGroup && asset) setFoundGroup(true);
    return <Accordion key={asset.id} title={asset.name} />;
  };

  const handleIsEditing = (type: 'parents' | 'children'): void => {
    if (currentlyEditing === type) {
      setCurrentlyEditing(undefined);
      return;
    }
    setCurrentlyEditing(type);
  };

  const handleRelatableItemOnClick = (id: number): void => {
    setMeterId(id);
    setCurrentlyEditing(undefined);
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentlyEditing(undefined);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      title={data?.meter?.name}
      useSkeleton={true}
      maxWidth={550}
    >
      <div className="flex flex-col gap-6">
        <div className="border border-gray-10 rounded-lg">
          <div className="px-4 py-2 border-b border-gray-10">
            {isFetching ? (
              <Skeleton width={100} />
            ) : (
              <div className="flex justify-between items-center">
                <h3 className="uppercase text-gray-60 font-bold">
                  {t('common.details')}
                </h3>
                <Link
                  className="text-tertiary-dark font-bold"
                  to={
                    data?.meter?.last_change?.id
                      ? `/app/operations/anomalies/details/${data.meter.last_change.id}`
                      : '#'
                  }
                >
                  {t('assetsPage.seeLastChange')}
                </Link>
              </div>
            )}
          </div>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={120} />
            ) : (
              <p className="text-gray-60">{t('common.utilityType')}</p>
            )}
            <div>
              {isFetching ? (
                <Skeleton width={120} height={24} borderRadius={20} />
              ) : (
                <UtilityChip utility={data?.meter.utility_type} />
              )}
            </div>
          </DetailsRow>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={80} />
            ) : (
              <p className="text-gray-60">{t('assetsPage.table.meterType')}</p>
            )}
            {isFetching ? <Skeleton width={60} /> : <p>{'Main meter'}</p>}
          </DetailsRow>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={90} />
            ) : (
              <p className="text-gray-60">{t('common.description')}</p>
            )}
            {isFetching ? (
              <Skeleton width={70} />
            ) : (
              <p>{data?.meter.description ?? '-'}</p>
            )}
          </DetailsRow>
          <DetailsRow>
            {isFetching ? (
              <Skeleton width={80} />
            ) : (
              <p className="text-gray-60">{t('common.location')}</p>
            )}
            {isFetching ? (
              <Skeleton width={120} />
            ) : (
              <p>
                {data?.meter?.assets?.length
                  ? formatAddress(data?.meter.assets?.[0]?.address)
                  : '-'}
              </p>
            )}
          </DetailsRow>
        </div>
        <RelatableMeterContainer
          type="parents"
          meterId={meterId}
          data={parentMetersData?.meters}
          isEditing={currentlyEditing === 'parents'}
          currentlyEditing={currentlyEditing}
          isLoading={isFetchingParents}
          handleHierarchyOpen={handleHierarchyOpen}
          handleIsEditing={handleIsEditing}
          handleRelatableItemOnClick={handleRelatableItemOnClick}
        />

        <RelatableMeterContainer
          type="children"
          meterId={meterId}
          data={childrenMetersData?.meters}
          isEditing={currentlyEditing === 'children'}
          currentlyEditing={currentlyEditing}
          isLoading={isFetchingChildren}
          handleHierarchyOpen={handleHierarchyOpen}
          handleIsEditing={handleIsEditing}
          handleRelatableItemOnClick={handleRelatableItemOnClick}
        />

        <AccordionSection title={t('common.groups')} isLoading={isFetching}>
          {data?.meter?.assets?.map(
            (asset) => asset.type === 'GROUP' && createGroupAccordion(asset)
          )}
          {!foundGroup && (
            <Accordion
              title={t('common.empty.accordion', {
                value: t('common.groups').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>
        <AccordionSection title={t('common.buildings')} isLoading={isFetching}>
          {data?.meter?.assets?.map(
            (asset) =>
              asset.type === 'BUILDING' && createBuildingAccordion(asset)
          )}
          {!foundBuilding && (
            <Accordion
              title={t('common.empty.accordion', {
                value: t('common.buildings').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>
      </div>
      <HierarchyModal
        isOpen={isHierarchyOpen}
        handleClose={handleHierarchyClose}
        meterId={meterId}
        type={currentlyEditing}
      />
    </Modal>
  );
};
export default MeterDetailsModal;
