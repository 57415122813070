// import { useGetOrganizationImpactSummary } from 'api/impact/impactServices';
import { type FC } from 'react';
// import { useParams } from 'react-router-dom';
import { BuildingsMapContainer } from './components/BuildingsMapContainer';
import { DetailsOverview } from './components/DetailsOverview';
import { EnergyEvolutionSection } from './components/EnergyEvolutionSection';
import { OrganizationalSavingsContainer } from './components/OrganizationalSavingsContainer';
import { useTranslation } from 'react-i18next';

const ImpactDetails: FC = () => {
  const { t } = useTranslation();
  // const { id } = useParams();
  // const { data } = useGetOrganizationImpactSummary(id);
  return (
    <div className="flex flex-col gap-8">
      <h1>{t('impactPage.summaryTitle')}</h1>
      <DetailsOverview />
      <div className="flex gap-8">
        <div className="flex flex-1 min-w-1/2">
          <BuildingsMapContainer />
        </div>
        <div className="flex-1">
          <OrganizationalSavingsContainer />
        </div>
      </div>
      <EnergyEvolutionSection />
    </div>
  );
};
export default ImpactDetails;
