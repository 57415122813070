import { HeadlessModal } from 'components/modal';
import { t } from 'i18next';
import { Button, type ButtonProps } from 'layout/Button';
import { type FC } from 'react';

interface ConfirmModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen?: () => void;
  onConfirm: () => void | Promise<void>;
  isLoading?: boolean;
  contentText: string;
  actionBtns?: {
    primary?: ButtonProps;
    secondary?: ButtonProps;
  };
}

const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  onConfirm,
  isLoading,
  contentText,
  actionBtns,
}): JSX.Element => {
  return (
    <HeadlessModal
      isOpen={isOpen}
      handleClose={handleClose}
      minWidth={500}
      maxWidth={700}
    >
      <div className="flex flex-1 flex-col">
        <div className="flex mb-6">
          <span>{contentText}</span>
        </div>
        <div className="flex gap-4 justify-end">
          <Button
            onClick={handleClose}
            label={t('common.buttons.cancel')}
            variant="secondary"
            {...actionBtns?.secondary}
          />
          <Button
            onClick={onConfirm}
            label={t('common.buttons.confirm')}
            isLoading={isLoading}
            {...actionBtns?.primary}
          />
        </div>
      </div>
    </HeadlessModal>
  );
};

export default ConfirmModal;
