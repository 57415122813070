import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

const DetailsOverview: FC = () => {
  const { t } = useTranslation();
  return (
    <CardContainer gap={6}>
      <div className="flex flex-col gap-2">
        <h2 className="uppercase text-sm">
          {t('impactPage.organizationTitle')}
        </h2>
        <h3 className="text-base">
          Track the performance of all active buildings in your organization.
        </h3>
      </div>
      <div className="flex gap-6 justify-between items-center">
        <OverviewDataContainer title="Number of buildings" value={18} />
        <OverviewDataContainer title="Total surface (m₂)" value={19.434} />
        <OverviewDataContainer
          title="Organizational target in %"
          value={35}
          unit={'%'}
        />
        <OverviewDataContainer
          title="Total planned savings"
          value={22}
          unit={'%'}
        />
      </div>
    </CardContainer>
  );
};

const OverviewDataContainer: FC<{
  title: string;
  value?: number;
  unit?: string;
}> = ({ title, value, unit }) => {
  return (
    <div className="flex flex-1 flex-col gap-4 rounded-lg bg-gray-10 px-4 py-2.5">
      <p className="text-base">{title}</p>
      <span className="text-[28px] font-bold">
        {value}
        {unit}
      </span>
    </div>
  );
};
export default DetailsOverview;
