import { type FC, type SVGProps } from 'react';

const ThumbsDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="current"
    {...props}
  >
    <path
      d="M14.667 10H12.667L12.667 2L14.667 2C15.0352 2 15.3337 2.29848 15.3337 2.66667L15.3337 9.33333C15.3337 9.70153 15.0352 10 14.667 10ZM11.1384 10.8619L6.87139 15.1289C6.75413 15.2462 6.56842 15.2594 6.43571 15.1599L5.86733 14.7336C5.54419 14.4913 5.39859 14.0783 5.49823 13.6869L6.26711 10.6667H2.00033C1.26395 10.6667 0.666992 10.0697 0.666992 9.33333L0.666992 7.93047C0.666992 7.75627 0.701112 7.5838 0.767419 7.4228L2.83033 2.41283C2.93319 2.16303 3.17664 2 3.44679 2L10.667 2C11.0352 2 11.3337 2.29848 11.3337 2.66667L11.3337 10.3905C11.3337 10.5673 11.2634 10.7369 11.1384 10.8619Z"
      fill="current"
    />
  </svg>
);
export default ThumbsDownIcon;
