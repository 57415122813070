import { type FC, type SVGProps } from 'react';

const ElectricityIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="current"
    {...props}
  >
    <path
      d="M8.66699 6.66699H13.3337L7.33366 15.3337V9.33366H2.66699L8.66699 0.666992V6.66699Z"
      fill="current"
    />
  </svg>
);
export default ElectricityIcon;
