import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import { t } from 'i18next';
import {
  type AlertDefinitionListResponse,
  type AlertHistoryListResponse,
} from 'interfaces/alerts/Alert.interface';
// import { t } from 'i18next';
import queryClient from 'lib/queryClient';
import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';
import { type WeeklyReportResponse } from 'interfaces/luzmo/Luzmo.interface';

// export interface AlertDefinitionListResponse {
//   total_users: number;
//   users: AlertDefinitionResponse[];
// }
export const useListAlerts = ({
  page = 1,
  perPage = 20,
  query = '',
  orderBy = '',
}: {
  page?: number;
  perPage?: number;
  query?: string;
  orderBy?: string;
} = {}): UseInfiniteQueryResult<InfiniteData<AlertHistoryListResponse>> => {
  return useInfiniteQuery<AlertHistoryListResponse>({
    queryKey: ['listAlertDefinitions', query, orderBy],
    queryFn: async ({ pageParam }) => {
      interface Params {
        page: number;
        per_page: number;
        query?: string;
        order_by?: string;
      }

      const params: Params = {
        page: pageParam as number,
        per_page: perPage,
        query,
        order_by: orderBy,
      };

      if (!params.order_by) {
        delete params?.order_by;
      }

      const response = await axiosInstance.get(`change_detection/alerts`, {
        params,
      });

      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNextPage) {
        return lastPage.nextPage;
      }
    },
    refetchOnWindowFocus: false,
  });
};

export const useAlertDefinitionsList = ({
  page = 1,
  perPage = 20,
  query = '',
  status = '',
}: {
  page?: number;
  perPage?: number;
  query?: string;
  status?: string;
} = {}): UseInfiniteQueryResult<InfiniteData<AlertDefinitionListResponse>> => {
  return useInfiniteQuery<AlertDefinitionListResponse>({
    queryKey: ['listAlertDefinitions', query],
    queryFn: async ({ pageParam }) => {
      const response = await axiosInstance.get(`custom_event/list`, {
        params: {
          page: pageParam,
          per_page: perPage,
          query,
        },
      });

      return response.data;
    },
    initialPageParam: page,
    getNextPageParam: (lastPage) => {
      if (lastPage?.hasNextPage) {
        return lastPage.nextPage;
      }
    },
    refetchOnWindowFocus: false,
  });
};

export const useCreateAlertDefinition = (): UseMutationResult => {
  return useMutation({
    mutationFn: async (params) => {
      const response = await axiosInstance.post(`custom_event/`, params);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(
        t('alertsPage.settingsTab.alertDefinitions.toasters.create')
      );
      await queryClient.invalidateQueries({
        queryKey: ['listAlertDefinitions'],
      });
    },
  });
};

export const useUpdateWeeklyReport = (): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  { enableWeeklyReport: boolean },
  unknown
> => {
  return useMutation({
    mutationFn: async ({ enableWeeklyReport }) => {
      await axiosInstance.patch(`change_detection/reports/weekly`, {
        enable_weekly_report: enableWeeklyReport,
      });
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(t('alertsPage.settingsTab.weeklyReports.toasters.update'));
    },
  });
};

export const useGetWeeklyReport = (): UseQueryResult<WeeklyReportResponse> => {
  return useQuery({
    queryKey: ['getWeeklyReport'],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `change_detection/reports/weekly`
      );
      return response.data;
    },
  });
};

export const useDeleteAlertDefinition = (): UseMutationResult<
  void,
  Error | AxiosError<unknown, any>,
  string | number,
  unknown
> => {
  return useMutation({
    mutationFn: async (id: string | number) => {
      await axiosInstance.delete(`custom_event/${id}`);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess(
        t('alertsPage.settingsTab.alertDefinitions.toasters.delete')
      );
      await queryClient.invalidateQueries({
        queryKey: ['listAlertDefinitions'],
      });
    },
  });
};

export const useUpdateAlertDefinition = (
  id: number | null | undefined | string
): UseMutationResult => {
  return useMutation({
    mutationFn: async (params) => {
      if (!id) return;
      const response = await axiosInstance.patch(`custom_event/${id}`, params);
      return response.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      toast.success(
        t('alertsPage.settingsTab.alertDefinitions.toasters.update')
      );
      await queryClient.invalidateQueries({
        queryKey: ['alertDefinitions'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['listAlertDefinitions'],
      });
    },
  });
};
