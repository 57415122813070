const PASSWORD_LENGTH = 12;

const random = (min = 0, max = 1): number => {
  return Math.floor(Math.random() * (max + 1 - min) + min);
};

const randomLower = (): string => {
  return String.fromCharCode(random(97, 122));
};

const randomUpper = (): string => {
  return String.fromCharCode(random(65, 90));
};

const randomSymbol = (): string => {
  const symbols = "~*$%@#^&!?*'-=/,.{}()[]<>";
  return symbols[random(0, symbols.length - 1)];
};

const randomDigit = (): string => {
  return String.fromCharCode(random(48, 57));
};

const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const generatePassword = (): string => {
  let passwordArray = [
    randomLower(),
    randomUpper(),
    randomDigit(),
    randomSymbol(),
  ];
  while (passwordArray.length < PASSWORD_LENGTH) {
    const choice = random(0, 3);
    if (choice === 0) {
      passwordArray.push(randomLower());
    } else if (choice === 1) {
      passwordArray.push(randomUpper());
    } else if (choice === 2) {
      passwordArray.push(randomSymbol());
    } else if (choice === 3) {
      passwordArray.push(randomDigit());
    }
  }

  passwordArray = shuffleArray(passwordArray);
  return passwordArray.join('');
};
