import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import Tippy from '@tippyjs/react';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatAddress,
  stringToCapitalize,
} from 'utils/formatters/string/stringFormatter';

const useGetAssetsTableColumn = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): any => {
  const { t } = useTranslation();
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('name', {
      header: () => t('assetsPage.table.assetName'),
      cell: (info) => (
        <Tippy content={info.getValue()}>
          <span className="truncate phone:max-w-[250px] desktop:max-w-[300px] block">
            {info.renderValue()}
          </span>
        </Tippy>
      ),
      enableSorting: true,
      size: 180,
    }),
    columnHelper.accessor('type', {
      header: () => t('assetsPage.table.assetType'),
      cell: (info) => (
        <span className="min-w-[150px] block">
          {stringToCapitalize(info.renderValue()) ?? '-'}
        </span>
      ),
    }),
    columnHelper.accessor('function', {
      header: () => t('assetsPage.table.assetFunction'),
      cell: (info) => (
        <span className="min-w-[150px] block">{info.renderValue() ?? '-'}</span>
      ),
    }),
    columnHelper.accessor('address', {
      header: () => t('assetsPage.table.location'),
      cell: (info) => {
        const address = info.getValue();
        return <span>{address ? formatAddress(address) : '-'}</span>;
      },
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};
export default useGetAssetsTableColumn;
