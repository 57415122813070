import { MultiComboBoxInput } from '@components/inputs/ComboBox';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { Modal } from '@components/modal';
import {
  useFetchLinkableGroups,
  useFetchMeter,
  useUpdateMeter,
} from 'api/assets/assetsServices';
import { useDebounce } from 'hooks/useDebounce';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AddToGroupModalProps extends Disclosure {
  meterId?: number;
}

const AddToGroupModal: FC<AddToGroupModalProps> = ({
  isOpen,
  handleOpen,
  handleClose,
  meterId,
}) => {
  const [query, setQuery] = useState('');
  const [selectedGroups, setSelectedGroups] = useState<DropdownOptions[]>();
  const { t } = useTranslation();
  const debouncedQuery: string = useDebounce(query, 300);

  const { data: groupList } = useFetchLinkableGroups({
    id: meterId,
    query: debouncedQuery,
  });

  const { mutate: updateMeter, isPending, isSuccess } = useUpdateMeter(meterId);

  const { data: meterData } = useFetchMeter(meterId);

  const groupListOptions = useMemo(
    () =>
      groupList?.groups?.map((group) => ({
        name: group.name,
        value: group.id,
      })) ?? [],
    [groupList]
  );

  const handleUpdateMeter = (): void => {
    if (!meterData) return;

    const newGroupsId: { id: number }[] | undefined = selectedGroups?.map(
      (group) => ({
        id: group.value as number,
      })
    );

    if (!newGroupsId?.length) return;

    const currentGroupsId =
      meterData.meter.groups.map((group) => ({
        id: group?.id,
      })) ?? [];

    const body = {
      ...meterData.meter,
      groups: [...currentGroupsId, ...newGroupsId],
    };

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { groups, end_client_id, user_id } = body;

    updateMeter({ groups, end_client_id, user_id });
  };

  useEffect(() => {
    if (isSuccess) handleClose();
  }, [isSuccess]);

  return (
    <Modal
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      title={t('assetsPage.AddToGroupModal.title')}
      actionBtns={{
        primary: {
          label: t('common.buttons.add'),
          onClick: handleUpdateMeter,
          isLoading: isPending,
        },
        secondary: {
          label: t('common.buttons.cancel'),
        },
      }}
    >
      <h5 className="mb-4">{t('assetsPage.AddToGroupModal.body')}</h5>
      <MultiComboBoxInput
        query={query}
        handleQuery={setQuery}
        options={groupListOptions}
        isFetching={false}
        selectedOptionsContainerClasses="h-[150px]"
        value={selectedGroups}
        onChange={setSelectedGroups}
        placeholder={'Group name'}
      />
    </Modal>
  );
};
export default AddToGroupModal;
