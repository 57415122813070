export const FEATURE_FLAGS_LIST = {
  project: {
    create: {
      endpoint: '/v2/project/',
      method: 'POST',
      description: 'Hides the project creation button and modal',
    },
    delete: {
      endpoint: '/v2/project/<int:project_id>',
      method: 'DELETE',
      description: 'Hides the delete button and modal',
    },
    patch: {
      endpoint: '/v2/project/<int:project_id>',
      method: 'PATCH',
      description: 'Hides the edit button and modal',
    },
    get: {
      endpoint: '/v2/project/<int:project_id>',
      method: 'GET',
      description: 'Block details page',
    },
    list: {
      endpoint: '/v2/project/filter',
      method: 'GET',
      description: 'Block project page and hide side navigation button',
    },
    listDashboard: {
      endpoint: '/v2/project/filter/dashboard',
      method: 'GET',
      description: 'Block dashboard project list',
    },
  },
  changes: {
    list: {
      endpoint: '/v2/change_detection/filter',
      method: 'GET',
      description: 'Block changes page and side navigation button',
    },
    get: {
      endpoint: '/v2/change_detection/<int:change_id>',
      method: 'GET',
      description: 'Block change details page',
    },
    patch: {
      endpoint: '/v2/change_detection/<change_detection_id>',
      method: 'PATCH',
      description: 'Disables the lessons learned and root causes components',
    },
    patch_status: {
      endpoint: '/v2/change_detection/<change_detection_id>/status',
      method: 'PATCH',
      description: 'Disabled the update change status component',
    },
    post_activity: {
      endpoint: '/v2/change_detection/<change_detection_id>/activity',
      method: 'POST',
      description: 'Disable activity log post comment button',
    },
  },
  impact: {
    get: {
      endpoint: '/v2/organization_impact/summary',
      method: 'GET',
      description: 'Disable mocked impact list and summary',
    },
  },
};
