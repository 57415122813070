import { type FC } from 'react';
import { StyledStep } from './styled';
interface StepProps {
  activeStep: boolean;
  onClick: () => void;
}

const Step: FC<StepProps> = ({ activeStep, onClick }) => {
  return (
    <StyledStep
      onClick={onClick}
      $activeStep={activeStep}
      data-testid="navigate-step"
    />
  );
};
export default Step;
