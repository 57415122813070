import { type CSSProperties, type FC, type ReactNode } from 'react';
import { type FieldError } from 'react-hook-form';
import { addInputErrorClasses } from 'utils/handleFormErrorStyle';
import { Addorment, AddormentContainer } from './styled';

export interface InputAddormentProps {
  children?: ReactNode;
  error?: FieldError | undefined;
  addorment?: {
    startAddorment?: ReactNode;
    endAddorment?: ReactNode;
  };
  inputDisabled?: boolean;
  wrapperStyle?: CSSProperties;
}

const InputAddorment: FC<InputAddormentProps> = ({
  children,
  addorment,
  error,
  inputDisabled,
  wrapperStyle,
}) => {
  return (
    <Addorment
      $inputDisabled={inputDisabled}
      className={`form-input focus-within:border-primary-light ${
        error ? addInputErrorClasses(error) : 'border-gray-30'
      } `}
      style={wrapperStyle}
    >
      {addorment?.startAddorment && (
        <AddormentContainer className="pr-3">
          {addorment.startAddorment}
        </AddormentContainer>
      )}
      {children}
      {addorment?.endAddorment && (
        <AddormentContainer className="pl-3">
          {addorment?.endAddorment}
        </AddormentContainer>
      )}
    </Addorment>
  );
};
export default InputAddorment;
