import { FLabel } from '@components/form/FLabel';
import { MultiComboBoxInput } from '@components/inputs/ComboBox';
import { Modal } from '@components/modal';
import { SkeletonInput } from '@components/skeleton';
import {
  useAddMeterHierarchy,
  useFetchRelatableMeters,
} from 'api/assets/assetsServices';
import { t } from 'i18next';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC, useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface HierarchyModalProps extends Disclosure {
  meterId?: number;
  type?: 'children' | 'parents';
}

const HierarchyModal: FC<HierarchyModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  meterId,
  type,
}) => {
  const [relatableQuery, setRelatableQuery] = useState('');
  const [relatableMeter, setRelatableMeter] = useState<number | undefined>();

  const {
    data: relatableMetersData,
    isFetching,
    isSuccess,
  } = useFetchRelatableMeters({
    id: meterId,
    query: relatableQuery,
    relatable: type as string,
  });

  const { mutate, isPending } = useAddMeterHierarchy();

  const parentOptions = useMemo(() => {
    if (relatableMetersData?.meters) {
      return relatableMetersData.meters.map((meter) => ({
        value: meter.id,
        name: meter.name,
      }));
    }
    return [];
  }, [relatableMetersData]);

  const handleChange = (id: number): void => {
    setRelatableMeter(id);
  };

  const handleSubmit = (): void => {
    const body = {
      parent_id: type === 'parents' ? relatableMeter : meterId,
      child_id: type === 'children' ? relatableMeter : meterId,
    };
    mutate(body);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <Modal
      title={'Hierarchy'}
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: t('common.buttons.confirm'),
          onClick: handleSubmit,
          isLoading: isPending,
        },
        secondary: { label: t('common.buttons.cancel') },
      }}
      width={600}
    >
      <div className="flex flex-col gap-2 min-h-[215px]">
        {isFetching ? (
          <>
            <Skeleton width={150} />
            <SkeletonInput />
          </>
        ) : (
          <>
            <FLabel id={'relatable-dropdown'}>
              {type === 'parents'
                ? t('assetsPage.meterDetailsModal.parentMeter')
                : t('assetsPage.meterDetailsModal.childrenMeter')}
            </FLabel>
            <MultiComboBoxInput
              id={'relatable-dropdown'}
              options={parentOptions}
              query={relatableQuery}
              handleQuery={setRelatableQuery}
              isFetching={isFetching}
              maxSelected={1}
              placeholder={t('assetsPage.placeholders.meterDescription')}
              onChange={(option) => {
                if (option?.[0]?.value && typeof option[0].value === 'number') {
                  handleChange(option[0].value);
                }
              }}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
export default HierarchyModal;
