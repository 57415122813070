import { FErrorLabel } from 'components/form/FErrorLabel';
import { type FC, type TextareaHTMLAttributes, forwardRef } from 'react';
import { addTextErrorClasses } from 'utils/handleFormErrorStyle';
import InputAddorment, {
  type InputAddormentProps,
} from '../InputAddornment/InputAddorment';

export interface TextAreaInputProps
  extends InputAddormentProps,
    Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'name' | 'children'> {
  className?: string;
  id?: string;
}

const TextAreaInput: FC<TextAreaInputProps> = forwardRef(
  ({ addorment, id, className, error, disabled, ...props }, ref) => {
    return (
      <div className="flex-1">
        <InputAddorment
          error={error}
          addorment={addorment}
          inputDisabled={disabled}
        >
          <textarea
            id={id}
            className={`flex flex-1 ${className ?? ''} ${addTextErrorClasses(
              error
            )}`}
            disabled={disabled}
            {...props}
          />
        </InputAddorment>
        <FErrorLabel message={error?.message} />
      </div>
    );
  }
);

export default TextAreaInput;
