import { SwitchButton } from '@components/switchButton';
import {
  useGetWeeklyReport,
  useUpdateWeeklyReport,
} from 'api/alerts/alertsServices';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface WeeklyReportsProps {
  data?: any;
  isLoading?: boolean;
}

const WeeklyReports: FC<WeeklyReportsProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const [sendWeeklyReport, setSendWeeklyReport] = useState(false);
  const { data: weeklyReportData } = useGetWeeklyReport();
  const { mutate } = useUpdateWeeklyReport();

  const handleWeeklyReport = (): void => {
    setSendWeeklyReport(!sendWeeklyReport);
    mutate({ enableWeeklyReport: !sendWeeklyReport });
  };

  useEffect(() => {
    if (weeklyReportData) {
      setSendWeeklyReport(weeklyReportData.enable_weekly_report);
    }
  }, [weeklyReportData]);

  return (
    <div>
      <CardContainer>
        <CardTitle
          title={t('alertsPage.settingsTab.weeklyReports.title')}
          style={{ fontSize: 14 }}
        />
        <div className="pt-3">
          <SwitchButton
            label={'Monday report'}
            checked={sendWeeklyReport}
            onChange={handleWeeklyReport}
          />
          <p className="pt-3 pl-[53px]">
            {t('alertsPage.settingsTab.weeklyReports.body')}
          </p>
        </div>
      </CardContainer>
    </div>
  );
};

export default WeeklyReports;
