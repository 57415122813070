import {
  Listbox,
  ListboxButton,
  ListboxOption,
  Transition,
} from '@headlessui/react';
import {
  type ButtonHTMLAttributes,
  type FC,
  Fragment,
  type MouseEvent,
  type ReactNode,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IoIosArrowDown, IoIosArrowUp, IoIosClose } from 'react-icons/io';
import { CustomListboxOptions } from '../styled';
import { FErrorLabel } from 'components/form/FErrorLabel';
import { type InputAddormentProps } from '@components/inputs/InputAddornment/InputAddorment';
import { addInputErrorClasses } from 'utils/handleFormErrorStyle';
import { usePopper } from 'hooks/usePopper';
import SubOption from './SubOption';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { stringToCapitalize } from 'utils/formatters/string/stringFormatter';
import { useDebounce } from 'hooks/useDebounce';

export interface SubOptionsSelectInputProps
  extends InputAddormentProps,
    Omit<
      ButtonHTMLAttributes<HTMLButtonElement>,
      'name' | 'children' | 'onChange'
    > {
  options: DropdownSubOptions[];
  defaultValue?: string | number;
  placeholder?: string;
  dropdownClassName?: string;
  getSelectedName?: (value: DropdownSubOptions) => ReactNode;
  value?: string | number;
  onChange?: (value: string | number | undefined) => void;
  disabledOptions?: number[];
  componentRender?: (value: string | undefined) => ReactNode;
  maxWidth?: string;
}
export interface DropdownSubOptions {
  value: string | number;
  key: string | undefined;
  id?: number;
  startIcon?: ReactNode;
  dropdownOptions?: { name?: string; component?: ReactNode };
  sub_options?: DropdownSubOptions[];
}

const INITIAL_POSITION = {
  top: 0,
};

const SubOptionsSelectInput: FC<SubOptionsSelectInputProps> = forwardRef(
  (
    {
      error,
      options,
      defaultValue,
      dropdownClassName,
      placeholder,
      value,
      getSelectedName,
      onChange,
      componentRender,
      maxWidth,
      disabledOptions = [],
      disabled,
      ...props
    },
    ref
  ) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hoveredOption, setHoveredOption] = useState<
      string | number | undefined
    >();
    const [hoveredItemPosition, setHoveredItemPosition] =
      useState<typeof INITIAL_POSITION>();
    const [listPosition, setListPosition] = useState<typeof INITIAL_POSITION>();
    const [truncateAtTheStart, setTruncateAtTheStart] = useState(0);
    const [resizeContent, setResizeContent] = useState(0);
    const debouncedResizeContent = useDebounce(resizeContent, 150);
    const textRef = useRef(null);

    const [trigger, container] = usePopper({
      placement: 'bottom-end',
      modifiers: [{ name: 'applyStyles', options: { offset: [0, 10] } }],
    });

    const handleHoveredOption = (
      event: MouseEvent<HTMLElement>,
      value?: string | number
    ): void => {
      if (!value) {
        return;
      }
      setHoveredOption(value);

      const { top } = event.currentTarget.getBoundingClientRect();
      setHoveredItemPosition({ top });
    };

    const handleHoveredPos = (event: MouseEvent<HTMLElement>): void => {
      const { top } = event.currentTarget.getBoundingClientRect();
      setListPosition({ top });
    };

    const handleDropDown = (): void => {
      setIsDropdownOpen(!isDropdownOpen);
    };

    const handleOnChange = (value?: string | number): void => {
      onChange?.(value);
    };

    const hasSubOption = (option: DropdownSubOptions): boolean => {
      return !!(option?.sub_options?.length ?? 0);
    };

    const findObjectByKey = (
      arr: any[],
      key: string
    ): DropdownSubOptions | null => {
      for (const element of arr) {
        if (element.id === key) {
          return element;
        }
        if (element.sub_options.length > 0) {
          const found = findObjectByKey(element.sub_options, key);
          if (found) return found;
        }
      }
      return null;
    };

    const findOptionLabel = (
      arr: DropdownSubOptions[],
      id: number,
      path: string[] = []
    ): string[] | null => {
      for (const element of arr) {
        // If the current element's ID matches the target ID, return the path
        if (element.id === id) {
          path.push(element.value as string); // Add the current key to the path
          return path; // Return the path
        }

        // If the current element has sub-options, recursively search them
        if (element?.sub_options && element.sub_options?.length > 0) {
          path.push(element.value as string); // Add the current key to the path
          const result = findOptionLabel(element.sub_options, id, [...path]);
          if (result !== null) return result; // If a path is found, return it
          else path.pop(); // If not found, remove the current key from the path
        }
      }

      // If the target ID is not found, return null
      return null;
    };

    const selectedOption = useMemo(() => {
      return findObjectByKey(options, value as string);
    }, [options, value]);

    const parsedValue = useMemo(() => {
      if (selectedOption?.id) {
        const selectedOptionPath = findOptionLabel(options, selectedOption.id);
        if (selectedOptionPath && selectedOptionPath?.length > 0) {
          if (truncateAtTheStart > 0) {
            const newPath = selectedOptionPath;

            for (let i = 0; i < truncateAtTheStart; i++) {
              if (i + 1 < newPath.length) newPath[i] = '...';
            }

            return newPath.join(' > ');
          }
          return selectedOptionPath.join(' > ');
        }
      }
    }, [selectedOption, truncateAtTheStart]);

    useEffect(() => {
      if (!textRef.current) return;
      const resizeObserver = new ResizeObserver((entries) => {
        const component = textRef.current;
        const offsetHeight = (component as any)?.offsetHeight;
        const lineHeight = 20;
        const lines = offsetHeight / lineHeight;
        const currentWidth = entries[0].contentRect.width;

        if (lines >= 2 && currentWidth <= debouncedResizeContent) {
          setTruncateAtTheStart((prevValue) => prevValue + 1);
        } else if (currentWidth > debouncedResizeContent && lines < 2.0) {
          setTruncateAtTheStart((prevValue) =>
            prevValue > 0 ? prevValue - 1 : 0
          );
        }

        setResizeContent(currentWidth);
      });
      resizeObserver.observe(textRef.current);
      return () => {
        resizeObserver.disconnect();
      }; // clean up
    }, [debouncedResizeContent]);

    return (
      <div className={`flex-1 max-w-[${maxWidth ?? 'none'}]`}>
        <Listbox
          value={value}
          onChange={handleOnChange}
          ref={trigger}
          disabled={disabled}
        >
          <div className="relative w-full bg-white">
            <ListboxButton
              className={`relative w-full flex flex-1 items-center self-stretch px-3 py-2 border rounded-lg h-11 focus-within:border-primary-light
              ${error ? addInputErrorClasses(error) : 'border-gray-30'}
              `}
              onClick={handleDropDown}
              {...props}
            >
              <span className="flex items-center gap-2 justify-between w-full">
                <span className="flex items-center gap-2 flex-1 min-w-0">
                  {selectedOption && getSelectedName ? (
                    getSelectedName(selectedOption)
                  ) : (
                    <>
                      {!selectedOption && (
                        <span className="text-gray-400">{placeholder}</span>
                      )}
                      {selectedOption?.startIcon}
                      <span
                        className={`text-gray-500 flex flex-1 justify-between items-center min-w-0`}
                        // style={{ lineHeight: 20 }}
                        ref={textRef}
                      >
                        <span>{parsedValue}</span>
                        {parsedValue && (
                          <span>
                            <IoIosClose
                              size={22}
                              className="cursor-pointer text-primary"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleOnChange?.('');
                              }}
                            />
                          </span>
                        )}
                      </span>
                    </>
                  )}
                </span>
                {isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </ListboxButton>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="relative">
                <CustomListboxOptions
                  style={{ overflow: 'visible' }}
                  className={`z-50 relative ${dropdownClassName ?? ''}`}
                  ref={container}
                  onScroll={(e: MouseEvent<HTMLElement>) => {
                    handleHoveredPos(e);
                  }}
                >
                  <div className="overflow-auto max-h-[230px]">
                    {options.map((option, index) => {
                      const isDisabled =
                        disabledOptions?.length > 0 &&
                        disabledOptions?.some(
                          (disabledValue) => option.id === disabledValue
                        );
                      return (
                        <div key={index}>
                          <ListboxOption
                            key={option?.id ?? index}
                            className={({ focus }) =>
                              `cursor-default select-none z-40 ${
                                focus
                                  ? 'bg-primary-lighter font-bold text-gray-60'
                                  : 'text-gray-50 bg-white'
                              }`
                            }
                            disabled={isDisabled}
                            value={option.id}
                            data-testid="select-option"
                            data-value={option.id}
                            onMouseEnter={(e) => {
                              handleHoveredOption(e, option.id);
                            }}
                            onClick={(e) => {
                              if (option?.sub_options?.length) {
                                e.preventDefault();
                              }
                            }}
                          >
                            {({ selected, disabled }) => (
                              <div
                                className={`flex items-center justify-between gap-2 px-3 py-3 pr-4 cursor-pointer ${
                                  hoveredOption === option.id
                                    ? 'bg-primary-lighter'
                                    : ''
                                }`}
                                onClick={handleDropDown}
                                onMouseEnter={(e) => {
                                  handleHoveredOption(e, option.id);
                                }}
                              >
                                <div className="flex min-w-0 gap-2.5 items-center">
                                  {option.startIcon && (
                                    <span className="w-6 h-6 inline-block">
                                      {option.startIcon}
                                    </span>
                                  )}
                                  <span
                                    className={`inline-block truncate ${
                                      disabled ? 'text-gray-30' : ''
                                    } ${
                                      hoveredOption === option.id
                                        ? 'font-medium'
                                        : 'font-normal'
                                    }`}
                                  >
                                    {option?.dropdownOptions?.name ??
                                      stringToCapitalize(
                                        option.value as string
                                      )}
                                  </span>

                                  {hasSubOption(option) && (
                                    <SubOption
                                      value={option.id}
                                      onChange={handleOnChange}
                                      subOptions={option.sub_options ?? []}
                                      listPosition={listPosition}
                                      hoveredOption={hoveredOption}
                                      {...hoveredItemPosition}
                                    />
                                  )}
                                </div>
                                {hasSubOption(option) && (
                                  <MdOutlineKeyboardArrowRight size={18} />
                                )}
                              </div>
                            )}
                          </ListboxOption>
                        </div>
                      );
                    })}
                  </div>
                </CustomListboxOptions>
              </div>
            </Transition>
          </div>
        </Listbox>
        <FErrorLabel message={error?.message} />
      </div>
    );
  }
);
export default SubOptionsSelectInput;
