import { type RefObject, useEffect } from 'react';

const useClickOutside = (
  ref: RefObject<any>,
  onClickOutside: () => void
): void => {
  useEffect(() => {
    /**
     * Invoke Function onClick outside of element
     */
    const handleClickOutside = (event: Event): void => {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        onClickOutside();
      }
    };
    // Bind
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // dispose
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
export default useClickOutside;
