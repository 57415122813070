import { activateAccount } from 'api/auth/authService';
import { OnboardingContainer } from 'components/auth/OnboardingContainer';
import { FTextField } from 'components/form/FTextField';
import { useMultistep } from 'context/MultiStepProvider/MultiStepProvider';
import useHRForm from 'hooks/useHRForm';
import { Button } from 'layout/Button';
import { AuthRegisterFooter } from 'pages/Authentication/RegisterPage/components/AuthRegisterFooter';
import { type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StepFourInitialValues } from '../initialValue';
import { registerStepFourSchema } from '../schema';

const RegisterStepFour: FC = (props) => {
  const [parsedCode, setParsedCode] = useState('');
  const {
    methods: { watch },
    Form,
  } = useHRForm({
    schema: registerStepFourSchema,
    initialValues: StepFourInitialValues,
  });
  const { formData, navigate } = useMultistep();
  const { t } = useTranslation();

  const emailAddress: string = formData.email;
  const code = watch('code');

  const handleSubmit = async (): Promise<void> => {
    const response = await activateAccount({
      email: emailAddress,
      code: parsedCode,
    });
    if (response?.status === 200) {
      navigate('/login', { replace: true });
    }
  };

  useEffect(() => {
    if (code) {
      const codeWithoutSpaces = code.replace(/\s/g, '');
      setParsedCode(codeWithoutSpaces);
    }
  }, [code]);

  return (
    <OnboardingContainer
      heading={t('registerPage.stepFour.heading')}
      detailsHeading={t('registerPage.stepFour.detailsHeading', {
        emailAddress,
      })}
      detailsClassName="text-base font-normal text-gray-50"
    >
      <Form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <FTextField
            name={'code'}
            label={t('registerPage.stepFour.code')}
            placeholder={t('registerPage.stepFour.placeholders.enterCode')}
            data-testid="registration-code"
          />
        </div>
        <div className="flex flex-col gap-6 mt-8">
          <Button
            type="submit"
            size="large"
            label={t('registerPage.stepFour.buttons.confirmAccount')}
            className="w-full"
          />
          <Button
            type="submit"
            size="large"
            label={t('registerPage.stepFour.buttons.sendAgain')}
            className="w-full"
            variant="secondary"
          />
        </div>

        <div>
          <AuthRegisterFooter />
        </div>
      </Form>
    </OnboardingContainer>
  );
};
export default RegisterStepFour;
