import Tippy from '@tippyjs/react';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC } from 'react';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import { NumericFormat } from 'react-number-format';
import {
  type ImpactTextColorOptions,
  getImpactTextColorClassName,
} from 'utils/formatters/string/stringFormatter';

interface ProportionImpactWrapperProps {
  label: string;
  value?: number;
  target?: number;
  endAddorment: string;
  isLoading?: boolean;
  isMissingData?: boolean;
  maxWidth?: string;
  numericColorVisualization?: ImpactTextColorOptions;
}

const ProportionImpactWrapper: FC<ProportionImpactWrapperProps> = ({
  label,
  value,
  target,
  endAddorment,
  isLoading = false,
  isMissingData,
  maxWidth = '',
  numericColorVisualization = {},
  ...props
}) => {
  const { theme } = useTheme();

  const textColor = getImpactTextColorClassName(
    value,
    numericColorVisualization
  );

  return (
    <div
      className={
        'flex gap-4 py-2.5 px-4 bg-gray-10 rounded-lg items-center flex-1 ' +
        maxWidth
      }
    >
      {isLoading ? (
        <Skeleton containerClassName="flex-1" />
      ) : (
        <span className="text-base">{label}</span>
      )}
      <div
        className="flex justify-end flex-1 gap-1 font-bold text-gray-60 text-2xl"
        {...props}
      >
        {isLoading ? (
          <Skeleton containerClassName="flex-1" />
        ) : (
          <div className="flex gap-1.5 items-center">
            <NumericFormat
              className={textColor}
              displayType="text"
              value={value}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
            />
            {endAddorment} /
            <NumericFormat
              displayType="text"
              value={target}
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={0}
            />
            {endAddorment}
            {isMissingData && (
              <Tippy
                content={
                  'The value may be incorrectly calculated due to missing data. Contact an administrator for more details'
                }
                className="bg-red"
              >
                <span>
                  <HiQuestionMarkCircle color={theme.colors.error} size={20} />
                </span>
              </Tippy>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default ProportionImpactWrapper;
