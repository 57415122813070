import { TextAreaInput } from '@components/inputs/TextAreaInput';
import {
  useFetchActivityLog,
  usePostActivityLogComment,
} from 'api/changes/changesServices';
import { type ActivityLogs } from 'interfaces/activityLog/ActivityLog.interface';
import { Button } from 'layout/Button';
import {
  type ChangeEvent,
  type FC,
  type ReactNode,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import LogList from './LogList';
import { toast } from 'react-toastify';
import { handleError } from 'utils/handleError/handleError';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';

interface ActivityLogProps {
  changeId?: number;
  forceUpdate?: number;
  isLoading: boolean;
}

const createLogSkeleton = (): ReactNode => {
  const skeletonArray = Array(5).fill({});

  return skeletonArray.map((_, index) => (
    <div key={index} className="flex gap-4">
      <Skeleton height={'18px'} width={'18px'} borderRadius="100%" />
      <div>
        <Skeleton
          width={200 + (index % 2 === 0 ? index * 10 : index / 10)}
          height="18px"
        />
        <Skeleton width={'80px'} />
      </div>
    </div>
  ));
};

const MinActivityLog: FC<ActivityLogProps> = ({ changeId, isLoading }) => {
  const { data } = useFetchActivityLog(changeId);

  return (
    <div
      className="flex flex-col gap-4 mt-4"
      data-testid={'minimalist-activity-log'}
    >
      {isLoading ? createLogSkeleton() : <LogList data={data} type="min" />}
    </div>
  );
};

const ActivityLog: FC<ActivityLogProps> = ({ changeId, isLoading }) => {
  const [comment, setComment] = useState('');
  const { data } = useFetchActivityLog(changeId);
  const [logData, setLogData] = useState<ActivityLogs | undefined>();
  const { mutateAsync } = usePostActivityLogComment(changeId);
  const { t } = useTranslation();
  const { hasFeatureFlagPermission } = useFeatureFlag();

  useEffect(() => {
    if (data) setLogData(data);
  }, [data]);

  const handleCommentOnChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setComment(e.target.value);
  };

  const handlePostComment = async (): Promise<void> => {
    if (comment === '') {
      toast.error('Comment cannot be empty');
    } else {
      try {
        await mutateAsync({ comment });
        setComment('');
      } catch (e: any) {
        handleError(e);
      }
    }
  };

  return (
    <div className="flex flex-col gap-4 mt-4" data-testid={'activity-log'}>
      {isLoading ? createLogSkeleton() : <LogList data={logData} />}

      <div>
        {isLoading ? (
          <Skeleton containerClassName="flex-1" height={'160px'} />
        ) : (
          <TextAreaInput
            placeholder={t('anomaliesPage.activityLog.placeholders.addComment')}
            className="resize-none"
            rows={7}
            value={comment}
            onChange={handleCommentOnChange}
            disabled={
              !hasFeatureFlagPermission(
                FEATURE_FLAGS_LIST.changes.post_activity
              )
            }
          />
        )}
      </div>
      <div className="flex justify-end mt-2">
        {isLoading ? (
          <Skeleton width={'140px'} height={'40px'} borderRadius={20} />
        ) : (
          <Button
            type="button"
            label={t('anomaliesPage.activityLog.postComment')}
            onClick={handlePostComment}
            disabled={
              !hasFeatureFlagPermission(
                FEATURE_FLAGS_LIST.changes.post_activity
              )
            }
          />
        )}
      </div>
    </div>
  );
};
export { ActivityLog, MinActivityLog };
