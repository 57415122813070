import { TextAreaInput } from '@components/inputs/TextAreaInput';
import { type TextAreaInputProps } from '@components/inputs/TextAreaInput/TextAreaInput';
import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FLabel } from '../FLabel';

interface FTextAreaFieldProps extends TextAreaInputProps {
  label?: string;
  name: string;
}

const FTextAreaField: FC<FTextAreaFieldProps> = ({
  label,
  name,
  id,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="flex flex-col gap-1.5">
        {label && <FLabel id={id}>{label}</FLabel>}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => <TextAreaInput id={id} {...field} {...props} />}
      />
    </div>
  );
};
export default FTextAreaField;
