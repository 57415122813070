import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { type FC, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import generateRoutes from '../generateRoutes';

const Router: FC = () => {
  const token = localStorage.getItem('jwtToken');
  const { hasFeatureFlagPermission } = useFeatureFlag();

  const isAuthenticated = useMemo(() => !!token, [token]);

  return useRoutes(generateRoutes(isAuthenticated, hasFeatureFlagPermission));
};

export default Router;
