import { StatusEnum } from 'enums/StatusEnum';
import i18next from '../../i18n';

export const getStatusReadableName = (status: StatusEnum): string => {
  switch (status) {
    case StatusEnum.NEW:
      return i18next.t('common.status.new');
    case StatusEnum.IDENTIFYING:
      return i18next.t('common.status.identifying');
    case StatusEnum.CLOSED:
      return i18next.t('common.status.closed');
    case StatusEnum.PROBLEM_DETECTED:
      return i18next.t('common.status.problemsDetected');
    case StatusEnum.SOLVING:
      return i18next.t('common.status.solving');
    case StatusEnum.SOLVED:
      return i18next.t('common.status.solved');
    case StatusEnum.TO_BE_IDENTIFIED:
      return i18next.t('common.status.toBeIdentified');
    case StatusEnum.ARCHIVED:
      return i18next.t('common.status.archived');
    default:
      return 'Unknown';
  }
};

export const getStatusKeyByValue = <T extends Record<string, string>>(
  value: string
): keyof T => {
  return Object.keys(StatusEnum).find(
    (key) => (StatusEnum as unknown as T)[key] === value
  ) as keyof T;
};

export const getStatusTailwindColor = (status: StatusEnum): string => {
  switch (status) {
    case StatusEnum.NEW:
      return 'bg-status-new';
    case StatusEnum.IDENTIFYING:
      return 'bg-status-identifying';
    case StatusEnum.CLOSED:
      return 'bg-status-closed';
    case StatusEnum.PROBLEM_DETECTED:
      return 'bg-status-problems-detected';
    case StatusEnum.SOLVING:
      return 'bg-status-solving';
    case StatusEnum.SOLVED:
      return 'bg-status-solved';
    case StatusEnum.TO_BE_IDENTIFIED:
      return 'bg-status-to-be-identified';
    case StatusEnum.ARCHIVED:
      return 'bg-status-archived';
    default:
      return 'bg-gray-20';
  }
};
