import axiosInstance from 'api/axios';
import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { handleError } from 'utils/handleError/handleError';
import { type AxiosError } from 'axios';
import queryClient from 'lib/queryClient';
import { type CompanyResponse } from 'interfaces/company/Company.interface';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';

export const useFetchCompany = (): UseQueryResult<CompanyResponse> => {
  return useQuery<CompanyResponse>({
    queryKey: ['company'],
    queryFn: async () => {
      const response = await axiosInstance.get('company/');
      return response.data;
    },
  });
};

export const useUpdateCompany = (): UseMutationResult => {
  return useMutation({
    mutationFn: async (params) => {
      await axiosInstance.patch('company/', params);
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      handleSuccess('Company updated successfully');
      await queryClient.invalidateQueries({ queryKey: ['company'] });
    },
  });
};
