import { ListboxOption, ListboxOptions } from '@headlessui/react';
import {
  type CSSProperties,
  type FC,
  type MouseEvent,
  type ReactNode,
  useMemo,
  useState,
} from 'react';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { stringToCapitalize } from 'utils/formatters/string/stringFormatter';
import { type DropdownSubOptions } from './SubOptionsSelectInput';

interface SubOptionProps {
  value?: string | number;
  subOptions: DropdownSubOptions[];
  top?: number;
  listPosition?: { top?: number };
  componentRender?: (value?: string | number) => ReactNode;
  hoveredOption?: string | number;
  onChange: (value?: string | number) => void;
}

const SubOption: FC<SubOptionProps> = ({
  value,
  subOptions,
  top,
  listPosition,
  componentRender,
  hoveredOption,
  onChange,
}) => {
  const [hoveredSubOption, setHoveredSubOption] = useState<
    number | undefined
  >();

  const style = useMemo((): CSSProperties => {
    if (!top || !listPosition?.top)
      return {
        position: 'absolute',
        left: `calc(100% + 8px)`,
      };
    const newTop = top - listPosition?.top;

    return {
      position: 'absolute',
      top: `${newTop}px`,
      left: `calc(100% + 8px)`,
    };
  }, [top, listPosition]);

  const handleHoveredOption = (
    event: MouseEvent<HTMLElement>,
    value?: number
  ): void => {
    if (value) {
      setHoveredSubOption(value);
    }
  };

  return hoveredOption === value ? (
    <div style={style}>
      <ListboxOptions
        style={{ overflow: 'visible' }}
        className={`z-50 relative rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5`}
      >
        <div className="overflow-auto max-h-[350px]">
          {subOptions.map((option, index) => (
            <ListboxOption
              key={option?.id ?? index}
              className={({ focus }) =>
                `cursor-default select-none z-40 ${
                  focus
                    ? 'bg-primary-lighter font-bold text-gray-60'
                    : 'text-gray-50 bg-white'
                }`
              }
              value={option?.id}
              data-testid="select-option"
              data-value={option?.id}
              onMouseEnter={(e) => {
                handleHoveredOption(e, option?.id);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                if (option?.sub_options?.length) {
                  return;
                }
                onChange(option.id);
              }}
            >
              {({ selected, disabled }) => (
                <div
                  className={`flex items-center justify-between gap-2 px-3 py-3 pr-4 cursor-pointer ${
                    hoveredSubOption === option.id ? 'bg-primary-lighter' : ''
                  }`}
                >
                  <div className="flex min-w-0 gap-2.5 items-center">
                    <span
                      className={`inline-block truncate ${
                        disabled ? 'text-gray-30' : ''
                      } ${
                        hoveredSubOption === option.id
                          ? 'font-medium'
                          : 'font-normal'
                      }`}
                    >
                      {option?.dropdownOptions?.name ??
                        stringToCapitalize(option?.value as string)}
                    </span>
                    {option?.sub_options && (
                      <SubOption
                        value={option.id}
                        onChange={onChange}
                        subOptions={option.sub_options}
                        listPosition={listPosition}
                        componentRender={componentRender}
                        hoveredOption={hoveredSubOption}
                        top={top}
                      />
                    )}
                  </div>
                  {option?.sub_options && option?.sub_options?.length > 0 && (
                    <MdOutlineKeyboardArrowRight size={18} />
                  )}
                </div>
              )}
            </ListboxOption>
          ))}
        </div>
      </ListboxOptions>
    </div>
  ) : (
    <></>
  );
};
export default SubOption;
