import { type FC, type SVGProps } from 'react';

const ImpactIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="current"
    {...props}
  >
    <path
      d="M12 0L12.001 3.06201C15.6192 3.51365 18.4869 6.38163 18.9381 10H22V12L18.938 12.001C18.4864 15.6189 15.6189 18.4864 12.001 18.938L12 22H10V18.9381C6.38163 18.4869 3.51365 15.6192 3.06201 12.001L0 12V10H3.06189C3.51312 6.38129 6.38129 3.51312 10 3.06189V0H12ZM11 9C9.8954 9 9 9.8954 9 11C9 12.1046 9.8954 13 11 13C12.1046 13 13 12.1046 13 11C13 9.8954 12.1046 9 11 9Z"
      fill={'current'}
    />
  </svg>
);
export default ImpactIcon;
