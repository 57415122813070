import { SearchBar } from '@components/inputs/SearchBar';
import { useDebounce } from 'hooks/useDebounce';
import { type ChangeEvent, type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertsHistoryTable } from './tables/AlertsHistoryTable';

const AlertsHistoryPage: FC = () => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const { t } = useTranslation();

  const handleSearchOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  return (
    <div>
      <div className="flex gap-4 my-6 w-full justify-between items-center">
        <div className="flex-[1.5_1_0%] max-w-[270px]">
          <SearchBar
            placeholder={t('common.placeholders.typeToSearch')}
            onChange={handleSearchOnChange}
          />
        </div>
      </div>
      <div>
        <AlertsHistoryTable query={debouncedQuery} />
      </div>
    </div>
  );
};
export default AlertsHistoryPage;
