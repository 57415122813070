import { InfoIcon } from '@components/icons/InfoIcon';
import Tippy from '@tippyjs/react';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, type ReactNode } from 'react';
import { ReportCard } from './styled';

interface SelectableReportCardProps {
  icon: ReactNode;
  tooltipMessage: string;
  description: string;
  isSelected: boolean;
  value: string;
  onClick: (value: string) => void;
}

const SelectableReportCard: FC<SelectableReportCardProps> = ({
  icon,
  tooltipMessage,
  description,
  isSelected,
  value,
  onClick,
}) => {
  const { theme } = useTheme();
  return (
    <ReportCard
      $isSelected={isSelected}
      onClick={() => {
        onClick(value);
      }}
    >
      <div className="flex justify-between items-center">
        <span>{icon}</span>
        <Tippy content={tooltipMessage}>
          <span>
            <InfoIcon fill={theme.colors['gray-40']} />
          </span>
        </Tippy>
      </div>
      <div>
        <p>{description}</p>
      </div>
    </ReportCard>
  );
};
export default SelectableReportCard;
