import { type FC, type SVGProps } from 'react';

const InfoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="#BDBDBD"
    {...props}
  >
    <g clipPath="url(#clip0_973_38472)">
      <path
        d="M8.00016 1.33398C4.32016 1.33398 1.3335 4.32065 1.3335 8.00065C1.3335 11.6807 4.32016 14.6673 8.00016 14.6673C11.6802 14.6673 14.6668 11.6807 14.6668 8.00065C14.6668 4.32065 11.6802 1.33398 8.00016 1.33398ZM8.66683 11.334H7.3335V7.33398H8.66683V11.334ZM8.66683 6.00065H7.3335V4.66732H8.66683V6.00065Z"
        fill="current"
      />
    </g>
    <defs>
      <clipPath id="clip0_973_38472">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default InfoIcon;
