import React, {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

interface LocationPathNameContextType {
  locationPathnames: string[];
  getPreviousPageLocation: () => string | undefined;
}

const LocationPathNameContext =
  createContext<LocationPathNameContextType | null>(null);

export const useLocationPathname = (): LocationPathNameContextType => {
  const context = useContext(LocationPathNameContext);
  if (!context) {
    throw new Error('No context found');
  }
  return context;
};

export const LocationPathNameProvider = ({
  children,
}: {
  children: ReactNode;
}): React.JSX.Element => {
  const [locationPathnames, setLocationPathnames] = useState<string[]>(() => {
    const storedPathnames = sessionStorage.getItem('locationPathnames');
    return storedPathnames ? JSON.parse(storedPathnames) : [];
  });
  const location = useLocation();

  useEffect(() => {
    setLocationPathnames((prevPathnames: string[]) => {
      const isNewPathname =
        location.pathname !== prevPathnames?.[prevPathnames?.length - 1];
      if (isNewPathname) {
        const updatedPathnames = [
          ...prevPathnames.slice(-4),
          location.pathname,
        ];
        sessionStorage.setItem(
          'locationPathnames',
          JSON.stringify(updatedPathnames)
        );
        return updatedPathnames;
      }
      return prevPathnames;
    });
  }, [location.pathname]);

  const getPreviousPageLocation = (): string | undefined => {
    return locationPathnames?.[locationPathnames?.length - 2] ?? undefined;
  };

  return (
    <LocationPathNameContext.Provider
      value={{ locationPathnames, getPreviousPageLocation }}
    >
      {children}
    </LocationPathNameContext.Provider>
  );
};
