import { useEffect, useState } from 'react';

const usePageHasFocus = ({
  onFocus,
}: {
  onFocus?: () => void | Promise<void>;
}): { tabHasFocus: boolean } => {
  const [tabHasFocus, setTabHasFocus] = useState(true);

  useEffect(() => {
    const handleFocus = (): void => {
      setTabHasFocus(true);
    };

    const handleBlur = (): void => {
      setTabHasFocus(false);
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    // Clean up
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  useEffect(() => {
    if (onFocus) {
      const handleFocus = async (): Promise<void> => {
        if (onFocus) {
          await onFocus();
        }
      };

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleFocus();
    }
  }, [tabHasFocus]);

  return { tabHasFocus };
};
export default usePageHasFocus;
