import { z } from 'zod';

export const changePasswordSchema = z
  .object({
    currentPassword: z
      .string()
      .min(10, 'Password must be at least 10 characters long')
      .regex(/[A-Z]/, 'Password must contain at least one capital letter')
      .regex(
        /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
        'Password must contain at least one special character'
      ),
    newPassword: z
      .string()
      .min(10, 'Password must be at least 10 characters long')
      .regex(/[A-Z]/, 'Password must contain at least one capital letter')
      .regex(
        /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
        'Password must contain at least one special character'
      ),
    confirmNewPassword: z
      .string()
      .min(10, 'Password must be at least 10 characters long')
      .regex(/[A-Z]/, 'Password must contain at least one capital letter')
      .regex(
        /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
        'Password must contain at least one special character'
      ),
  })
  .superRefine(({ confirmNewPassword, newPassword }, ctx) => {
    if (confirmNewPassword !== newPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'The passwords did not match',
        path: ['confirmNewPassword'],
      });
    }
  });

interface changePasswordControlNamesType {
  currentPassword: 'currentPassword';
  newPassword: 'newPassword';
  confirmNewPassword: 'confirmNewPassword';
}

export const changePasswordControlNames: changePasswordControlNamesType = {
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  confirmNewPassword: 'confirmNewPassword',
};

export type ChangePasswordSchemaType = z.infer<typeof changePasswordSchema>;
