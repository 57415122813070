import { type FC } from 'react';
import { Switch } from '@headlessui/react';

interface SwitchButtonProps {
  label?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (e: boolean) => void | Promise<void>;
}

const SwitchButton: FC<SwitchButtonProps> = ({
  label,
  checked,
  defaultChecked,
  onChange,
}) => {
  return (
    <div className="flex items-center gap-4">
      <Switch
        checked={checked}
        defaultChecked={defaultChecked}
        onChange={onChange}
        className={`data-[checked]:bg-tertiary-dark bg-gray-40 relative inline-flex h-[20px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        {({ checked }) => (
          <span
            aria-hidden="true"
            className={`${checked ? 'translate-x-4' : 'translate-x-0'}
            pointer-events-none inline-block h-[16px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        )}
      </Switch>
      <label className="text-base">{label}</label>
    </div>
  );
};
export default SwitchButton;
