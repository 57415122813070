import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import { type FC } from 'react';
import {
  getUtilityIcon,
  getUtilityTypeReadableName,
} from 'utils/utilityTypeUtils/utilityTypeUtils';

interface UtilityChipProps {
  utility?: string;
}

function isUtilityValid(value?: string): value is UtilityTypeEnum {
  return Object.values(UtilityTypeEnum).includes(value as UtilityTypeEnum);
}

const UtilityChip: FC<UtilityChipProps> = ({ utility }) => {
  if (!isUtilityValid(utility)) {
    console.error('"utility" props is not a valid value', utility);
    return <></>;
  }
  return (
    <div className="flex bg-primary rounded-full px-3 py-1 items-center justify-between text-white gap-1.5">
      <div className="flex">{getUtilityIcon(utility, 'white')}</div>
      <span className="w-max">{getUtilityTypeReadableName(utility)}</span>
    </div>
  );
};
export default UtilityChip;
