export const ROLES = [
  { name: 'Administrator', value: 'ADMINISTRATOR' },
  { name: 'Manager', value: 'MANAGER' },
  { name: 'Responsible', value: 'RESPONSIBLE' },
  { name: 'Reader', value: 'READER' },
];

export const FUNCTIONS_FILTER = [
  { name: 'Energy manager', value: 'ENERGY_MANAGER' },
  { name: 'Head of facility manager', value: 'HEAD_OF_FACILITY' },
  { name: 'Energy manager', value: 'ENERGY_MANAGER' },
  { name: 'Head of facility manager', value: 'HEAD_OF_FACILITY_MANAGER' },
  { name: 'Energy consultant', value: 'ENERGY_CONSULTANT' },
  { name: 'Facility manager', value: 'FACILITY_MANAGER' },
  { name: 'Technical manager', value: 'TECHNICAL_MANAGER' },
  { name: 'Technical expert', value: 'TECHNICAL_EXPERT' },
  { name: 'Project manager', value: 'PROJECT_MANAGER' },
  { name: 'Head of energy manager', value: 'HEAD_OF_ENERGY_MANAGER' },
  { name: 'Sustainability manager', value: 'SUSTAINABILITY_MANAGER' },
];

export const ASSET_FUNCTIONS = [
  { name: 'School', value: 'SCHOOL' },
  { name: 'Hospital', value: 'HOSPITAL' },
];

export const ASSET_TYPES = [
  { name: 'Building', value: 'BUILDING' },
  { name: 'Technical installation', value: 'TECHNICAL_INSTALLATION' },
];

export const LANGUAGES = [
  {
    name: 'English',
    value: 'EN',
  },
  { name: 'Dutch', value: 'NL' },
  { name: 'French', value: 'FR' },
];

export const USER_FUNCTIONS = [
  { name: 'Energy manager', value: 'ENERGY MANAGER' },
  { name: 'Head of facility manager', value: 'HEAD OF FACILITY MANAGER' },
  { name: 'Energy consultant', value: 'ENERGY CONSULTANT' },
  { name: 'Facility manager', value: 'FACILITY MANAGER' },
  { name: 'Technical manager', value: 'TECHNICAL MANAGER' },
  { name: 'Technical expert', value: 'TECHNICAL EXPERT' },
  { name: 'Project manager', value: 'PROJECT MANAGER' },
  { name: 'Head of energy manager', value: 'HEAD OF ENERGY MANAGER' },
  { name: 'Sustainability manager', value: 'SUSTAINABILITY MANAGER' },
];

export const UTILITY_DATA = {
  ELECTRICITY: [{ name: 'kWh', value: 'KWH' }],
  GAS: [
    { name: 'kWh', value: 'KWH' },
    { name: 'm³', value: 'CUBIC_METER' },
  ],
  WATER: [
    { name: 'l', value: 'LITER' },
    { name: 'm³', value: 'CUBIC_METER' },
  ],
  THERMAL_ENERGY: [{ name: 'kWh', value: 'KWH' }],
  UNDEFINED: [{ name: 'undefined', value: 'UNDEFINED' }],
};

export const UTILITY_TYPE = [
  { name: 'Electricity', value: 'ELECTRICITY' },
  { name: 'Gas', value: 'GAS' },
  { name: 'Water', value: 'WATER' },
  { name: 'Thermal energy', value: 'THERMAL_ENERGY' },
];

export const UTILITY_UNIT = [
  { name: 'kWh', value: 'KWH' },
  { name: 'l', value: 'LITER' },
  { name: 'm³', value: 'CUBIC_METER' },
  { name: 'undefined', value: 'UNDEFINED' },
];

export const OPERATORS = [
  { name: 'Greater than', value: 'gt' },
  { name: 'Greater or equal', value: 'ge' },
  { name: 'Less than', value: 'lt' },
  { name: 'Less or equal', value: 'le' },
  { name: 'Not equal', value: 'neq' },
];

export const ALERT_THRESHOLDS = [
  { name: 'Daily impact', value: 'impact_average_diff_euro' },
  { name: 'Total impact', value: 'excess_total' },
];

export const STATUS_LIST = [
  { name: 'New', value: 'NEW ' },
  { name: 'To be identified', value: 'TO_BE_IDENTIFIED' },
  { name: 'Identifying', value: 'IDENTIFYING' },
  { name: 'Problem detected', value: 'PROBLEM_DETECTED' },
  { name: 'Solving', value: 'SOLVING' },
  { name: 'Solved', value: 'SOLVED' },
  { name: 'Closed', value: 'CLOSED' },
  { name: 'Archived', value: 'ARCHIVED' },
];
