import styled from 'styled-components';
import tw from 'twin.macro';

interface SelectedItemProps {
  $index: number;
  $length: number;
}

export const SelectedItem = styled.div<SelectedItemProps>`
  ${tw`flex justify-between py-3 border-b border-gray-10`}
  ${(props) => props.$length === props.$index + 1 && tw`border-none`}
`;

export const SelectedOptionsContainer = styled.div`
  ${tw`flex flex-col px-5 mt-1 `}
`;
