import { FTextField } from 'components/form/FTextField';
import { useMultistep } from 'context/MultiStepProvider/MultiStepProvider';
import useHRForm from 'hooks/useHRForm';
import { Button } from 'layout/Button';
import { AuthRegisterFooter } from 'pages/Authentication/RegisterPage/components/AuthRegisterFooter';
import { RegisterContainer } from 'pages/Authentication/RegisterPage/components/RegisterContainer';
import { type FC } from 'react';
import { registerControlNames, registerStepThreeSchema } from '../schema';
import SelectAddressCountry from '../../components/SelectAddressCountry/SelectAddressCountry';
import { StepThreeInitialValues } from '../initialValue';
import { useTranslation } from 'react-i18next';
import { registerUser } from 'api/auth/authService';

const RegisterStepThree: FC = () => {
  const {
    methods: { getValues, setValue },
    Form,
  } = useHRForm({
    schema: registerStepThreeSchema,
    initialValues: StepThreeInitialValues,
  });

  const { goToNextStep, setIsLoadingNextStep, isLoadingNextStep, formData } =
    useMultistep();
  const { t } = useTranslation();

  const handleSubmit = async (): Promise<void> => {
    setIsLoadingNextStep(true);
    const companyData = getValues();
    const userData = { ...formData, ...companyData };

    const response = await registerUser(userData);

    setIsLoadingNextStep(false);

    if (response?.status === 201) {
      goToNextStep(userData);
    }
  };

  const handleCountryChange = (value: string): void => {
    setValue(registerControlNames.company.address.country, value);
  };

  return (
    <RegisterContainer>
      <Form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <div>
            <h5 className="font-bold mb-2 uppercase">
              {t('registerPage.stepThree.companyInfo')}
            </h5>
            <FTextField
              name={registerControlNames.company.name}
              label={t('common.inputs.name')}
              placeholder={t(
                'registerPage.stepThree.placeholders.enterCompany'
              )}
              data-testid="company-name"
            />
          </div>

          <FTextField
            name={registerControlNames.company.vat}
            label={t('common.inputs.vat')}
            placeholder={t('registerPage.stepThree.placeholders.enterVat')}
            data-testid="company-vat"
          />
          <h5 className="font-bold">{t('common.address').toUpperCase()}</h5>

          <div className="flex gap-4">
            <div className="flex w-6/12">
              <SelectAddressCountry onChange={handleCountryChange} />
            </div>
            <div className="flex w-6/12">
              <FTextField
                name={registerControlNames.company.address.zipCode}
                placeholder={t('registerPage.stepThree.placeholders.zipCode')}
                data-testid="company-zip"
              />
            </div>
          </div>

          <div className="flex gap-4">
            <FTextField
              name={registerControlNames.company.address.street}
              placeholder={t('common.placeholders.street')}
              data-testid="company-street"
            />
            <FTextField
              name={registerControlNames.company.address.number}
              placeholder={t('common.placeholders.number')}
              data-testid="company-number"
            />
          </div>
          <div className="flex gap-4">
            <FTextField
              name={registerControlNames.company.address.city}
              placeholder={t('registerPage.stepThree.placeholders.city')}
              data-testid="company-city"
            />
            <FTextField
              name={registerControlNames.company.address.province}
              placeholder={t('registerPage.stepThree.placeholders.province')}
              data-testid="company-province"
            />
          </div>
        </div>

        <Button
          type="submit"
          size="large"
          label={t('common.buttons.continue')}
          className="w-full mt-8"
          isLoading={isLoadingNextStep}
        />
        <div>
          <AuthRegisterFooter />
        </div>
      </Form>
    </RegisterContainer>
  );
};

export default RegisterStepThree;
