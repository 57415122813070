import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type ResetPasswordSchemaType,
  resetPasswordControlNames,
  resetPasswordSchema,
} from './schema';
import { PasswordField } from '@components/auth/PasswordField';
import { Button } from 'layout/Button';
import useHRForm from 'hooks/useHRForm';
import { ResetPasswordFormInitialValues } from './initialValues';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from 'api/auth/authService';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

const ResetPasswordForm: FC = () => {
  Mixpanel.pageview();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const [isLoading, setIsLoading] = useState(false);
  const { Form } = useHRForm({
    schema: resetPasswordSchema,
    initialValues: ResetPasswordFormInitialValues,
  });
  const onSubmit = async (data: ResetPasswordSchemaType): Promise<void> => {
    setIsLoading(true);
    const response = await resetPassword(data, token);
    setIsLoading(false);
    if (response?.status === 200) {
      localStorage.removeItem('jwtToken');

      navigate('/login');
    }
  };
  return (
    <div data-testid="reset-password-form">
      <Form onSubmit={onSubmit}>
        <div className="flex flex-col gap-6">
          <div>
            <p>{t('common.inputs.newPassword')}</p>
            <PasswordField
              placeholder={t('common.placeholders.createPassword')}
              name={resetPasswordControlNames.newPassword}
              data-testid="new-password"
            />
          </div>
          <div>
            <p>{t('common.inputs.confirmNewPassword')}</p>
            <PasswordField
              placeholder={t('common.placeholders.confirmNewPassword')}
              name={resetPasswordControlNames.confirmNewPassword}
              data-testid="confirm-new-password"
            />
          </div>
        </div>
        <div className="flex gap-6">
          <Button
            className="w-full mt-lg"
            type="submit"
            label={t('common.buttons.createPassword')}
            size="large"
            isLoading={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;
