import { SubOptionsSelectInput } from '@components/inputs/SelectInput/SubOptionsSelectInput';
import {
  useFetchRootCauses,
  useUpdateChange,
} from 'api/changes/changesServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { t } from 'i18next';
import { Button } from 'layout/Button';
import { CardContainer, CardTitle } from 'layout/Card';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type FC, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

interface RootCauseProps {
  rootCauseId?: number;
  changeId: string | undefined;
  isLoading?: boolean;
}

const RootCause: FC<RootCauseProps> = ({
  rootCauseId,
  changeId,
  isLoading,
}) => {
  const {
    mutate,
    isPending: isUpdateChangeLoading,
    isSuccess,
  } = useUpdateChange(changeId);
  const { data } = useFetchRootCauses();
  const { hasFeatureFlagPermission } = useFeatureFlag();
  const [selectedRootCause, setSelectedRootCause] = useState<
    number | undefined
  >(rootCauseId);

  const onSubmit = (): void => {
    const body = {
      root_cause_id: selectedRootCause,
    };
    mutate(body);
  };

  useEffect(() => {
    if (rootCauseId) setSelectedRootCause(rootCauseId);
  }, [rootCauseId]);

  useEffect(() => {
    if (isSuccess) {
      Mixpanel.track('root_cause_updated', {
        root_cause: selectedRootCause,
        change_id: changeId,
      });
    }
  }, [isSuccess]);

  return (
    <CardContainer>
      <CardTitle
        title={t('anomaliesDetailsPage.rootCause.title')}
        subtitle={t('anomaliesDetailsPage.rootCause.subTitle')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
        skeletonVariant="subtitle"
      />

      <>
        <div className="mt-4">
          {isLoading ? (
            <Skeleton borderRadius={'8px'} height={'40px'} />
          ) : (
            <SubOptionsSelectInput
              options={data ?? []}
              placeholder={t('anomaliesDetailsPage.select.selectLabel')}
              value={selectedRootCause}
              onChange={(value) => {
                setSelectedRootCause(value as number);
              }}
              disabled={
                !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch)
              }
            />
          )}
        </div>
        <div className="flex justify-end pt-6">
          {isLoading ? (
            <Skeleton width={'140px'} borderRadius={'20px'} height={'40px'} />
          ) : (
            <Button
              type="button"
              label={t('common.buttons.send')}
              isLoading={isUpdateChangeLoading}
              onClick={onSubmit}
              disabled={
                !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch)
              }
            />
          )}
        </div>
      </>
    </CardContainer>
  );
};
export default RootCause;
