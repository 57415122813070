import { TextAreaInput } from '@components/inputs/TextAreaInput';
import { useUpdateChange } from 'api/changes/changesServices';
import { useFeatureFlag } from 'context/FeatureFlagProvider/FeatureFlagProvider';
import { Button } from 'layout/Button';
import { CardContainer, CardTitle } from 'layout/Card';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';
import { type ChangeEvent, type FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { handleError } from 'utils/handleError/handleError';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

interface LessonsLearnedProps {
  changeId?: string;
  isLoading?: boolean;
}

const LessonsLearned: FC<LessonsLearnedProps> = ({
  changeId,
  isLoading = false,
}) => {
  const [selectedLessonsLearned, setSelectedLessonsLearned] = useState('');
  const {
    mutate,
    isPending: isUpdateChangeLoading,
    isSuccess,
  } = useUpdateChange(changeId);
  const { t } = useTranslation();
  const { hasFeatureFlagPermission } = useFeatureFlag();

  const onSubmit = (): void => {
    const body = {
      lessons_learned: selectedLessonsLearned,
    };
    if (selectedLessonsLearned === '') {
      toast.error('Lesson cannot be empty');
    } else {
      try {
        mutate(body);
        setSelectedLessonsLearned('');
      } catch (e: any) {
        handleError(e);
      }
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const value = event.target.value;
    setSelectedLessonsLearned(value);
  };

  useEffect(() => {
    if (isSuccess) {
      Mixpanel.track('lessons_learned_updated', {
        lessons_learned: selectedLessonsLearned,
        change_id: changeId,
      });
    }
  }, [isSuccess]);

  return (
    <div className="flex w-full h-min">
      <CardContainer>
        <CardTitle
          title={t('anomaliesPage.details.lessonsLearned.title')}
          style={{ fontSize: 14 }}
          className="mb-4"
          isLoading={isLoading}
        />
        {isLoading ? (
          <Skeleton width={'60%'} />
        ) : (
          <p className="mb-4">
            {t('anomaliesPage.details.lessonsLearned.body')}
          </p>
        )}

        <>
          {isLoading ? (
            <Skeleton height={'160px'} />
          ) : (
            <TextAreaInput
              rows={7}
              placeholder={t('anomaliesPage.placeholders.lessonsLearned')}
              className="resize-none"
              onChange={handleOnChange}
              value={selectedLessonsLearned}
              disabled={
                !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch)
              }
            />
          )}

          <div className="flex justify-end pt-6">
            {isLoading ? (
              <Skeleton width={'140px'} height={'40px'} borderRadius="20px" />
            ) : (
              <Button
                type="button"
                label={t('common.buttons.send')}
                onClick={onSubmit}
                isLoading={isUpdateChangeLoading}
                disabled={
                  !hasFeatureFlagPermission(FEATURE_FLAGS_LIST.changes.patch)
                }
              />
            )}
          </div>
        </>
      </CardContainer>
    </div>
  );
};
export default LessonsLearned;
