import { FLabel } from '@components/form/FLabel';
import { FMultiComboBox } from '@components/form/FMultiComboBox';
import { FTextField } from '@components/form/FTextField';
import { WarningIcon } from '@components/icons/WarningIcon';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import {
  useFetchCompanies,
  useFindLuzmoDashboard,
  useSearchLuzmoName,
} from 'api/sysadmin/sysAdminServices';
import { type FC, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  type LuzmoReportSchemaType,
  ManageLuzmoReportControlNames,
} from './schema';

interface ManageLuzmoReportFormProps {
  initialValues?: LuzmoReportSchemaType;
}
const ManageLuzmoReportForm: FC<ManageLuzmoReportFormProps> = ({
  initialValues,
}) => {
  const [nameQuery, setNameQuery] = useState('');
  const [companyQuery, setCompanyQuery] = useState('');
  const { watch, setValue, control } = useFormContext();

  const selectedLuzmoName = watch(ManageLuzmoReportControlNames.luzmo_name);
  const isUniversal = watch(ManageLuzmoReportControlNames.is_universal);

  const { data: luzmoDashboardsList, isFetching: isFetchingLuzmoDashboards } =
    useSearchLuzmoName({
      query: nameQuery,
    });

  const { data: companiesList, isFetching: isFetchingCompanies } =
    useFetchCompanies({ query: companyQuery, perPage: 999 });

  const { data: luzmoDashboard } = useFindLuzmoDashboard(
    selectedLuzmoName?.[0]?.name
  );

  const luzmoDashboardOptions = useMemo(() => {
    if (!luzmoDashboardsList) return [];
    return luzmoDashboardsList?.dashboards?.map((dashboard) => ({
      name: dashboard.name,
      value: dashboard.id,
    }));
  }, [luzmoDashboardsList]);

  const companiesOptions = useMemo(() => {
    if (!companiesList) return [];
    return companiesList?.companies?.map((company) => ({
      name: company.name,
      value: company.id,
    }));
  }, [companiesList]);

  const selectedLuzmoFilters = useMemo(() => {
    if (!luzmoDashboard) return [];
    const filters = luzmoDashboard?.dashboard?.filters;
    setValue(ManageLuzmoReportControlNames.filters, filters);
    return filters;
  }, [luzmoDashboard]);

  return (
    <form className="flex flex-col gap-6">
      <div className="flex items-center gap-2">
        <WarningIcon />
        <p>{`Select the same dashboard created on  Luzmo platform but without the environment.`}</p>
      </div>
      <FTextField
        label="Name"
        name={ManageLuzmoReportControlNames.internal_name}
      />
      <FMultiComboBox
        label="Luzmo dashboard name"
        name={ManageLuzmoReportControlNames.luzmo_name}
        query={nameQuery}
        handleQuery={setNameQuery}
        options={luzmoDashboardOptions}
        isFetching={isFetchingLuzmoDashboards}
        initialValues={initialValues?.luzmo_name as DropdownOptions[]}
        maxSelected={1}
      />
      <div className="flex gap-2">
        <FLabel>Active for all companies</FLabel>
        <Controller
          name={ManageLuzmoReportControlNames.is_universal}
          control={control}
          render={({ field, fieldState: { error } }) => {
            return (
              <input
                id="active"
                className="w-[15px]"
                type={'checkbox'}
                checked={field.value}
                {...field}
              />
            );
          }}
        />
      </div>
      <FMultiComboBox
        label="Company"
        name={ManageLuzmoReportControlNames.companies}
        query={companyQuery}
        handleQuery={setCompanyQuery}
        options={companiesOptions}
        isFetching={isFetchingCompanies}
        initialValues={initialValues?.companies as DropdownOptions[]}
        disabled={isUniversal}
      />

      {selectedLuzmoFilters && selectedLuzmoFilters.length > 0 && (
        <div>
          <FLabel>Filters</FLabel>
          <div className="flex flex-col gap-2 mt-2">
            {selectedLuzmoFilters.map((filter) => {
              return (
                <div
                  key={filter}
                  className="border border-gray-30 rounded-lg py-2 px-4"
                >
                  {filter}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </form>
  );
};
export default ManageLuzmoReportForm;
