import { FTextField } from '@components/form/FTextField';
import { type FC, useMemo, useState } from 'react';
import { type ManageAssetSchemaType, manageAssetControlNames } from './schema';
import { FSelectField } from '@components/form/FSelectField';
import { ASSET_TYPES } from 'lib/constants';
import { t } from 'i18next';
import { SelectAddressCountry } from 'pages/Authentication/RegisterPage/components/SelectAddressCountry';
import {
  useFetchCompanyMeters,
  useListAssetFunctions,
} from 'api/assets/assetsServices';
import { FNumericField } from '@components/form/FNumericField';
import { FRadioGroup } from '@components/form/FRadioGroup';
import { RadioButton } from '@components/inputs/RadioGroup';
import { FMultiComboBox } from '@components/form/FMultiComboBox';
import { useAuth } from 'hooks/contexts/AuthContext';
import { FLabel } from '@components/form/FLabel';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { type UseFormReturn } from 'react-hook-form';

interface ManageAssetFormProps {
  methods: UseFormReturn<ManageAssetSchemaType>;
  initialValues?: ManageAssetSchemaType;
}

const ManageAssetForm: FC<ManageAssetFormProps> = ({
  methods,
  initialValues,
}) => {
  const { user } = useAuth();
  const [query, setQuery] = useState('');
  const { data: meterList, isFetching } = useFetchCompanyMeters(
    user?.company_id,
    {
      query,
    }
  );
  const { data: assetFunctionsList } = useListAssetFunctions();

  const meterOptions = useMemo(() => {
    if (!meterList?.meters) return [];

    return meterList.meters.map((meter) => ({
      value: meter.id,
      name: meter.name,
    }));
  }, [meterList]);

  const assetFunctionOptions = useMemo(() => {
    if (!assetFunctionsList?.functions) return [];

    return assetFunctionsList.functions.map((assetFunc) => ({
      value: assetFunc,
      name: assetFunc,
    }));
  }, [assetFunctionsList]);

  return (
    <form {...methods}>
      <div className="flex flex-col gap-6">
        <FRadioGroup
          label={t('assetsPage.assetsTab.form.labels.type')}
          name={manageAssetControlNames.type}
          data-testid="type"
        >
          {ASSET_TYPES.map((assetType) => (
            <RadioButton
              key={assetType.value}
              label={assetType.name}
              value={assetType.value}
            />
          ))}
        </FRadioGroup>
        <FTextField
          label={t('assetsPage.assetsTab.form.labels.name')}
          name={manageAssetControlNames.name}
          placeholder={t('assetsPage.assetsTab.form.placeholders.name')}
          data-testid="name"
        />
        <FNumericField
          onChangeValue={() => {}}
          label={t('assetsPage.assetsTab.form.labels.surface_area')}
          name={manageAssetControlNames.surface_area}
          placeholder={t('assetsPage.assetsTab.form.placeholders.surface_area')}
          data-testid="surface_area"
        />
        <FLabel>{t('common.address')}*</FLabel>
        <div className="flex gap-4">
          <div className="flex w-6/12">
            <SelectAddressCountry
              onChange={(value) => {
                methods.setValue(
                  manageAssetControlNames.address.country_iso,
                  value
                );
              }}
            />
          </div>
          <div className="flex w-6/12">
            <FTextField
              name={manageAssetControlNames.address.zip_code}
              placeholder={t('registerPage.stepThree.placeholders.zipCode')}
              data-testid="address-zip"
            />
          </div>
        </div>
        <div className="flex gap-4">
          <FTextField
            name={manageAssetControlNames.address.street}
            placeholder={t('common.placeholders.street')}
            data-testid="address-street"
          />
          <FTextField
            name={manageAssetControlNames.address.number}
            placeholder={t('common.placeholders.number')}
            data-testid="address-number"
          />
        </div>
        <div className="flex gap-4">
          <FTextField
            name={manageAssetControlNames.address.city}
            placeholder={t('registerPage.stepThree.placeholders.city')}
            data-testid="address-city"
          />
          <FTextField
            name={manageAssetControlNames.address.province}
            placeholder={t('registerPage.stepThree.placeholders.province')}
            data-testid="company-province"
          />
        </div>
        <FSelectField
          options={assetFunctionOptions}
          label={t('assetsPage.assetsTab.form.labels.function')}
          name={manageAssetControlNames.function}
          placeholder={t('assetsPage.assetsTab.form.placeholders.function')}
          data-testid="function"
        />
        <FMultiComboBox
          label={t('assetsPage.assetsTab.form.labels.meters')}
          name={manageAssetControlNames.meters}
          query={query}
          handleQuery={setQuery}
          options={meterOptions}
          isFetching={isFetching}
          placeholder={t('assetsPage.assetsTab.form.placeholders.meters')}
          data-testid="meters"
          initialValues={initialValues?.meters as DropdownOptions[]}
        />
      </div>
    </form>
  );
};
export default ManageAssetForm;
