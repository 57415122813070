import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { FLabel } from 'components/form/FLabel';
import { type FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  defaultCountries,
  guessCountryByPartialPhoneNumber,
  usePhoneInput,
} from 'react-international-phone';

import { SelectCountryInput } from '../SelectCountryInput';
import { TextInput } from '../TextInput';

interface PhoneNumberInputProps {
  label: string;
  name: string;
  labelTooltip?: string;
}

const BELGIUM_ISO2 = 'be';
const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
  name,
  label,
  labelTooltip,
}) => {
  const { control, watch, setValue } = useFormContext();

  const phoneNumber = watch(name);

  const countryFromPhoneNumber = useMemo(
    () =>
      guessCountryByPartialPhoneNumber({
        phone: phoneNumber,
      }).country?.iso2,
    [phoneNumber]
  );

  const { setCountry, handlePhoneValueChange, inputValue } = usePhoneInput({
    defaultCountry: countryFromPhoneNumber ?? BELGIUM_ISO2,
    value: phoneNumber,
    countries: defaultCountries,
    forceDialCode: true,
    onChange: (data) => {
      setValue(name, data.phone);
    },
  });

  const handleChangeCountry = (event: any): void => {
    setCountry(event);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <FLabel>{label}</FLabel>
        {labelTooltip && <TooltipIconComponent content={labelTooltip} />}
      </div>
      <div className="flex w-full gap-4">
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <div className="w-4/12">
                  <SelectCountryInput
                    value={countryFromPhoneNumber}
                    onChange={handleChangeCountry}
                  />
                </div>
                <div className="w-8/12">
                  <TextInput
                    {...field}
                    value={inputValue}
                    type="tel"
                    onChange={(e) => {
                      handlePhoneValueChange(e);
                    }}
                    error={error}
                    data-testid="telephone-number"
                  />
                </div>
              </>
            );
          }}
        />
      </div>
    </div>
  );
};
export default PhoneNumberInput;
