import { z } from 'zod';
import { PhoneNumberUtil } from 'google-libphonenumber';

export const registerStepOneSchema = z.object({
  email: z.string().email(),
  password: z
    .string()
    .min(10, 'Password must be at least 10 characters long')
    .regex(/[A-Z]/, 'Password must contain at least one capital letter')
    .regex(
      /[a-zA-Z0-9]*[^a-zA-Z0-9]+[a-zA-Z0-9]*/,
      'Password must contain at least one special character'
    ),
});

export const registerStepTwoSchema = z.object({
  given_name: z.string().trim().min(1, 'Required'),
  family_name: z.string().trim().min(1, 'Required'),
  phone_number: z.string().superRefine((value, ctx) => {
    const phoneUtil = PhoneNumberUtil.getInstance();

    const isValid =
      value.length > 6 &&
      phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value));
    if (!isValid) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid number format.',
      });
    }
  }),
});

export const registerStepThreeSchema = z.object({
  company: z.object({
    name: z.string().min(4, 'Minimum 4 characters required'),
    vat_number: z.string().min(4, 'Minimum 4 characters required'),
    address: z.object({
      country_iso: z.string(),
      zip_code: z.string().min(4, 'Minimum 4 characters required'),
      street: z.string().min(3, 'Minimum 3 characters required'),
      number: z.string().min(1, 'Required'),
      city: z.string().min(3, 'Minimum 3 characters required'),
      province: z.string(),
    }),
  }),
});

export const registerStepFourSchema = z.object({
  code: z.string().transform((value) => value.replace(/\s/g, '')),
});

interface registerControlNamesType {
  email: 'email';
  password: 'password';
  name: 'given_name';
  familyName: 'family_name';
  phoneNumber: 'phone_number';
  company: {
    name: 'company.name';
    vat: 'company.vat_number';
    address: {
      country: 'company.address.country_iso';
      zipCode: 'company.address.zip_code';
      street: 'company.address.street';
      number: 'company.address.number';
      city: 'company.address.city';
      province: 'company.address.province';
    };
  };
}

export const registerControlNames: registerControlNamesType = {
  email: 'email',
  password: 'password',
  name: 'given_name',
  familyName: 'family_name',
  phoneNumber: 'phone_number',
  company: {
    name: 'company.name',
    vat: 'company.vat_number',
    address: {
      country: 'company.address.country_iso',
      zipCode: 'company.address.zip_code',
      street: 'company.address.street',
      number: 'company.address.number',
      city: 'company.address.city',
      province: 'company.address.province',
    },
  },
};

export type RegisterSchemaType = z.infer<typeof registerStepOneSchema>;
