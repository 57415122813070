import {
  type CellContext,
  type ColumnDef,
  createColumnHelper,
} from '@tanstack/react-table';
import { StatusChip } from '@components/statusChip';
import i18next from '../../../../../i18n';
import { getUtilityTypeReadableName } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { type ReactNode } from 'react';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { numericFormatter } from 'react-number-format';
import { getDaysFromStatusLastUpdate } from 'utils/formatters/getDaysFromDate/getDaysFromDate';
import { getReadableUnitName } from 'utils/formatters/units/getReadableUnitName';
import Tippy from '@tippyjs/react';
import { calculateImpactPerDayUnit } from 'utils/chart/chartUtils';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';

interface ChangesTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetChangesTableColumns = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): ChangesTableColumnsReturnType => {
  const columnHelper = createColumnHelper<any>();

  const columns = [
    columnHelper.accessor('meter_name', {
      header: () => i18next.t('anomaliesPage.table.meterName'),
      cell: (info) => (
        <Tippy content={info.getValue()} maxWidth={450}>
          <span className="block text-ellipsis overflow-hidden truncate phone:max-w-[100px] tablet:max-w-[150px] laptop:max-w-[170px] desktop:max-w-[250px] desktop-l:max-w-[300px]">
            {info.renderValue()}
          </span>
        </Tippy>
      ),
    }),
    columnHelper.accessor('moment_change', {
      header: () => i18next.t('anomaliesPage.table.momentOfChange'),
      cell: (info) => convertToReadableDate(info.renderValue()),
    }),
    columnHelper.accessor('main_building', {
      header: () => i18next.t('anomaliesPage.table.asset'),
      cell: (info) => (
        <Tippy content={info.getValue()} disabled={!info.getValue()}>
          <span className="truncate block overflow-hidden phone:max-w-[100px] laptop:max-w-[130px] desktop:max-w-[200px]">
            {info.renderValue() ?? '-'}
          </span>
        </Tippy>
      ),
    }),
    columnHelper.accessor('utility_type', {
      header: () => i18next.t('anomaliesPage.table.utilityType'),
      cell: (info) => (
        <span className="capitalize">
          {getUtilityTypeReadableName(info.renderValue())}
        </span>
      ),
    }),
    columnHelper.accessor('impact_average_diff_euro', {
      id: 'impact_average_diff_euro',
      header: () => (
        <div className="flex items-center gap-2">
          <span>{i18next.t('anomaliesPage.table.impactPerDay')}</span>
          <TooltipIconComponent
            content={i18next.t('anomaliesPage.table.impactPerDayTooltip')}
          />
        </div>
      ),
      cell: (info) =>
        `${numericFormatter((info.getValue() ?? 0).toFixed(2), {
          decimalSeparator: ',',
          thousandSeparator: '.',
          decimalScale: 2,
        })} €`,
    }),
    columnHelper.accessor('impact_average_diff', {
      id: 'impact_average_diff',
      header: () => (
        <div className="flex items-center gap-2">
          <span>{i18next.t('anomaliesPage.table.impactPerDay')}</span>
          <TooltipIconComponent
            content={i18next.t('anomaliesPage.table.impactPerDayTooltip')}
          />
        </div>
      ),
      cell: (info) => {
        const unitType = info.row.original.utility_unit;
        return `${numericFormatter(
          calculateImpactPerDayUnit(info.getValue() ?? 0).toFixed(2),
          {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 2,
          }
        )} ${getReadableUnitName(unitType)}`;
      },
    }),
    columnHelper.accessor('status', {
      header: () => i18next.t('anomaliesPage.table.status'),
      cell: (info) => <StatusChip status={info.getValue()} />,
    }),
    columnHelper.accessor('time_on_status', {
      header: () => i18next.t('anomaliesPage.table.timeOnStatus'),
      cell: (info) => {
        return getDaysFromStatusLastUpdate(info.renderValue());
      },
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 25,
    }),
  ];

  return {
    columns,
  };
};
export default useGetChangesTableColumns;
