import { OnboardingContainer } from '@components/auth/OnboardingContainer';
import { OnboardingWrapper } from '@components/auth/OnboardingWrapper';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ResetPasswordForm } from './forms';

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation();

  return (
    <OnboardingWrapper>
      <OnboardingContainer
        heading={t('resetPasswordPage.heading')}
        detailsHeading={t('resetPasswordPage.enterDetails')}
      >
        <ResetPasswordForm />
      </OnboardingContainer>
    </OnboardingWrapper>
  );
};

export default ResetPasswordPage;
