import { forwardRef, useEffect } from 'react';

import useChart from 'hooks/useChart/useChart';
import HChart from '@components/HChart/HChart';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import type Highcharts from 'highcharts/highstock';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';
import { type HighchartsReactRefObject } from 'highcharts-react-official';

interface ExcessConsumptionChartProps {
  id?: string;
  momentChange?: string;
  unit?: string;
  data?: ChangeTS[] | null;
  changesData?: { date: string; id?: string }[];
  setExtremes?: ({ min, max }: { min: number; max: number }) => void;
  min?: number;
  max?: number;
}

const ExcessConsumptionCuSUMChart = forwardRef<
  HighchartsReactRefObject,
  ExcessConsumptionChartProps
>(
  (
    { momentChange, id, unit = '', data, changesData, setExtremes, min, max },
    ref
  ) => {
    const {
      defaultOptions,
      changes,
      currentChange,
      labelFormatter,
      defaultTooltipPointFormatter,
    } = useChart({
      chartData: data,
      momentChange,
      changesData,
      unit,
      type: 'excess_cusum',
    });

    const { theme } = useTheme();

    const options: Highcharts.Options = {
      ...defaultOptions,
      xAxis: {
        plotLines: [...changes, ...currentChange],
        events: {
          afterSetExtremes: (extremes) => {
            setExtremes?.({ min: extremes.min, max: extremes.max });
          },
        },
      },
      yAxis: [
        {
          labels: {
            formatter: labelFormatter,
          },
          title: {
            text: 'Excess CuSUM',
          },
        },
      ],

      series: [
        {
          animation: {
            duration: 0,
          },
          name: 'Excess CuSUM',
          type: 'column',
          color: theme.colors.tertiary,
          data: data?.map((ts, index) => ({
            x: new Date(ts.time).getTime(),
            y: ts.excess_cusum,
            impactPerDay: ts.savings,
            expectedConsumption: ts.result_baseline,
            time: ts.time,
          })),
          dataGrouping: {
            approximation: 'average',
            groupPixelWidth: 10,
            units: [
              ['hour', [1]],
              ['day', [1]],
              ['week', [1]],
              ['month', [1, 3, 6]],
              ['year', null],
            ],
          },
          tooltip: {
            pointFormatter: defaultTooltipPointFormatter,
          },
          turboThreshold: data?.length,
          yAxis: 0,
        },
      ],
    };

    useEffect(() => {
      if (typeof ref !== 'function') {
        const chart = ref?.current?.chart;
        if (chart && min && max) {
          chart.xAxis[0].setExtremes(min, max, true, false);
        }
      }
    }, [ref]);

    return <>{options && <HChart ref={ref} options={options} />}</>;
  }
);
export default ExcessConsumptionCuSUMChart;
