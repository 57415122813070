import Highcharts from 'highcharts/highstock';
import highChartZoom from 'highcharts/modules/mouse-wheel-zoom';
import patternFill from 'highcharts/modules/pattern-fill';

import HighchartsReact, {
  type HighchartsReactRefObject,
} from 'highcharts-react-official';
import { forwardRef } from 'react';

export interface HChartOptionsProps {
  options: Highcharts.Options;
  id?: string;
  constructorType?: string;
}

const HChart = forwardRef<HighchartsReactRefObject, HChartOptionsProps>(
  ({ options, id, constructorType = 'stockChart' }, ref) => {
    highChartZoom(Highcharts);
    patternFill(Highcharts);

    return (
      <div>
        <HighchartsReact
          id={id}
          ref={ref}
          highcharts={Highcharts}
          options={options}
          constructorType={constructorType}
        />
      </div>
    );
  }
);

export default HChart;
