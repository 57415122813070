import { AssetFunctionEnum } from 'enums/AssetFunctionEnum';
import { AssetTypeEnum } from 'enums/AssetTypeEnum';

export const getAssetTypeReadableName = (assetType: AssetTypeEnum): string => {
  switch (assetType) {
    case AssetTypeEnum.BUILDING:
      return 'Building';
    case AssetTypeEnum.TECHNICAL_INSTALLATION:
      return 'Technical installation';
    case AssetTypeEnum.UNDEFINED:
      return 'Undefined';
    default:
      return 'Unknown';
  }
};

export const getAssetTypeReadableNameFromStringEnum = (
  stringEnum: string
): string => {
  switch (stringEnum.toLowerCase()) {
    case 'building':
      return 'Building';
    case 'technical_installation':
      return 'Technical installation';
    case 'undefined':
      return 'Undefined';
    default:
      return 'Unknown';
  }
};

export const getAssetFunctionReadableName = (
  assetFunction: AssetFunctionEnum
): string => {
  switch (assetFunction) {
    case AssetFunctionEnum.SCHOOL:
      return 'School';
    case AssetFunctionEnum.HOSPITAL:
      return 'Hospital';
    case AssetFunctionEnum.UNDEFINED:
      return 'Undefined';
    default:
      return 'Unknown';
  }
};
