import { FTextField } from 'components/form/FTextField';
import { PhoneNumberInput } from 'components/inputs/PhoneNumberInput';
import { useMultistep } from 'context/MultiStepProvider/MultiStepProvider';
import useHRForm from 'hooks/useHRForm';
import { Button } from 'layout/Button';
import { AuthRegisterFooter } from 'pages/Authentication/RegisterPage/components/AuthRegisterFooter';
import { RegisterContainer } from 'pages/Authentication/RegisterPage/components/RegisterContainer';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StepTwoInitialValues } from '../initialValue';
import { registerControlNames, registerStepTwoSchema } from '../schema';

const RegisterStepTwo: FC = () => {
  const {
    methods: { getValues },
    Form,
  } = useHRForm({
    schema: registerStepTwoSchema,
    initialValues: StepTwoInitialValues,
  });
  const { goToNextStep } = useMultistep();
  const { t } = useTranslation();

  const handleSubmit = (): void => {
    const formData = getValues();
    goToNextStep(formData);
  };

  return (
    <RegisterContainer>
      <Form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-4">
          <div>
            <h5 className="font-bold mb-2">
              {t('registerPage.stepTwo.userInfo').toUpperCase()}
            </h5>
            <div className="flex flex-col gap-6">
              <FTextField
                name={registerControlNames.name}
                label={t('common.inputs.name')}
                placeholder={t('common.placeholders.name')}
                data-testid="user-name"
              />
              <FTextField
                name={registerControlNames.familyName}
                label={t('registerPage.stepTwo.lastName')}
                placeholder={t('common.placeholders.name')}
                data-testid="last-name"
              />
            </div>
          </div>

          <PhoneNumberInput
            name={registerControlNames.phoneNumber}
            label={t('registerPage.stepTwo.businessNumber')}
          />
        </div>

        <Button
          type="submit"
          size="large"
          label={t('common.buttons.continue')}
          className="w-full mt-8"
        />
        <div>
          <AuthRegisterFooter />
        </div>
      </Form>
    </RegisterContainer>
  );
};
export default RegisterStepTwo;
