import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const PublicSwaggerPage: React.FC = () => {
  const url = `${process.env.REACT_APP_LEGACY_API_URL ?? ''}docs/spec`;

  const plugins = [
    {
      statePlugins: {
        spec: {
          wrapActions: {
            // any here as defined in swagger-ui-react library
            updateJsonSpec: (oriAction: any, _system: any) => (spec: any) => {
              const urlWithoutVersion = process.env.REACT_APP_API_URL?.replace(
                '/v2/',
                ''
              );
              spec.servers = [
                {
                  url: urlWithoutVersion,
                  description: 'Legacy API',
                },
              ];
              return oriAction(spec);
            },
          },
        },
      },
    },
  ];
  return (
    <div>
      <SwaggerUI url={url} plugins={plugins} />
    </div>
  );
};

export default PublicSwaggerPage;
