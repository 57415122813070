import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const PrivateSwaggerPage: React.FC = () => {
  const url = `${process.env.REACT_APP_API_URL ?? ''}docs/spec`;

  const authenticatedRequest = async <T extends Record<string, any>>(
    req: T
  ): Promise<T> => ({
    ...req,
    headers: { Authorization: localStorage.getItem('jwtToken') },
  });

  const plugins = [
    {
      statePlugins: {
        spec: {
          wrapActions: {
            // any here as defined in swagger-ui-react library
            updateJsonSpec: (oriAction: any, _system: any) => (spec: any) => {
              const urlWithoutVersion = process.env.REACT_APP_API_URL?.replace(
                '/v2/',
                ''
              );
              spec.servers = [
                {
                  url: urlWithoutVersion,
                  description: 'API',
                },
              ];
              return oriAction(spec);
            },
          },
        },
      },
    },
  ];

  return (
    <div>
      <SwaggerUI
        url={url}
        requestInterceptor={authenticatedRequest}
        plugins={plugins}
      />
    </div>
  );
};

export default PrivateSwaggerPage;
