import { Accordion } from '@components/accordion';
import { Modal } from '@components/modal';
import { useFetchFeatureFlagDetail } from 'api/sysadmin/sysAdminServices';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type FC } from 'react';

interface FeatureFlagDetailsModalProps extends Disclosure {
  featureFlagId?: number;
  handleEditOpen: () => void;
}
const FeatureFlagDetailsModal: FC<FeatureFlagDetailsModalProps> = ({
  featureFlagId,
  isOpen,
  handleEditOpen,
  handleClose,
  handleOpen,
}) => {
  const { data } = useFetchFeatureFlagDetail(featureFlagId);
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        primary: {
          label: 'Edit',
          onClick: () => {
            handleClose();
            handleEditOpen();
          },
        },
      }}
      title={data?.name}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-4">
          <Accordion
            title={'Routes'}
            items={data?.routes.map((route) => ({
              id: route.id,
              name: `${route.method} - ${route.endpoint}`,
            }))}
            defaultOpen={true}
          />
        </div>
        <div className="flex flex-col gap-4">
          <Accordion
            title="Companies"
            items={data?.companies_affected.map((company) => ({
              id: company.id,
              name: company.name,
            }))}
            defaultOpen={true}
          />
        </div>
      </div>
    </Modal>
  );
};
export default FeatureFlagDetailsModal;
