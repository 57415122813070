import { FSelectField } from '@components/form/FSelectField';
import { UTILITY_DATA, UTILITY_TYPE } from 'lib/constants';
import { type FC, useEffect, useState } from 'react';
import {
  utilityPriceControlNames,
  utilityPriceSchema,
  type utilityPriceSchemaType,
} from './schema';
import useHRForm from 'hooks/useHRForm';
import {
  useDeleteUtilityPrice,
  useUpdateUtilityPrice,
} from 'api/utilityPrices/UtilityPriceService';
import { Button } from 'layout/Button';
import { FNumericField } from '@components/form/FNumericField';

interface UtilityPriceFormProps {
  id: number;
  initialValues: utilityPriceSchemaType;
  companyId?: number | null | undefined;
}
const UtilityPriceForm: FC<UtilityPriceFormProps> = ({
  id,
  initialValues,
  companyId,
}) => {
  const [isLoadingDelete] = useState(false);

  const { mutate: updateUtilityPriceMutate } = useUpdateUtilityPrice(
    id,
    companyId
  );
  const { mutate: deleteUtilityPriceMutate } = useDeleteUtilityPrice(
    id,
    companyId
  );

  const {
    Form,
    methods: {
      watch,
      resetField,
      getValues,
      formState: { defaultValues, isSubmitSuccessful },
      reset,
    },
  } = useHRForm({
    schema: utilityPriceSchema,
    initialValues,
    mode: 'onBlur',
  });

  const utilityType = watch(
    utilityPriceControlNames.utility_type
  ) as keyof typeof UTILITY_DATA;

  // each time utilityType is changed select unit options are reset
  useEffect(() => {
    resetField('unit_type');
  }, [utilityType]);

  const updateUtilityPrice = (): void => {
    const modifiedData: utilityPriceSchemaType = {
      ...getValues(),
      price: getValues().price,
    };
    // if the values of the utility price have changed, it is updated
    // and if there are no empty fields
    if (hasChanged(getValues(), defaultValues) && notEmpty(getValues())) {
      if (updateUtilityPriceMutate) updateUtilityPriceMutate(modifiedData);
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      // reset will put formState.defaultValues to ensure the update will work if updated multiple times
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  const hasChanged = (
    values: utilityPriceSchemaType,
    defaultValues: any
  ): boolean => {
    for (const key in values) {
      if (
        Object.prototype.hasOwnProperty.call(values, key) &&
        values[key as keyof typeof values] !==
          defaultValues[key as keyof typeof defaultValues]
      ) {
        return true;
      }
    }

    return false;
  };

  const notEmpty = (values: utilityPriceSchemaType): boolean => {
    return (
      values.price > 0 && values.utility_type !== '' && values.unit_type !== ''
    );
  };

  const deleteUtilityPrice = (): void => {
    deleteUtilityPriceMutate(id);
  };

  return (
    <div className="pt-3">
      <Form onSubmit={() => {}}>
        <div className="flex justify-between w-full gap-6">
          <FSelectField
            id="utility-type"
            onBlur={updateUtilityPrice}
            options={UTILITY_TYPE}
            name={utilityPriceControlNames.utility_type}
            placeholder="Select utility type"
            data-testid="utilityType"
          />
          <FSelectField
            onBlur={updateUtilityPrice}
            options={UTILITY_DATA[utilityType]}
            name={utilityPriceControlNames.unit_type}
            placeholder="Select unit"
            data-testid="unit"
          />
          <FNumericField
            onBlur={updateUtilityPrice}
            name={utilityPriceControlNames.price}
            placeholder="Enter prince"
            data-testid="price"
          />
          <div>
            <Button
              label={'-'}
              variant="secondary"
              isLoading={isLoadingDelete}
              type="submit"
              minWidth={'45'}
              onClick={async () => {
                deleteUtilityPrice();
              }}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
export default UtilityPriceForm;
