import AddIcon from '@components/icons/AddIcon/AddIcon';
import { t } from 'i18next';
import { type Meter } from 'interfaces/assets/Meters.interface';
import { type FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import RelatableMeterItem from './RelatableMeterItem';

interface RelatableMeterContainerProps {
  meterId?: number;
  type: 'parents' | 'children';
  currentlyEditing?: 'parents' | 'children';
  data?: Meter[];
  isEditing: boolean;
  isLoading?: boolean;
  handleIsEditing: (type: 'parents' | 'children') => void;
  handleRelatableItemOnClick: (id: number) => void;
  handleHierarchyOpen: () => void;
}

const RelatableMeterContainer: FC<RelatableMeterContainerProps> = ({
  data,
  meterId,
  type,
  currentlyEditing,
  isEditing,
  isLoading,
  handleIsEditing,
  handleRelatableItemOnClick,
  handleHierarchyOpen,
}) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        {isLoading ? (
          <Skeleton width={130} />
        ) : (
          <p className="font-bold text-base text-gray-60 uppercase">
            {type === 'parents'
              ? t('assetsPage.meterDetailsModal.parentMeter')
              : t('assetsPage.meterDetailsModal.childrenMeter')}
          </p>
        )}

        {isLoading ? (
          <Skeleton width={40} />
        ) : (
          <span
            className="text-tertiary-dark font-bold text-bold text-base cursor-pointer"
            onClick={() => {
              handleIsEditing(type);
            }}
          >
            {isEditing ? t('common.buttons.cancel') : t('common.buttons.edit')}
          </span>
        )}
      </div>
      <div className="flex flex-col gap-4">
        {data && data.length > 0 ? (
          data?.map((meter) => (
            <RelatableMeterItem
              key={meter.id}
              name={meter.name}
              id={meter.id}
              isEditing={isEditing}
              type={currentlyEditing}
              currentMeterId={meterId}
              onClick={handleRelatableItemOnClick}
            />
          ))
        ) : isLoading ? (
          <Skeleton width={200} />
        ) : (
          <span>{t('assetsPage.meterDetailsModal.noRelation')}</span>
        )}
        {isEditing && (
          <div
            data-testid={'add-relatable-btn'}
            className="flex justify-center items-center p-3 border border-gray-10 cursor-pointer"
            onClick={handleHierarchyOpen}
            role={'button'}
          >
            <AddIcon />
          </div>
        )}
      </div>
    </div>
  );
};
export default RelatableMeterContainer;
