import { z } from 'zod';

interface editCompanyControlNamesType {
  companyName: 'name';
  vatNumber: 'vat_number';
  address: {
    countryIso: 'address.country_iso';
    street: 'address.street';
    number: 'address.number';
    zipCode: 'address.zip_code';
    city: 'address.city';
    province: 'address.province';
  };
}

export const editCompanySchema = z.object({
  name: z.string().min(4, 'Minimum 4 characters required'),
  vat_number: z.string().min(4, 'Minimum 4 characters required'),
  address: z.object({
    country_iso: z.string(),
    street: z.string().min(3, 'Minimum 3 characters required'),
    number: z.string().min(1, 'Required'),
    city: z.string().min(3, 'Minimum 3 characters required'),
    zip_code: z.string().nullable(),
    province: z.string().nullable(),
  }),
});

export const editCompanyControlNames: editCompanyControlNamesType = {
  companyName: 'name',
  vatNumber: 'vat_number',
  address: {
    countryIso: 'address.country_iso',
    street: 'address.street',
    number: 'address.number',
    zipCode: 'address.zip_code',
    city: 'address.city',
    province: 'address.province',
  },
};
