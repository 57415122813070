/* eslint-disable @typescript-eslint/no-misused-promises */
import { type FormEvent, type ReactNode, useMemo } from 'react';
import {
  type FieldValues,
  FormProvider,
  type SubmitErrorHandler,
  type SubmitHandler,
  type UseFormReturn,
  useForm,
} from 'react-hook-form';
import { type z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

interface FormProps<SchemaType extends z.ZodSchema> {
  children: ReactNode;
  className?: string;
  onSubmit: SubmitHandler<z.infer<SchemaType>>;
  onInvalid?: SubmitErrorHandler<FieldValues> | undefined;
}

interface RHFormAndMethods<SchemaType extends z.ZodSchema> {
  methods: UseFormReturn<z.infer<SchemaType>>;
  Form: ({ children }: FormProps<SchemaType>) => JSX.Element;
}

interface useHRFormProps<SchemaType extends z.ZodSchema, ValueType> {
  schema: SchemaType;
  initialValues?: z.infer<SchemaType>;
  values?: ValueType;
  mode?: 'onSubmit' | 'onBlur' | 'onChange' | 'all';
}

const useHRForm = <SchemaType extends z.ZodSchema<any>, ValueType>({
  schema,
  initialValues,
  values,
  mode = 'onBlur',
}: useHRFormProps<SchemaType, ValueType>): RHFormAndMethods<SchemaType> => {
  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: initialValues,
    values,
    mode,
  });

  const Form = useMemo(() => {
    const CustomForm = ({
      children,
      className,
      onSubmit,
      onInvalid,
    }: FormProps<SchemaType>): JSX.Element => {
      const submit = async (
        event: FormEvent<HTMLFormElement>
      ): Promise<void> => {
        event?.stopPropagation();
        event?.preventDefault();

        const actualSubmit = methods.handleSubmit(onSubmit, onInvalid);
        await actualSubmit(event);
      };
      return (
        <FormProvider {...methods}>
          <form className={className} onSubmit={submit}>
            {children}
          </form>
        </FormProvider>
      );
    };
    return CustomForm;
  }, [methods]);

  return { methods, Form };
};

export default useHRForm;
