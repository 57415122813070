import { LuzmoDashboardComponent } from '@luzmo/react-embed';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, useRef } from 'react';

interface LuzmoDashboardProps {
  authKey?: string;
  authToken?: string;
  dashboardId?: string;
}

const LuzmoDashboard: FC<LuzmoDashboardProps> = ({
  authKey,
  authToken,
  dashboardId,
}) => {
  const ref = useRef(null);
  const { theme } = useTheme();

  return (
    <div className="flex flex-1">
      <LuzmoDashboardComponent
        ref={ref}
        _lcStyle={{ padding: '0px' }}
        authKey={authKey}
        authToken={authToken}
        dashboardId={dashboardId}
        switchScreenModeOnResize={false}
        loaderSpinnerColor={theme.colors.primary}
        loaderSpinnerBackground="rgb(236 248 255)"
      />
    </div>
  );
};
export default LuzmoDashboard;
