import { Table } from '@components/table/Table';
import { useListAlerts } from 'api/alerts/alertsServices';
import { useServerSideTableSorting } from 'hooks/useServerSideTableSorting';
import { type Alert } from 'interfaces/alerts/Alert.interface';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import useGetAlertsHistoryTableColumns from '../../hooks/useGetAlertsHistoryTableColumns/useGetAlertsHistoryTableColumns';

interface AlertsHistoryTableProps {
  query?: string;
}
const AlertsHistoryTable: FC<AlertsHistoryTableProps> = ({ query }) => {
  const navigate = useNavigate();

  const { orderBy, handleSortChange } = useServerSideTableSorting();

  const { data, isFetching, fetchNextPage } = useListAlerts({
    query,
    orderBy,
  });

  const totalDBRowCount = data?.pages?.[0]?.total ?? 0;
  const { columns } = useGetAlertsHistoryTableColumns();

  const flattenAssets =
    data?.pages.flatMap((page) => page?.alert_changes) ?? [];

  const handleRowClick = (alert: Alert): void => {
    const id = alert?.change_detection_id;
    if (!id) return;

    navigate(`/app/operations/anomalies/details/${id}`);
  };

  return (
    <Table
      id={'alert-history-table'}
      data={flattenAssets}
      columns={columns}
      isFetching={isFetching}
      totalDBRowCount={totalDBRowCount}
      fetchNextPage={fetchNextPage}
      rowEstimateSize={75}
      handleSortChange={handleSortChange}
      onClickRow={handleRowClick}
      manualSorting={true}
    />
  );
};
export default AlertsHistoryTable;
