import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DescriptionContainerProps {
  projectDescription?: string;
  isLoading: boolean;
}
const DescriptionContainer: FC<DescriptionContainerProps> = ({
  projectDescription,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardTitle
        title={t('projectsDetailsPage.description')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
      />
      <p>{projectDescription}</p>
    </CardContainer>
  );
};
export default DescriptionContainer;
