import { SearchBar } from '@components/inputs/SearchBar';
import { SelectInput } from '@components/inputs/SelectInput';
import { type ChangeEvent, type FC, useMemo, useState } from 'react';
import { AssetsTable } from './Tables';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { Button } from 'layout/Button';
import useDisclosure from 'hooks/useDisclosure';
import AddAssetModal from './modal/AddAssetModal/AddAssetModal';
import { AssetTypeEnum } from 'enums/AssetTypeEnum';
import { getAssetTypeReadableName } from 'utils/assetUtils/assetUtils';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { useListAssetFunctions } from 'api/assets/assetsServices';

const AssetsListPage: FC = () => {
  const { t } = useTranslation();

  const [type, setType] = useState('');
  const [assetFunction, setAssetFunction] = useState('');
  const { data: assetFunctionsList, isFetching: isFetchingAssetFunctions } =
    useListAssetFunctions();

  const [searchValue, setSearchValue] = useState('');

  const query = useDebounce(searchValue, 300);

  const [typeOptions] = useState<DropdownOptions[]>([
    ...Object.entries(AssetTypeEnum)
      .filter(([, value]) => value !== 'undefined') // Filter out entries with undefined values
      .map(([key, value]) => ({
        name: getAssetTypeReadableName(value),
        value,
      })),
    { name: 'All', value: 'all' },
  ]);

  const functionOptions = useMemo(() => {
    const functions = assetFunctionsList?.functions;
    if (!functions) return [];

    return functions.map((func) => ({ name: func, value: func }));
  }, [assetFunctionsList]);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  const handleTypeFilter = (utility: string | number | undefined): void => {
    if (typeof utility === 'string') {
      setType(utility === 'all' ? '' : utility);
    }
  };

  const handleFunctionFilter = (utility: string | number | undefined): void => {
    setAssetFunction((utility as string) ?? '');
  };

  const {
    isOpen: isAddAssetOpen,
    handleClose: handleAddAssetClose,
    handleOpen: handleAddAssetOpen,
  } = useDisclosure();

  return (
    <div>
      <div className="absolute top-0 right-0">
        <div className="flex gap-4">
          <Button
            label={t('assetsPage.assetsTab.buttons.addAsset')}
            onClick={handleAddAssetOpen}
            className="shadow-button"
          />
        </div>
      </div>

      <div className="flex gap-4 my-6 w-6/12">
        <div className="flex-[1.5_1_0%]">
          <SearchBar
            placeholder={t('anomaliesPage.placeholders.search')}
            onChange={handleSearchInput}
          />
        </div>
        <SelectInput
          placeholder={t('common.type')}
          options={typeOptions}
          value={type}
          onChange={handleTypeFilter}
          data-testid="type-dropdown"
        />
        <SelectInput
          placeholder={t('common.function')}
          options={functionOptions}
          value={assetFunction}
          onChange={handleFunctionFilter}
          data-testid="function-dropdown"
          isFetching={isFetchingAssetFunctions}
        />
      </div>
      <div>
        <AddAssetModal
          isOpen={isAddAssetOpen}
          handleClose={handleAddAssetClose}
          handleOpen={handleAddAssetOpen}
        />
        <AssetsTable
          query={query}
          filters={{
            type: type?.toUpperCase(),
            assetFunction,
          }}
        />
      </div>
    </div>
  );
};

export default AssetsListPage;
