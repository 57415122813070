import { DialogPanel } from '@headlessui/react';
import styled from 'styled-components';
import tw from 'twin.macro';

interface ModalContainerProps {
  $position: 'left' | 'center' | 'right';
}
interface ModalHeaderProps {
  $variant: 'normal' | 'small';
}

export const ModalContainer = styled.div<ModalContainerProps>`
  ${(props) => props.$position === 'center' && tw`justify-center`}
  ${(props) => props.$position === 'right' && tw`justify-end`}
${(props) => props.$position === 'left' && tw`justify-start`}
${tw`flex min-h-full items-center p-4 text-center fixed inset-0 overflow-y-auto`}
`;

export const CustomDialogPanel = styled(DialogPanel)`
  ${tw`min-w-[550px] relative max-h-[90vh] overflow-auto align-middle transform rounded-2xl bg-white text-left shadow-xl transition duration-200 ease-out data-[closed]:opacity-0`}
`;

export const DialogPanelHeadlessModal = styled(DialogPanel)`
  ${tw`min-w-[500px] relative max-h-[780vh] overflow-auto align-middle transform rounded-2xl text-left shadow-deletion-modal transition-all bg-white`}
`;

export const ModalHeader = styled.div<ModalHeaderProps>`
  ${tw`flex justify-between items-center border-primary-lighter sticky top-0 bg-white z-50 border-b-2`}
  ${(props) => (props.$variant === 'small' ? tw` px-6 pb-3 pt-3.5` : tw`p-6`)}
`;
