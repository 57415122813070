import { type FC, type SVGProps } from 'react';

const ManagementIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="currentColor"
    {...props}
  >
    <path d="M0 10H6V18H0V10ZM14 5H20V18H14V5ZM7 0H13V18H7V0ZM2 12V16H4V12H2ZM9 2V16H11V2H9ZM16 7V16H18V7H16Z" />
  </svg>
);
export default ManagementIcon;
