import { BackIcon } from '@components/icons/BackIcon';
import { useLocationPathname } from 'context/LocationProvider/LocationProvider';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
  navigateTo: string;
  goBackIfPreviousPage?: string[];
}

const BackButton: FC<BackButtonProps> = ({
  navigateTo,
  goBackIfPreviousPage,
}) => {
  const navigate = useNavigate();
  const { getPreviousPageLocation } = useLocationPathname();

  const { t } = useTranslation();

  const handleOnClick = (): void => {
    const previousPage = getPreviousPageLocation();
    if (
      goBackIfPreviousPage &&
      goBackIfPreviousPage?.length > 0 &&
      previousPage
    ) {
      if (goBackIfPreviousPage?.includes(previousPage)) {
        navigate(-1);
      } else {
        navigate(navigateTo);
      }
    }
    navigate(navigateTo);
  };

  return (
    <div
      className="flex items-center gap-2 font-bold text-primary text-base cursor-pointer w-min"
      onClick={handleOnClick}
    >
      <BackIcon />
      <p>{t('common.buttons.back')}</p>
    </div>
  );
};
export default BackButton;
