import { ArrowIcon } from '@components/icons/ArrowIcon';
import {
  type ActivityLogEntry,
  type ActivityLogs,
} from 'interfaces/activityLog/ActivityLog.interface';
import { type FC } from 'react';
import Avatar from 'react-avatar';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { createLog } from './createLog';

const LogList: FC<{
  data?: ActivityLogs;
  type?: 'default' | 'min';
}> = ({ data, type = 'default' }) => {
  return (
    <div className="flex flex-col max-h-[400px] overflow-auto relative">
      {data?.activity?.map((log: ActivityLogEntry, index: number) => (
        <div key={index} className="flex gap-4 py-5 items-start relative ">
          {index + 1 < data.activity.length && (
            <div className="absolute h-full border-r border-step border-dashed left-2 z-0" />
          )}

          {type === 'default' ? (
            <Avatar
              name={log.assigned_from.name ?? '*'}
              className="rounded-full cursor-pointer z-10"
              textSizeRatio={1.8}
              size={'18px'}
            />
          ) : (
            <div className="min-w-[18px] h-[18px] rounded-full z-10 bg-primary-light" />
          )}

          <div>
            {type === 'min' ? (
              <div className="flex items-start gap-2">
                <span>
                  {createLog({
                    fieldName: log.field_name,
                    assignedFrom: log.assigned_from,
                    assignedTo: log.assigned_to,
                    title: log.title,
                    value: log.new_value,
                    type,
                  })}
                </span>
                {log.assigned_from.name && (
                  <div className="flex justify-start items-center max-w-[200px]">
                    <ArrowIcon />
                    <span className="text-gray-60 truncate">
                      {log.assigned_from.name}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              <p>
                {createLog({
                  fieldName: log.field_name,
                  assignedFrom: log.assigned_from,
                  assignedTo: log.assigned_to,
                  title: log.title,
                  value: log.new_value,
                })}
              </p>
            )}

            <span className="text-xs font-extralight text-gray-50">
              {convertToReadableDate(log.created_at)}
            </span>
            {log.field_name === 'activity' && type === 'default' && (
              <UserComment message={log.new_value} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const UserComment: FC<{ message?: string }> = ({ message }) => {
  return (
    <div className="px-4 py-2 mt-4 border rounded-md bg-gray-10 text-gray-50">
      <p>{message}</p>
    </div>
  );
};

export default LogList;
