import type React from 'react';
import { useCallback, useId, useState } from 'react';

export interface UseDisclosureProps {
  isOpen?: boolean;
  defaultIsOpen?: boolean;
  handleClose?: () => void;
  handleOpen?: () => void;
  id?: string;
}

type HTMLProps = React.HTMLAttributes<HTMLElement>;

const useDisclosure = (
  props: UseDisclosureProps = {}
): {
  isOpen: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  onToggle: () => void;
  isControlled: boolean;
  getButtonProps: () => HTMLProps;
  getDisclosureProps: () => HTMLProps;
} => {
  const {
    handleClose: handleCloseProps,
    handleOpen: handleOpenProps,
    isOpen: isOpenProp,
    id: idProp,
  } = props;

  const [isOpenState, setIsOpen] = useState(props.defaultIsOpen ?? false);

  const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenState;

  const isControlled = isOpenProp !== undefined;

  const uid = useId();
  const id = idProp ?? `disclosure-${uid}`;

  const handleClose = useCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    handleCloseProps?.();
  }, [isControlled, handleCloseProps]);

  const handleOpen = useCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    handleOpenProps?.();
  }, [isControlled, handleOpenProps]);

  const onToggle = useCallback(() => {
    if (isOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  }, [isOpen, handleOpen, handleClose]);

  function getButtonProps(props: HTMLProps = {}): HTMLProps {
    return {
      ...props,
      'aria-expanded': isOpen,
      'aria-controls': id,
      onClick(event) {
        props.onClick?.(event);
        onToggle();
      },
    };
  }

  function getDisclosureProps(props: HTMLProps = {}): HTMLProps {
    return {
      ...props,
      hidden: !isOpen,
      id,
    };
  }

  return {
    isOpen,
    handleOpen,
    handleClose,
    onToggle,
    isControlled,
    getButtonProps,
    getDisclosureProps,
  };
};

export default useDisclosure;

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
