import { type FC, type SVGProps } from 'react';

const WaterIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="current"
    {...props}
  >
    <path
      d="M3.75736 4.42233L8 0.179688L12.2427 4.42233C14.5858 6.76546 14.5858 10.5645 12.2427 12.9076C9.89947 15.2507 6.10051 15.2507 3.75736 12.9076C1.41421 10.5645 1.41421 6.76546 3.75736 4.42233Z"
      fill="current"
    />
  </svg>
);
export default WaterIcon;
