import { type FC, type SVGProps } from 'react';

const HeatIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 16 16"
    fill="current"
    {...props}
  >
    <path
      d="M12.3335 6.00033C13.0002 6.70739 13.3335 7.59626 13.3335 8.66699C13.3335 10.9779 10.8668 11.5177 9.66683 14.667C9.22236 14.2839 9.00016 13.7284 9.00016 13.0003C9.00016 10.6791 12.3335 9.47373 12.3335 6.00033ZM9.66683 3.33366C10.4668 4.15927 10.8668 5.04816 10.8668 6.00033C10.8668 9.30119 6.83696 9.79486 7.66683 14.667C6.55572 13.8931 6.00016 12.7821 6.00016 11.3337C6.00016 9.11699 9.66683 7.33366 9.66683 3.33366ZM6.66683 0.666992C7.5557 1.77811 8.00016 2.77811 8.00016 3.66699C8.00016 7.83366 2.3335 9.14819 5.3335 14.667C3.58918 14.2805 2.3335 12.667 2.3335 10.667C2.3335 6.33366 6.66683 5.66699 6.66683 0.666992Z"
      fill="current"
    />
  </svg>
);
export default HeatIcon;
