import { type FC, type SVGProps } from 'react';

const ThumbsUpIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M1.33301 6L3.33301 6L3.33301 14L1.33301 14C0.964808 14 0.666341 13.7015 0.666341 13.3333L0.666341 6.66667C0.666342 6.29847 0.964808 6 1.33301 6ZM4.86161 5.13807L9.12861 0.871067C9.24588 0.753801 9.43158 0.740601 9.56429 0.840134L10.1327 1.2664C10.4558 1.50873 10.6014 1.92173 10.5018 2.31313L9.73289 5.33333L13.9997 5.33333C14.7361 5.33333 15.333 5.93027 15.333 6.66667L15.333 8.06953C15.333 8.24373 15.2989 8.4162 15.2326 8.5772L13.1697 13.5872C13.0668 13.837 12.8234 14 12.5532 14L5.33301 14C4.96481 14 4.66634 13.7015 4.66634 13.3333L4.66634 5.60947C4.66634 5.43267 4.73661 5.26307 4.86161 5.13807Z"
      fill="#8BD35D"
    />
  </svg>
);
export default ThumbsUpIcon;
