import { type FC, type SVGProps } from 'react';

const EuroIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M12.0049 22.0039C6.48204 22.0039 2.00488 17.5268 2.00488 12.0039C2.00488 6.48106 6.48204 2.00391 12.0049 2.00391C17.5277 2.00391 22.0049 6.48106 22.0049 12.0039C22.0049 17.5268 17.5277 22.0039 12.0049 22.0039ZM12.0049 20.0039C16.4232 20.0039 20.0049 16.4222 20.0049 12.0039C20.0049 7.58563 16.4232 4.00391 12.0049 4.00391C7.5866 4.00391 4.00488 7.58563 4.00488 12.0039C4.00488 16.4222 7.5866 20.0039 12.0049 20.0039ZM10.0549 11.0039H15.0049V13.0039H10.0549C10.2865 14.1451 11.2954 15.0039 12.5049 15.0039C13.1201 15.0039 13.6833 14.7818 14.1188 14.4132L15.8198 15.5472C14.9973 16.4427 13.8166 17.0039 12.5049 17.0039C10.1886 17.0039 8.28107 15.2539 8.03235 13.0039H7.00488V11.0039H8.03235C8.28107 8.75393 10.1886 7.00391 12.5049 7.00391C13.8166 7.00391 14.9973 7.56518 15.8198 8.46067L14.1189 9.59467C13.6834 9.22612 13.1201 9.00391 12.5049 9.00391C11.2954 9.00391 10.2865 9.86279 10.0549 11.0039Z"
      fill="#3E2069"
    />
  </svg>
);
export default EuroIcon;
