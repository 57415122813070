import useDisclosure from 'hooks/useDisclosure';
import { type ProjectBaseline } from 'interfaces/projects/ProjectData.interface';
import { Button } from 'layout/Button';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModelAndSavingsDetailsModal } from '../ModelAndSavingsDetailsModal';

interface ModelAndSavingsDetailsProps {
  isLoading: boolean;
  referencePeriodStart?: string;
  referencePeriodEnd?: string;
  data?: ProjectBaseline;
}
const ModelAndSavingsDetails: FC<ModelAndSavingsDetailsProps> = ({
  data,
  referencePeriodStart,
  referencePeriodEnd,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { isOpen, handleClose, handleOpen } = useDisclosure();

  return (
    <CardContainer>
      <CardTitle
        title={t('projectsDetailsPage.modelAndSavingsDetails.title')}
        style={{ fontSize: 14 }}
        isLoading={isLoading}
      />
      <div className="flex flex-row justify-between gap-6">
        <p>{t('projectsDetailsPage.modelAndSavingsDetails.description')}</p>
        <Button
          label={'See details'}
          variant="secondary"
          onClick={handleOpen}
        />
      </div>
      <ModelAndSavingsDetailsModal
        data={data}
        referencePeriodStart={referencePeriodStart}
        referencePeriodEnd={referencePeriodEnd}
        isOpen={isOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        isLoading={isLoading}
      />
    </CardContainer>
  );
};
export default ModelAndSavingsDetails;
