import styled from 'styled-components';
import tw from 'twin.macro';
interface FilterProps {
  $isActive?: boolean;
}

export const Filter = styled.div<FilterProps>`
  ${tw`flex-1 flex justify-center gap-1 p-2.5 cursor-pointer min-w-fit`}
  ${(props) =>
    props.$isActive ? tw`text-primary-light font-bold` : tw`text-gray-50`}
`;
