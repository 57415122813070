import styled from 'styled-components';
import tw from 'twin.macro';

interface StyledButtonProps {
  $active: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${tw`bg-white text-secondary text-base border-2 border-secondary rounded-lg min-w-[140px] min-h-[40px] py-2 px-4`}
  ${(props) => props.$active && tw`bg-secondary text-white`}
`;
