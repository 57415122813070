import SearchIcon from 'components/icons/SearchIcon/SearchIcon';
import { type FC, type Ref, forwardRef, useEffect } from 'react';
import { TextInput } from '../TextInput';
import { type TextInputProps } from '../TextInput/TextInput';

interface SearchBarProps extends TextInputProps {
  iconPosition?: 'start' | 'end';
  onRender?: () => void;
  ref?: any;
}

const SearchBar: FC<SearchBarProps> = forwardRef(
  (
    { iconPosition = 'start', onRender, ...props },
    ref: Ref<HTMLInputElement>
  ) => {
    useEffect(() => {
      onRender?.();
    }, []);

    return (
      <TextInput
        addorment={
          iconPosition === 'start'
            ? { startAddorment: <SearchIcon role="icon" /> }
            : { endAddorment: <SearchIcon role="icon" /> }
        }
        placeholder="Search"
        ref={ref}
        {...props}
      />
    );
  }
);
export default SearchBar;
