import { FSelectField } from 'components/form/FSelectField';
import { FTextField } from 'components/form/FTextField';
import { type FC, useMemo, useState } from 'react';
import { manageUserControlNames } from './schema';
import { ROLES, USER_FUNCTIONS } from 'lib/constants';
import { PhoneNumberInput } from '@components/inputs/PhoneNumberInput';
import { SkeletonInput } from '@components/skeleton';
import Skeleton from 'react-loading-skeleton';
import { useListAssets, useListGroups } from 'api/assets/assetsServices';
import { type Asset } from 'interfaces/assets/Asset.interface';
import { type Group } from 'interfaces/group/Group.interface';
import { FMultiComboBox } from '@components/form/FMultiComboBox';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { useTranslation } from 'react-i18next';

interface ManageUserFormProps {
  initialValues?: any;
  isLoading?: boolean;
  setMetersQuery: (value: string) => void;
  meterOptions: DropdownOptions[];
  isFetchingMeterList: boolean;
  metersQuery: string;
}

const ManageUserForm: FC<ManageUserFormProps> = ({
  initialValues,
  isLoading,
  metersQuery,
  setMetersQuery,
  meterOptions,
  isFetchingMeterList,
}) => {
  const skeletonInputs = new Array(9).fill(null);

  const [assetsQuery, setAssetsQuery] = useState('');
  const [groupsQuery, setGroupsQuery] = useState('');
  const { t } = useTranslation();
  const { data: assetList, isFetching: isFetchingAssetList } = useListAssets({
    query: assetsQuery,
  });

  const { data: groupList, isFetching: isFetchingGroupList } = useListGroups({
    query: groupsQuery,
  });

  const assetOptions = useMemo(
    () =>
      assetList?.pages?.flatMap(
        (page: any) =>
          page?.assets.map((asset: Asset) => ({
            name: asset.name,
            value: asset.id,
          }))
      ) ?? [],
    [assetList]
  );

  const groupOptions = useMemo(
    () =>
      groupList?.pages?.flatMap(
        (page: any) =>
          page?.groups.map((group: Group) => ({
            name: group.name,
            value: group.id,
          }))
      ) ?? [],
    [groupList]
  );

  return (
    <form>
      <div className="flex flex-col gap-6">
        {isLoading ? (
          <>
            {skeletonInputs.map((_, index) => {
              if (index === 3) {
                return (
                  <div className="flex gap-4" key={index}>
                    <div className="flex-1 max-w-[160px]">
                      <Skeleton width={100} />
                      <SkeletonInput />
                    </div>
                    <div className="flex-1">
                      <Skeleton width={100} />
                      <SkeletonInput />
                    </div>
                  </div>
                );
              }
              return (
                <div className="flex flex-col gap-2" key={index}>
                  <Skeleton width={100} />
                  <SkeletonInput />
                </div>
              );
            })}
          </>
        ) : (
          <>
            <FTextField
              label="Name"
              name={manageUserControlNames.given_name}
              placeholder="Enter the user name"
              data-testid="name"
              id="user-name"
            />
            <FTextField
              label="Family Name"
              name={manageUserControlNames.family_name}
              placeholder="Enter the user family name"
              data-testid="family_name"
              id="family-name"
            />
            <FTextField
              label="Email"
              name={manageUserControlNames.email}
              placeholder="Enter the user email"
              data-testid="email"
              id="email"
            />
            <PhoneNumberInput
              name={manageUserControlNames.phone_number}
              label={'Phone Number'}
              labelTooltip={t('settingsPage.usersTab.tooltips.phone')}
            />
            <FSelectField
              options={ROLES}
              label="Role"
              name={manageUserControlNames.role}
              placeholder="Select role"
              data-testid="role"
              id="role"
              labelTooltip={t('settingsPage.usersTab.tooltips.role')}
            />
            <FSelectField
              options={USER_FUNCTIONS}
              label="Function"
              name={manageUserControlNames.function}
              placeholder="Select function"
              data-testid="function"
              id="function"
              labelTooltip={t('settingsPage.usersTab.tooltips.function')}
            />
            <FMultiComboBox
              selectAll={true}
              id="meters"
              label="Meters"
              name={manageUserControlNames.meters}
              options={meterOptions}
              query={metersQuery}
              isFetching={isFetchingMeterList}
              handleQuery={setMetersQuery}
              disableSelectedList={true}
              placeholder="Select meters"
              initialValues={initialValues?.meters as DropdownOptions[]}
              labelTooltip={t('settingsPage.usersTab.tooltips.meters')}
            />
            <FMultiComboBox
              selectAll={true}
              label="Assets"
              name={manageUserControlNames.assets}
              options={assetOptions}
              placeholder="Select assets"
              query={assetsQuery}
              isFetching={isFetchingAssetList}
              handleQuery={setAssetsQuery}
              disableSelectedList={true}
              initialValues={initialValues?.assets as DropdownOptions[]}
              labelTooltip={t('settingsPage.usersTab.tooltips.assets')}
            />
            <FMultiComboBox
              selectAll={true}
              label="Groups"
              name={manageUserControlNames.groups}
              options={groupOptions}
              placeholder="Select groups"
              query={groupsQuery}
              isFetching={isFetchingGroupList}
              handleQuery={setGroupsQuery}
              disableSelectedList={true}
              initialValues={initialValues?.groups as DropdownOptions[]}
              labelTooltip={t('settingsPage.usersTab.tooltips.groups')}
            />
          </>
        )}
      </div>
    </form>
  );
};
export default ManageUserForm;
