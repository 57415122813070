import { LoadingSpinner } from '../../components/loadingSpinner';
import { type FC } from 'react';
import { StyledButton } from './styled';

export interface ButtonProps {
  label: string;
  onClick?: () => void | Promise<void>;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  variant?: 'primary' | 'secondary' | 'gray';
  size?: 'normal' | 'large';
  isLoading?: boolean;
  shadowBox?: boolean;
  minWidth?: string;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  label,
  onClick,
  type = 'button',
  variant = 'primary',
  size = 'normal',
  minWidth = '140',
  className,
  isLoading,
  ...props
}) => {
  return (
    <StyledButton
      style={{ minWidth: `${minWidth}px` }}
      type={type}
      onClick={onClick}
      className={className}
      $variant={variant}
      $size={size}
      disabled={isLoading}
      aria-role="button"
      role="button"
      {...props}
    >
      {isLoading ? <LoadingSpinner /> : label}
    </StyledButton>
  );
};
export default Button;
