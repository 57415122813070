import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { StyledButton } from './styled';
import { type FC } from 'react';
import Skeleton from 'react-loading-skeleton';

interface ChartButtonOption {
  label: string;
  value: string;
  tooltip?: string;
}

interface ChartButtonProps {
  activeChart: string;
  handleActiveChart: (value: string) => void;
  isLoading?: boolean;
  buttonList: ChartButtonOption[];
}

const ChartButton: FC<ChartButtonProps> = ({
  activeChart,
  handleActiveChart,
  isLoading = false,
  buttonList,
}) => {
  return (
    <div className="flex gap-4 mt-6 mb-2">
      {isLoading ? (
        <Skeleton height={'40px'} width={'180px'} />
      ) : (
        buttonList?.map((btn, index) => {
          return (
            <StyledButton
              key={btn.label}
              $active={activeChart === btn.value}
              onClick={() => {
                handleActiveChart(btn.value);
              }}
              data-active={activeChart === btn.value}
            >
              <div className="flex gap-2 items-center">
                <span className="text-base">{btn.label}</span>
                {btn.tooltip && <TooltipIconComponent content={btn.tooltip} />}
              </div>
            </StyledButton>
          );
        })
      )}
    </div>
  );
};
export default ChartButton;
