import { type Address } from 'interfaces/assets/Address.interface';

export const stringToCapitalize = (str: string): string => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const removeUnderlineAndAddWhitespace = (str?: string): string => {
  if (!str) return '';
  return str.replace(/_/g, ' ');
};

export const formatAddress = (address: Address): string => {
  return `${address?.street ?? ''} ${address?.number ?? ''},
  ${address?.zip_code ?? ''} - ${address?.city ?? ''}/${
    address?.country_iso ?? ''
  }`;
};
export interface ImpactTextColorOptions {
  reverse?: boolean;
  isGray?: boolean;
}

export const getImpactTextColorClassName = (
  value: number = 0,
  options: ImpactTextColorOptions = {}
): string => {
  const { reverse = false, isGray = false } = options;

  if (isGray) return 'text-gray-60';

  const positiveClass = reverse ? 'text-success' : 'text-error';
  const negativeClass = reverse ? 'text-error' : 'text-success';

  if (value > 0) return positiveClass;
  if (value < 0) return negativeClass;

  return 'text-gray-60';
};
