import { type ControlledInput } from 'interfaces/inputs/ControlledInput.interface';
import { type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextInput, {
  type TextInputProps,
} from '../../inputs/TextInput/TextInput';
import FLabel from '../FLabel/FLabel';

export interface FTextFieldProps extends ControlledInput, TextInputProps {
  label?: string;
}

const FTextField: FC<FTextFieldProps> = ({
  label,
  name,
  id,
  ...props
}: FTextFieldProps) => {
  const { control } = useFormContext();

  return (
    <div className="flex flex-1 flex-col gap-2">
      {label && <FLabel id={id}>{label}</FLabel>}
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => {
          return (
            <TextInput
              id={id}
              error={error}
              data-testid={`${name}-field`}
              {...field}
              {...props}
            />
          );
        }}
      />
    </div>
  );
};
export default FTextField;
