import { Container } from '@components/container';
import { Currency } from '@components/currency';
import Tippy from '@tippyjs/react';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiQuestionMarkCircle } from 'react-icons/hi';
import Skeleton from 'react-loading-skeleton';
import { getImpactTextColorClassName } from 'utils/formatters/string/stringFormatter';

interface ImpactCardProps {
  impact?: {
    perDay?: number;
    total?: number;
  };
  isFetched: boolean;
  type: 'euros' | string;
  isMissingData?: boolean;
}

const ImpactCard: FC<ImpactCardProps> = ({
  impact,
  type,
  isFetched,
  isMissingData,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const addorment = type === 'euros' ? '€' : type;
  const name = type === 'euros' ? 'Euros' : type;
  return (
    <Container className="flex text-base py-4 px-6">
      <div className="flex flex-col w-full">
        <p className="font-bold mb-2">
          {isFetched ? (
            t('anomaliesPage.sideMenu.impactInTime', { time: name })
          ) : (
            <Skeleton width={'200px'} />
          )}
        </p>
        <div className="flex justify-between gap-6">
          <div className="flex flex-col flex-1 bg-gray-10 py-2 px-4 rounded-lg">
            <p className="text-gray-50 text-2xl">
              {isFetched ? (
                <div className="flex gap-2.5">
                  <Currency
                    value={impact?.perDay}
                    addorment={addorment}
                    className={getImpactTextColorClassName(impact?.perDay)}
                  />
                </div>
              ) : (
                <Skeleton />
              )}
            </p>
            <p>
              {isFetched ? (
                t('anomaliesPage.sideMenu.impactPerDay')
              ) : (
                <Skeleton />
              )}
            </p>
          </div>
          <div className="flex flex-col flex-1 bg-gray-10 py-2 px-4">
            <p className="text-gray-50 text-2xl">
              {isFetched ? (
                <div className="flex gap-2.5 items-center">
                  <Currency
                    value={impact?.total}
                    addorment={addorment}
                    className={getImpactTextColorClassName(impact?.total)}
                  />
                  {isMissingData && (
                    <Tippy
                      content={
                        'The value may be incorrectly calculated due to missing data. Contact an administrator for more details'
                      }
                      className="bg-red"
                    >
                      <span>
                        <HiQuestionMarkCircle
                          color={theme.colors.error}
                          size={20}
                        />
                      </span>
                    </Tippy>
                  )}
                </div>
              ) : (
                <Skeleton />
              )}
            </p>
            <p>
              {isFetched ? (
                t('anomaliesPage.sideMenu.totalImpact')
              ) : (
                <Skeleton />
              )}
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default ImpactCard;
