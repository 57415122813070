import { Modal } from 'components/modal';
import { type FC } from 'react';
import { useFetchUser } from 'api/user/userService';
import { normalizeFieldValue } from 'utils/normalizeFieldValue';
import Skeleton from 'react-loading-skeleton';
import Accordion from '@components/accordion/Accordion';
import { AccordionSection } from '@components/accordionSection';
import { useTranslation } from 'react-i18next';

interface UserDetailsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleEditOpen: () => void;
  selectedUserId?: number | null;
}
const UserDetailsModal: FC<UserDetailsModalProps> = ({
  isOpen,
  handleClose,
  handleOpen,
  handleEditOpen,
  selectedUserId,
}) => {
  const { t } = useTranslation();

  const { data, isFetching } = useFetchUser(selectedUserId);
  const userData = data?.user ?? undefined;

  const getReadableRole = (roleEnum: string | undefined): string => {
    if (roleEnum === undefined) {
      return ''; // Return an empty string if roleEnum is undefined
    }
    // Convert the first character to uppercase and the rest to lowercase
    const result =
      roleEnum.charAt(0).toUpperCase() + roleEnum.slice(1).toLowerCase();

    return result;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      onOpen={handleOpen}
      actionBtns={{
        secondary: {
          label: t('common.buttons.edit'),
          onClick: () => {
            handleClose();
            handleEditOpen();
          },
        },
      }}
      maxWidth={600}
      title={t('settingsPage.usersTab.userDetailsModal.title')}
    >
      <div className="flex flex-col gap-4">
        <UserDetail
          label={t('common.name')}
          value={userData?.given_name}
          isLoading={isFetching}
          skeletonSize={'180px'}
        />
        <UserDetail
          label={t('common.role')}
          value={getReadableRole(userData?.role)}
          isLoading={isFetching}
          skeletonSize={'120px'}
        />
        <UserDetail
          label={t('common.function')}
          value={normalizeFieldValue(userData?.function)}
          isLoading={isFetching}
          skeletonSize={'140px'}
        />
        <AccordionSection title={t('common.groups')} isLoading={isFetching}>
          {userData?.groups && userData.groups.length > 0 ? (
            userData?.groups.map((group) => (
              <Accordion
                key={group.id}
                title={group.name}
                // Temporary workaround, we are still using mocked data for meters and buildings here
                // items={group?.building.concat(group?.meters as any)}
              />
            ))
          ) : (
            <Accordion
              title={t('common.empty.accordion', {
                value: t('common.groups').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>
        <AccordionSection title={t('common.meters')} isLoading={isFetching}>
          <div className="max-h-[500px] overflow-y-scroll flex flex-col gap-4">
            {userData?.meters && userData.meters.length > 0 ? (
              userData?.meters?.map((meter) => (
                <Accordion key={meter.id} title={meter.name} items={[]} />
              ))
            ) : (
              <Accordion
                title={t('common.empty.accordion', {
                  value: t('common.meters').toLowerCase(),
                })}
              />
            )}
          </div>
        </AccordionSection>
        <AccordionSection title={t('common.assets')} isLoading={isFetching}>
          {userData?.assets && userData.assets.length > 0 ? (
            userData?.assets?.map((asset) => (
              <Accordion
                key={asset.id}
                title={asset.name}
                items={asset?.meters}
              />
            ))
          ) : (
            <Accordion
              title={t('common.empty.accordion', {
                value: t('common.assets').toLowerCase(),
              })}
            />
          )}
        </AccordionSection>
      </div>
    </Modal>
  );
};

const UserDetail: FC<{
  label: string;
  value: string | undefined;
  isLoading?: boolean;
  skeletonSize: string;
}> = ({ label, value, skeletonSize, isLoading = false }) => {
  return (
    <div className="flex gap-2 justify-between items-center text-base">
      {isLoading ? (
        <Skeleton containerClassName="flex-1 max-w-[160px] " />
      ) : (
        <label className="font-bold text-gray-60">{label ?? ''}</label>
      )}
      {isLoading ? (
        <div className="flex justify-end">
          <Skeleton containerClassName="flex-1" width={skeletonSize} />
        </div>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};
export default UserDetailsModal;
