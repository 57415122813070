import { type SortingState, type Updater } from '@tanstack/react-table';
import { useURLSearchParams } from 'hooks/useURLSearchParams.tsx';
import { useEffect, useState } from 'react';

interface UseServerSideTableSortingParams {
  addSortToUrlParams?: boolean;
}

const useServerSideTableSorting = ({
  addSortToUrlParams = false,
}: UseServerSideTableSortingParams = {}): {
  handleSortChange: (item: Updater<SortingState>) => SortingState | undefined;
  orderBy: string | undefined;
} => {
  const [orderBy, setOrderBy] = useState<string | undefined>();
  const [activeSort, setActiveSort] = useState({
    id: '',
    desc: false,
  });
  const { addURLSearchParams, getURLSearchParams } = useURLSearchParams();

  const handleSortChange = (
    item: Updater<SortingState>
  ): SortingState | undefined => {
    if (typeof item !== 'function') {
      return;
    }

    let sortValue = item([activeSort]);
    if (!sortValue?.length) {
      if (activeSort.id) {
        sortValue = [
          {
            id: activeSort.id,
            desc: !activeSort.desc,
          },
        ];
      } else {
        return;
      }
    }

    const { id: sortByColumnName, desc } = sortValue[0];
    const queryOrderBy = desc ? `-${sortByColumnName}` : sortByColumnName;

    if (addSortToUrlParams) {
      addURLSearchParams('sort', sortByColumnName);
      addURLSearchParams('order', desc ? 'desc' : 'asc');
    }
    setOrderBy(queryOrderBy);

    return sortValue;
  };

  useEffect(() => {
    if (addSortToUrlParams) {
      const sortValue = getURLSearchParams('sort');

      if (sortValue) {
        const order = getURLSearchParams('order');
        const isDesc = order === 'desc';

        const orderBy = isDesc ? `-${sortValue}` : sortValue;

        setOrderBy(orderBy);
        setActiveSort({
          desc: isDesc,
          id: sortValue,
        });
      }
    }
  }, [location.search]);

  return { handleSortChange, orderBy };
};
export default useServerSideTableSorting;
