import { FSearchMeterField } from '@components/form/FSearchMeterInput';
import { FTextField } from '@components/form/FTextField';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { SkeletonInput } from '@components/skeleton';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { type GroupSchemaType, groupControlNames } from './schema';

interface ManageGroupFromProps {
  initialValues?: GroupSchemaType;
  isFetching?: boolean;
}

const ManageGroupForm: FC<ManageGroupFromProps> = ({
  initialValues,
  isFetching = false,
}) => {
  const { t } = useTranslation();

  return (
    <form className="h-full">
      <div className="flex flex-col justify-start gap-6">
        <div>
          {isFetching ? (
            <SkeletonInput withLabel={true} labelWidth={100} />
          ) : (
            <FTextField
              id="group-name"
              label={t('common.name')}
              name={groupControlNames.name}
              placeholder={t('assetsPage.group.placeholders.enterGroupName')}
            />
          )}
        </div>

        <div>
          {isFetching ? (
            <SkeletonInput withLabel={true} labelWidth={80} />
          ) : (
            <FTextField
              id="group-description"
              label={t('common.description')}
              name={groupControlNames.description}
              placeholder={t('assetsPage.group.placeholders.enterDescription')}
            />
          )}
        </div>

        <div>
          <FSearchMeterField
            label={t('common.meter')}
            subLabel={t('assetsPage.group.meterSubtitle')}
            name={groupControlNames.meters}
            initialValues={initialValues?.meters as DropdownOptions[]}
            isFetching={isFetching}
          />
        </div>
      </div>
    </form>
  );
};
export default ManageGroupForm;
