import { Modal } from '@components/modal';
import { type Disclosure } from 'interfaces/disclosure/Disclosure.interface';
import { type AnchorHTMLAttributes, type FC } from 'react';
import Markdown from 'react-markdown';

interface HowToIntegrateModalProps extends Disclosure {}

const HowToIntegrateModal: FC<HowToIntegrateModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const text =
    "**Step 1**: Creating a Dashboard on Luzmo Platform \n\nWelcome to Luzmo! Let's get started by creating a dashboard on our platform. [Click here](https://app.luzmo.com/home/login?returnUrl=%2Fdashboards). \n\n**Step 2**: Duplicate Dashboards for **STAGING** (STG) and **PRODUCTION** (PRD) \n\nIt's important to have separate dashboards for staging and production environments to ensure smooth deployment and testing. We'll create two dashboards of the same type: one for STAGING and the other for PRODUCTION. \n\n**Step 3**: Naming Convention \n\nWhen naming the dashboard, follow this pattern: <Dashboard_Name> - <Environment>. For example, if your dashboard is named 'My Custom Dashboard' the STAGING version should be named 'My Custom Dashboard - STG' and the PRODUCTION version 'My Custom Dashboard - PRD' \n\n And that's it! You're all set to create and manage your dashboards on Enersee's platform.";

  return (
    <Modal
      title="How to Integrate Luzmo Dashboard"
      isOpen={isOpen}
      onClose={handleClose}
      actionBtns={{
        secondary: {
          label: 'Close',
          onClick: handleClose,
        },
      }}
      maxWidth={500}
    >
      <Markdown
        components={{ a: LinkRenderer }}
        className={'whitespace-pre-wrap'}
      >
        {text}
      </Markdown>
    </Modal>
  );
};

const LinkRenderer: FC<AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href,
  children,
}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};

export default HowToIntegrateModal;
