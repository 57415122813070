import { DoubleLayerProgressBar } from '@components/progressBar';
import {
  type DoubleLayerProgressBarProps,
  getColorByPercentage,
  getCurrentPercentage,
} from '@components/progressBar/DoubleLayerProgressBar';
import { type FC } from 'react';
import { numericFormatter } from 'react-number-format';
import { convertToPercentageNumber } from 'utils/formatters/number/numberUtils';

interface ImpactTableProgressBarProps extends DoubleLayerProgressBarProps {}

const ImpactTableProgressBar: FC<ImpactTableProgressBarProps> = ({
  completed,
  maxCompleted,
  maxWidth,
  minWidth,
  layerCompleted,
  tooltipProjects,
}) => {
  const layerPercentage = getCurrentPercentage(layerCompleted, maxCompleted);
  const textColor = getColorByPercentage(layerPercentage);
  return (
    <div className="flex items-center gap-4">
      <DoubleLayerProgressBar
        completed={completed}
        maxCompleted={maxCompleted}
        maxWidth={maxWidth}
        minWidth={minWidth}
        layerCompleted={layerCompleted}
        tooltipProjects={tooltipProjects}
      />

      <div>
        <div className="flex gap-1 color-gray-50 w-[80px]">
          <span className="font-bold" style={{ color: textColor }}>
            {numericFormatter(
              convertToPercentageNumber(layerCompleted).toString(),
              {
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 1,
              }
            )}
            %
          </span>

          <span>/</span>
          <span>
            {numericFormatter(
              convertToPercentageNumber(maxCompleted).toString(),
              {
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 1,
              }
            )}
            %
          </span>
        </div>
      </div>
    </div>
  );
};
export default ImpactTableProgressBar;
