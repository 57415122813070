import { SelectCountryInput } from 'components/inputs/SelectCountryInput';
import { type FC, useEffect, useState } from 'react';

interface SelectAddressCountryProps {
  onChange?: (value: string) => void;
  defaultCountry?: string;
}

const SelectAddressCountry: FC<SelectAddressCountryProps> = ({
  onChange,
  defaultCountry = 'be',
}) => {
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const handleSelectCountry = (country: any): void => {
    setSelectedCountry(country);
  };
  useEffect(() => {
    if (onChange && selectedCountry) {
      onChange(selectedCountry);
    }
  }, [selectedCountry]);

  return (
    <SelectCountryInput
      value={selectedCountry}
      onChange={handleSelectCountry}
      disableCountryCode={true}
    />
  );
};
export default SelectAddressCountry;
