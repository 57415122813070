const formInitialValues = {
  email: '',
  password: '',
  name: '',
  family_name: '',
  businessNumber: '',
  company: {
    name: '',
    vat: '',
    address: {
      country: '',
      zipCode: '',
      street: '',
      number: '',
      city: '',
      province: '',
    },
  },
  code: '',
};

export const StepOneInitialValues = {
  email: formInitialValues.email,
  password: formInitialValues.password,
};

export const StepTwoInitialValues = {
  given_name: formInitialValues.name,
  family_name: formInitialValues.family_name,
  phone_number: formInitialValues.businessNumber,
};

export const StepThreeInitialValues = {
  company: {
    name: formInitialValues.company.name,
    vat_number: formInitialValues.company.vat,
    address: {
      country_iso: formInitialValues.company.address.country,
      zip_code: formInitialValues.company.address.zipCode,
      street: formInitialValues.company.address.street,
      number: formInitialValues.company.address.number,
      city: formInitialValues.company.address.city,
      province: formInitialValues.company.address.province,
    },
  },
};

export const StepFourInitialValues = {
  code: formInitialValues.code,
};
