import { type Options, createPopper } from '@popperjs/core';
import { type RefCallback, useCallback, useRef } from 'react';

export function usePopper(
  options?: Partial<Options>
): [RefCallback<Element | null>, RefCallback<HTMLElement | null>] {
  const reference = useRef<Element | null>(null);
  const popper = useRef<HTMLElement | null>(null);

  const cleanupCallback = useRef<() => void>(() => {});

  const instantiatePopper = useCallback(() => {
    if (reference.current && popper.current) {
      cleanupCallback.current();
      cleanupCallback.current = createPopper(
        reference.current,
        popper.current,
        options
      ).destroy;
    }
  }, [reference, popper, cleanupCallback, options]);

  const setReference = useCallback(
    (referenceDomNode: Element) => {
      reference.current = referenceDomNode;
      instantiatePopper();
    },
    [instantiatePopper]
  );

  const setPopper = useCallback(
    (popperDomNode: HTMLElement) => {
      popper.current = popperDomNode;
      instantiatePopper();
    },
    [instantiatePopper]
  );

  return [setReference, setPopper];
}
