import { ConfirmModal } from '@components/confirmModal';
import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { useDeleteUser, useFetchCompanyUsers } from 'api/user/userService';
import { useAuth } from 'hooks/contexts/AuthContext';
import useDisclosure from 'hooks/useDisclosure';
import { t } from 'i18next';
import { type UserResponse } from 'interfaces/user/UserResponse.interface';
import { EditUserModal } from 'pages/SettingsPage/components/EditUserModal';
import { UserDetailsModal } from 'pages/SettingsPage/components/UserDetailsModal';
import { useGetUserTableColumns } from 'pages/SettingsPage/hooks/useGetUserTableColumns';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';

interface UsersTableProps {
  query: string;
  roles: string;
  functions: string;
}

const UsersTable: FC<UsersTableProps> = ({ query, roles, functions }) => {
  const [selectedUser, setSelectedUser] = useState<number | undefined>();
  const { user } = useAuth();

  const {
    data: usersListData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
  } = useFetchCompanyUsers({ query, roles, functions });
  const { mutate: deleteUserMutate, isSuccess } = useDeleteUser();

  const {
    isOpen: isEditUserOpen,
    handleOpen: handleEditUserOpen,
    handleClose: handleEditUserClose,
  } = useDisclosure();
  const {
    isOpen: isUserDetailsOpen,
    handleOpen: handleUserDetailsOpen,
    handleClose: handleUserDetailsClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    handleOpen: handleDeleteOpen,
    handleClose: handleDeleteClose,
  } = useDisclosure();

  const flatData: UserResponse[] = useMemo(() => {
    if (usersListData?.pages?.[0] === undefined) {
      return [];
    }
    return (
      usersListData?.pages?.flatMap((page: any) => {
        return page?.users;
      }) ?? []
    );
  }, [usersListData]);

  const totalDBRowCount = usersListData?.pages?.[0]?.total_users ?? 0;

  const createTableActions = (id: number): ReactNode => {
    const actions = [
      {
        name: t('common.actions.details'),
        onClick: () => {
          setSelectedUser(id);
          handleUserDetailsOpen();
        },
      },
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setSelectedUser(id);
          handleEditUserOpen();
        },
      },
      {
        name: t('common.actions.delete'),
        onClick: () => {
          setSelectedUser(id);
          handleDeleteOpen();
        },
      },
    ];

    return <TableActions actions={actions} />;
  };

  const { columns } = useGetUserTableColumns({ createTableActions });

  const handleRowClick = (user?: UserResponse): void => {
    setSelectedUser(user?.id);
    handleUserDetailsOpen();
  };

  const handleDeleteUser = (): void => {
    if (selectedUser) {
      deleteUserMutate(selectedUser);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleDeleteClose();
    }
  }, [isSuccess]);

  return (
    <>
      <Table
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        data={flatData}
        columns={columns}
        onClickRow={handleRowClick}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        rowEstimateSize={75}
      />

      <EditUserModal
        selectedUserId={selectedUser}
        companyId={user?.company_id}
        isOpen={isEditUserOpen}
        handleClose={handleEditUserClose}
        handleOpen={handleEditUserOpen}
        handleDeleteUser={handleDeleteUser}
      />

      <UserDetailsModal
        isOpen={isUserDetailsOpen}
        handleClose={handleUserDetailsClose}
        handleOpen={handleUserDetailsOpen}
        handleEditOpen={handleEditUserOpen}
        selectedUserId={selectedUser}
      />

      <ConfirmModal
        isOpen={isDeleteOpen}
        handleOpen={handleDeleteOpen}
        handleClose={handleDeleteClose}
        onConfirm={handleDeleteUser}
        contentText={t('common.confirmModal.delete')}
      />
    </>
  );
};
export default UsersTable;
