import { InfoIcon } from '@components/icons/InfoIcon';
import Tippy from '@tippyjs/react';
import { type FC } from 'react';

interface TooltipIconComponentProps {
  content: string;
  pb?: number;
  color?: string;
}

const TooltipIconComponent: FC<TooltipIconComponentProps> = ({
  content,
  pb = 0,
  color,
}) => {
  return (
    <Tippy content={content}>
      <span className={`pb-[${pb}px]`}>
        <InfoIcon fill={color} />
      </span>
    </Tippy>
  );
};
export default TooltipIconComponent;
