import { SearchBar } from '@components/inputs/SearchBar';
// import { RoleEnum } from 'enums/RoleEnum';
// import { useAuth } from 'hooks/contexts/AuthContext';
import { useDebounce } from 'hooks/useDebounce';
import useDisclosure from 'hooks/useDisclosure';
import { Button } from 'layout/Button';
import { type ChangeEvent, type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateGroupModal } from './modals/CreateGroupModal';
import GroupsTable from './Tables/GroupsTable';

const GroupsListPage: FC = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  // const { user } = useAuth();

  const {
    isOpen: isCreateGroupOpen,
    handleClose: handleCreateGroupClose,
    handleOpen: handleCreateGroupOpen,
  } = useDisclosure();

  const query = useDebounce(searchValue, 300);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  return (
    <div>
      <div className="absolute top-0 right-0 pt-2">
        <Button
          label={t('assetsPage.group.createNewGroup')}
          onClick={handleCreateGroupOpen}
        />
      </div>
      <h1>{t('common.groups')}</h1>
      <div className="flex gap-4 my-6 w-4/12">
        <div className="flex-[1.5_1_0%]">
          <SearchBar
            placeholder={t('anomaliesPage.placeholders.search')}
            onChange={handleSearchInput}
          />
        </div>
      </div>
      <div>
        <GroupsTable query={query} />
        <CreateGroupModal
          isOpen={isCreateGroupOpen}
          handleClose={handleCreateGroupClose}
        />
      </div>
    </div>
  );
};
export default GroupsListPage;
