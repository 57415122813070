import { type FC, type SVGProps } from 'react';

const ImpactChartLegendIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4563_29770)">
      <rect x="0.5" width="20" height="20" rx="2" fill="#BABFC7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-16.101 -22.9602L-23.5815 -21.4565L-47.0099 9.88667L-47.4463 5.91056L-27.5927 -20.6502L-35.0792 -19.1453L-54.2339 6.48047L-54.6703 2.50436L-39.0904 -18.3389L-46.5707 -16.8353L-61.4556 3.07812L-61.892 -0.897992L-50.5819 -16.0289L-58.0623 -14.5252L-68.6772 -0.324221L-69.1137 -4.30034L-62.0735 -13.7189L-69.5601 -12.214L-75.9014 -3.73047L-76.3378 -7.70658L-73.5713 -11.4076L-81.0513 -9.90403L-83.1228 -7.13277L-83.3757 -9.43679L-92.4999 -7.60265L37.2373 52.7659C47.0247 32.5276 50.8894 7.18354 49.9601 -15.3117L10.7705 37.1172L10.3341 33.1411L49.7255 -19.5579C49.5476 -22.1219 49.3062 -24.6408 49.003 -27.1022L3.54639 33.7109L3.10995 29.7348L48.4713 -30.9508C48.2198 -32.5891 47.9399 -34.1973 47.6321 -35.7717L45.38 -35.319L-3.67529 30.3086L-4.11173 26.3325L41.3688 -34.5127L33.8885 -33.009L-10.897 26.9063L-11.3334 22.9301L29.8773 -32.2027L22.3906 -30.6977L-18.1211 23.5L-18.5575 19.5239L18.3794 -29.8914L10.8996 -28.3878L-25.3423 20.0977L-25.7787 16.1215L6.88843 -27.5815L-0.598206 -26.0765L-32.5664 16.6914L-33.0028 12.7153L-4.60943 -25.2702L-12.0898 -23.7665L-39.7881 13.2891L-40.2245 9.31295L-16.101 -22.9602Z"
        fill="black"
        fillOpacity="0.4"
      />
    </g>
    <defs>
      <clipPath id="clip0_4563_29770">
        <rect x="0.5" width="20" height="20" rx="2" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default ImpactChartLegendIcon;
