import { type FC, type ReactNode } from 'react';

interface FLabelProps {
  children: ReactNode;
  id?: string;
}

const FLabel: FC<FLabelProps> = ({ children, id }) => {
  return (
    <label
      htmlFor={id}
      aria-labelledby={id}
      className="font-bold text-base text-gray-60"
      data-testid={id}
    >
      {children}
    </label>
  );
};
export default FLabel;
