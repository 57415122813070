import { SearchBar } from '@components/inputs/SearchBar';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import { PermissionWrapper } from '@components/PermissionWrapper';
import { SwitchButton } from '@components/switchButton';
import { useListAssets } from 'api/assets/assetsServices';
import { useDebounce } from 'hooks/useDebounce';
import useDisclosure from 'hooks/useDisclosure';
import { type Asset } from 'interfaces/assets/Asset.interface';
import { Button } from 'layout/Button';
import { UTILITY_TYPE } from 'lib/constants';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateProjectModal } from './modals/CreateProjectModal';
import { ProjectsTable } from './table';
import { FEATURE_FLAGS_LIST } from 'lib/featureFlagsList';

const ProjectsPage: FC = () => {
  const [query, setQuery] = useState('');
  const [utilityType, setUtilityType] = useState<string>();
  const [asset, setAsset] = useState<number>();
  const [showSavingsInUnit, setShowSavingsInUnit] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({
    savings_euro: true,
    savings_total: false,
  });
  const debouncedQuery = useDebounce(query, 300);

  const {
    isOpen: isCreateProjectOpen,
    handleClose: handleCreateProjectClose,
    handleOpen: handleCreateProjectOpen,
  } = useDisclosure();

  const { t } = useTranslation();

  const { data: assetList, isFetching: isFetchingAsset } = useListAssets({
    perPage: 150,
    type: 'BUILDING',
  });

  const assetOptions = useMemo(
    () =>
      assetList?.pages?.flatMap(
        (page: any) =>
          page?.assets.map((asset: Asset) => ({
            name: asset.name,
            value: asset.id,
          }))
      ) ?? [],
    [assetList]
  );

  useEffect(() => {
    if (showSavingsInUnit) {
      setColumnVisibility({ savings_euro: false, savings_total: true });
    } else {
      setColumnVisibility({ savings_euro: true, savings_total: false });
    }
  }, [showSavingsInUnit]);

  return (
    <div className="flex flex-col gap-6">
      <div>
        <h1 className="mb-6">Projects</h1>
        <div className="flex w-full justify-end items-center">
          <PermissionWrapper validate={FEATURE_FLAGS_LIST.project.create}>
            <Button
              label="Create new project"
              className="mb-4"
              onClick={handleCreateProjectOpen}
            />
          </PermissionWrapper>
        </div>
        <div className="flex w-full items-center gap-6">
          <div className="max-w-[340px] flex-1">
            <SearchBar
              placeholder="Search by project name, category..."
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
              }}
            />
          </div>
          <div className="max-w-[210px] flex-1">
            <SingleSelectInput
              options={assetOptions}
              placeholder={t('common.asset')}
              value={asset}
              onChange={(e) => {
                setAsset(e as number);
              }}
              isFetching={isFetchingAsset}
            />
          </div>
          <div className="max-w-[210px] flex-1">
            <SingleSelectInput
              options={UTILITY_TYPE}
              placeholder={t('common.utilityType')}
              value={utilityType}
              onChange={(e) => {
                setUtilityType(e as string);
              }}
            />
          </div>
          <div className="ml-auto">
            <SwitchButton
              label={'Show savings in utility type'}
              checked={showSavingsInUnit}
              onChange={() => {
                setShowSavingsInUnit(!showSavingsInUnit);
              }}
            />
          </div>
        </div>
      </div>
      <ProjectsTable
        columnVisibility={columnVisibility}
        query={debouncedQuery}
        assetFilter={asset}
        utilityTypeFilter={utilityType}
      />
      <PermissionWrapper validate={FEATURE_FLAGS_LIST.project.create}>
        <CreateProjectModal
          isOpen={isCreateProjectOpen}
          handleClose={handleCreateProjectClose}
        />
      </PermissionWrapper>
    </div>
  );
};
export default ProjectsPage;
