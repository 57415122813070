import { SelectInput } from '@components/inputs/SelectInput';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { StatusChip } from '@components/statusChip';
import { type StatusEnum } from 'enums/StatusEnum';
import { type FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectStatusProps {
  onChange?: (value: string | number | undefined) => void;
  value?: string | number | undefined;
  statusList?: string[];
  disabled?: boolean;
}

const SelectStatus: FC<SelectStatusProps> = ({
  onChange,
  value,
  statusList,
  disabled,
}) => {
  const [parsedStatus, setParsedStatus] = useState<DropdownOptions[] | []>([]);
  const { t } = useTranslation();

  useMemo(() => {
    if (!statusList) {
      setParsedStatus([]);
      return;
    }

    const parsedArray = statusList.map((statusValue) => ({
      value: statusValue,
      name: statusValue,
    }));

    setParsedStatus(parsedArray);
  }, [statusList]);

  return (
    <SelectInput
      options={parsedStatus ?? []}
      onChange={onChange}
      value={value}
      componentRender={(value: string | number | undefined) =>
        value && <StatusChip status={value as StatusEnum} />
      }
      placeholder={t('common.placeholders.selectStatus')}
      data-testid="select-status"
      disabled={disabled}
    />
  );
};
export default SelectStatus;
