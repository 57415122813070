import { t } from 'i18next';
import { type Change } from 'interfaces/change/ChangeData.interface';
import { CardContainer, CardTitle } from 'layout/Card';
import { type FC } from 'react';
import {
  calculateImpactPerDayUnit,
  calculateImpactTotalUnit,
} from 'utils/chart/chartUtils';
import { getReadableUnitName } from 'utils/formatters/units/getReadableUnitName';
import { ImpactWrapper } from '@components/wrappers/ImpactWrapper';

interface ImpactContainerProps {
  data?: Change;
  isLoading?: boolean;
}

const ImpactContainer: FC<ImpactContainerProps> = ({
  data,
  isLoading = false,
}) => {
  return (
    <div>
      <CardContainer>
        <CardTitle
          title={t('anomaliesDetailsPage.impact.title')}
          style={{ fontSize: 14 }}
          isLoading={isLoading}
        />
        <div className="flex justify-between mt-4">
          <ImpactWrapper
            label={'Impact per day'}
            value={data?.impact_average_diff_euro}
            direction="column"
            endAddorment={'€'}
            isLoading={isLoading}
            containerClassName={'phone:max-w-[320px] desktop-l:max-w-[360px]'}
            data-testid="impact-per-day-euro"
            tooltip="The daily cost impact of the detected change. How much more or less energy do you consume daily since this change occurred?"
          />
          <ImpactWrapper
            label={t('anomaliesDetailsPage.impact.totalImpact')}
            value={data?.impact_total_euro}
            endAddorment={'€'}
            direction="column"
            isLoading={isLoading}
            containerClassName={'phone:max-w-[274px] desktop-l:max-w-[320px]'}
            data-testid="impact-total-euro"
            tooltip=" The total cost impact of the detected change since the moment of change. How much more or less energy have you consumed since this change occurred?"
          />
          <ImpactWrapper
            label={t('anomaliesDetailsPage.impact.impactPerDay')}
            value={calculateImpactPerDayUnit(data?.impact_average_diff ?? 0)}
            endAddorment={getReadableUnitName(
              data?.meter_unit.unit_type ?? 'undefined'
            )}
            direction="column"
            isLoading={isLoading}
            containerClassName={'phone:max-w-[274px] desktop-l:max-w-[320px]'}
            data-testid="impact-per-day-unit"
            tooltip="The daily impact of the detected change. How much more or less energy do you consume daily since this change occurred?"
          />
          <ImpactWrapper
            label={t('anomaliesDetailsPage.impact.totalImpact')}
            value={calculateImpactTotalUnit(
              calculateImpactPerDayUnit(data?.impact_average_diff ?? 0),
              data?.impact_total_euro,
              data?.impact_average_diff_euro
            )}
            endAddorment={getReadableUnitName(
              data?.meter_unit.unit_type ?? 'undefined'
            )}
            direction="column"
            isLoading={isLoading}
            containerClassName={'phone:max-w-[274px] desktop-l:max-w-[320px]'}
            data-testid="impact-total-unit"
            tooltip="The total impact of the detected change since the moment of change. How much more or less energy have you consumed since this change occurred?"
            isMissingData={
              !(
                data?.impact_average_diff &&
                data?.impact_total_euro &&
                data?.impact_average_diff_euro
              )
            }
          />
        </div>
      </CardContainer>
    </div>
  );
};
export default ImpactContainer;
