import { ConfirmModal } from '@components/confirmModal';
import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import { useDeleteMeter, useListMeters } from 'api/assets/assetsServices';
import useDisclosure from 'hooks/useDisclosure';
import { type Meter } from 'interfaces/assets/Meters.interface';
import { type FC, type ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useGetMetersTableColumn from '../hooks/useGetMetersTableColumns';
import { AddToGroupModal } from '../modal/AddToGroupModal';
import { EditMeterModal } from '../modal/EditMeterModal';
import { LinkAssetModal } from '../modal/LinkAssetModal';
import { MeterDetailsModal } from '../modal/MeterDetailsModal';

interface MetersTableProps {
  query?: string;
  filters?: {
    utilityType?: string;
    companyId?: number;
  };
}

const MetersTable: FC<MetersTableProps> = ({ query, filters }) => {
  const { t } = useTranslation();
  const [meterId, setMeterId] = useState<number>();

  const { data, isFetching, fetchNextPage, isFetchingNextPage } = useListMeters(
    {
      query,
      utilityType: filters?.utilityType,
      companyId: filters?.companyId,
    }
  );

  const { mutate, isPending: isLoadingDeleteMeter } = useDeleteMeter();

  const totalDBRowCount = data?.pages?.[0]?.total ?? 0;

  const {
    isOpen: isDetailsOpen,
    handleClose: handleDetailsClose,
    handleOpen: handleDetailsOpen,
  } = useDisclosure();

  const {
    isOpen: isLinkAssetOpen,
    handleClose: handleLinkAssetClose,
    handleOpen: handleLinkAssetOpen,
  } = useDisclosure();

  const {
    isOpen: isAddToGroupOpen,
    handleClose: handleAddToGroupClose,
    handleOpen: handleAddToGroupOpen,
  } = useDisclosure();

  const {
    isOpen: isDeleteMeterOpen,
    handleClose: handleDeleteMeterClose,
    handleOpen: handleDeleteMeterOpen,
  } = useDisclosure();

  const {
    isOpen: isEditMeterOpen,
    handleClose: handleEditMeterClose,
    handleOpen: handleEditMeterOpen,
  } = useDisclosure();

  const flatData = useMemo(
    () => data?.pages?.flatMap((page) => page?.meters ?? []) ?? [],
    [data]
  );

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.id;
    const actions = [
      {
        name: t('common.actions.details'),
        onClick: () => {
          setMeterId(id);
          handleDetailsOpen();
        },
      },
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setMeterId(id);
          handleEditMeterOpen();
        },
      },
      {
        name: t('common.actions.addToGroup'),
        onClick: () => {
          setMeterId(id);
          handleAddToGroupOpen();
        },
      },
      {
        name: t('common.actions.linkToAsset'),
        onClick: () => {
          setMeterId(id);
          handleLinkAssetOpen();
        },
      },
      {
        name: t('common.actions.delete'),
        onClick: () => {
          setMeterId(id);
          handleDeleteMeterOpen();
        },
      },
    ];
    return (
      <TableActions id={id.toString()} key={`action-${id}`} actions={actions} />
    );
  };

  const handleRowClick = (meter?: Meter): void => {
    if (meter) {
      setMeterId(meter.id);
      handleDetailsOpen();
    }
  };

  const handleDeleteMeter = (): void => {
    if (meterId) {
      mutate(meterId);
    }
  };

  const { columns } = useGetMetersTableColumn({ createTableActions });

  return (
    <>
      <Table
        columns={columns}
        data={flatData}
        enableRowSelection={true}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={totalDBRowCount}
        onClickRow={handleRowClick}
        rowEstimateSize={75}
      />
      <MeterDetailsModal
        meterId={meterId}
        isOpen={isDetailsOpen}
        handleClose={handleDetailsClose}
        handleOpen={handleDetailsOpen}
        setMeterId={setMeterId}
      />
      <LinkAssetModal
        isOpen={isLinkAssetOpen}
        handleClose={handleLinkAssetClose}
        handleOpen={handleLinkAssetOpen}
        meterId={meterId}
      />
      <AddToGroupModal
        isOpen={isAddToGroupOpen}
        handleClose={handleAddToGroupClose}
        handleOpen={handleAddToGroupOpen}
        meterId={meterId}
      />
      <ConfirmModal
        isOpen={isDeleteMeterOpen}
        handleOpen={handleDeleteMeterOpen}
        handleClose={handleDeleteMeterClose}
        onConfirm={handleDeleteMeter}
        contentText={t('common.confirmModal.delete')}
        isLoading={isLoadingDeleteMeter}
      />
      <EditMeterModal
        isOpen={isEditMeterOpen}
        handleClose={handleEditMeterClose}
        handleOpen={handleEditMeterOpen}
        meterId={meterId}
      />
    </>
  );
};
export default MetersTable;
