import NotificationCircleIcon from '@components/icons/MenuIcons/NotificationCircle';
import NotificationsIcon from '@components/icons/MenuIcons/NotificationsIcon';
import {
  useListNotifications,
  useReadAllNotifications,
  useReadNotification,
} from 'api/user/userService';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import useClickOutside from 'hooks/useOutsideClick/useOutsideClick';
import { usePopper } from 'hooks/usePopper';
import { Button } from 'layout/Button';
import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { NotificationItem } from '../NotificationItem';
import useInterval from '@use-it/interval';
import {
  DrawerBody,
  DrawerContainer,
  DrawerFooter,
  DrawerHeader,
} from './styled';
import { t } from 'i18next';
import { usePageHasFocus } from 'hooks/usePageHasFocus';

const POLLING_DELAY = 5 * 60 * 1000;
const NotificationDrawer: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { theme } = useTheme();
  const containerRef = useRef(null);
  const [setRefence, setPopper] = usePopper({
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [5, 20],
        },
      },
    ],
  });

  useClickOutside(containerRef, () => {
    setIsOpen(false);
  });

  const { data, refetch, dataUpdatedAt } = useListNotifications();
  const { mutate: readAllNotifications } = useReadAllNotifications();
  const { mutate: readNotification } = useReadNotification();

  const hasUnreadNotification = useMemo(() => {
    if (data?.notifications) {
      return data.notifications.some((notification) => !notification.read);
    }
  }, [data]);

  const handleNotificationPolling = async (): Promise<void> => {
    const currentTime = Date.now();
    const hasPollingTimePassed = currentTime - dataUpdatedAt > POLLING_DELAY;
    if (hasPollingTimePassed) {
      await refetch();
    }
  };
  const { tabHasFocus } = usePageHasFocus({
    onFocus: handleNotificationPolling,
  });

  const handleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  const fetchData = async (): Promise<void> => {
    await refetch();
  };

  const onClick = (id: number): void => {
    readNotification(id);
  };

  useInterval(() => {
    if (tabHasFocus) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, POLLING_DELAY);

  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchData();
    }
  }, [isOpen]);

  return (
    <div ref={containerRef} data-testid="notification-drawer-button">
      <span
        ref={setRefence}
        onClick={handleOpen}
        className="cursor-pointer relative"
        role={'button'}
        title="notifications"
      >
        <NotificationsIcon />
        {hasUnreadNotification && (
          <span className="absolute top-[2px] right-0">
            <NotificationCircleIcon
              width={9}
              height={9}
              fill={theme.colors.tertiary}
            />
          </span>
        )}
      </span>
      {isOpen && (
        <DrawerContainer ref={setPopper}>
          <DrawerHeader>
            <h3 className="text-lg font-bold leading-6 text-gray-60">
              {t('common.notifications')}
            </h3>
          </DrawerHeader>
          <DrawerBody>
            {data?.notifications?.map((notification) => {
              return (
                <NotificationItem
                  key={notification.id}
                  id={notification.id}
                  read={notification.read}
                  title={notification.text}
                  time={notification.created_at}
                  onClick={onClick}
                />
              );
            })}
          </DrawerBody>
          <DrawerFooter>
            <Button
              label={t('common.buttons.markAllRead')}
              variant="secondary"
              onClick={() => {
                readAllNotifications({});
              }}
            />
            {/* <Button label="View all" /> */}
          </DrawerFooter>
        </DrawerContainer>
      )}
    </div>
  );
};
export default NotificationDrawer;
