import styled from 'styled-components';
import tw from 'twin.macro';

interface StyledButtonProps {
  $variant: 'primary' | 'secondary' | 'gray';
  $size: 'normal' | 'large';
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${tw`rounded-full text-base font-extrabold tracking-wider flex items-center justify-center`}
  ${(props) => props.$size === 'large' && tw`px-4 py-3 h-[48px]`}
  ${(props) => props.$size === 'normal' && tw`px-4 py-2 h-[38px]`}
  ${(props) => props.$variant === 'primary' && tw`text-primary bg-tertiary`}
  ${(props) =>
    props.$variant === 'secondary' && tw`text-primary bg-tertiary-light`}
    ${(props) => props.$variant === 'gray' && tw`text-primary bg-gray-25`}
`;
