import { type FC, type FocusEvent, useState } from 'react';
import { SearchBar } from 'components/inputs/SearchBar';
import { SelectInput } from '@components/inputs/SelectInput';
import { AddUserModal } from '../../components/AddUserModal';
import { Button } from 'layout/Button';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/contexts/AuthContext';
import { FUNCTIONS_FILTER, ROLES } from 'lib/constants';
import useDisclosure from 'hooks/useDisclosure';
import { useDebounce } from 'hooks/useDebounce';
import { UsersTable } from './tables';

const UsersTabContent: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const [roles, setRoles] = useState('');
  const [functions, setFunctions] = useState('');
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 300);

  const {
    isOpen: isAddUserOpen,
    handleOpen: handleAddUserOpen,
    handleClose: handleAddUserClose,
  } = useDisclosure();

  const handleSearchChange = (event: FocusEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
  };

  return (
    <div>
      <div className="absolute top-0 right-0">
        <div className="flex gap-4">
          <Button
            label={t('settingsPage.usersTab.buttons.inviteNewUser')}
            className="shadow-button"
            onClick={handleAddUserOpen}
          />
        </div>
      </div>
      <div className="flex gap-6 mb-7">
        <div className="max-w-[340px] w-full">
          <SearchBar
            placeholder={t('settingsPage.usersTab.placeholder.search')}
            onChange={handleSearchChange}
          />
        </div>
        <div className="w-[208px]">
          <SelectInput
            options={[...FUNCTIONS_FILTER]}
            onChange={(value: string | number | undefined) => {
              setFunctions(value as string);
            }}
            value={functions ?? ''}
            placeholder={t('common.placeholders.function')}
          />
        </div>
        <div className="w-[208px]">
          <SelectInput
            options={[...ROLES]}
            onChange={(value: string | number | undefined) => {
              setRoles(value as string);
            }}
            value={roles ?? ''}
            placeholder={t('common.placeholders.role')}
          />
        </div>
      </div>

      <AddUserModal
        isOpen={isAddUserOpen}
        handleClose={handleAddUserClose}
        handleOpen={handleAddUserOpen}
        companyId={user?.company_id}
      />

      <UsersTable query={debouncedQuery} roles={roles} functions={functions} />
    </div>
  );
};
export default UsersTabContent;
