import { type FC } from 'react';
import { OnboardingWrapper } from '../../../components/auth/OnboardingWrapper';
import { RegisterForm } from './components/RegisterForm';

const RegisterPage: FC = () => {
  return (
    <OnboardingWrapper>
      <RegisterForm />
    </OnboardingWrapper>
  );
};
export default RegisterPage;
