import { FEditableField } from 'components/form/FEditableField';
import { FTextField } from 'components/form/FTextField';
import useHRForm from 'hooks/useHRForm';
import { CardContainer, CardTitle } from 'layout/Card';
import { SelectAddressCountry } from 'pages/Authentication/RegisterPage/components/SelectAddressCountry';
import { type FC, useEffect, useMemo, useState } from 'react';
import { EditFormButton } from '../../components';
import {
  editCompanyControlNames,
  editCompanySchema,
} from '../../TabsContent/CompanyTab/schema';
import { useTranslation } from 'react-i18next';
import { useFetchCompany, useUpdateCompany } from 'api/company/companyService';

const CompanyForm: FC = () => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [address, setAddress] = useState({
    country: '',
    streetNameAndNumber: '',
    cityAndProvince: '',
  });
  const { data: companyData } = useFetchCompany();
  const {
    mutate: updateCompanyMutate,
    isPending,
    isSuccess,
  } = useUpdateCompany();

  const initialValues = useMemo(() => {
    const companyInfo = companyData?.company;
    if (!companyInfo) return;
    return {
      name: companyInfo.name,
      vat_number: companyInfo.vat_number,
      address: {
        country_iso: companyInfo.address.country_iso,
        street: companyInfo.address.street,
        number: companyInfo.address.number,
        zip_code: companyInfo.address.zip_code,
        city: companyInfo.address.city,
        province: companyInfo.address.province,
      },
    };
  }, [companyData]);

  const { methods, Form } = useHRForm({
    schema: editCompanySchema,
    initialValues,
  });

  useEffect(() => {
    methods.reset(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!companyData) return;
    const companyAddress = companyData.company?.address;

    setAddress({
      country: companyAddress?.country_iso?.toLocaleLowerCase(),
      streetNameAndNumber: `${companyAddress?.street}, ${companyAddress?.number}, ${
        companyAddress?.zip_code ?? ''
      }`,
      cityAndProvince: `${companyAddress?.city}${
        companyAddress?.province ? ', ' + companyAddress.province : ''
      }`,
    });
  }, [companyData]);

  const handleIsEditing = (): void => {
    setIsEditing(!isEditing);
  };

  const updateCompany = (): void => {
    const company = methods.getValues();
    updateCompanyMutate(company);
  };

  return (
    <CardContainer>
      <CardTitle
        title={t('settingsPage.companyTab.title')}
        subtitle={t('settingsPage.companyTab.subTitle')}
        isLoading={!companyData}
        skeletonVariant={'subtitleAndButton'}
        endAddorment={
          <EditFormButton
            isEditing={isEditing}
            onCancel={methods.reset}
            handleIsEditing={handleIsEditing}
            onSubmit={async () => {
              await methods.handleSubmit(updateCompany)();
            }}
            isLoading={isPending}
            isSuccess={isSuccess}
          />
        }
      />
      <div className="flex flex-col">
        <Form onSubmit={updateCompany} {...methods}>
          <div className="flex flex-col w-5/12 gap-6 mt-6">
            <FEditableField
              label={t('common.inputs.companyName')}
              name={editCompanyControlNames.companyName}
              isEditing={isEditing}
              isLoading={!companyData}
              value={initialValues?.name}
              fieldComponent={
                <FTextField
                  label={t('common.inputs.companyName')}
                  id={'company-name'}
                  name={editCompanyControlNames.companyName}
                  data-testid="company-name-field"
                />
              }
            />
            <FEditableField
              label={t('common.inputs.vatNumber')}
              name={editCompanyControlNames.vatNumber}
              isEditing={isEditing}
              isLoading={!companyData}
              value={initialValues?.vat_number}
              fieldComponent={
                <FTextField
                  id={'vat-number'}
                  label={t('common.inputs.vatNumber')}
                  name={editCompanyControlNames.vatNumber}
                  data-testid="vat-number-field"
                />
              }
            />
            <FEditableField
              label={t('common.inputs.address')}
              isEditing={isEditing}
              value={address.streetNameAndNumber}
              isLoading={!companyData}
              fieldComponent={
                <div className="flex flex-col gap-6">
                  <div className="flex gap-4">
                    <div className="w-6/12">
                      <SelectAddressCountry
                        defaultCountry={address.country}
                        onChange={(value) => {
                          methods.setValue(
                            editCompanyControlNames.address.countryIso,
                            value
                          );
                        }}
                      />
                    </div>
                    <div className="w-6/12">
                      <FTextField
                        id="zip-code"
                        placeholder={t(
                          'registerPage.stepThree.placeholders.zipCode'
                        )}
                        name={editCompanyControlNames.address.zipCode}
                        data-testid="zip-code-field"
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <FTextField
                      name={editCompanyControlNames.address.street}
                      data-testid="street-field"
                      placeholder={t('common.placeholders.street')}
                      id="street-name"
                    />
                    <FTextField
                      name={editCompanyControlNames.address.number}
                      data-testid="number-field"
                      placeholder={t('common.placeholders.number')}
                      id="street-number"
                    />
                  </div>
                </div>
              }
            />
            <FEditableField
              label={t('common.inputs.cityProvince')}
              isEditing={isEditing}
              value={address.cityAndProvince}
              isLoading={!companyData}
              fieldComponent={
                <div className="flex flex-col gap-6">
                  <div className="flex gap-4">
                    <FTextField
                      name={editCompanyControlNames.address.city}
                      data-testid="city-field"
                      placeholder={t('common.placeholders.city')}
                    />
                    <FTextField
                      name={editCompanyControlNames.address.province}
                      data-testid="province-field"
                      placeholder={t('common.placeholders.province')}
                    />
                  </div>
                </div>
              }
            />
            <FEditableField
              label={t('common.inputs.country')}
              isEditing={isEditing}
              name={editCompanyControlNames.address.countryIso}
              isLoading={!companyData}
              fieldComponent={<></>}
              uppercase={true}
            />
          </div>
        </Form>
      </div>
    </CardContainer>
  );
};
export default CompanyForm;
