import { ConfirmModal } from '@components/confirmModal';
import { ArrowIcon } from '@components/icons/ArrowIcon';
import { RemoveIcon } from '@components/icons/RemoveIcon';
import { useDeleteMeterHierarchy } from 'api/assets/assetsServices';
import useDisclosure from 'hooks/useDisclosure';
import { type FC, useEffect } from 'react';
import { RelatabeItemContainer } from './styled';

interface RelatableMeterItemProps {
  name: string;
  id: number;
  isEditing?: boolean;
  type?: string;
  currentMeterId?: number;
  onClick: (meter: number) => void;
}

const RelatableMeterItem: FC<RelatableMeterItemProps> = ({
  name,
  id,
  isEditing,
  type,
  currentMeterId,
  onClick,
}) => {
  const {
    mutate: deleteHierarchy,
    isSuccess,
    isPending,
  } = useDeleteMeterHierarchy();
  const { isOpen, handleClose, handleOpen } = useDisclosure();

  const handleDelete = (): void => {
    if (currentMeterId) {
      const body = {
        parent_id: type === 'parents' ? id : currentMeterId,
        child_id: type === 'children' ? id : currentMeterId,
      };
      deleteHierarchy(body);
    }
  };

  const handleOnClick = (): void => {
    if (id) {
      onClick(id);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <RelatabeItemContainer onClick={isEditing ? undefined : handleOnClick}>
      {isEditing && (
        <RemoveIcon
          role={'button'}
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            handleOpen();
          }}
        />
      )}
      <span className="inline-block truncate text-ellipsis">{name}</span>
      <ArrowIcon />
      <ConfirmModal
        contentText="Are you sure you want to delete this meter relation?"
        isOpen={isOpen}
        onConfirm={handleDelete}
        handleClose={handleClose}
        isLoading={isPending}
      />
    </RelatabeItemContainer>
  );
};
export default RelatableMeterItem;
