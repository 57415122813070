import { z } from 'zod';

interface ManageLuzmoReportControlNamesType {
  internal_name: 'internal_name';
  luzmo_name: 'luzmo_name';
  is_universal: 'is_universal';
  companies: 'companies';
  filters: 'filters';
}

export const luzmoReportSchema = z.object({
  internal_name: z.string().min(3, 'Required.'),
  luzmo_name: z.array(
    z
      .object({
        name: z.string(),
        value: z.number().or(z.string()),
      })
      .optional()
  ),
  companies: z.array(
    z
      .object({
        name: z.string(),
        value: z.number().or(z.string()),
      })
      .optional()
  ),
  is_universal: z.boolean().default(false),
  filters: z.array(z.string()),
});

export const ManageLuzmoReportControlNames: ManageLuzmoReportControlNamesType =
  {
    internal_name: 'internal_name',
    luzmo_name: 'luzmo_name',
    is_universal: 'is_universal',
    companies: 'companies',
    filters: 'filters',
  };

export type LuzmoReportSchemaType = z.infer<typeof luzmoReportSchema>;
