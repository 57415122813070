import { SwitchButton } from '@components/switchButton';
import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import BuildingsMap from '../BuildingsMap/BuildingsMap';

const BuildingsMapContainer: FC = () => {
  return (
    <CardContainer gap={6}>
      <div className="flex justify-between items-center">
        <h2 className="uppercase text-sm">Buildings</h2>
        <SwitchButton label="Show Buildings Based on Planned Savings." />
      </div>
      <h3 className="text-base">
        Explore the map to assess each building&apos;s energy intensity.
      </h3>
      <BuildingsMap />
    </CardContainer>
  );
};
export default BuildingsMapContainer;
