import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeFieldValue } from 'utils/normalizeFieldValue';

interface UserTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetImpersonateUserColumns = ({
  createTableActions,
}: {
  createTableActions: (id: number) => ReactNode;
}): UserTableColumnsReturnType => {
  const columnHelper = createColumnHelper<any>();
  const { t } = useTranslation();

  const columns = [
    columnHelper.accessor('given_name', {
      header: () => t('common.name'),
      cell: (info) =>
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${info.renderValue()} ${info.row.original?.family_name ?? ''}`,
      enableSorting: true,
      maxSize: 180,
      size: 180,
    }),
    columnHelper.accessor('email', {
      header: () => t('common.inputs.email'),
      cell: (info) => info.renderValue(),
      maxSize: 170,
      size: 170,
    }),
    columnHelper.accessor('company_name', {
      header: () => t('common.companyName'),
      cell: (info) => normalizeFieldValue(info.renderValue()),
    }),
    columnHelper.accessor('main_role', {
      header: () => t('common.inputs.role'),
      cell: (info) => normalizeFieldValue(info.renderValue()),
    }),
    columnHelper.accessor('function', {
      header: () => t('common.function'),
      cell: (info) => normalizeFieldValue(info.renderValue()),
      size: 150,
    }),
    columnHelper.accessor('', {
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info.row.original.id),
      size: 150,
    }),
  ];

  return {
    columns,
  };
};

export default useGetImpersonateUserColumns;
