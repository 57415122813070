import { IconTable } from '@components/table/IconTable';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type ProjectData } from 'interfaces/projects/ProjectData.interface';
import { useGetDashboardProjectsTableColumns } from 'pages/DashboardPage/hooks/useGetDashboardProjectTable';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface NeedAttentionProjectsTableProps {
  data?: ProjectData[];
  isFetching?: boolean;
  hoveredId?: number;
  onRowHover: (value: number | undefined) => void;
}

const NeedAttentionProjectsTable: FC<NeedAttentionProjectsTableProps> = ({
  data,
  isFetching = false,
  hoveredId,
  onRowHover,
}) => {
  const navigate = useNavigate();
  const { columns } = useGetDashboardProjectsTableColumns();
  const { theme } = useTheme();

  const handleOnClickRow = (project: ProjectData): void => {
    if (project.id) {
      navigate(`/app/management/projects/details/${project.id}`);
    }
  };

  return (
    <div className="flex flex-col gap-4" data-testid="attention-table">
      <h3 className="font-bold">Need attention</h3>

      <IconTable<ProjectData>
        columns={columns}
        data={data ?? []}
        isFetching={isFetching}
        enableLastColumnClick={true}
        disableHeader={true}
        stripped={true}
        minHeight={150}
        onClickRow={handleOnClickRow}
        onRowHover={onRowHover}
        hoverColor={theme.colors['light-blue']}
        hoveredId={hoveredId}
      />
    </div>
  );
};
export default NeedAttentionProjectsTable;
