import { CardContainer } from 'layout/Card';
import { type FC } from 'react';
import EnergyEvolutionChart from '../../charts/EnergyEvolutionChart/EnergyEvolutionChart';

const EnergyEvolutionSection: FC = () => {
  return (
    <CardContainer gap={6}>
      <h2 className="uppercase text-sm">ENERGY INTENSITY EVOLUTION</h2>
      <EnergyEvolutionChart
        data={[
          { value: 312_000, year: '2023', secondary: 5 },
          { value: 312_000, year: '2024', secondary: 5 },
        ]}
        futureData={[
          { value: 310_000, year: '2025', secondary: 1 },
          { value: 308_200, year: '2026', secondary: 1 },
          { value: 306_800, year: '2027', secondary: 1 },
          { value: 305_300, year: '2028', secondary: 1 },
          { value: 303_500, year: '2029', secondary: 1 },
        ]}
        targetData={[
          { value: 312_000, year: '2024', secondary: 1 },
          { value: 309_000, year: '2025', secondary: 1 },
          { value: 307_000, year: '2026', secondary: 1 },
          { value: 305_500, year: '2027', secondary: 1 },
          { value: 304_000, year: '2028', secondary: 1 },
          { value: 303_000, year: '2029', secondary: 1 },
          { value: 302_000, year: '2030', secondary: 1 },
        ]}
      />
    </CardContainer>
  );
};
export default EnergyEvolutionSection;
