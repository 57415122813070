import { CardContainer, CardTitle } from 'layout/Card';
import { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AddAlertsForm } from '../../forms/AddAlertsForm';
import { type DropdownOptions } from '@components/inputs/SelectInput/MultiSelectInput/MultiSelectInput';
import { useAlertDefinitionsList } from 'api/alerts/alertsServices';
import AlertDefinitionList from '../AlertDefinitionList/AlertDefinitionList';

interface AlertDefinitionsProps {
  data?: any;
  userOptions: DropdownOptions[];
}

const AlertDefinitions: FC<AlertDefinitionsProps> = ({ userOptions }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useAlertDefinitionsList({});

  const flatData = useMemo(
    () =>
      data?.pages?.flatMap((page: any) => page?.customEventDefinitions ?? []) ??
      [],
    [data]
  );

  const getRuleKeyName = (alertDefinition: any): string => {
    return Object.keys(alertDefinition.condition.rules[0])[0];
  };

  const alertDefinitions = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/naming-convention
      flatData.map(({ company_id, ...alertDefinition }: any) => ({
        ...alertDefinition,
        rule: getRuleKeyName(alertDefinition), // add an attribute that represent the rule key
        users: alertDefinition.users.map(({ id }: { id: string }) => ({
          id, // remove email and username from users otherwise backend does not accept the request
        })),
      })),
    [flatData]
  );
  return (
    <div>
      <CardContainer>
        <CardTitle
          title={t('alertsPage.settingsTab.alertDefinitions.title')}
          style={{ fontSize: 14 }}
        />
        <AddAlertsForm userOptions={userOptions} />
        <AlertDefinitionList
          userOptions={userOptions}
          alertDefinitions={alertDefinitions}
          isLoading={isLoading}
        />
      </CardContainer>
    </div>
  );
};

export default AlertDefinitions;
