import { MultiTab } from '@components/multiTab';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupsListPage } from './Groups';
import { MetersListPage } from './Meters';
import AssetsListPage from './Assets/AssetsListPage';
import { Mixpanel } from 'utils/mixpanel/mixpanel_agent';

const AssetsPage: FC = () => {
  const { t } = useTranslation();
  Mixpanel.pageview();
  const tabs = [
    {
      title: t('common.meters'),
      component: <MetersListPage />,
    },
    {
      title: t('common.assets'),
      component: <AssetsListPage />,
    },
    {
      title: t('common.groups'),
      component: <GroupsListPage />,
    },
  ];

  return (
    <div>
      <MultiTab tabs={tabs} />
    </div>
  );
};
export default AssetsPage;
